import React from 'react';
import { createPortal } from 'react-dom';
import { ToastContainer } from 'react-toastify';

const ToastBodyContainer = () =>
    createPortal(
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            style={{ zIndex: 999999999999 }}
        />,
        document.body,
    );

export default ToastBodyContainer;
