import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations } from 'src/redux/services/common/Common.slice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import PageHeader from 'src/components/PageHeader';
import SearchBar from 'src/components/SearchBar/SearchBar';
import CustomButton from 'src/components/CustomButton';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import StaffLocation from './StaffLocation';
import AdminLocation from './AdminLocation';
import { IShopLocation } from 'src/redux/services/common/Common.interface';
import Badge from 'src/components/Badge';
import { useRolePermission } from 'src/hooks/useRolePermission';

const Location = () => {
    const { t } = useTranslation();
    const { hasPermission, isStaff } = useRolePermission();
    const navigate = useNavigate();
    const shopLocations: IShopLocation[] = useAppSelector(allShopLocations);
    const [locations, setLocations] = useState<IShopLocation[]>([]);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        const list: IShopLocation[] = shopLocations.filter((location: IShopLocation) => {
            const name = location.name.toLowerCase();
            const searchParam = searchValue.toLowerCase();
            return name.includes(searchParam);
        });
        setLocations(list);
    }, [searchValue, shopLocations]);

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    const handleClick = () => {
        navigate(ROUTES.LOCATION.CREATE);
    };

    return (
        <div className="inner-page-wrape ">
            <div className="flex-1 flex flex-col mb-4">
                <PageHeader title={t('Locations')} subtitle="Manage and keep track of all your brand branches and locations in one place."></PageHeader>
                <div className="side-spaching flex flex-1 flex-col w-full">
                    <div className="w-full border rounded-xl flex flex-col flex-1 shadow">
                        <div className=" w-full flex flex-col items-center border-b border-gray-200">
                            <div className="flex items-center px-5 py-3 justify-between w-full gap-4">
                                <div className="flex flex-col flex-1">
                                    <div className="flex justify-start items-center gap-1.5">
                                        <h2 className="table-title">{t('All locations')}</h2>
                                        <Badge icon>
                                            {locations.length} {t('Locations')}
                                        </Badge>
                                    </div>
                                    <p className="table-subtitle">{t('Add, view and manage your different brand locations.')}</p>
                                </div>
                                <div className="flex gap-3">
                                    {hasPermission('location_add') && (
                                        <CustomButton outlinePrimary onClick={handleClick} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                                            {t('Add new location')}
                                        </CustomButton>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center px-5 py-3 justify-between w-full border-gray-200 border-b">
                            <div className="table-searchInput">
                                <SearchBar placeholder={t('Search')} className="form-control-md" onChange={handleSearch} />
                            </div>
                            <div className="flex flex-row gap-2">
                                {/* <CustomButton primary icon={<Grid01 width="16" height="16" />} className="w-[36px] !px-2  py-2 rounded-lg"></CustomButton>
                                <CustomButton outlineSecondary className="w-[36px] !px-2  py-2 rounded-lg">
                                    <List className="w-4 h-4 !text-black" />
                                </CustomButton> */}
                                {/* <div className="w-[200px]">
                                    <SelectBox noOptionsMessage="No Location found" placeholder={t('All locations')} onChangeFunc={() => {}} classComp="outline-select-box" isClearable={false} />
                                </div> */}
                            </div>
                        </div>
                        {isStaff ? (
                            <StaffLocation loading={false} locations={locations} searchValue={searchValue} />
                        ) : (
                            <AdminLocation loading={false} locations={locations} searchValue={searchValue} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Location;
