import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import Switch from 'src/components/Switch/Switch';
import { AppearanceHero, InstagramColor } from 'src/theme/Images';
import { Collapse } from '../Collapse';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { HeroProps, IFormData } from './Hero.interface';
import TemplateLayout from '../Layout/Layout';
import { currentLanguage, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import { checkFileTypeValidation, convertBase64ToFile } from 'src/utils/global-functions';
import { s3Upload } from 'src/utils/s3Operations';
import { s3Path } from 'src/constants/s3Path';
import CropperModal from 'src/components/CropperModal/CropperModal';
import CustomButton from 'src/components/CustomButton';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import ImageUpload from 'src/components/ImageUpload';
import { FILE_VALIDATION } from 'src/utils/global-variables';
import { useLanguage } from 'src/hooks/useCommon';
import { CHARACTERS_LIMIT } from 'src/constants/common';
import RadioSwitch from 'src/components/RadioSwitch';

const Hero: FC<HeroProps> = (props) => {
    const { section, template } = props;
    const { t } = useTranslation();
    const { languageOptions, languages, handleLanguage, handleErrorsAndSetLanguage } = useLanguage();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    const [image, setImage] = useState<any>('');
    const [fieldName, setFieldName] = useState<keyof IFormData | null>(null);
    const language = useAppSelector(currentLanguage);

    const schemaFields: any = {
        status: Yup.bool().required('This field is required.'),
        is_title: Yup.bool().required('This field is required.'),
        is_subtitle: Yup.bool().required('This field is required.'),
        is_button: Yup.bool().required('This field is required.'),
        is_instgram_button: Yup.bool().required('This field is required.'),
        instgram_button: Yup.string()
            .nullable()
            .when('is_instgram_button', ([is_instgram_button], customSchema) => (is_instgram_button ? customSchema.required('This field is required.') : customSchema.nullable())),
        is_reviews: Yup.bool().required('This field is required.'),
        reviews: Yup.string()
            .nullable()
            .when('is_reviews', ([is_reviews], customSchema) => (is_reviews ? customSchema.required('This field is required.') : customSchema.nullable())),
        is_background: Yup.bool().required(t('This field is required')),
        background: Yup.object()
            .nullable()
            .shape({
                name: Yup.string().when('is_background', ([is_background], customSchema) => (is_background ? customSchema.required(t('This field is required')) : customSchema.nullable())),
                file: Yup.string().nullable(),
                url: Yup.string().when('is_background', ([is_background], customSchema) => (is_background ? customSchema.required(t('This field is required')) : customSchema.nullable())),
            })
            .test('required', t('This field is required'), function (value) {
                const { is_background: isImage } = this.parent;
                if (!isImage) return true; // If is_image is false, no need to validate
                if (!value) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t('This field is required'),
                    });
                }
                const { name, url } = value;
                if (!name || !url) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t('This field is required'),
                    });
                }
                return true;
            }),
    };
    languages.forEach((name: string) => {
        schemaFields[`${name}_title`] = Yup.string().when('is_title', ([is_title], customSchema) =>
            is_title ? customSchema.required(t('This field is required.')).max(CHARACTERS_LIMIT.SHORT, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.SHORT })) : customSchema.nullable(),
        );
        schemaFields[`${name}_subtitle`] = Yup.string().when('is_subtitle', ([is_subtitle], customSchema) =>
            is_subtitle ? customSchema.required('This field is required.').max(CHARACTERS_LIMIT.MEDIUM, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.MEDIUM })) : customSchema.nullable(),
        );
        schemaFields[`${name}_button`] = Yup.string().when('is_button', ([is_button], customSchema) =>
            is_button ? customSchema.required('This field is required.').max(CHARACTERS_LIMIT.SHORT, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.SHORT })) : customSchema.nullable(),
        );
        schemaFields[`${name}_reviews_description`] = Yup.string()
            .nullable()
            .when('is_reviews', ([is_reviews], customSchema) => (is_reviews ? customSchema.required('This field is required.') : customSchema.nullable()));
    });
    const schema = Yup.object(schemaFields);

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as unknown as Resolver<IFormData>,
    });
    const handleError = (err: any) => handleErrorsAndSetLanguage(err);

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        clearErrors,
        reset,
        formState: { errors },
    } = methods;
    const isReviews = watch('is_reviews');
    const isStatus = watch('status');
    const isBackground = watch('is_background');
    const backgroundData = watch('background');

    useEffect(() => {
        let existingData: IFormData = {
            status: false,
            is_title: false,
            is_subtitle: false,
            is_button: false,
            is_instgram_button: false,
            instgram_button: shop.instagram_page,
            is_reviews: false,
            reviews: '',
            is_background: false,
            background: null,
        };
        languages.forEach((locale) => {
            existingData[`${locale}_title`] = '';
            existingData[`${locale}_subtitle`] = '';
            existingData[`${locale}_button`] = '';
            existingData[`${locale}_reviews_description`] = '';
        });
        if (section) {
            existingData = {
                status: section.status || false,
                is_title: section.is_title || false,
                is_subtitle: section.is_subtitle || false,
                is_button: section.is_button || false,
                is_instgram_button: section.is_instgram_button || false,
                instgram_button: section.instgram_button || '',
                is_reviews: section.is_reviews || false,
                reviews: section.reviews || '',
                is_background: template === 'template_2' ? section.is_background : false,
                background:
                    template === 'template_2' && section.background
                        ? {
                              name: section.background,
                              url: section.background && section.background_url,
                              file: null,
                          }
                        : null,
            };
            languages.forEach((locale) => {
                existingData[`${locale}_title`] = section[`${locale}_title`] || '';
                existingData[`${locale}_subtitle`] = section[`${locale}_subtitle`] || '';
                existingData[`${locale}_button`] = section[`${locale}_button`] || '';
                existingData[`${locale}_reviews_description`] = section[`${locale}_reviews_description`] || '';
            });
        }
        Object.entries(existingData).forEach(([key, value]) => {
            setValue(key as keyof IFormData, value);
        });
        reset(existingData);
    }, [section, template]);

    const handleSwitchOnChange = (name: keyof IFormData) => (event: any) => {
        setValue(name, event.value);
    };
    // const handleReviewsChange = (event: any) => {
    //     setValue('reviews', event ? event.value : null);
    // };
    const handleChange = async (data: any) => {
        setIsLoading(true);
        let imageStatus = errorCode.updateSuccess;
        if (backgroundData?.file && backgroundData?.name) {
            const logoImageResponse: any = await s3Upload(backgroundData?.file, `${s3Path.SHOP_HERO_BACKGROUND}${backgroundData?.name}`);
            imageStatus = logoImageResponse?.status;
        }

        if (imageStatus === errorCode.updateSuccess) {
            submitForm(data);
        }
    };
    const submitForm = (data: IFormData) => {
        const payload = {
            hero: { ...data, background: data?.background?.name },
            section: 'hero',
        };

        axiosPost(API.THEME.TEMPLATE.SECTION, payload, { shop_id: shop.id })
            .then(async () => {
                await dispatch(getAllShopSettings({ shop_id: shop.id }));
            })
            .catch((error) => {
                if (error.response.data.status === errorCode.unprocessable) {
                    if (error.response.data.data) {
                        Object.keys(error.response.data.data).forEach((field) => {
                            const fieldKey = field.replace('hero.', '');
                            setError(fieldKey as keyof IFormData, {
                                type: 'manual',
                                message: error.response.data.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };
    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };

    const onChangeCrop = (type: keyof IFormData) => async (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const imageValidation = {
            files: files,
            ...FILE_VALIDATION.APPEARANCE.HERO,
        };
        const { result, message } = await checkFileTypeValidation(imageValidation);
        if (!result) {
            setError(type, {
                type: 'manual',
                message: message,
            });
            return;
        } else {
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setIsUpload(false);
            };
            clearErrors(type);

            reader.onloadend = () => {
                setImage(reader.result as any);
                setIsUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            setFieldName(type);
        }
    };
    const handleCropData = (data: any) => {
        const convertedFile = convertBase64ToFile(data);
        if (convertedFile && convertedFile.filename && fieldName) {
            setValue(fieldName, {
                file: convertedFile.convertedFile as File,
                url: data,
                name: convertedFile.filename,
            });
        }
    };
    // const handleRemoveImage = () => {
    //     setValue('background', null);
    // };

    useFormErrorFocus<IFormData>({ errors, formSectionName: 'home' });

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleChange, handleError)} className="rounded-xl border border-borderPrimary">
                <Collapse
                    title={t('Hero')}
                    description={t('The Hero section is the prominent top section of the website, featuring a key image or video and headline to engage visitors immediately.')}
                    isCollapsed={isStatus}
                    isLoading={isLoading}
                    handleStatus={handleStatus}
                    handleSave={handleSubmit(handleChange, handleError)}
                >
                    {languages.length > 1 && (
                        <div className="flex justify-end mt-5 mx-5">
                            <RadioSwitch options={languageOptions} name="hero_language" value={language} onChange={handleLanguage} />
                        </div>
                    )}
                    <TemplateLayout ImgProp={AppearanceHero} key={language}>
                        <>
                            <div className="flex justify-between mb-4 gap-4 items-start">
                                <Controller
                                    name={`${language}_title`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id={`${language}_title`}
                                                name={`${language}_title`}
                                                label={t('Title')}
                                                placeholder={t('Enter hero title')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name="is_title"
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name={`${language}_subtitle`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id={`${language}_subtitle`}
                                                name={`${language}_subtitle`}
                                                label={t('Subtitle')}
                                                placeholder={t('Enter hero subtitle')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name="is_subtitle"
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex gap-4 mb-4">
                                <div className="w-1/3">
                                    <Controller
                                        name={`${language}_button`}
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    label={t('Book Button Title')}
                                                    placeholder={t('Enter Button Title')}
                                                    id={`${language}_button`}
                                                    name={`${language}_button`}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                    toggle={
                                                        <Controller
                                                            name="is_button"
                                                            control={control}
                                                            render={({ field, fieldState }: any) => (
                                                                <>
                                                                    <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                    {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                                </>
                                                            )}
                                                        />
                                                    }
                                                />
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="w-2/3">
                                    <Controller
                                        name="instgram_button"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    label={t('Instagram Profile URL')}
                                                    placeholder={t('Enter Instagram Username')}
                                                    id="home-instgram_button"
                                                    onChange={onChange}
                                                    labelIcon={
                                                        <div className="flex flex-row gap-1.5 items-center">
                                                            <InstagramColor />
                                                            <p className="text-xs font-medium text-mainTextColor">instagram.com/</p>
                                                        </div>
                                                    }
                                                    value={value}
                                                    required
                                                    error={!!error}
                                                    name="instgram_button"
                                                    labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                                    inputControlClassName="pl-3"
                                                    toggle={
                                                        <Controller
                                                            name="is_instgram_button"
                                                            control={control}
                                                            render={({ field, fieldState }: any) => (
                                                                <>
                                                                    <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                    {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                                </>
                                                            )}
                                                        />
                                                    }
                                                />
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="flex gap-4 mb-4">
                                    <div className="w-1/3">
                                        <div className="flex items-center gap-[10px] mb-[6px]">
                                            <label htmlFor="" className="fl-field-title-label mb-0">
                                                {t('Reviews')}
                                            </label>
                                            <Switch className="" onChange={handleSwitchOnChange('is_reviews')} isChecked={isReviews} />
                                        </div>
                                        <Controller
                                            name="reviews"
                                            control={control}
                                            render={({ field: { value, onChange } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel type="number" min={0} max={5} placeholder={t('Enter Stars')} onChange={onChange} value={value} name="reviews" id="home-reviews" />
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="w-2/3">
                                        <Controller
                                            name={`${language}_reviews_description`}
                                            control={control}
                                            render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                                <div className="w-full items-bottom">
                                                    <InputWithLabel
                                                        id={`${language}_reviews_description`}
                                                        name={`${language}_reviews_description`}
                                                        placeholder={t('Total Google reviews')}
                                                        label={t('Total Google reviews')}
                                                        onChange={onChange}
                                                        value={value}
                                                        error={!!error}
                                                    />
                                                    {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            {template === 'template_2' && (
                                <div className="flex gap-4 mb-4">
                                    <Controller
                                        name={`background`}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div id="home-background" className="w-full">
                                                <div className="flex items-center gap-[10px] mb-[6px]" color="gray">
                                                    <label className="fl-field-title-label mb-0">{t('Background content')}</label>
                                                    <Switch className="" onChange={handleSwitchOnChange('is_background')} isChecked={isBackground} />
                                                </div>
                                                <ImageUpload cropData={value?.url} onChangeCrop={onChangeCrop('background')} id="background" shape="square" error={error} />
                                            </div>
                                        )}
                                    />
                                </div>
                            )}
                        </>
                    </TemplateLayout>
                    {isUpload && fieldName && (
                        <CropperModal
                            imageUrl={image}
                            setUpload={setIsUpload}
                            setCropData={handleCropData}
                            defaultCropType="Square"
                            title={t('Background Image')}
                            description={t('Upload a  max 800Kb image for best results.')}
                            isCropType={false}
                            btnTitle={t('Confirm')}
                        />
                    )}
                    <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end">
                        <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </Collapse>
            </form>
        </FormProvider>
    );
};

export default Hero;
