import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowNarrowRight, Check, InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomButton from 'src/components/CustomButton';
import InputWithLabel from 'src/components/InputWithLabel';
import InputWithToggle from 'src/components/InputWithToggle';
import Switch from 'src/components/Switch/Switch';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { ROUTES } from 'src/constants/routes';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { allShopSettings, currentShop, me, userMe } from 'src/redux/services/common/Common.slice';
import { axiosGet, axiosPost } from 'src/utils/requestClient';
import * as Yup from 'yup';
import { IPaymentSettings } from './Payment.interface';
import moment from 'moment';
import { useRolePermission } from 'src/hooks/useRolePermission';

const Payment = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const { isAdmin, isRent } = useRolePermission();
    const user = useAppSelector(userMe);
    const stripeStep = user.shop_staff[0].account_step.stripe_account;
    const shopSettings = useAppSelector(allShopSettings);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [toggle, setToggle] = useState({
        cancellation: '$',
        noshow: '$',
    });
    const [sripeDetails, setSripeDetails] = useState<any>(null);
    const [policy, setPolicy] = useState<any>(null);
    const [isSaveEnabled, setSaveEnabled] = useState(false);
    const [isFormEnabled, setIsFormEnabled] = useState(false);

    useEffect(() => {
        setIsFormEnabled(isAdmin || isRent);
    }, []);

    useEffect(() => {
        const noShow = shopSettings.find((setting: any) => setting.type === 'no_show');
        const cancellation = shopSettings.find((setting: any) => setting.type === 'cancellation');
        if (noShow && cancellation) {
            const data = {
                ...noShow.value,
                ...cancellation.value,
                is_no_show: noShow.status,
                is_cancellation: cancellation.status,
            };
            setPolicy(data);
            extractPaymentData(data);
        } else {
            resetForm();
        }
    }, [shopSettings]);

    useEffect(() => {
        axiosGet(API.STRIPE.ACCOUNT_DETAILS)
            .then((response) => {
                setSripeDetails(response.data.data);
            })
            .finally(() => setIsLoading(false));
    }, []);

    const extractPaymentData = (policyDetailsValue: any) => {
        const existingData: IPaymentSettings = {
            is_no_show: policyDetailsValue.is_no_show,
            no_show_charge: Number(policyDetailsValue.no_show_charge),
            is_cancellation: policyDetailsValue.is_cancellation,
            cancellation_charge: Number(policyDetailsValue.cancellation_charge),
            cancellation_window: Number(policyDetailsValue.cancellation_window),
        };
        setToggle((prev: any) => ({
            ...prev,
            noshow: policyDetailsValue.no_show_type === 'amount' ? '$' : '%',
            cancellation: policyDetailsValue.cancellation_type === 'amount' ? '$' : '%',
        }));
        Object.keys(existingData).forEach((key) => {
            setValue(key as keyof IPaymentSettings, existingData[key as keyof IPaymentSettings]);
        });
        reset(existingData);
    };

    const schema = Yup.object().shape({
        is_no_show: Yup.boolean().required(),
        no_show_charge: Yup.number().when('is_no_show', {
            is: true,
            then: (sch) =>
                sch
                    .required('No show charge is required')
                    .min(0, 'No show charge must be 0 or greater')
                    .test('is-valid', 'No show charge must be between 0 to 100 and positive', function (value) {
                        const { is_no_show: isNoShow } = this.parent;
                        if (isNoShow && toggle.cancellation === '%') {
                            return value >= 0 && value <= 100;
                        }
                        return true;
                    }),
            otherwise: (sch) => sch.notRequired(),
        }),
        is_cancellation: Yup.boolean().required(),
        cancellation_charge: Yup.number().when('is_cancellation', {
            is: true,
            then: (sch) =>
                sch
                    .required('Cancellation charge is required')
                    .min(0, 'No show charge must be 0 or greater')
                    .test('is-valid', 'Cancellation charge must be between 0 to 100 and positive', function (value) {
                        const { is_cancellation: isCancellation } = this.parent;
                        if (isCancellation && toggle.cancellation === '%') {
                            return value >= 0 && value <= 100;
                        }
                        return true;
                    }),
            otherwise: (sch) => sch.notRequired(),
        }),
        cancellation_window: Yup.number().when('is_cancellation', {
            is: true,
            then: (sch) =>
                sch
                    .required('Cancellation window is required')
                    .min(0, 'No show charge must be 0 or greater')
                    .test('is-valid', 'Cancellation window must be between 0 to 100 and positive', function (value) {
                        const { is_cancellation: isCancellation } = this.parent;
                        if (isCancellation && toggle.cancellation === '%') {
                            return value >= 0 && value <= 100;
                        }
                        return true;
                    }),
            otherwise: (sch) => sch.notRequired(),
        }),
    });

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        reset,
        watch,
        formState: { isDirty },
    } = useForm<IPaymentSettings>({
        resolver: yupResolver(schema),
        defaultValues: {
            is_no_show: false,
            no_show_charge: 0,
            is_cancellation: false,
            cancellation_charge: 0,
            cancellation_window: 0,
        },
    });

    const watchAllFields = watch();

    useEffect(() => {
        setSaveEnabled(isDirty);
    }, [watchAllFields, isDirty]);

    const handleInputChange = (fieldName: string, value: string) => {
        setToggle((old) => ({ ...old, ...{ [fieldName]: value } }));
    };

    const handleSave = (data: IPaymentSettings) => {
        setIsLoading(true);
        const payload = {
            ...data,
            no_show_type: toggle.noshow === '%' ? 'percentage' : 'amount',
            cancellation_type: toggle.cancellation === '%' ? 'percentage' : 'amount',
        };
        axiosPost(API.SETTING.POLICY, payload, { shop_id: shop.id })
            .then(async (response) => {
                await dispatch(me());
            })
            .catch((error) => {
                if (error.data.status === errorCode.unprocessable) {
                    if (error.data.data) {
                        Object.keys(error.data.data).forEach((field) => {
                            setError(field as keyof IPaymentSettings, {
                                type: 'manual',
                                message: error.data.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };
    const disconnectStripe = async () => {
        setIsLoading(true);
        await axiosGet(API.STRIPE.DISCONNECT, { shop_id: shop.id }, {})
            .then(async (response) => {
                await dispatch(me());
            })
            .catch((error) => {
                if (error.data.status === errorCode.unprocessable) {
                    if (error.data.data) {
                        Object.keys(error.data.data).forEach((field) => {
                            setError(field as keyof IPaymentSettings, {
                                type: 'manual',
                                message: error.data.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };
    const resetForm = () => {
        if (policy) {
            extractPaymentData(policy);
        } else {
            reset();
        }
    };

    return (
        <>
            <div className="w-[full] flex-1 mb-6 ">
                <form onSubmit={handleSubmit(handleSave)} className="w-full px-2 py-1">
                    <div className="detail-page-title-block !py-4 !px-0 border-borderSecondary">
                        <div className="flex flex-col ">
                            <h2 className="flex flex-row gap-1.5 items-center">
                                <div className="title-text-header">{t('Payment Settings')}</div>
                            </h2>
                            <p className="subtitle-text">{t('Manage the cancellation and no-show policies.')}</p>
                        </div>
                        {isFormEnabled && (
                            <div className="flex flex-row gap-3 ">
                                <CustomButton secondary disabled={!user.is_verified_stripe_account || isLoading} onClick={resetForm} className="!h-[36px]">
                                    {t('Cancel')}
                                </CustomButton>
                                <CustomButton primary disabled={!user.is_verified_stripe_account || isLoading || !isSaveEnabled} isLoading={isLoading} type="submit" className="!h-[36px]">
                                    {t('Save Changes')}
                                </CustomButton>
                            </div>
                        )}
                    </div>
                    {!user.is_verified_stripe_account && stripeStep !== 'pending' && !stripeStep && (
                        <div
                            onClick={() => navigate(ROUTES.PAYMENT)}
                            className="w-full h-[52px] px-[14px] py-[8px] flex flex-row items-center border border-[#D0D5DD] rounded-lg justify-between cursor-pointer hover:bg-[#F9FAFB] mt-3"
                        >
                            <div className="flex flex-row items-center gap-2">
                                <InfoCircle className="w-5 h-5 text-primary" />
                                <p className="text-[#344054] text-sm leading-5 font-semibold">Payments must be enabled to configure no-show and cancellation policies.</p>
                            </div>
                            <ArrowNarrowRight className="w-5 h-5 text-[#344054]" />
                        </div>
                    )}

                    <div className="w-full flex flex-row  gap-[32px] border-b  border-borderSecondary py-4 items-center">
                        <div className=" w-[310px]">
                            <p className="title-text">{t('Enable Cancellation Policy')}</p>
                            <p className="subtitle-text">{t('This allows to charge on cancellation appointment')}</p>
                        </div>
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Controller
                                    name={`is_cancellation`}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div className="flex items-center gap-2">
                                            <Switch onChange={onChange} isChecked={value} isDisabled={!user.is_verified_stripe_account || !isFormEnabled} />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-row  gap-[32px] border-b  border-borderSecondary py-4 items-center">
                        <div className=" w-[310px]">
                            <p className="title-text">{t('Specify Cancellation Window')}</p>
                            <p className="subtitle-text">{t('Set the time frame within which cancellations are allowed before the appointment.')}</p>
                        </div>
                        <div className="max-2xl:w-[240px] w-[310px]">
                            <div className="w-full">
                                <div className="relative rounded-md">
                                    <Controller
                                        name={`cancellation_window`}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <InputWithLabel
                                                id="cancellation_window"
                                                // label="Website URL"
                                                type="number"
                                                placeholder="Recommended 1-72 hours"
                                                onChange={onChange}
                                                value={value ?? 0}
                                                required
                                                disabled={!user.is_verified_stripe_account || !watchAllFields.is_cancellation || !isFormEnabled}
                                                // disabled={!user.is_verified_stripe_account}
                                                name="cancellation_window"
                                                error={!!error}
                                                labelEndIcon={<div className="flex text-xs leading-[18px] font-semibold text-[#344054] flex-row gap-1 items-center">{'Hours'}</div>}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-row  gap-[32px] border-b  border-borderSecondary py-4 items-center">
                        <div className=" w-[310px]">
                            <p className="title-text">{t('Specify Cancellation Charge')}</p>
                            <p className="subtitle-text">{t('Define the charge that will be applied if a cancellation occurs within the specified window.')}</p>
                        </div>
                        <div className="max-2xl:w-[240px] w-[310px]">
                            <div className="w-full">
                                <Controller
                                    name={`cancellation_charge`}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <InputWithToggle
                                            type="number"
                                            name="cancellation_charge"
                                            className="!pl-[0px]"
                                            disabled={!user.is_verified_stripe_account || !watchAllFields.is_cancellation || !isFormEnabled}
                                            placeholder={t('Recommended 25-50 %')}
                                            toggleClassName="bg-[#3570F8] text-white drop-shadow-cardShadow"
                                            value={value ?? 0}
                                            toggleValue={toggle.cancellation}
                                            setselected={(e: any) => {
                                                handleInputChange('cancellation', e);
                                            }}
                                            onChange={onChange}
                                            error={!!error}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4 items-center">
                        <div className=" w-[310px]">
                            <p className="title-text">{t('Enable No-Show Policy')}</p>
                            <p className="subtitle-text">{t('This allows to charge on No Show appointment')}</p>
                        </div>
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Controller
                                    name={`is_no_show`}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div className="flex items-center gap-2">
                                            <Switch onChange={onChange} isChecked={value} isDisabled={!isFormEnabled || !user.is_verified_stripe_account} />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-row  gap-[32px] border-b  border-borderSecondary py-4 items-center">
                        <div className=" w-[310px]">
                            <p className="title-text">{t('Specify No-Show Charge')}</p>
                            <p className="subtitle-text">{t('Set the charge that will be applied if the client fails to attend without prior notice.')}</p>
                        </div>
                        <div className="max-2xl:w-[240px] w-[310px]">
                            <div className="w-full">
                                <Controller
                                    name={`no_show_charge`}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <InputWithToggle
                                            type="number"
                                            name="no_show_charge"
                                            className="!pl-[0px]"
                                            disabled={!isFormEnabled || !user.is_verified_stripe_account || !watchAllFields.is_no_show}
                                            placeholder={t('Recommended 25-50 %')}
                                            toggleClassName="bg-[#3570F8] text-white drop-shadow-cardShadow"
                                            value={value ?? 0}
                                            toggleValue={toggle.noshow}
                                            setselected={(e: any) => {
                                                handleInputChange('noshow', e);
                                            }}
                                            onChange={onChange}
                                            error={!!error}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {(user.is_verified_stripe_account || stripeStep || stripeStep === 'pending') && sripeDetails && (
                        <>
                            <div className="w-full flex flex-row  gap-[32px] border-b  border-borderSecondary py-4 ">
                                <div className="w-[310px]">
                                    <div className="flex flex-row gap-1.5">
                                        <p className="text-gray-700 font-semibold text-sm">{t('Stripe Configuration')}</p>
                                        <div className="w-max h-[22px] flex text-xs leading-[18px] px-2.5 py-[2px] font-medium text-[#067647] bg-[#ECFDF3] border rounded-full border-[#ABEFC6] flex-row gap-1 items-center">
                                            <Check className="text-[#067647] w-4 h-4" />
                                            <p>{t('Connected')}</p>
                                        </div>
                                    </div>
                                    <p className="subtitle-text">{t('Review and confirm your Stripe account setup details.')}</p>
                                </div>
                                <div className="max-2xl:w-[480px] w-[644px] flex flex-col gap-6">
                                    <div className="w-full flex flex-row gap-6">
                                        {/* <div className="w-1/2">
                                            <InputWithLabel
                                                readOnly
                                                name="name"
                                                value={sripeDetails.name ? sripeDetails.name : ''}
                                                onChange={() => {}}
                                                label={t('Name')}
                                                inputControlClassName="bg-[#F9FAFB]"
                                            />
                                        </div> */}
                                        <div className="w-1/2">
                                            <InputWithLabel
                                                readOnly
                                                name="account"
                                                value={sripeDetails.destination_accounts ? sripeDetails.destination_accounts : ''}
                                                disabled={true}
                                                onChange={() => ({})}
                                                label={t('Destination accounts')}
                                                inputControlClassName="bg-[#F9FAFB]"
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <InputWithLabel
                                                readOnly
                                                inputControlClassName="bg-[#F9FAFB]"
                                                name="insurance_number"
                                                value={sripeDetails.social_insurance_number ? t('Provided') : t('Not Provided')}
                                                onChange={() => ({})}
                                                label={t('Social insurance number')}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-row gap-6">
                                        <div className="w-1/2">
                                            <InputWithLabel
                                                readOnly
                                                inputControlClassName="bg-[#F9FAFB]"
                                                name="account_number"
                                                disabled={true}
                                                value={sripeDetails.account_number ? `*****${sripeDetails.account_number}` : ''}
                                                onChange={() => ({})}
                                                label={t('Account Number')}
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <InputWithLabel
                                                readOnly
                                                inputControlClassName="bg-[#F9FAFB]"
                                                name="account_verified"
                                                value={sripeDetails.account_verified ? t('Yes') : t('No')}
                                                onChange={() => ({})}
                                                label={t('Account verified')}
                                                placeholder="Yes"
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="w-full flex flex-row gap-6"> */}
                                    {/* <div className="w-1/2">
                                            <InputWithLabel
                                                readOnly
                                                inputControlClassName="bg-[#F9FAFB]"
                                                name="insurance_number"
                                                value={sripeDetails.social_insurance_number ? t('Provided') : t('Not Provided')}
                                                onChange={() => ({})}
                                                label={t('Social insurance number')}
                                            />
                                        </div> */}
                                    {/* <div className="w-1/2">
                                            <InputWithLabel
                                                readOnly
                                                inputControlClassName="bg-[#F9FAFB]"
                                                name="date_of_birth"
                                                value={sripeDetails.date_of_birth ? moment(sripeDetails.date_of_birth).format('MMM D, YYYY') : ''}
                                                onChange={() => ({})}
                                                label={t('Date of birth')}
                                            />
                                        </div> */}
                                    {/* </div> */}
                                    <div className="w-full flex flex-row gap-6">
                                        <div className="w-1/2">
                                            <InputWithLabel
                                                readOnly
                                                inputControlClassName="bg-[#F9FAFB]"
                                                name="phone"
                                                value={sripeDetails.support_phone ? sripeDetails.support_phone : ''}
                                                onChange={() => ({})}
                                                label={t('Phone number')}
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <InputWithLabel
                                                readOnly
                                                inputControlClassName="bg-[#F9FAFB]"
                                                name="created_at"
                                                value={sripeDetails.created_at ? moment(sripeDetails.created_at).format('MMM D, YYYY') : ''}
                                                onChange={() => ({})}
                                                label={t('Date of creation')}
                                                placeholder="Dec 3, 2023"
                                            />
                                        </div>
                                    </div>
                                    {isFormEnabled && (
                                        <div className="w-full flex justify-end">
                                            <CustomButton secondary className="!h-[36px]" onClick={disconnectStripe}>
                                                Disconnect Stripe
                                            </CustomButton>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {isFormEnabled && (
                        <div className="flex flex-row gap-3 w-full justify-end pt-5 mb-36">
                            <CustomButton secondary disabled={!user.is_verified_stripe_account || isLoading} onClick={resetForm} className="!h-[36px]">
                                {t('Cancel')}
                            </CustomButton>
                            <CustomButton primary disabled={!user.is_verified_stripe_account || isLoading || !isSaveEnabled} isLoading={isLoading} type="submit" className="!h-[36px]">
                                {t('Save Changes')}
                            </CustomButton>
                        </div>
                    )}
                </form>
            </div>
        </>
    );
};

export default Payment;
