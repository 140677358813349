import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import InputOtpFiled from 'src/components/InputOtp/InputOtpFiled';

const PinSetupStep1 = () => {
    const { control } = useFormContext();
    const { t } = useTranslation();
    return (
        <>
            <h2 className="font-bold text-[18px] leading-[28px] text-center pb-1">{t('Set a 4 Digit PIN')}</h2>
            <div className="flex flex-wrap">
                <Controller
                    name="pin"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="w-full flex justify-center flex-col items-center">
                            <div className="flex flex-col gap-8 text-center pb-4 w-[70%]">
                                <p className="text-secondaryTxtColor text-sm">{t('Enter 4-digit code to enable receptionist mode')}</p>
                            </div>
                            <div className="w-full mt-5 mb-1">
                                <InputOtpFiled inputClassName={'!w-[80px] !h-[80px] otp-input'} otp={value} setOtp={onChange} errors={error} handleKeyPress={() => {}} mask={true} />
                                {error && <p className="text-error">{error.message}</p>}
                            </div>
                        </div>
                    )}
                />
            </div>
        </>
    );
};

export default PinSetupStep1;
