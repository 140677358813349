import { useEffect, useState } from 'react';
import { LANGUAGE, LOCAL_STORAGE_KEYS } from 'src/constants/common';
import i18n from 'src/i18n';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { TLanguage } from 'src/redux/services/common/Common.interface';
import { allLanguages, setCurrentLanguage } from 'src/redux/services/common/Common.slice';

export const useLanguage = () => {
    const languages = useAppSelector(allLanguages);
    const [language, setLanguage] = useState<TLanguage>(LANGUAGE);
    const [languageOptions, setLanguageOptions] = useState([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const currentLanguage = languages.length ? languages[0] : LANGUAGE;
        setLanguage(currentLanguage);
        dispatch(setCurrentLanguage(currentLanguage));
        localStorage.setItem(LOCAL_STORAGE_KEYS.currentLanguage, currentLanguage);
    }, []);

    useEffect(() => {
        const options: any = languages.map((lang: any) => ({
            value: lang,
            label: lang.toUpperCase(),
            id: lang,
        }));
        setLanguageOptions(options);
    }, [languages]);

    useEffect(() => {
        const originalLanguage = i18n.language;
        i18n.changeLanguage(language);
        return () => {
            i18n.changeLanguage(originalLanguage);
            // localStorage.setItem(LOCAL_STORAGE_KEYS.currentLanguage, originalLanguage);
        };
    }, [i18n, language]);

    const handleLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setLanguage(value as TLanguage);
        dispatch(setCurrentLanguage(value as TLanguage));
        localStorage.setItem(LOCAL_STORAGE_KEYS.currentLanguage, value);
    };

    const handleErrorsAndSetLanguage = (err: any) => {
        const findLanguageInErrors = (errors: any, languageKey: string): boolean => {
            if (!errors || typeof errors !== 'object') return false;
            return Object.keys(errors).some((key) => {
                if (key.startsWith(`${languageKey}_`)) {
                    return true;
                }
                if (typeof errors[key] === 'object') {
                    return findLanguageInErrors(errors[key], languageKey); // Recursive call
                }
                return false;
            });
        };

        const currentLanguageErrors = findLanguageInErrors(err, language);
        if (currentLanguageErrors) {
            return;
        }

        const newLanguage = languages.find((lan) => findLanguageInErrors(err, lan));

        if (newLanguage && newLanguage !== language) {
            setLanguage(newLanguage);
            dispatch(setCurrentLanguage(newLanguage as TLanguage));
        }
    };

    return { languageOptions, languages, language, handleLanguage, setLanguage, handleErrorsAndSetLanguage };
};
