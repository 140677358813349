import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import moment from 'moment';
import { useAppSelector } from 'src/redux/hooks';
import CustomButton from 'src/components/CustomButton';
import { Plus, X } from '@untitled-ui/icons-react/build/cjs';
import { currentShop } from 'src/redux/services/common/Common.slice';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';

const Service = () => {
    const [serviceOptionShow, setServiceOptionShow] = useState<boolean>(false);
    const [serviceList, setserviceList] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const shop = useAppSelector(currentShop);
    const { control, setValue, watch } = useFormContext();
    const { fields, remove, append } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'services',
    });
    const handleRemoveService = (index: number) => async () => {
        remove(index);
        const updatedFields = fields.filter((_, i) => i !== index);
        setValue('services', updatedFields);
    };

    const staffId = watch('staff_id');

    const getStaff = () => {
        axiosGet(API.STAFF.GET, { shop_id: shop.id, id: staffId })
            .then((response) => {
                const services = response.data.data.services.map((item: any) => ({
                    ...item,
                    value: item.id,
                    label: item.name,
                }));
                setserviceList(services);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (staffId) {
            setIsLoading(true);
            setValue('services', []);
            getStaff();
        }
    }, [staffId]);

    const handleChangeService = (value: any) => {
        const selectedService = serviceList.find((service: { value: any }) => service.value === value.value);
        if (!selectedService) {
            return;
        }
        setServiceOptionShow(false);
        append({
            name: selectedService?.name,
            price: selectedService.price.price,
            duration: selectedService.price.duration,
            id: selectedService.id,
            is_custom_price: false,
            qty: 1,
        });
    };
    useEffect(() => {
        if (fields.length === 0) {
            setServiceOptionShow(true);
        }
    }, [fields]);

    return (
        <>
            <div className="mb-4">
                <div className="">
                    <div className="flex justify-between">
                        <label htmlFor="newclient" className={`fl-field-title-label normal-case text-gray-700 text-xs font-semibold leading-[18px] mb-1.5`}>
                            Select the Service(s)
                        </label>
                        {!serviceOptionShow && (
                            <CustomButton
                                type="button"
                                onClick={() => setServiceOptionShow(true)}
                                className="!text-xs text-primary font-semibold flex items-center gap-1.5 h-[15px] shadow-none pr-0.5 "
                                icon={<Plus width="16" />}
                            >
                                Add Service
                            </CustomButton>
                        )}
                    </div>
                </div>

                <div className="flex flex-col gap-2">
                    {fields.length > 0 && (
                        <div className="flex flex-col gap-2">
                            {fields.map((item: any, index: any) => (
                                <Fragment key={item.uuid}>
                                    <div className="flex h-[36px] select_border input-group  justify-between items-center p-2 border border-gray-300 rounded-lg shadow-InputAndButton text-xs">
                                        <div className="flex font-medium select-box-custom ">
                                            <span className="font-medium max-w-[110px] truncate">{item.name}</span>
                                            <Controller
                                                name={`services.${index}.duration`}
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <div>
                                                        <span className="font-normal ml-1">{`(`}</span>
                                                        <input
                                                            id="duration"
                                                            required
                                                            onChange={(e) => {
                                                                onChange(moment.utc(moment.duration(e.target.value, 'minutes').asMilliseconds()).format('HH:mm:ss'));
                                                            }}
                                                            value={moment.duration(value).asMinutes()}
                                                            className="!font-medium text-xs  !max-w-[22px]   !border-0 !bg-transparent !pl-0 !pr-0 !outline-none"
                                                        />
                                                        <span className="font-normal ml-1">{`minutes)`}</span>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Controller
                                                name={`services.${index}.price`}
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <div>
                                                        <span className="font-normal ml-1">{`$`}</span>
                                                        <input
                                                            id="price"
                                                            required
                                                            type="number"
                                                            onChange={(e) => onChange(e.target.value)}
                                                            value={value}
                                                            className="font-normal  !max-w-[49px] !border-0 !bg-transparent !pl-0 !pr-0 !outline-none"
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <button className="text-gray-500 hover:text-red-500" type="button" onClick={handleRemoveService(index)}>
                                                <X width={16} height={16} />
                                            </button>
                                        </div>
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                    )}
                    {(serviceOptionShow || fields.length === 0) && (
                        <Controller
                            name="services"
                            control={control}
                            render={({ fieldState: { error } }: any) => (
                                <div className="w-full">
                                    <SelectBox
                                        isSearchable
                                        name="services"
                                        errorText={!!error}
                                        id="services"
                                        value={null}
                                        options={serviceList}
                                        isLoading={isLoading}
                                        onChangeFunc={handleChangeService}
                                        className=""
                                        error={error}
                                        placeholder="Select a service"
                                    />
                                    {error?.type && error.type !== 'required' && error.type !== 'services-or-product' && <p className="text-error">{error.message}</p>}
                                </div>
                            )}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Service;
