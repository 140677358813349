import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { capitalizeFirstLetterAndUnderscore, capitalizeFirstLetterSpace, formatDateTime, getShortName } from 'src/utils/global-functions';
import CustomButton from 'src/components/CustomButton';
import { ArrowUp, ArrowDown, UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import NoDataMessage from 'src/components/NoDataMessage';
import { perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { PaginatorTemplate } from 'src/utils/global-component';
import Badge from 'src/components/Badge';

const StaffBooking = ({ staff }: any) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [totalBookings, setTotalBookings] = useState<number>(0);
    const [bookings, setBookings] = useState([]);
    const [isImageError, setIsImageError] = useState<{ [key: number]: boolean }>({});
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: perPageOptions[0].value,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            type: { value: 'all' },
        },
    });

    const listBookings = async () => {
        setIsLoading(true);
        await axiosGet(API.STAFF.APPOINTMENT, { shop_id: shop.id, id }, lazyState)
            .then((response) => {
                setBookings(response.data.data.data);
                setTotalBookings(response.data.data.totalRecords);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        listBookings();
    }, [lazyState, shop]);

    const handleImageError = (ids: number) => () => {
        setIsImageError((old: any) => ({ ...old, [ids]: true }));
    };

    const handleBookingDownload = async () => {
        setIsDownloading(true);

        await axiosGet(API.STAFF.BOOKING_HISTORY, { shop_id: shop.id, id: id })
            .then(async (response) => {
                const exportResponse = response.data.data;
                const byteCharacters = atob(exportResponse);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                    type: 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet',
                });

                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `booking_details_${staff.full_name}_${moment().format('YYYY-MM-DD')}.xlsx`;

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            .finally(() => setIsDownloading(false));
    };

    const getFullName = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.user.profile_image_url && !isImageError[row.id] ? (
                    <img src={row.user.profile_image_url} alt="" className="w-full h-full object-cover" onError={handleImageError(row.id)} />
                ) : (
                    getShortName(row.user.full_name)
                )}
            </figure>
            <div>
                <p className="text-xs font-medium text-gray-900 leading-[18px] w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[100px] 2xl:max-w-[100px] 2xl:min-w-[100px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {row.user.full_name}
                </p>
                <p className="text-xs font-normal text-gray-600 leading-[18px] w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[100px] 2xl:max-w-[100px] 2xl:min-w-[100px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {moment(row.created_at).fromNow()}
                </p>
            </div>
        </div>
    );

    const getDateTime = (row: any) => formatDateTime(`${row.booking_date} ${row.booking_start_time}`);

    const getDuration = (row: any) => `${moment.duration(row.total_duration).asMinutes()} min`;

    const getService = (row: any) => {
        const servicesArray = row.booking_services;

        return (
            <p className="text-xs font-medium w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[100px] 2xl:max-w-[100px] 2xl:min-w-[100px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">{`${
                servicesArray[0].service.name
            } ${servicesArray.length > 1 ? `+${servicesArray.length - 1}` : ''}`}</p>
        );
    };

    const getTotal = (row: any) => `$${row.total}`;

    const GetStatus = (row: any) => (
        <Badge icon status={row.status}>
            {capitalizeFirstLetterAndUnderscore(row.status)}
        </Badge>
    );

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map((_, index) => ({
                id: index,
                user: 'loading',
                date: 'loading',
                duration: 'loading',
                service: 'loading',
                price: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );

    return (
        <div className="w-full border rounded-xl mt-4  flex-col mb-5 flex shadow datatable-custom">
            <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                <div>
                    <h2 className="view-table-title">{t('Recent booking activity')}</h2>
                    <p className="view-table-subtitle">{t('View location latest booking activity', { name: capitalizeFirstLetterSpace(staff.full_name) })}</p>
                </div>
                <div className="flex gap-4">
                    <div className="location-dropdown">
                        <CustomButton
                            isLoading={isDownloading}
                            disabled={isDownloading || !totalBookings}
                            secondary
                            outlineSecondary
                            icon={<UploadCloud02 className="w-[18px] h-[18px] mt-[2px]" />}
                            onClick={handleBookingDownload}
                            className="flex items-center"
                        >
                            {t('Export')}
                        </CustomButton>
                    </div>
                    <SelectBox
                        name="page"
                        options={perPageOptions}
                        isClearable={false}
                        isSearchable={false}
                        onChangeFunc={onPageHandle}
                        value={perPageOptions.find((option) => option.value === lazyState.rows)}
                        classComp="w-[70px]"
                    />
                </div>
            </div>
            <DataTable
                lazy
                paginatorTemplate={PaginatorTemplate()}
                value={isLoading ? skeletons : bookings}
                totalRecords={totalBookings}
                paginator={!isLoading && totalBookings > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                dataKey="id"
                className="rounded-b-xl overflow-hidden"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                        <NoDataMessage title="Recent booking activity" description="Bookings not available." />
                    </div>
                }
            >
                <Column
                    field="users.created_at"
                    header={renderHeader(t('Client'), 'users.created_at')}
                    style={{ width: '160px', minWidth: '160px', maxWidth: '160px' }}
                    body={isLoading ? <Skeleton /> : getFullName}
                    className="font-normal text-mainTextColor   truncate"
                    sortable
                ></Column>
                <Column
                    field="status"
                    header={renderHeader(t('Status'), 'status')}
                    body={isLoading ? <Skeleton /> : GetStatus}
                    style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                    className="text-xs text-gray-600 font-normal  truncate"
                    sortable
                ></Column>
                <Column
                    field="booking_date"
                    header={renderHeader(t('Date and Time'), 'booking_date')}
                    style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                    body={isLoading ? <Skeleton /> : getDateTime}
                    className="text-xs text-gray-600 font-normal truncate "
                    sortable
                ></Column>
                <Column
                    field="total_duration"
                    header={renderHeader(t('Duration'), 'total_duration')}
                    style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                    body={isLoading ? <Skeleton /> : getDuration}
                    className="text-xs text-gray-600 font-normal  truncate"
                    sortable
                ></Column>
                <Column
                    field="shop_services.name"
                    header={renderHeader(t('Service'), 'shop_services.name')}
                    style={{ width: '110px', minWidth: '110px', maxWidth: '110px' }}
                    body={isLoading ? <Skeleton /> : getService}
                    className="text-xs text-gray-600 font-medium  truncate"
                    sortable
                ></Column>
                <Column
                    field="total"
                    header={renderHeader(t('Total'), 'total')}
                    style={{ width: '70px', minWidth: '70px', maxWidth: '70px' }}
                    body={isLoading ? <Skeleton /> : getTotal}
                    className="text-xs font-medium text-tableText  truncate"
                    sortable
                ></Column>
            </DataTable>
        </div>
    );
};

export default StaffBooking;
