import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import moment from 'moment';
import { sortBy } from 'lodash';
import { formatAmount, formatPercentage } from 'src/utils/global-functions';
import { colorArray } from '../Analytics/Analytics.interface';

interface IProps {
    graph: any[];
    activeFilter: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
    selectedGraphOption: {
        seriesArray: any[];
        value: string;
    };
}
const AmChart: FC<IProps> = (props) => {
    const { graph, activeFilter, selectedGraphOption, startDate, endDate } = props;
    const chartRef = useRef(null);
    const [processData, setProcessData] = useState<any>([]);

    useEffect(() => {
        processArray(graph);
    }, [graph, activeFilter]);

    useLayoutEffect(() => {
        if (processData.length && chartRef.current) {
            const root = am5.Root.new(chartRef.current);
            if (root._logo) {
                root._logo.dispose();
            }
            root.setThemes([am5themes_Animated.new(root)]);

            const chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    wheelX: 'none',
                    wheelY: 'none',
                    pinchZoomY: false,
                    // layout: root.verticalLayout,
                    // maxTooltipDistance: -1,
                    cursor: am5xy.XYCursor.new(root, {}),
                }),
            );
            chart.appear(1000, 100);
            // chart?.get('colors')?.set('colors', [am5.color('#1642C5'), am5.color('#01A3FF')]);
            chart.get('colors')?.set('colors', [am5.color(colorArray[0]), am5.color(colorArray[4]), am5.color(colorArray[5]), am5.color(colorArray[6])]);

            const xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                    startLocation: 0.1,
                    endLocation: 0.9,
                    markUnitChange: false,
                    baseInterval: { timeUnit: 'day', count: 1 },
                    renderer: am5xy.AxisRendererX.new(root, {
                        minGridDistance: 10,
                    }),
                }),
            );
            xAxis.get('renderer').grid.template.setAll({
                visible: false,
            });

            xAxis.get('renderer').labels.template.setAll({
                fontFamily: 'Inter, sans-serif',
                fontWeight: 'lighter',
                fontSize: 13,
            });

            const yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    extraMax: 0.1,
                    min: 0,
                    strictMinMax: true,
                    paddingBottom: 10,
                    numberFormat: '#a',
                    renderer: am5xy.AxisRendererY.new(root, {
                        minGridDistance: 30,
                        strokeOpacity: 0, // Hides the Y-axis line itself
                    }),
                }),
            );

            yAxis.get('renderer').grid.template.setAll({
                strokeDasharray: [5, 5], // This will create a dash pattern of 5 pixels dash and 5 pixels gap
                strokeOpacity: 0.5,
                stroke: am5.color(0xcccccc),
            });

            yAxis.get('renderer').labels.template.setAll({
                fontFamily: 'Inter, sans-serif',
                fontWeight: 'lighter',
                fontSize: 13,
            });

            const tooltip = am5.Tooltip.new(root, {
                paddingTop: 12,
                paddingBottom: 12,
                paddingRight: 15,
                paddingLeft: 15,
            });
            tooltip.set(
                'background',
                am5.RoundedRectangle.new(root, {
                    fill: am5.color(0xffffff),
                    fillOpacity: 1,
                    cornerRadiusBL: 12,
                    cornerRadiusBR: 12,
                    cornerRadiusTL: 12,
                    cornerRadiusTR: 12,
                    shadowColor: am5.color(0xdddddd),
                    shadowBlur: 5,
                    shadowOffsetY: 1,
                    stroke: am5.color(0xfcfcfd),
                    strokeWidth: 1,
                }),
            );
            chart.plotContainer.set('tooltipPosition', 'pointer');
            chart.plotContainer.set('tooltipText', 'a');
            chart.plotContainer.set('tooltip', tooltip);

            tooltip.label.adapters.add('text', function (text, target) {
                text = '';

                chart.series.each(function (seriesItem: am5xy.XYSeries, index) {
                    let tooltips = [];
                    let valueX = null;

                    const tooltipDataItem: any = seriesItem.get('tooltipDataItem');
                    if (tooltipDataItem) {
                        valueX = tooltipDataItem.get('valueX')!;
                        switch (selectedGraphOption.value) {
                            case 'sales':
                                tooltips.push(
                                    {
                                        name: 'Services',
                                        value: formatAmount(Number(tooltipDataItem.dataContext.service_amount) || 0),
                                        color: am5.color(colorArray[0]),
                                    },
                                    {
                                        name: 'Products',
                                        value: formatAmount(Number(tooltipDataItem.dataContext.product_amount) || 0),
                                        color: am5.color(colorArray[1]),
                                    },
                                    {
                                        name: 'Taxes',
                                        value: formatAmount(Number(tooltipDataItem.dataContext.taxes_and_fees) || 0),
                                        color: am5.color(colorArray[2]),
                                    },
                                    {
                                        name: 'Tip',
                                        value: formatAmount(Number(tooltipDataItem.dataContext.tip_amount) || 0),
                                        color: am5.color(colorArray[3]),
                                    },
                                    {
                                        name: 'Total Sales',
                                        value: formatAmount(Number(tooltipDataItem.dataContext.total_amount) || 0),
                                        color: am5.color(colorArray[4]),
                                    },
                                );
                                break;
                            case 'bookings':
                                tooltips.push(
                                    {
                                        name: 'Completed',
                                        value: Number(tooltipDataItem.dataContext.completed_amount) || 0,
                                        color: am5.color(colorArray[0]),
                                    },
                                    {
                                        name: 'Canceled',
                                        value: Number(tooltipDataItem.dataContext.canceled_amount) || 0,
                                        color: am5.color(colorArray[1]),
                                    },
                                    {
                                        name: 'No-Show',
                                        value: Number(tooltipDataItem.dataContext.no_show_amount) || 0,
                                        color: am5.color(colorArray[2]),
                                    },
                                    {
                                        name: 'Total Appointment',
                                        value: Number(tooltipDataItem.dataContext.total_bookings) || 0,
                                        color: am5.color(colorArray[3]),
                                    },
                                );
                                break;
                            case 'active_users':
                                tooltips.push(
                                    {
                                        name: 'New Clients',
                                        value: Number(tooltipDataItem.dataContext.new_users) || 0,
                                        color: am5.color(colorArray[0]),
                                    },
                                    {
                                        name: 'Active Clients',
                                        value: Number(tooltipDataItem.dataContext.active_users) || 0,
                                        color: am5.color(colorArray[1]),
                                    },
                                    {
                                        name: 'Total Clients',
                                        value: Number(tooltipDataItem.dataContext.total_users) || 0,
                                        color: am5.color(colorArray[2]),
                                    },
                                );
                                break;
                            case 'retention_users':
                                tooltips.push(
                                    {
                                        name: 'New Client Retention',
                                        value: formatPercentage(Number(tooltipDataItem.dataContext.new_users) || 0),
                                        color: am5.color(colorArray[0]),
                                    },
                                    {
                                        name: 'Active Client Retention',
                                        value: formatPercentage(Number(tooltipDataItem.dataContext.active_users) || 0),
                                        color: am5.color(colorArray[1]),
                                    },
                                    {
                                        name: 'Total Client Retention',
                                        value: formatPercentage(Number(tooltipDataItem.dataContext.total_users) || 0),
                                        color: am5.color(colorArray[2]),
                                    },
                                );
                                break;
                        }
                    }

                    if (tooltips.length) {
                        let date = '';
                        switch (activeFilter) {
                            case 'daily':
                                date = moment(valueX).format('HH:mm');
                                break;
                            case 'weekly':
                                date = moment(valueX).format('dddd');
                                break;
                            case 'monthly':
                                date = moment(valueX).format('DD MMM');
                                break;
                            case 'yearly':
                                date = moment(valueX).format('MMM yyyy');
                                break;
                            default:
                                date = moment(valueX).format('DD MMM yyyy');
                                break;
                        }

                        text += !index ? date : '';
                        tooltips.forEach((tip: any) => {
                            text += `\n[${tip.color}]●[/] [bold width:100px fontFamily:Inter, sans-serif]${tip.name}:[/] ${tip.value}`;
                        });
                    }
                });
                return text;
            });

            chart.children.unshift(
                am5.Label.new(root, {
                    // text: '',
                    fontSize: '1.25rem',
                    fontWeight: '700',
                    textAlign: 'left',
                    x: am5.percent(0),
                    centerX: am5.percent(0),
                    paddingTop: 0,
                    paddingBottom: 50,
                }),
            );

            const cursor = chart.set(
                'cursor',
                am5xy.XYCursor.new(root, {
                    behavior: 'none',
                    xAxis: xAxis,
                    yAxis: yAxis,
                }),
            );
            cursor.lineX.setAll({
                visible: false,
                /* strokeDasharray: [5, 5],
                stroke: am5.color('#8C9CBB'),
                strokeWidth: 1.5, */
            });
            cursor.lineY.setAll({
                visible: false,
            });

            var previousBulletSprites: any = [];
            const cursorMoved = () => {
                for (var i = 0; i < previousBulletSprites.length; i++) {
                    previousBulletSprites[i].unhover();
                }
                previousBulletSprites = [];
                chart.series.each(function (series: any) {
                    var dataItem = series.get('tooltip').dataItem;
                    if (dataItem) {
                        var bulletSprite = dataItem.bullets[0].get('sprite');
                        bulletSprite.hover();
                        previousBulletSprites.push(bulletSprite);
                    }
                });
            };
            cursor?.events.on('cursormoved', cursorMoved);

            const removeBullets = () => {
                for (var i = 0; i < previousBulletSprites.length; i++) {
                    previousBulletSprites[i].unhover();
                }
                previousBulletSprites = [];
            };
            chart.plotContainer.events.on('pointerout', removeBullets);

            const createSeries = (item: any) => {
                const series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        xAxis: xAxis,
                        yAxis: yAxis,
                        name: item.label,
                        valueYField: item.value,
                        valueXField: 'booking_date_time',
                        // connect: true,
                        // setStateOnChildren: true,
                        stroke: am5.color('#0E34A5'),
                        forceHidden: false,
                        tooltip: am5.Tooltip.new(root, {
                            // labelText: undefined,
                            forceHidden: true,
                            // animationDuration: 0,
                        }),
                    }),
                );
                series.strokes.template.setAll({
                    strokeWidth: 2,
                });

                series.fills.template.setAll({
                    fillOpacity: 0.1,
                    visible: true,
                });

                series.fills.template.set(
                    'fillGradient',
                    am5.LinearGradient.new(root, {
                        stops: [
                            {
                                color: am5.color('#0E34A5'),
                                brighten: 0.1,
                            },
                            {
                                color: am5.color('#0E34A5'),
                                lighten: 1,
                            },
                        ],
                        rotation: 90,
                    }),
                );
                series.bullets.push(function () {
                    const circle = am5.Circle.new(root, {
                        radius: 5,
                        stroke: series.get('fill'),
                        strokeWidth: 2,
                        interactive: true,
                        fill: series.get('stroke'),
                        opacity: 0,
                    });

                    circle.states.create('default', {
                        opacity: 0,
                    });

                    circle.states.create('hover', {
                        opacity: 1,
                    });

                    return am5.Bullet.new(root, {
                        sprite: circle,
                    });
                });

                // series.set('setStateOnChildren', true);
                // series.states.create('hover', {});
                series.data.processor = am5.DataProcessor.new(root, {
                    dateFormat: 'yyyy-MM-dd HH:mm:ii',
                    dateFields: ['booking_date_time'],
                });

                // series.mainContainer.set('setStateOnChildren', true);
                // series.mainContainer.states.create('hover', {});

                series.strokes.template.states.create('hover', {
                    strokeWidth: 3,
                });

                series.data.setAll(processData);
                series.appear(1000);
            };

            selectedGraphOption.seriesArray.forEach((item: any) => {
                createSeries(item);
            });

            const legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.percent(100),
                    x: am5.percent(100),
                    centerY: am5.percent(0),
                    y: am5.percent(0),
                    clickTarget: 'none',
                    useDefaultMarker: true,
                }),
            );
            legend.valueLabels.template.set('forceHidden', true);
            legend.markers.template.setAll({
                visible: false,
                width: 10,
                height: 10,
            });
            legend.itemContainers.template.events.on('pointerover', function (e: any) {
                e.target.dataItem.dataContext.hover();
            });
            legend.itemContainers.template.events.on('pointerout', function (e: any) {
                e.target.dataItem.dataContext.unhover();
            });
            legend.data.setAll(chart.series.values);

            let timeUnit: am5.time.TimeUnit;
            let count: number;
            let dateFormats: Record<string, string> = {};
            switch (activeFilter) {
                case 'daily':
                    timeUnit = 'hour';
                    count = 1;
                    dateFormats.hour = 'HH:mm';
                    break;
                case 'weekly':
                    timeUnit = 'day';
                    count = 1;
                    dateFormats.day = 'EEEE';
                    break;
                case 'monthly':
                    timeUnit = 'day';
                    count = 2;
                    dateFormats.day = 'MMM dd';
                    break;
                case 'yearly':
                    timeUnit = 'month';
                    count = 1;
                    dateFormats.month = 'MMM';
                    break;
                default:
                    timeUnit = 'day';
                    count = 1;
                    dateFormats.day = 'dd';
                    break;
            }

            // Set the axis configuration
            xAxis.set('baseInterval', { timeUnit, count });
            xAxis.set('dateFormats', dateFormats);

            return () => {
                root.dispose();
            };
        }
    }, [processData]);

    const processArray = (array: any) => {
        const data = [];
        const amountMap: { [key: string]: any } = {};

        const optionFields: Record<string, string[]> = {
            sales: ['total_amount', 'service_amount', 'product_amount', 'taxes_and_fees', 'tip_amount'],
            bookings: ['total_bookings', 'canceled_amount', 'completed_amount', 'no_show_amount'],
            active_users: ['total_users', 'active_users', 'new_users'],
            retention_users: ['total_users', 'active_users', 'new_users'],
        };

        const fields = optionFields[selectedGraphOption.value];

        const initFields = fields.reduce((acc: any, key: string) => {
            acc[key] = 0;
            return acc;
        }, {});

        switch (activeFilter) {
            case 'daily':
                array = sortBy(array, (item) => moment(item.booking_date_time).valueOf());
                array.forEach((item: any) => {
                    const createdAt = moment(item.booking_date_time).format('YYYY-MM-DD-HH');
                    if (!amountMap[createdAt]) {
                        amountMap[createdAt] = item;
                    } else {
                        fields.forEach((field: string) => {
                            amountMap[createdAt][field] += parseFloat(item[field]) || 0;
                        });
                    }
                });

                const currentStartDay = moment().startOf('day');
                for (let i = 0; i < 24; i++) {
                    const targetDate = currentStartDay.clone().add(i, 'hours').startOf('hour');
                    const originalDate = targetDate.format('YYYY-MM-DD-HH');
                    const values = {
                        booking_date_time: targetDate.toDate().getTime(),
                        ...initFields,
                        ...amountMap[originalDate],
                    };
                    data.push(values);
                }
                break;
            case 'weekly':
                array = sortBy(array, (item) => moment(item.booking_date_time).valueOf());
                array.forEach((item: any) => {
                    const createdAt = moment(item.booking_date_time).format('YYYY-MM-DD');
                    if (!amountMap[createdAt]) {
                        amountMap[createdAt] = item;
                    } else {
                        fields.forEach((field: string) => {
                            amountMap[createdAt][field] += parseFloat(item[field]) || 0;
                        });
                    }
                });

                const currentStartWeek = moment().startOf('week');
                for (let i = 0; i < 7; i++) {
                    const targetDate = currentStartWeek.clone().add(i, 'days').startOf('day');
                    const originalDate = targetDate.format('YYYY-MM-DD');

                    const values = {
                        booking_date_time: targetDate.toDate().getTime(),
                        ...initFields,
                        ...amountMap[originalDate],
                    };
                    data.push(values);
                }
                break;
            case 'monthly':
                array = sortBy(array, (item) => moment(item.booking_date_time).valueOf());
                array.forEach((item: any) => {
                    const createdAt = moment(item.booking_date_time).format('YYYY-MM-DD');
                    if (!amountMap[createdAt]) {
                        amountMap[createdAt] = item;
                    } else {
                        fields.forEach((field: string) => {
                            amountMap[createdAt][field] += parseFloat(item[field]) || 0;
                        });
                    }
                });

                const currentStartMonth = moment().startOf('month');
                for (let i = 0; i < 30; i++) {
                    const targetDate = currentStartMonth.clone().add(i, 'days').startOf('day');
                    const originalDate = targetDate.format('YYYY-MM-DD');
                    const values = {
                        booking_date_time: targetDate.toDate().getTime(),
                        ...initFields,
                        ...amountMap[originalDate],
                    };
                    data.push(values);
                }
                break;
            case 'yearly':
                array = sortBy(array, (item) => moment(item.booking_date_time).valueOf());
                array.forEach((item: any) => {
                    const createdAt = moment(item.booking_date_time).format('YYYY-MM');
                    if (!amountMap[createdAt]) {
                        amountMap[createdAt] = item;
                    } else {
                        fields.forEach((field: string) => {
                            amountMap[createdAt][field] += parseFloat(item[field]) || 0;
                        });
                    }
                });

                const currentStartYear = moment().startOf('year');
                for (let i = 0; i < 12; i++) {
                    const targetDate = currentStartYear.clone().add(i, 'months').startOf('month');
                    const originalDate = targetDate.format('YYYY-MM');
                    const values = {
                        booking_date_time: targetDate.toDate().getTime(),
                        ...initFields,
                        ...amountMap[originalDate],
                    };
                    data.push(values);
                }
                break;
            default:
                if (array.length) {
                    array = sortBy(array, (item) => moment(item.booking_date_time).valueOf());
                    array.forEach((item: any) => {
                        const createdAt = moment(item.booking_date_time).format('YYYY-MM-DD');
                        if (!amountMap[createdAt]) {
                            amountMap[createdAt] = {
                                ...item,
                                booking_date_time: moment(item.booking_date_time).toDate().getTime(),
                            };
                        } else {
                            fields.forEach((field: string) => {
                                amountMap[createdAt][field] += parseFloat(item[field]) || 0;
                            });
                        }
                    });
                    data.push(...Object.values(amountMap));
                } else {
                    const start = moment(startDate);
                    const end = moment(endDate);
                    while (start.isSameOrBefore(end)) {
                        const values = {
                            booking_date_time: start.toDate().getTime(),
                            ...initFields,
                        };
                        data.push(values);
                        start.add(1, 'day');
                    }
                }
                break;
        }

        setProcessData(data);
    };

    return <div ref={chartRef} className="w-full h-[400px]"></div>;
};

export default AmChart;
