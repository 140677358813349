import React, { useEffect, useState } from 'react';
import { Checkbox, Label } from 'flowbite-react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Plus, XClose } from '@untitled-ui/icons-react/build/cjs';
import SelectBox from 'src/components/SelectBox/SelectBox';

const WeekHours = ({ errors, name = 'hours', isLoginStep = false, baseTabIndex = 0 }: any) => {
    const { t } = useTranslation();

    const { control, setValue, getValues } = useFormContext();

    const { fields } = useFieldArray({
        control,
        keyName: 'uuid',
        name,
    });

    const valueWatch = useWatch({
        name,
        control,
    });

    const [timeOptions, setTimeOptions] = useState([]);

    useEffect(() => {
        const options: any = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const hourIn12 = hour % 12 === 0 ? 12 : hour % 12;
                const period = hour < 12 ? 'AM' : 'PM';
                const value = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
                const label = `${String(hourIn12).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period}`;
                options.push({ value, label });
            }
        }
        setTimeOptions(options);
    }, []);

    const handleTimeTypeChange = (onChange: any, field: string) => (event: any) => {
        onChange(event.value);
    };

    const handleChangeWorkingStatus = (index: number, status: boolean) => {
        if (status) {
            let previousIndex = index;
            for (let i = 0; i < 7; i++) {
                previousIndex = (previousIndex - 1 + 7) % 7;

                if (getValues(`${name}[${previousIndex}].status`)) {
                    setValue(`${name}[${index}].from_time_hours`, getValues(`${name}[${previousIndex}].from_time_hours`));
                    setValue(`${name}[${index}].to_time_hours`, getValues(`${name}[${previousIndex}].to_time_hours`));
                    return;
                }
            }
            setValue(`${name}[${index}].from_time_hours`, '00:00');
            setValue(`${name}[${index}].to_time_hours`, '00:00');
        } else {
            setValue(`${name}[${index}].from_time_hours`, '00:00');
            setValue(`${name}[${index}].to_time_hours`, '00:00');
        }
    };

    return (
        <div>
            {fields.map((item: any, index: number) => (
                <label
                    className={`opening-hours-row ${fields?.length === index + 1 && !isLoginStep ? 'pb-0 border-0' : ''} ${!getValues(`${name}.[${index}].status`) ? 'cursor-pointer' : ''}`}
                    key={item.uuid}
                    htmlFor={!getValues(`${name}.[${index}].status`) ? `day_${item.day}_${name}` : ''}
                >
                    <div className="flex items-center gap-3 xl:gap-5 day-checkbox">
                        <Controller
                            key={`${name}.[${index}].status`}
                            name={`${name}.[${index}].status`}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <>
                                    <Label className="cursor-pointer" htmlFor={`day_${item.day}_${name}`}>
                                        {value ? <XClose className="w-5 h-5 text-error-600" /> : <Plus className="w-5 h-5 text-primary" />}
                                    </Label>
                                    <Checkbox
                                        className="day-check-box"
                                        id={`day_${item.day}_${name}`}
                                        name={`${name}.[${index}].status`}
                                        value={value}
                                        checked={value}
                                        onChange={(event) => {
                                            handleChangeWorkingStatus(index, event.target.checked);
                                            onChange(event);
                                        }}
                                    />
                                    <Label className={`day-checkbox-label capitalize cursor-pointer ${value ? 'text-gray-700' : 'text-primary'}`} htmlFor={`day_${item.day}_${name}`}>
                                        {item.day}
                                    </Label>
                                    {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                </>
                            )}
                        />
                    </div>
                    {valueWatch?.[index]?.status ? (
                        <div className="flex flex-col flex-1 justify-end">
                            <div className="flex flex-row item-center gap-3 flex-1 max-w-[310px ]">
                                <Controller
                                    name={`${name}.[${index}].from_time_hours`}
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <SelectBox
                                            classComp="outline-select-box"
                                            isSearchable={false}
                                            labelPrefixText="From"
                                            name={`${name}.${index}.from_time_hours`}
                                            id={`${name}.${index}.from_time_hours`}
                                            options={timeOptions}
                                            value={timeOptions.find((option: any) => option.value === value)}
                                            onChangeFunc={handleTimeTypeChange(onChange, 'from')}
                                            isClearable={false}
                                            error={errors?.hours && errors?.hours[index] && errors?.hours[index]?.from_time_hours}
                                        />
                                    )}
                                />
                                <Controller
                                    name={`${name}.[${index}].to_time_hours`}
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <SelectBox
                                            isSearchable={false}
                                            name={`${name}.${index}.to_time_hours`}
                                            id={`${name}.${index}.to_time_hours`}
                                            options={timeOptions}
                                            labelPrefixText="To"
                                            value={timeOptions.find((option: any) => option.value === value)}
                                            onChangeFunc={handleTimeTypeChange(onChange, 'to')}
                                            isClearable={false}
                                            error={errors?.hours && errors?.hours[index] && errors?.hours[index]?.to_time_hours}
                                        />
                                    )}
                                />
                            </div>
                            {errors?.hours && errors?.hours[index] ? (
                                errors?.hours[index]?.from_time_hours && errors?.hours[index]?.from_time_hours.message ? (
                                    <p className="text-error ">{errors.hours[index].from_time_hours.message}</p>
                                ) : errors?.hours[index]?.to_time_hours ? (
                                    <p className="text-error ">{errors?.hours[index]?.to_time_hours?.message}</p>
                                ) : null
                            ) : null}
                        </div>
                    ) : (
                        <div className="flex xl:justify-end items-center flex-1 self-center">
                            <span className="text-xs leading-[18px] text-gray-500 font-semibold ">{t('Not working on this day')}</span>
                        </div>
                    )}
                </label>
            ))}
        </div>
    );
};

export default WeekHours;
