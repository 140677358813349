import { Dispatch, SetStateAction } from 'react';
import { ICountStep } from 'src/components/Stepper/Stepper.interface';

export interface IProps {
    setStep?: Dispatch<SetStateAction<ICountStep>>;
    handleStep: (value: 'prev' | 'next', type?: boolean) => () => void;
    inviteStaffShop?: any;
}

export interface IAccountFormData {
    country_name: string;
    language_name: string;
    profile_image_name?: string;
}

export interface IAccountTypeFormData {
    type: 'owner' | 'individual';
}

export interface IServiceProviderFormData {
    status: 'yes' | 'no';
}

export interface IBrandFormData {
    logo_image_name: string;
    admin_logo_image_name: string;
    is_same_as_logo: boolean;
    business_name: string;
    slug: string;
    google_review?: string;
    facebook_page?: string;
    instagram_page?: string;
    admin_emails?: any;
    logo_image_type: string;
    admin_logo_image_type: string;
}

export interface IProfileFormData {
    profile_image_name: string;
    about: string;
    instagram_link: string;
    gallery_image_names?: any;
}

export interface IWorkingHoursValue {
    day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
    status: NonNullable<boolean | undefined>;
    from_time_hours: string;
    to_time_hours: string;
}

export interface IWorkingHoursFormData {
    frequency_booking: string;
    shop_locations: Array<{ shop_location_id: number | null; shop_location_name?: string | null; hours: Array<IWorkingHoursValue> }> | null;
}

export interface IDataRequest {
    shop_id: number;
    status: boolean;
}

export const InitWorkingHours: IWorkingHoursValue[] = [
    {
        day: 'monday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
    },
    {
        day: 'tuesday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
    },
    {
        day: 'wednesday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
    },
    {
        day: 'thursday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
    },
    {
        day: 'friday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
    },
    {
        day: 'saturday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
    },
    {
        day: 'sunday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
    },
];

export const INTERVAL_OPTIONS = [
    { value: '00:15:00', label: 'Every 15 minutes' },
    { value: '00:30:00', label: 'Every 30 minutes' },
    { value: '00:45:00', label: 'Every 45 minutes' },
    { value: '01:00:00', label: 'Every 1 hour' },
];

export type ImageName = 'logo_image_name' | 'admin_logo_image_name' | null;
export type ImageType = 'logo_image_type' | 'admin_logo_image_type' | null;
export type IShapeType = 'circle' | 'square' | 'rectangle' | 'free_size' | undefined;
