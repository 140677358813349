export const FONT_FAMILY = [
    { value: 'inter', label: 'Inter' },
    { value: 'merriweather', label: 'Merriweather' },
    { value: 'roboto_slab', label: 'Roboto slab' },
    { value: 'roboto', label: 'Roboto' },
    { value: 'plus_jakarta_sans', label: 'Plus Jakarta Sans' },
    { value: 'montserrat', label: 'Montserrat' },
    { value: 'poppins', label: 'Poppins' },
    { value: 'mulish', label: 'Mulish' },
    { value: 'raleway', label: 'Raleway' },
];

export const FONT_WEIGHT = [
    { value: 200, label: 'Thin' },
    { value: 300, label: 'Light' },
    { value: 400, label: 'Regular' },
    { value: 500, label: 'Medium' },
    { value: 600, label: 'Semi Bold' },
    { value: 700, label: 'Bold' },
    { value: 800, label: 'Extra Bold' },
];

export const FONT_FAMILY_WEIGHT = {
    inter: [200, 300, 400, 500, 600, 700, 800, 900],
    merriweather: [300, 400, 700, 900],
    roboto_slab: [300, 400, 500, 600, 700, 800],
    roboto: [100, 300, 400, 500, 700, 900],
    plus_jakarta_sans: [200, 300, 400, 500, 600, 700, 800],
    montserrat: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    poppins: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    mulish: [200, 300, 400, 500, 600, 700, 800, 900],
    raleway: [100, 200, 300, 400, 500, 600, 700, 800, 900],
};

export const LANGUAGES = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'French' },
];
export interface IFormData {
    // slug: string;
    brand_color: string;
    font_family: string;
    base_theme: string;
    menu_placement: string;
    element_style: string;
    primary_language: string;
    secondary_language?: string;
    brand_text_color: string;
    brand_navigation_color: string;
}

export interface IColor {
    name: string;
    hex: string;
}
export const COLORS: IColor[] = [
    { name: 'Teal', hex: '#57908B' },
    { name: 'PurpleBlue', hex: '#5F5790' },
    { name: 'Blue', hex: '#4589FF' },
    { name: 'Gray', hex: '#F4F4F4' },
    { name: 'Black', hex: '#000000' },
    { name: 'Purple', hex: '#800080' },
    { name: 'Pink', hex: '#FF7EB6' },
    { name: 'Red', hex: '#B8001F' },
];
