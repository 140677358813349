import React from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';
import { Clock, CurrencyDollar } from '@untitled-ui/icons-react/build/cjs';

const EditForm = ({ index }: { index: number }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className="flex gap-4">
            <div className="flex font-medium select-box-custom ">
                <Controller
                    name={`services.${index}.duration`}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <InputWithLabel
                            type="number"
                            labelIcon={<Clock className="w-4 h-4" />}
                            labelIconClassName="text-xs !pr-1 flex items-center "
                            inputControlClassName="pl-0"
                            name={`services.${index}.duration`}
                            id="duration"
                            label={t('Duration')}
                            placeholder={t('Enter Duration')}
                            onChange={(e: any) => {
                                onChange(moment.utc(moment.duration(e.target.value, 'minutes').asMilliseconds()).format('HH:mm:ss'));
                            }}
                            value={moment.duration(value).asMinutes()}
                            error={!!error}
                        />
                    )}
                />
            </div>
            <div className="flex items-center space-x-4">
                <Controller
                    name={`services.${index}.price`}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <InputWithLabel
                            type="number"
                            labelIcon={<CurrencyDollar className="w-4 h-4" />}
                            labelIconClassName="text-xs !pr-1 flex items-center "
                            inputControlClassName="pl-0"
                            name={`services.${index}.price`}
                            id="price"
                            label={t('Price')}
                            placeholder={t('Enter Price')}
                            onChange={onChange}
                            value={value}
                            error={!!error}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export default EditForm;
