import { TLanguage } from 'src/redux/services/common/Common.interface';

export interface IStaff {
    name: string;
    is_instgram_button: boolean;
    instgram_button?: string;
    en_description?: string;
    fr_description?: string;
    role: any;
    image?: {
        file: File | null;
        url: string | null;
        name: string | null;
    } | null;
    is_gallery?: boolean;
    gallery?: IImage[] | null;
    location: string[];
}
export interface IImage {
    name: string;
    file: File | null;
    url: string;
}
export interface IFormData {
    status: boolean;
    is_title: boolean;
    en_title?: string;
    fr_title?: string;
    is_subtitle: boolean;
    en_subtitle?: string;
    fr_subtitle?: string;
    is_staff: boolean;
    staff: IStaff[];
}

export const InitStaffData: IStaff = {
    name: '',
    is_instgram_button: false,
    instgram_button: '',
    role: null,
    image: {
        file: null,
        url: null,
        name: null,
    },
    is_gallery: false,
    gallery: null,
    location: [],
};
export const InitRoleOptions = [{ id: 0, label: 'Select Role', value: null }];
export interface TeamProps {
    section: any;
}
export interface FormProps {
    language: TLanguage;
}
