import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosDelete, axiosGet, axiosPost } from 'src/utils/requestClient';
import { errorCode } from 'src/constants/errorCode';
import { IInitialState, ErrorType } from './Staff.interface';

const initialState: IInitialState = {
    data: null,
    loading: false,
    error: null,
};

export const roleList = createAsyncThunk('/shop/staff/role', async (payload: { shop_id: number }, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.STAFF_ROLE.LIST, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const createRole = createAsyncThunk('/shop/staff/role/create', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.STAFF_ROLE.CREATE, payload.payload, { shop_id: payload.shop_id });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const removeRole = createAsyncThunk('/shop/staff/role/delete', async (payload: any, { rejectWithValue }) => {
    try {
        const response: any = await axiosDelete(API.STAFF_ROLE.DELETE, undefined, { shop_id: payload?.shop_id, id: payload?.id });
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const staffSlice = createSlice({
    name: 'shop/staff/details',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(roleList.pending, (state) => {
                state.loading = true;
            })
            .addCase(roleList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(roleList.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});

export const { reducer: accountReducer } = staffSlice;
