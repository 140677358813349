import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatName, getShortName } from 'src/utils/global-functions';
import NoDataMessage from 'src/components/NoDataMessage';
import { ArrowUp, ArrowDown, List } from '@untitled-ui/icons-react/build/cjs';
import { Skeleton } from 'primereact/skeleton';
import Badge from 'src/components/Badge';
import CustomButton from 'src/components/CustomButton';

const LatestCheckout = (props: any) => {
    const { handleAction } = props;
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [isImageError, setIsImageError] = useState<{ [type: string]: { [key: number]: boolean } }>({
        staff: {},
        user: {},
    });
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: 6,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
        },
    });

    useEffect(() => {
        getList();
    }, [lazyState]);

    const getList = () => {
        setIsLoading(true);
        axiosPost(API.BOOKING.LIST, { type: 'checkout', ...lazyState }, { shop_id: shop.id })
            .then((response) => {
                setData(response.data.data.data);
                setTotalRecords(response.data.data.totalRecords);
            })
            .finally(() => setIsLoading(false));
    };

    const handleImageError = (id: number, types: string) => () => {
        setIsImageError((old: any) => ({ ...old, [types]: { ...old[types], [id]: true } }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const getClient = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.user.profile_image_url && !isImageError.user[row.id] ? (
                    <img src={row.user.profile_image_url} alt="" className="min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] object-cover" onError={handleImageError(row.id, 'user')} />
                ) : (
                    getShortName(row.user.full_name)
                )}
            </figure>
            <div>
                <p className="text-xs font-medium text-gray-900 leading-[18px] 2xl:w-[80px] 2xl:max-w-[80px] 2xl:min-w-[80px] 2xlm:min-w-[90px] 2xlm:w-[90px] 2xlm:max-w-[90px] xxl:min-w-[110px] xxl:w-[90px11 xxl:max-w-[110px] truncate">
                    {row.user.full_name}
                </p>
            </div>
        </div>
    );
    const getTeam = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.staff.profile_image_url && !isImageError.staff[row.id] ? (
                    <img src={row.staff.profile_image_url} alt="" className=" w-full h-full object-cover" onError={handleImageError(row.id, 'staff')} />
                ) : (
                    getShortName(row.staff.full_name)
                )}
            </figure>
            <div>
                <p className="text-xs font-normal text-secondaryTxtColor leading-[18px] 2xl:w-[80px] 2xl:max-w-[80px] 2xl:min-w-[80px] 2xlm:min-w-[90px] 2xlm:w-[90px] 2xlm:max-w-[90px]  xxl:min-w-[110px] xxl:w-[90px11 xxl:max-w-[110px] truncate">
                    {formatName(row.staff.full_name)}
                </p>
            </div>
        </div>
    );

    const getStatus = (row: any) => (
        <Badge icon status={row.status === 'confirmed' ? 'pending' : row.status}>
            {row.status === 'confirmed' ? 'Pending' : row.status}
        </Badge>
    );

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map((_, index) => ({
                id: index,
                booking_date: 'loading',
                total_duration: 'loading',
                shop_services: { name: 'loading' },
                shop_admins: { first_name: 'loading' },
                total: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    return (
        <div
            className={`w-full border rounded-xl flex-1 flex-col flex min-h-[494px] mb-5 shadow staff_service_table datatable-custom-service ${
                !totalRecords ? 'datatable-full-height datatable-noshow' : ''
            }`}
        >
            <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                <div>
                    <h2 className="view-table-title">{t('Latest Checkouts')}</h2>
                    <p className="view-table-subtitle">{t('Track overdue and completed appointment checkouts.')}</p>
                </div>
                <div>
                    <CustomButton outlinePrimary onClick={handleAction('checkout')} className="!min-w-[74px]">
                        {t('View All')}
                    </CustomButton>
                </div>
            </div>
            <DataTable
                lazy
                value={isLoading ? skeletons : data}
                totalRecords={totalRecords}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                dataKey="id"
                className="rounded-xl overflow-hidden overflow-x-auto scrollbar-hide"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <div className="rounded-xl flex justify-center items-center">
                        <NoDataMessage
                            title={t('No any latest checkout appointments.')}
                            description={t('No have any pending checkout and completed appointments.')}
                            iconComponent={<List className="text-gray-700" />}
                        />
                    </div>
                }
            >
                <Column
                    sortable
                    field="user_name"
                    header={renderHeader(t('Client'), 'user_name')}
                    body={isLoading ? <Skeleton /> : getClient}
                    className="truncate"
                    style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                ></Column>
                <Column
                    sortable
                    field="staff_name"
                    header={renderHeader(t('Team member'), 'staff_name')}
                    body={isLoading ? <Skeleton /> : getTeam}
                    className="truncate"
                    style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                ></Column>
                <Column
                    sortable
                    field="status"
                    header={renderHeader(t('Checkout Status'), 'status')}
                    body={isLoading ? <Skeleton /> : getStatus}
                    style={{ width: '135px', minWidth: '135px', maxWidth: '135px' }}
                ></Column>
            </DataTable>
        </div>
    );
};

export default LatestCheckout;
