import React from 'react';
import { Link } from 'react-router-dom';
import { APP_FULL_NAME } from 'src/constants/common';
import { Logo, AppStore, PlayStore } from 'src/theme/Images';

const MobileHome = () => (
    <div className="h-[100dvh] flex flex-col flex-1 overflow-hidden">
        <div className="flex shrink-0 justify-center pt-[32px] pb-[24px]">
            <img src={Logo} className="w-[108px]" alt="" title={APP_FULL_NAME} />
        </div>
        <div className="flex-grow-0 overflow-y-auto h-full flex flex-col justify-center items-center">
            <span className="flex flex-wrap justify-center text-center text-gray-900 mb-5 flex-col">
                <span className="font-semibold leading-[29.05px] text-[20px] xs:text-[24px]">For better experience,</span>
                <span className="font-semibold leading-[38.73px] text-[28px] xs:text-[32px]">Please Download App</span>
            </span>
            <div className="flex flex-col gap-4 max-w-[180px] mx-auto">
                <img src={AppStore} className="w-full" alt="" />
                <img src={PlayStore} className="w-full" alt="" />
            </div>
        </div>
        <div className="flex shrink-0 px-[20px] justify-between xs:px-[32px] flex-row gap-2 xs:gap-5 pb-[24px] pt-[24px]">
            <span className="text-gray-500 font-normal text-[12px] leading-[18px] tracking-[-0.01em]">© 2022 flair.io</span>
            <span className="flex flex-row gap-2 xs:gap-5">
                <Link to={''} className="text-primaryBackground font-normal text-[12px] leading-[18px] tracking-[-0.01em]">
                    Terms & Conditions
                </Link>
                <Link to={''} className="text-primaryBackground font-normal text-[12px] leading-[18px] tracking-[-0.01em]">
                    Privacy Policy
                </Link>
            </span>
        </div>
    </div>
);

export default MobileHome;
