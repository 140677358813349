import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { FormProps, IFormData, InitServiceInfoData } from './Service.interface';
import Button from 'src/components/Button';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import Switch from 'src/components/Switch/Switch';
import { Collapse } from '../Collapse';
import { CHARACTERS_LIMIT } from 'src/constants/common';

const Form = (props: FormProps) => {
    const { language } = props;
    const { t } = useTranslation();

    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext<IFormData>();

    const { remove, append, fields } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'services',
    });

    const removeService = (index: number) => () => {
        remove(index);
    };

    const addService = () => {
        InitServiceInfoData && append(InitServiceInfoData);
    };

    return (
        <>
            {fields?.map((item: any, index: number) => (
                <div key={item.uuid} className={`rounded-xl border border-borderSecondary mb-4 ${errors.services?.[index] ? 'is-invalid' : ''}`}>
                    <Collapse title={watch(`services.${index}.${language}_name`) || `${t('Service Name')}`} classNames="!p-4 !border-borderSecondary" handleRemove={removeService(index)}>
                        <div className="lex gap-4 relative  border-borderPrimary p-4">
                            {/* {
                        <div className="text-right">
                            <Button type="button" onClick={() => remove(index)} className="text-dangerErrorText text-xs font-medium">
                                Remove
                            </Button>
                        </div>
                    } */}
                            <div className="flex gap-4 location-dropdown-custom mb-4">
                                <Controller
                                    name={`services.${index}.${language}_name`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                name={`services.${index}.${language}_name`}
                                                id={`services.${index}.${language}_name`}
                                                label={t('Service name')}
                                                placeholder={t('Enter service name')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                required
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name={`services.${index}.price`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                required
                                                name={`staff.${index}.price`}
                                                id={`services.${index}.price`}
                                                label={t('Price')}
                                                placeholder={t('Enter service price')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-full">
                                <Controller
                                    name={`services.${index}.${language}_description`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id={`services.${index}.${language}_description`}
                                                name={`services.${index}.${language}_description`}
                                                label={t('Description')}
                                                textArea
                                                placeholder={t('Enter service description')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                rows={15}
                                                maxLenght={CHARACTERS_LIMIT.LONG}
                                                textAearClassName="rounded-md h-[100px]"
                                                toggle={
                                                    <Controller
                                                        name={`services.${index}.is_description`}
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </Collapse>
                </div>
            ))}

            <div className="flex justify-end items-center !mt-2 !mb-2">
                <Button type="button" onClick={addService} className="text-xs font-medium text-blue-600 flex gap-1 focus:shadow-none">
                    <Plus width={16} />
                    <span className="">{t('Add new service')}</span>
                </Button>
            </div>
        </>
    );
};
export default Form;
