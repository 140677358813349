import React, { ReactNode } from 'react';
import { GoDotFill } from 'react-icons/go';
import { getStatusColor } from 'src/utils/global-functions';

interface ITypeProp {
    children: ReactNode;
    status?: string;
    icon?: boolean;
    isLoading?: boolean;
}

const Badge = ({ children, icon, status = 'default', isLoading = false }: ITypeProp) => (
    <div className={`userCounter ${isLoading ? 'custom-loading' : ''}`}>
        {icon && <GoDotFill size={12} color={getStatusColor(status)} className="h-3 w-3 rounded-full flex mr-0.5" />}
        {children}
    </div>
);

export default Badge;
