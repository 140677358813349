import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';

import { axiosPatch, axiosGet } from 'src/utils/requestClient';
import { IActionType, ICalendar } from './Calendar.Interface';
import moment from 'moment';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}
export const initAction: IActionType = {
    newAppointment: false,
    bookedAppointment: false,
    updateAppointment: false,
    cancelAppointment: false,
    confirmedCancelAppointment: false,
    paymentAppointment: false,
    checkoutAppointment: false,
    completedPaymentAppointment: false,
    blockTime: false,
    rescheduleAppointment: false,
    waitList: true,
    noShowAppointment: false,
};

const bookingClearState = {
    // selectedSlotStaffId: null,
    // selectedSlotStaff: null,
    selectedClientInfo: null,
    isSidebarOpen: false,
    bookedSlotInfo: null,
    selectedDate: moment().toDate(),
    selectedTime: moment().toDate(),
    overlapBooking: {
        status: false,
        message: '',
        info: null,
    },
    payments: {
        payments: [],
        tip: 0,
        extra_payment_method: undefined,
    },
};

export const initialState: ICalendar = {
    data: {
        timeZone: null,
        isLoading: false,
        getBookingCalendarDate: moment().toDate(),
        view: 'day',
        calendarStep: initAction,
        bookedSlotInfo: null,
        serviceList: [],
        resourceList: [],
        selectedStaff: null,
        selectedLocation: null,
        selectedDate: moment().toDate(),
        selectedTime: moment().toDate(),
        selectedSlotStaffId: null,
        selectedSlotStaff: null,
        selectedClientInfo: null,
        isSidebarOpen: false,
        getBookingList: false,
        isTerminalLoading: false,
        overlapBooking: {
            status: false,
            message: '',
            info: null,
        },
        payments: {
            payments: [],
            tip: 0,
            extra_payment_method: undefined,
        },
        cancelAppointment: {
            type: null,
            status: false,
            message: '',
        },
    },
    loading: false,
    error: null,
};

export const getReceipt = createAsyncThunk('/shop/booking/receipt', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.BOOKING.RECEIPT, payload.params, payload.payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const updateAppointmentStatus = createAsyncThunk('/shop/booking/status', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.BOOKING.UPDATE_STATUS, payload.payload, {
            shop_id: payload.shop_id,
            id: payload.id,
        });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const CalendarSlice = createSlice({
    name: '/shop/calendar',
    initialState,
    reducers: {
        setData: (state, { payload }) => {
            state.data = {
                ...state.data, // Copy existing properties
                ...payload, // Override with new properties
            };
        },
        setClient: (state, { payload }) => {
            state.data.selectedClientInfo = {
                ...state.data.selectedClientInfo,
                ...payload,
            };
        },
        setClearBooking: (state) => {
            state.data = {
                ...state.data, // Copy existing properties
                ...bookingClearState, // Override with new properties
            };
        },
        setClearPayment: (state) => {
            state.data = {
                ...state.data, // Copy existing properties
                payments: {
                    payments: [],
                    tip: 0,
                    extra_payment_method: undefined,
                }, // Override with new properties
            };
        },
        setPayment: (state, { payload }) => {
            state.data.payments = {
                ...state.data.payments, // Copy existing properties
                ...payload, // Override with new properties
            };
        },
        setCalendarStep: (state, { payload }) => {
            state.data.calendarStep = Object.keys(initAction).reduce((acc, key) => {
                acc[key as keyof IActionType] = key === payload; // Only the activeKey is true, others false
                return acc;
            }, {} as IActionType);
        },
        setOverlapBooking: (state, { payload }) => {
            state.data.overlapBooking = payload;
        },
    },
});

export const { reducer: accountReducer } = CalendarSlice;
export const { setData, setClient, setClearBooking, setCalendarStep, setPayment, setClearPayment, setOverlapBooking } = CalendarSlice.actions;

export const selectCalendarData = (state: RootState) => state.CalendarInfo.data;
