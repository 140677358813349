import React, { FC } from 'react';
import { ICommonKpiCardd } from './CommonKpiCard.interface';
import { ArrowDown, ArrowUp } from '@untitled-ui/icons-react/build/cjs';

const CommonKpiCard: FC<ICommonKpiCardd> = ({ title, isLoading, description, signIcon, growth, fromDate }) =>
    isLoading ? (
        <>
            {/* <div key={key} className="border flex flex-col rounded-lg p-3">
                <p className="text-gray-600 text-xs font-normal mb-1.5 custom-loading">title</p>
                <p className="text-gray-900 font-semibold text-2xl pt-2 custom-loading">description</p>
            </div> */}
            <div className="rounded-lg border border-[#eaecf0] p-3">
                <div className="w-full justify-between flex items-start">
                    <h6 className="text-gray-600 text-xs font-medium mb-1.5 custom-loading">title</h6>
                </div>

                <div className="w-full flex flex-col justify-end pt-2 ">
                    <div className="flex">
                        <span className="font-semibold text-2xl custom-loading">description</span>
                        {signIcon && <sup className="text-sm font-semibold leading-tight xxl:top-[1px]">{signIcon}</sup>}
                    </div>

                    <div className="w-fit flex justify-center items-center mt-2 custom-loading">
                        <ArrowUp className="w-4 h-4 mr-[2px] max-xlm:w-4 max-xlm:h-4  text-[#17B26A]" />

                        <p className="text-gray-600 font-medium xxl:text-xs xl:text-[11px] text-xs ">
                            <span className="xxl:text-sm text-xs">1.49% </span>
                            vs. Last 30 Days
                        </p>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <>
            {/* <div key={key} className="border flex flex-col rounded-lg p-3">
                <p className={`text-gray-600 text-xs font-normal mb-1.5 `}>{title}</p>
                <p className={`text-gray-900 font-semibold text-2xl pt-2 ${isLoading ? 'custom-loading' : ''}`}>{description}</p>
            </div> */}
            <div className="rounded-lg border border-[#eaecf0] p-3">
                <div className="w-full justify-between flex items-start">
                    <h6 className="text-gray-600 text-xs font-medium mb-1.5">{title}</h6>
                </div>

                <div className="w-full flex flex-col justify-end pt-2">
                    <div className="flex">
                        <span className="font-semibold text-2xl">{description}</span>
                        {signIcon && <sup className="text-sm font-semibold leading-tight top-[2px]">{signIcon}</sup>}
                    </div>

                    <div className="w-fit flex justify-center items-center mt-2 ">
                        {/* <ArrowUp className="w-4 h-4 mr-[2px] max-xlm:w-4 max-xlm:h-4  text-[#17B26A]" /> */}

                        {growth >= 0 ? (
                            <ArrowUp className="w-4 h-4 mr-[2px] max-xlm:w-4 max-xlm:h-4  text-[#17B26A]" />
                        ) : (
                            <ArrowDown className="w-4 h-4 mr-[2px] max-xlm:w-4 max-xlm:h-4 text-[#ff4d54] " />
                        )}

                        <p className="text-gray-600 font-medium xxl:text-xs xl:text-[11px] text-xs capitalize">
                            <span className="xxl:text-sm text-xs">{`${Math.abs(growth)}%`} </span>
                            {`vs. ${fromDate}`}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );

export default CommonKpiCard;
