export const GLOBALVARIABLE = {
    accountImageSize: 4096,
    locationImageSize: 800,
    appearanceImageSize: 800,
    forNoValidation: 800000000,
    aboutImageSize: 800,
    heroImageSize: 800,
    productImageSize: 2000,
    locationBannerImageSize: 800,
    galleryImageSize: 2000,
};
export const FILE_VALIDATION = {
    ACCOUNT: {
        size: 4096,
        minWidth: 500,
        minHeight: 500,
    },
    LOCATION_PROFILE: {
        size: 2048,
        minWidth: 500,
        minHeight: 500,
    },
    LOCATION_BANNER: {
        size: 2048,
        minWidth: 820,
        minHeight: 700,
    },
    PRODUCT: {
        size: 2048,
        minWidth: 500,
        minHeight: 500,
    },
    APPEARANCE: {
        HERO: {
            size: 2048,
            minWidth: 820,
            minHeight: 900,
        },
        LOCATION: {
            size: 2048,
            minWidth: 900,
            minHeight: 470,
        },
        ACADEMY: {
            size: 2048,
            minWidth: 760,
            minHeight: 470,
        },
        TEAM: {
            size: 2048,
            minWidth: 428,
            minHeight: 592,
        },
        ABOUT: {
            size: 2048,
            minWidth: 760,
            minHeight: 470,
        },
        TESTIMONIAL: {
            size: 2048,
            minWidth: 400,
            minHeight: 400,
        },
    },
    SETTING: {
        BUSINESS_LOGO: {
            size: 4096,
            minWidth: 400,
            minHeight: 400,
        },
        BUSINESS_ICON: {
            size: 4096,
            minWidth: 400,
            minHeight: 400,
        },
        ACCOUNT: {
            size: 4096,
            minWidth: 760,
            minHeight: 470,
        },
        PROFILE: {
            size: 4096,
            minWidth: 500,
            minHeight: 500,
        },
    },
};

export const ColorKeysToCheck = ['accent_color', 'button_color', 'cards_color', 'content_background', 'main_text_color', 'secondary_text_color'];

export const LightThemeCustomColorId = 6;

export const DarkThemeCustomColorId = 12;

export const perPageOptions = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '150', value: 150 },
    { label: '200', value: 200 },
];

export const AllLocationsOptions = { label: 'All Locations', value: null };
export const AllRolesOptions = { label: 'All Roles', value: null };
export const AllTeamMembersOptions = { label: 'All Team Members', value: null };
