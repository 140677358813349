export interface ExistingFormData {
    [key: string]: any;
}
export interface IFormData {
    en_name?: string;
    fr_name?: string;
    en_description?: string;
    fr_description?: string;
    type: string;
    is_active: boolean;
    shop_category_id: string;
    shop_location_id: any[];
    variables?: any;
    starting_price?: boolean;
    price?: number;
    duration?: string | null;
}

export interface ISelectBox {
    label: string;
    value: string;
}

export const initAction = {
    id: null,
    delete: false,
};

export const DURATIONS = [
    { value: '00:05:00', label: '5 minutes' },
    { value: '00:10:00', label: '10 minutes' },
    { value: '00:15:00', label: '15 minutes' },
    { value: '00:20:00', label: '20 minutes' },
    { value: '00:25:00', label: '25 minutes' },
    { value: '00:30:00', label: '30 minutes' },
    { value: '00:35:00', label: '35 minutes' },
    { value: '00:40:00', label: '40 minutes' },
    { value: '00:45:00', label: '45 minutes' },
    { value: '00:50:00', label: '50 minutes' },
    { value: '00:55:00', label: '55 minutes' },
    { value: '01:00:00', label: '1 hour' },
    { value: '01:05:00', label: '1 hour 5 minutes' },
    { value: '01:10:00', label: '1 hour 10 minutes' },
    { value: '01:15:00', label: '1 hour 15 minutes' },
    { value: '01:20:00', label: '1 hour 20 minutes' },
    { value: '01:25:00', label: '1 hour 25 minutes' },
    { value: '01:30:00', label: '1 hour 30 minutes' },
];

export interface IStaffFormData {
    rows: Array<IStaffRows>;
    subRows: { [key: string]: Array<IStaffRows> };
}

export interface IStaffRows {
    is_add?: boolean;
    starting_price?: boolean;
    price?: number;
    duration?: string;
}
