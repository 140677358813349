import { TLanguage } from 'src/redux/services/common/Common.interface';

export interface ITranslation {
    description: string;
    id: string | number;
    locale: TLanguage;
    name: string;
    shop_service_id: string | number;
}

export interface IService {
    en_name?: string;
    fr_name?: string;
    price: string;
    is_description: boolean;
    en_description?: string;
    fr_description?: string;
    translations?: ITranslation[];
}

export interface IFormData {
    status: boolean;
    is_title: boolean;
    en_title?: string;
    fr_title?: string;
    is_subtitle: boolean;
    en_subtitle?: string;
    fr_subtitle?: string;
    services: IService[];
}

export const InitServiceInfoData: IService = {
    en_name: '',
    fr_name: '',
    price: '',
    is_description: true,
    en_description: '',
    fr_description: '',
};

export interface ServiceProps {
    section: any;
}

export interface FormProps {
    language: TLanguage;
}
