import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { AppearanceProduct } from 'src/theme/Images';
import { Collapse } from '../Collapse';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { IFormData, IProduct, ProductProps } from './Product.interface';
import Form from './Form';
import TemplateLayout from '../Layout/Layout';
import { allShopProducts, currentLanguage, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import Switch from 'src/components/Switch/Switch';
import CustomButton from 'src/components/CustomButton';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import { CHARACTERS_LIMIT } from 'src/constants/common';
import { useLanguage } from 'src/hooks/useCommon';
import RadioSwitch from 'src/components/RadioSwitch';

const Product: FC<ProductProps> = (props) => {
    const { section } = props;
    const { t } = useTranslation();
    const { languageOptions, languages, handleLanguage, handleErrorsAndSetLanguage } = useLanguage();
    const dispatch = useAppDispatch();
    const products = useAppSelector(allShopProducts);
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const language = useAppSelector(currentLanguage);

    const schemaFields: any = {
        status: Yup.bool().required(t('This field is required')),
        is_title: Yup.bool().required(t('This field is required')),
        is_sync: Yup.bool().required(t('This field is required')),
        is_subtitle: Yup.bool().required(t('This field is required')),
        products: Yup.array()
            .test({
                name: 'products',
                message: 'Please add at least one product',
                test: function (value) {
                    const { status } = this.parent;
                    if (status && (!value || value.length === 0)) {
                        return false;
                    }
                    return true;
                },
            })
            .of(
                Yup.object().shape({
                    name: Yup.string().required(t('This field is required')),
                    price: Yup.string().required(t('This field is required')),
                    button: Yup.string().required(t('This field is required')),
                    category: Yup.string().required(t('This field is required')),
                    is_description: Yup.bool().required(t('This field is required')),
                    description: Yup.string()
                        .nullable()
                        .when('is_description', ([is_description], customSchema) => (is_description ? customSchema.required(t('This field is required')) : customSchema.nullable())),

                    images: Yup.array()
                        .test({
                            name: 'image',
                            message: 'Please add at least one image',
                            test: function (value) {
                                const { status } = this.parent;
                                if (status && (!value || value.length === 0)) {
                                    return false;
                                }
                                return true;
                            },
                        })
                        .of(
                            Yup.object()
                                .shape({
                                    name: Yup.string().required(t('This field is required')),
                                    file: Yup.mixed().nullable(),
                                    url: Yup.string().nullable(),
                                })
                                .test('image-validation', t('This field is required'), function (value) {
                                    if (!value) {
                                        return this.createError({
                                            path: `${this.path}`,
                                            message: t('This field is required'),
                                        });
                                    }
                                    const { name, url } = value;
                                    if (!name || !url) {
                                        return this.createError({
                                            path: `${this.path}`,
                                            message: t('This field is required'),
                                        });
                                    }
                                    return true;
                                }),
                        )
                        .required(),
                }),
            )
            .when('status', ([status], customSchema) => (status ? customSchema.required() : customSchema.nullable()))
            .nullable(),
    };
    languages.forEach((name: string) => {
        schemaFields[`${name}_title`] = Yup.string().when('is_title', ([is_title], customSchema) =>
            is_title ? customSchema.required(t('This field is required.')).max(CHARACTERS_LIMIT.SHORT, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.SHORT })) : customSchema.nullable(),
        );
        schemaFields[`${name}_subtitle`] = Yup.string().when('is_subtitle', ([is_subtitle], customSchema) =>
            is_subtitle ? customSchema.required('This field is required.').max(CHARACTERS_LIMIT.MEDIUM, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.MEDIUM })) : customSchema.nullable(),
        );
    });
    const schema = Yup.object(schemaFields);

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as unknown as Resolver<IFormData>,
    });

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = methods;
    const isStatus = watch('status');
    const isSync = watch('is_sync');

    useEffect(() => {
        let existingData: IFormData = {
            status: false,
            is_title: false,
            is_subtitle: false,
            products: [],
            is_sync: false,
        };
        languages.forEach((locale) => {
            existingData[`${locale}_title`] = '';
            existingData[`${locale}_subtitle`] = '';
        });
        if (section) {
            const updatedProducts: IProduct[] = section.is_sync ? getProducts() : [];
            existingData = {
                status: section.status || false,
                is_title: section.is_title || false,
                is_subtitle: section.is_subtitle || false,
                products: updatedProducts || [],
                is_sync: section.is_sync || false,
            };
            languages.forEach((locale) => {
                existingData[`${locale}_title`] = section[`${locale}_title`] || '';
                existingData[`${locale}_subtitle`] = section[`${locale}_subtitle`] || '';
            });
        }
        Object.entries(existingData).forEach(([key, value]) => {
            setValue(key as keyof IFormData, value);
        });
        reset(existingData);
    }, [section]);

    const handleSync = () => {
        // event.stopPropagation();
        const updatedProducts: IProduct[] = !isSync ? getProducts() : [];
        setValue('products', updatedProducts);
        setValue('is_sync', !isSync);
    };

    const getProducts = () => {
        const updatedProducts = products.map((product: any) => ({
            name: product.name,
            category: product.category.name,
            price: product.variants[0].price,
            description: product.description,
            button: 'ADD TO ORDER',
            is_description: product.description ? true : false,
            images: product?.images?.map((image: any) => {
                if (image) {
                    return {
                        name: image.image_name,
                        url: (image.image_name && image.image_url) ?? null,
                    };
                }
                return null;
            }),
        }));
        return updatedProducts;
    };

    const handleChange = (data: any) => {
        setIsLoading(true);
        delete data.products;
        // const imagePromises = data.products.map((product: any) =>
        //     product?.images?.map((image: any) => (image?.file ? s3Upload(image.file, `${s3Path.SHOP_PRODUCT}${image.name}`) : Promise.resolve(null))),
        // );

        // if (imagePromises) {
        //     const imageResponses = await Promise.allSettled(imagePromises);
        //     const isSuccess = imageResponses.every((response: any) => response.status === 'fulfilled' && (response.value?.status === errorCode.updateSuccess || response?.value === null));
        //     if (isSuccess) {
        //         imageStatus = errorCode.updateSuccess;
        //     }
        // }

        // if (imageStatus === errorCode.updateSuccess) {
        const payload = {
            product: { ...data, is_sync: data.status ? data.is_sync : false },
            section: 'product',
        };
        axiosPost(API.THEME.TEMPLATE.SECTION, payload, { shop_id: shop.id })
            .then(async () => {
                await dispatch(getAllShopSettings({ shop_id: shop.id }));
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            const fieldKey = field.replace('product.', '');
                            setError(fieldKey as keyof IFormData, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };

    useFormErrorFocus<IFormData>({ errors, formSectionName: 'product' });
    const handleError = (err: any) => handleErrorsAndSetLanguage(err);
    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit(handleChange, handleError)}
                className={`h-full ${errors?.products && errors?.products?.message ? 'is-invalid rounded-xl border' : 'rounded-xl border border-borderPrimary'}`}
            >
                <Collapse
                    title={t('Products')}
                    description={t('The Products section displays and describes grooming products available for purchase at your brand.')}
                    isCollapsed={isStatus}
                    isLoading={isLoading}
                    handleStatus={handleStatus}
                    handleSave={handleSubmit(handleChange, handleError)}
                >
                    {languages.length > 1 && (
                        <div className="flex justify-end mt-5 mx-5">
                            <RadioSwitch options={languageOptions} name="product_language" value={language} onChange={handleLanguage} />
                        </div>
                    )}
                    <TemplateLayout ImgProp={AppearanceProduct} key={language}>
                        <div>
                            <div className="flex justify-between items-start mb-4 gap-4">
                                <Controller
                                    name={`${language}_title`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id={`${language}_title`}
                                                name={`${language}_title`}
                                                label={t('Title')}
                                                placeholder={t('Enter product title')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name={'is_title'}
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name={`${language}_subtitle`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id={`${language}_subtitle`}
                                                name={`${language}_subtitle`}
                                                label={t('Subtitle')}
                                                placeholder={t('Enter product subtitle')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name={'is_subtitle'}
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <Form />
                        </div>
                        {errors.products && errors?.products?.message && <p className="text-error text-center">{errors?.products?.message}</p>}
                    </TemplateLayout>
                    <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end gap-4">
                        {products && products?.length > 0 && (
                            <CustomButton type="button" secondary onClick={handleSync}>
                                <span className={`h-[8px] w-[8px] ${isSync ? 'bg-[#D9512C]' : 'bg-[#17B26A]'} rounded-full`}></span>
                                <span className="flex items-center ">{isSync ? 'Unsync' : 'Sync'}</span>
                            </CustomButton>
                        )}
                        <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </Collapse>
            </form>
        </FormProvider>
    );
};

export default Product;
