import React, { useState } from 'react';
import Tags from '@yaireo/tagify/dist/react.tagify';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { GoogleIcon, GoogleIdInfo1, GoogleIdInfo2, InstagramColor, SocialIcon } from 'src/theme/Images';
import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import { useRolePermission } from 'src/hooks/useRolePermission';

import PopupModal from 'src/components/PopupModal';

const OnlineLinks = ({ errors }: any) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const [isGoogleInfoModal, setIsGoogleInfoModal] = useState<boolean>(false);
    const handleGoogleInfoModal = () => {
        setIsGoogleInfoModal(!isGoogleInfoModal);
    };

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <GoogleIcon />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{'Find Your Google Reviews Business ID'}</p>

                <span className="text-xs font-normal text-gray-500">{'Steps to find your Google Business ID'}</span>
            </div>
        </div>
    );
    const { isAdmin } = useRolePermission();

    return (
        <>
            <div className="w-full flex flex-row gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]  flex flex-col">
                    <p className="title-text">{t('Social Media Links')}</p>
                    <p className="subtitle-text">{t('Add links to your brand’s social media accounts.')}</p>
                </div>
                <div className="flex flex-col max-2xl:w-[400px] w-[512px] ">
                    <Controller
                        name="instagram_page"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="w-full pt-1 pb-5">
                                <InputWithLabel
                                    placeholder="Instagram page"
                                    onChange={onChange}
                                    value={value}
                                    name="instagram_page"
                                    id="instagram_page"
                                    error={!!error}
                                    disabled={!isAdmin}
                                    labelIcon={
                                        <div className="flex flex-row gap-1.5 items-center">
                                            <InstagramColor />
                                            <p className="text-xs font-medium text-mainTextColor">instagram.com/</p>
                                        </div>
                                    }
                                    labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                    inputControlClassName="pl-3"
                                />
                            </div>
                        )}
                    />
                    <Controller
                        name="facebook_page"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="w-full pb-5">
                                <InputWithLabel
                                    placeholder="Facebook page"
                                    onChange={onChange}
                                    value={value}
                                    name="facebook_page"
                                    id="facebook_page"
                                    disabled={!isAdmin}
                                    labelIcon={
                                        <div className="flex flex-row gap-1.5 items-center">
                                            <SocialIcon />
                                            <p className="text-xs font-medium text-mainTextColor">facebook.com/</p>
                                        </div>
                                    }
                                    labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                    inputControlClassName="pl-3"
                                    error={!!error}
                                />
                            </div>
                        )}
                    />
                    <Controller
                        name="google_review"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="relative w-full brand_social pb-4">
                                <InputWithLabel
                                    placeholder="Google review page"
                                    onChange={onChange}
                                    disabled={!isAdmin}
                                    value={value}
                                    name="google_review"
                                    id="google_review"
                                    labelIcon={
                                        <div className="flex flex-row gap-1.5 items-center">
                                            <GoogleIcon />
                                            <p className="text-xs font-medium text-mainTextColor">google.com/</p>
                                        </div>
                                    }
                                    labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                    inputControlClassName="pl-3"
                                    error={!!error}
                                    labelEndIconClassName="!border-l-0"
                                    labelEndIcon={
                                        <div className="flex flex-row gap-1.5 items-center ">
                                            <InfoCircle className={` top-2 right-2  rounded-full text-[#98A2B3] w-4 h-4 !bg-transparent cursor-pointer`} onClick={handleGoogleInfoModal} />
                                        </div>
                                    }
                                />
                                {errors && errors?.google_review && <p className="text-error">{errors?.google_review?.message}</p>}
                            </div>
                        )}
                    />
                </div>
            </div>
            {isAdmin && (
                <div className="w-full flex flex-row gap-[32px] border-b  border-borderSecondary py-4">
                    <div className=" w-[310px]  flex flex-col">
                        <p className="title-text">{t('Additional Admin Accounts')}</p>
                        <p className="subtitle-text">{t('Invite team members with admin privileges.')}</p>
                    </div>
                    <div className="flex flex-col max-2xl:w-[400px] w-[512px] ">
                        <Controller
                            name="admin_emails"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <div id="admin_emails" className={`max-2xl:w-[400px] w-[512px] !h-auto hover:border-black ${errors.admin_emails ? 'is-invalid' : ''}`}>
                                    <Tags
                                        placeholder="Add admin emails"
                                        settings={{
                                            maxTags: 4,
                                            dropdown: {
                                                enabled: 0,
                                            },
                                            callbacks: {
                                                add: (e) => {
                                                    onChange(e.detail.tagify.value);
                                                },
                                                remove: (e) => {
                                                    onChange(e.detail.tagify.value);
                                                },
                                            },
                                        }}
                                        value={value}
                                        readOnly={!isAdmin}
                                        className="rounded-lg h-[36px]"
                                    />
                                </div>
                            )}
                        />
                    </div>
                </div>
            )}

            {isGoogleInfoModal && (
                <PopupModal
                    dismissible
                    onClose={handleGoogleInfoModal}
                    size="w-[500px]"
                    className="px-5 py-5"
                    title={<Title />}
                    primaryButton={t('Okay')}
                    secondaryButton={t('Cancel')}
                    declineAction={handleGoogleInfoModal}
                    acceptAction={handleGoogleInfoModal}
                    fixedHeightClass="h-[500px]"
                    // isLoading={isLoading}
                >
                    <>
                        <div className="flex flex-col gap-1.5 mb-5">
                            <div className="text-base font-semibold text-mainTextColor">How to get your Google review link</div>
                            <div className="text-sm font-normal text-secondaryTxtColor"> There are a few ways to get your Google review link, all of which are pretty simple.</div>
                        </div>
                        <div className="bg-[#F9FAFB] p-4 flex flex-col gap-2">
                            <div className="text-sm font-normal text-secondaryTxtColor">
                                1. Log into your <span className="underline decoration-solid text-[#2D67F6] cursor-pointer">Google Business Profile.</span>
                            </div>
                            <div className="text-sm font-normal text-secondaryTxtColor">2. Look for your business on Google Search and click the link that states the number of reviews you have.</div>
                            <div className="text-sm font-normal text-secondaryTxtColor">
                                3. Now click on <span className="font-semibold">“Ask for reviews”</span> button.
                            </div>
                            <img src={GoogleIdInfo1} alt="" />
                            <div className="text-sm font-normal text-secondaryTxtColor">
                                4. Then open modal named <span className="font-semibold">“Get more reviews”</span> which have review link.
                            </div>
                            <img src={GoogleIdInfo2} alt="" />
                        </div>
                    </>
                </PopupModal>
            )}
        </>
    );
};

export default OnlineLinks;
