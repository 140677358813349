import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Overview from './Overview';
import StaffUsers from './StaffUsers';
import StaffServices from './StaffServices';
import StaffPayment from './StaffPayment';
import StaffHeatMap from './StaffHeatMap';
import Analytics from './Analytics';

const StaffTab = ({ staff }: any) => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState(staff.pay_structure.pay_structure_type === 'partner' ? 'services' : staff.pay_structure.pay_structure_type === 'rent' ? 'payment' : 'overview');

    const handleTab = (tab: any) => () => {
        setCurrentTab(tab.name);
        document.title = tab.title;
    };

    const TABS = useMemo(() => {
        switch (staff.pay_structure.pay_structure_type) {
            case 'commission':
                return [
                    {
                        display_name: t('Overview'),
                        name: 'overview',
                        title: t('Titles.Team Overview'),
                    },
                    {
                        display_name: t('Client List'),
                        name: 'client',
                        title: t('Titles.Team Client List'),
                    },
                    {
                        display_name: t('Services'),
                        name: 'services',
                        title: t('Titles.Team Services'),
                    },
                    {
                        display_name: t('Payment History'),
                        name: 'payment',
                        title: t('Titles.Team Payment History'),
                    },
                    {
                        display_name: t('Heat Map'),
                        name: 'heat',
                        title: t('Titles.Team Heat Map'),
                    },
                    {
                        display_name: t('Analytics'),
                        name: 'analytics',
                        title: t('Titles.Team Analytics'),
                    },
                ];
            case 'rent':
                return [
                    {
                        display_name: t('Rent Collection History'),
                        name: 'payment',
                        title: t('Titles.Team Overview'),
                    },
                    {
                        display_name: t('Services'),
                        name: 'services',
                        title: t('Titles.Team Services'),
                    },
                ];
            case 'partner':
                return [
                    {
                        display_name: t('Services'),
                        name: 'services',
                        title: t('Titles.Team Services'),
                    },
                ];
            case 'no_tracking':
            case 'self':
                return [
                    {
                        display_name: t('Overview'),
                        name: 'overview',
                        title: t('Titles.Team Overview'),
                    },
                    {
                        display_name: t('Client List'),
                        name: 'client',
                        title: t('Titles.Team Client List'),
                    },
                    {
                        display_name: t('Services'),
                        name: 'services',
                        title: t('Titles.Team Services'),
                    },
                    {
                        display_name: t('Heat Map'),
                        name: 'heat',
                        title: t('Titles.Team Heat Map'),
                    },
                    {
                        display_name: t('Analytics'),
                        name: 'analytics',
                        title: t('Titles.Team Analytics'),
                    },
                ];
            default:
                return [];
        }
    }, []);

    const TabData = ({ tabs, currentStaff }: any) => {
        switch (tabs) {
            case 'overview':
                return <Overview staff={currentStaff} />;
            case 'client':
                return <StaffUsers staff={currentStaff} />;
            case 'services':
                return <StaffServices staff={currentStaff} />;
            case 'payment':
                return <StaffPayment staff={currentStaff} />;
            case 'heat':
                return <StaffHeatMap staff={currentStaff} />;
            case 'analytics':
                return <Analytics />;
            default:
                return <></>;
        }
    };

    return (
        <>
            <div className="fl-tab-btn-view w-full">
                {TABS.map((tab: any, index) => (
                    <button
                        key={index}
                        type="button"
                        onClick={handleTab(tab)}
                        className={`w-full fl-tab-link !min-w-fit 2xl:!max-w-[182px]  ${tab.name === currentTab ? '!text-primary !bg-[#E0EAFF]' : ''}`}
                    >
                        {tab.display_name}
                    </button>
                ))}
            </div>
            <TabData tabs={currentTab} currentStaff={staff} />
        </>
    );
};

export default memo(StaffTab);
