import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { Box, ArrowUp, ArrowDown, Edit01, Trash01, Plus } from '@untitled-ui/icons-react/build/cjs';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import NoDataMessage from 'src/components/NoDataMessage';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosDelete, axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import NoteForm from './NoteForm';
import { noteInitAction } from './Client.interface';
import { getShortName } from 'src/utils/global-functions';
import { PaginatorTemplate } from 'src/utils/global-component';

const Notes = ({ client }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(true);
    const [transactionList, setTransactionList] = useState([]);
    const [totalTransaction, setTotalTransaction] = useState<number>(0);
    const [action, setAction] = useState(noteInitAction);

    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: perPageOptions[0].value,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
        },
    });

    useEffect(() => {
        getNoteList();
    }, [lazyState, shop]);

    const getNoteList = async () => {
        setIsLoading(true);
        await axiosGet(API.CLIENT.NOTE, { shop_id: shop.id, client_id: client?.id }, lazyState)
            .then((response) => {
                setTransactionList(response.data.data.data);
                setTotalTransaction(response.data.data.totalRecords);
            })
            .finally(() => setIsLoading(false));
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const handleModalClose = useCallback(
        (type: boolean = false) =>
            async () => {
                if (type) {
                    if (action.noteData && action.noteData.id && action.delete) {
                        setIsLoading(true);
                        const params = {
                            shop_id: shop.id,
                            client_id: client.id,
                            id: action.noteData.id,
                        };
                        await axiosDelete(API.CLIENT.NOTEDELETE, {}, params)
                            .then(() => getNoteList())
                            .finally(() => setIsLoading(false));
                    }
                }
                setAction(noteInitAction);
            },
        [action],
    );

    const getAction = (row: any) => (
        <div className="flex items-center gap-4 justify-end">
            <CustomButton className="shadow-none w-4 !px-0 text-gray-600 hover:text-gray-900 !pr-[4px]" onClick={handleNote('update', row)}>
                <Edit01 className="h-4 w-4" />
            </CustomButton>
            <CustomButton className="shadow-none text-gray-600 hover:text-gray-900 w-4 !px-0" onClick={handleNote('delete', row)}>
                <Trash01 className="h-4 w-4 " />
            </CustomButton>
        </div>
    );

    const getFullName = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.user.profile_image_url ? <img src={row.user.profile_image_url} alt="" title={row.user.full_name} className="w-full h-full object-cover" /> : getShortName(row.user.full_name)}
            </figure>
            <div>
                <p className="text-xs font-medium ">{row.user.full_name}</p>
                {/* <p className="text-xs font-normal text-secondaryTxtColor">{row.user.staff_role.name}</p> */}
            </div>
        </div>
    );
    const GetDescription = (row: any) => `${row.description}`;

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                shop_services: { name: 'loading' },
                bookings: { subtotal: 'loading', taxes_and_fees: 'loading', total: 'loading', payment_type: 'loading' },
                payment_method: 'loading',
                payment_intent_id: 'loading',
                average_spend: 'loading',
                tip: 'loading',
                created_at: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );

    const handleNote =
        (type: string, noteData: any = null) =>
        () => {
            setAction((old) => ({ ...old, [type]: true, noteData }));
        };
    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    return (
        <div className="w-full border rounded-xl mt-6  flex-col mb-5 flex shadow  datatable-custom">
            <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                <div className="flex flex-col">
                    <h2 className="view-table-title">{t('All Clients Notes')}</h2>
                    <p className="view-table-subtitle mt-0">{t('View Note activity.')}</p>
                </div>
                <div className="flex flex-row gap-4">
                    <CustomButton outlinePrimary onClick={handleNote('create')} icon={<Plus className="w-4" />}>
                        Add a note
                    </CustomButton>
                    <div className="location-dropdown">
                        <SelectBox
                            name="page"
                            isClearable={false}
                            isSearchable={false}
                            options={perPageOptions}
                            onChangeFunc={onPageHandle}
                            value={perPageOptions.find((option) => option.value === lazyState.rows)}
                            classComp="w-[70px]"
                        />
                    </div>
                </div>
            </div>
            <DataTable
                lazy
                paginatorTemplate={PaginatorTemplate()}
                value={isLoading ? skeletons : transactionList}
                totalRecords={totalTransaction}
                paginator={!isLoading && totalTransaction > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                dataKey="id"
                className=" rounded-b-xl overflow-hidden"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                        <NoDataMessage title="No Notes." description={`Add a Note, to start using the add notes.`} iconComponent={<Box className="text-gray-700" />} />
                    </div>
                }
                rowClassName={(data) => 'max-w-12 truncate'}
            >
                <Column
                    field="user.name"
                    header={renderHeader(t('Client'), 'user.name')}
                    body={isLoading ? <Skeleton /> : getFullName}
                    style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                    className="font-normal text-mainTextColor truncate"
                    sortable
                ></Column>
                <Column
                    field="description"
                    header={renderHeader(t('Description'), 'description')}
                    body={isLoading ? <Skeleton /> : GetDescription}
                    style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                    className="text-xs font-normal text-secondaryTxtColor truncate"
                    sortable
                ></Column>

                <Column
                    field="status"
                    // header={'Action'}
                    style={{ width: '40px', minWidth: '40px', maxWidth: '40px' }}
                    body={isLoading ? <Skeleton /> : getAction}
                    className="font-medium text-mainTextColor"
                ></Column>
            </DataTable>

            {action.delete && (
                <DeletePopupModal
                    isLoading={isLoading}
                    onClose={handleModalClose}
                    size="w-[400px]"
                    title={t('Delete Note')}
                    description={t('Are you sure you want to delete this notes? This action cannot be undone.')}
                />
            )}
            {(action.create || action.update) && (
                <NoteForm handleClose={handleModalClose()} noteData={action.noteData && action.noteData.id ? action.noteData : null} setAction={setAction} client={client} getNoteList={getNoteList} />
            )}
        </div>
    );
};

export default Notes;
