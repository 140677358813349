import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { userMe } from 'src/redux/services/common/Common.slice';
import { receptionistInitAction } from './ReceptionistMode.interface';
import PinSetup from './PinSetup';
import PinVerification from './PinVerification';
import ForgotPin from './ForgotPin';
import EnablePopup from './EnablePopup';

const ReceptionistMode = ({ handleClose }: { handleClose: any }) => {
    const user = useAppSelector(userMe);

    const [receptionistAction, setReceptionistAction] = useState(receptionistInitAction);
    const handleReceptionistClose = () => {
        handleClose();
        setReceptionistAction(receptionistInitAction);
    };
    const handleReceptionistMode = (type: string) => {
        setReceptionistAction((old) => ({ ...receptionistInitAction, [type]: true }));
    };

    useEffect(() => {
        handleReceptionistMode(!user.is_pin_set ? 'enablePopup' : 'pinVerification');
    }, []);

    return Object.values(receptionistAction).some((value) => value === true) ? (
        receptionistAction.pinSetup ? (
            <PinSetup handleClose={handleReceptionistClose} />
        ) : receptionistAction.pinVerification ? (
            <PinVerification handleReceptionistMode={handleReceptionistMode} handleClose={handleReceptionistClose} />
        ) : receptionistAction.forgotPin ? (
            <ForgotPin handleReceptionistMode={handleReceptionistMode} handleClose={handleReceptionistClose} />
        ) : (
            <EnablePopup handleClose={handleReceptionistClose} handleReceptionistMode={handleReceptionistMode} />
        )
    ) : null;
};

export default ReceptionistMode;
