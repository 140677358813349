import React, { useState, useEffect, useMemo } from 'react';
import Account from './Account';
import AccountType from './AccountType';
import Brand from './Brand';
import { useAppSelector } from 'src/redux/hooks';
import { userMe } from 'src/redux/services/common/Common.slice';
import { ICountStep, IStep } from 'src/components/Stepper/Stepper.interface';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Sidebar from '../Layout/SideBarLayout/Sidebar';
import Welcome from './Welcome';
import { ROUTES } from 'src/constants/routes';
import ServiceProvider from './ServiceProvider';
import { List, Mail05, User01, Edit05 } from '@untitled-ui/icons-react/build/cjs';
import Profile from './Profile';
import { useRolePermission } from 'src/hooks/useRolePermission';
import { ROLES } from 'src/constants/common';

const SignupAdmin = () => {
    const { t } = useTranslation();
    const { isRoleExist, isOwner, allShopRoles } = useRolePermission();
    const user = useAppSelector(userMe);
    const signupStep = user.shop_staff[0].account_step;
    const navigate = useNavigate();
    const [step, setStep] = useState<ICountStep>(0);

    const handleStep = (direction: 'next' | 'prev', type?: boolean) => () => {
        let currentStep = direction === 'next' ? step + 1 : step - 1;
        switch (currentStep) {
            case 3:
                if (type) {
                    currentStep = direction === 'next' ? currentStep + 1 : currentStep - 1;
                }
                break;
            case 5:
                if (type) {
                    currentStep = direction === 'next' ? currentStep + 1 : currentStep - 1;
                }
                break;
            default:
                break;
        }
        setStep(currentStep as ICountStep);
    };

    const STEPS: IStep[] = useMemo(
        () => [
            {
                name: t('Complete your profile'),
                subTitle: t('Tell us a bit about yourself'),
                image: Mail05,
                step: 1,
                enabled: true,
            },
            {
                name: t('Select account type'),
                subTitle: t('Tell us a bit about yourself'),
                image: List,
                step: 2,
                enabled: true,
            },
            {
                step: 3,
                enabled: false,
            },
            {
                name: t('Configure your brand'),
                subTitle: t('Tell us a bit about your business'),
                image: Edit05,
                step: 4,
                enabled: true,
            },
            {
                step: 5,
                enabled: false,
            },
            {
                name: t('Welcome to', { shop_name: process.env.REACT_APP_FULL_NAME }),
                subTitle: t('Finish your account setup'),
                image: User01,
                step: 6,
                enabled: true,
            },
        ],
        [],
    );

    useEffect(() => {
        if (!signupStep.complete_account) {
            setStep(1);
        } else if (!signupStep.account_type) {
            setStep(2);
        } else if (!signupStep.service_provider && isOwner) {
            setStep(3);
        } else if (!signupStep.brand) {
            setStep(4);
        } else if (!signupStep.profile && isRoleExist(ROLES.STAFF_SELF)) {
            setStep(5);
        } else {
            navigate(ROUTES.DASHBOARD);
        }
    }, [allShopRoles]);

    const renderStepComponent = () => {
        const stepToRender = STEPS[step - 1];
        switch (stepToRender.step) {
            case 1:
                return <Account setStep={setStep} handleStep={handleStep} />;
            case 2:
                return <AccountType handleStep={handleStep} />;
            case 3:
                return <ServiceProvider handleStep={handleStep} />;
            case 4:
                return <Brand handleStep={handleStep} />;
            case 5:
                return <Profile handleStep={handleStep} />;
            case 6:
                return <Welcome />;
            default:
                return null;
        }
    };

    return (
        <>
            {step !== 0 && (
                <>
                    <Sidebar activeStep={step} steps={STEPS} handleStep={handleStep} />
                    <div className="flex-grow min-h-screen">
                        {renderStepComponent()}
                        {/* <main className="px-6 h-[calc(100vh-84px)] overflow-y-scroll flex-1 flex">{renderStepComponent()}</main> */}
                        {/* <div className="bottom-0 right-0 start-[480px] fixed">
                            <div className="w-full bg-white">
                                <StepProgressBar steps={STEPS.length} currentStep={step} className="w-full mx-auto justify-center" />
                            </div>
                            <Footer />
                        </div> */}
                    </div>
                </>
            )}
        </>
    );
};

export default SignupAdmin;
