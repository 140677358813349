import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import { Copy02, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import InputWithLabel from 'src/components/InputWithLabel';

const ProductDetailsForm = ({ language }: { language: string }) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext();

    const {
        fields: informationFields,
        append: appendOption,
        remove: removeOption,
    } = useFieldArray({
        control,
        name: 'details',
    });

    const handleCopyOption = (index: number) => () => {
        const fieldValues = watch(`details.${index}`);
        appendOption({ ...fieldValues, id: 0 });
    };
    return (
        <>
            <div className="flex flex-col border border-gray-200 rounded-xl shadow-sm w-full max-w-[666px] 2xl:min-w-[810px] xlm:min-w-[910px] 2xls:max-w-[98%]">
                <div className="flex justify-between items-center p-4 border-b">
                    <div className="flex flex-col">
                        <h2 className="table-title">{t('Add Additional Information')}</h2>
                        <h6 className="table-subtitle">{t('Enter the product details like Benefits, Usage and more.')}</h6>
                    </div>
                    <div className="">
                        <CustomButton
                            outlinePrimary
                            onClick={() => appendOption({ id: 0, en_name: '', en_description: '', fr_name: '', fr_description: '' })}
                            icon={<Plus className="h-4 w-4" />}
                            className="shadow-none "
                        >
                            {t('Add additional product information')}
                        </CustomButton>
                    </div>
                </div>
                <table className="min-w-full">
                    <thead>
                        <tr className="bg-gray-50 border-b h-[44px] *:text-gray-600 *:text-xs *:font-medium *:leading-[18px] *:pl-5 *:text-left">
                            <th className="pl-5 py-[13px] text-xs font-medium 2xl:min-w-[240px] xlm:min-w-[240px] xxl:min-w-[300px] text-start">{t('Title')}</th>
                            <th className="pl-5 py-[13px] text-xs font-medium 2xl:min-w-[450px] xlm:min-w-[550px] xxl:min-w-[700px] text-start">{t('Description')}</th>
                            <th className="pl-5 py-[13px] text-xs font-medium 2xl:min-w-[120px] xlm:min-w-[120px] xxl:min-w-[120px] text-start">{t('Action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {informationFields.map((item, index) => (
                            <tr key={item.id} className="border-b *:pl-5 h-[64px] last:border-b-0 ">
                                <td>
                                    <Controller
                                        name={`details.${index}.${language}_name`}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <div>
                                                <InputWithLabel
                                                    name={`details.${index}.${language}_name`}
                                                    id={`details.${index}.${language}_name`}
                                                    required
                                                    placeholder={t('Enter product detail title')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                />
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </td>
                                <td>
                                    <Controller
                                        name={`details.${index}.${language}_description`}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <div>
                                                <InputWithLabel
                                                    name={`details.${index}.${language}_description`}
                                                    id={`details.${index}.${language}_description`}
                                                    required
                                                    placeholder={t('Enter product detail description')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                />
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </td>
                                <td className="flex gap-2.5 py-[14px] px-5 min-h-[64px] h-[64px] table-arrow-btn !justify-start">
                                    <CustomButton className="shadow-none w-4 !px-0 !pr-[4px]" onClick={handleCopyOption(index)}>
                                        <Copy02 className="w-4 h-4 text-gray-600 hover:text-gray-900" />
                                    </CustomButton>
                                    {informationFields.length > 1 && (
                                        <CustomButton className="shadow-none w-4 !px-0 !pr-[4px]" onClick={() => removeOption(index)}>
                                            <Trash01 className="w-4 h-4 text-gray-600 hover:text-gray-900" />
                                        </CustomButton>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ProductDetailsForm;
