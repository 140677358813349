import _ from 'lodash';
import moment from 'moment';
import { ALLOW_IMAGE_TYPES, DATE_FORMAT, DATE_TIME_FORMAT, LOCAL_STORAGE_KEYS, ROLES } from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import americanCardIcon from 'src/assets/img/cards/american.png';
import defaultCardIcon from 'src/assets/img/cards/credit-card.png';
import dinerCardIcon from 'src/assets/img/cards/diners.png';
import discoverCardIcon from 'src/assets/img/cards/discover.png';
import jcbCardIcon from 'src/assets/img/cards/jcb.png';
import masterCardIcon from 'src/assets/img/cards/mastercard.png';
import unionCardIcon from 'src/assets/img/cards/unionpay.png';
import visaCardIcon from 'src/assets/img/cards/visa.png';
import { defaultImage } from 'src/theme/Images';

export enum EStripeCardType {
    VISA = 'visa',
    MASTER = 'mastercard',
    AMERICAN = 'amex',
    DINER = 'diners',
    DISCOVER = 'discover',
    JCB = 'jcb',
    UNION = 'unionpay',
}
const logout = () => {
    localStorage.removeItem('persist:root');
    localStorage.removeItem(LOCAL_STORAGE_KEYS.authToken);
    window.location.href = `${window.location.origin}/`;
};
const formatAmount = (amount: any) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD', // Change the currency code as needed
    }).format(amount);

const formatPercentage = (value: any) =>
    new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value / 100);

const formatDateTime = (dateTime: string) => {
    const momentDateTime = moment(dateTime);
    const today = moment().startOf('day');
    const tomorrow = moment().add(1, 'days').startOf('day');

    if (momentDateTime.isSame(today, 'day')) {
        return `Today, ${momentDateTime.format('MMM Do, YYYY · h:mm A')}`;
    } else if (momentDateTime.isSame(tomorrow, 'day')) {
        return `Tomorrow, ${momentDateTime.format('MMM Do, YYYY · h:mm A')}`;
    } else {
        return momentDateTime.format('dddd, MMM Do, YYYY · h:mm A');
    }
};

const formatDateTime2 = (dateString: string) => {
    const date = moment(dateString);
    const today = moment().startOf('day');
    const tomorrow = moment().add(1, 'days').startOf('day');

    if (date.isSame(today, 'day')) {
        return `Today, ${date.format('h:mm A')}`;
    } else if (date.isSame(tomorrow, 'day')) {
        return `Tomorrow, ${date.format('h:mm A')}`;
    } else {
        return date.format('MMM Do · h:mm A'); // "Jan 10th · 10:30AM" format
    }
};

const formatDate = (date: string) => {
    const momentDateTime = moment(date);
    const today = moment().startOf('day');
    const tomorrow = moment().add(1, 'days').startOf('day');

    if (momentDateTime.isSame(today, 'day')) {
        return `Today, ${momentDateTime.format('MMM Do, YYYY')}`;
    } else if (momentDateTime.isSame(tomorrow, 'day')) {
        return `Tomorrow, ${momentDateTime.format('MMM Do, YYYY')}`;
    } else {
        return momentDateTime.format('MMM Do, YYYY');
    }
};

const exceptValidation = (error: any) => {
    const isValid = error && error.type !== 'required' && error.type !== 'optionality' && error.type !== 'nullable';
    return isValid;
};

const handleNavigate = (user: any, pathname: string) => {
    if (user) {
        const shopStaff = user.shop_staff[0];
        const accountStep = shopStaff.account_step;
        const isPasswordSet = user.is_password_set;
        const social = user.social_id.google || user.social_id.facebook || user.social_id.apple;

        if ((!isPasswordSet && !social) || (!user.phone && social)) {
            return ROUTES.REGISTER;
        }

        let requiredSteps = ['complete_account', 'account_type', 'brand'];
        let isAdmin = true;
        let isStaff = false;
        let isIndividual = false;
        let isStaffSelf = false;

        if (user && user.roles && user.roles.length > 0) {
            isStaff = user.roles.some((role: any) => [ROLES.STAFF_RENT, ROLES.STAFF_COMMISSION, ROLES.STAFF_PARTNER, ROLES.STAFF_NO_TRACKING].includes(role.name));
            isAdmin = user.roles.some((role: any) => [ROLES.OWNER, ROLES.INDIVIDUAL].includes(role.name));
            isIndividual = user.roles.some((role: any) => role.name === ROLES.INDIVIDUAL);
            isStaffSelf = user.roles.some((role: any) => role.name === ROLES.STAFF_SELF);
        }

        if (isStaff && !isAdmin) {
            requiredSteps = ['invitation', 'profile', 'working_hours'];
        } else if (!isStaff && !isIndividual && shopStaff.created_by_id) {
            requiredSteps = ['complete_account'];
        } else if (isAdmin && isStaffSelf) {
            requiredSteps.push('profile');
        }

        const isAllStepsCompleted = requiredSteps.every((step) => (step === 'stripe_account' ? accountStep[step] === true || accountStep[step] === 'pending' : accountStep[step] === true));

        if (!isAllStepsCompleted) {
            return ROUTES.SIGNUP;
        }
    }
    return pathname !== ROUTES.SIGNUP && pathname !== ROUTES.REGISTER ? pathname : ROUTES.DASHBOARD;
};

const getResourceOptions = (
    data: Array<any>,
    first_name: string = 'first_name',
    last_name: string = 'last_name',
    id: string = 'id',
    profile_image_url: string = 'profile_image_url',
    role: string = 'staff_role',
) => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((res) => ({
        name: `${res[first_name]} ${res[last_name]}`,
        staff_id: res[id],
        profile_image_url: res[profile_image_url],
        role: res[role] && res[role].name,
    }));
};

const getSelectBoxOptions = (data: Array<any>, value: string = 'name', label: string = 'name', id: string = 'id', shortName: string | null = 'shortname', includeImage: string | null = null) => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((res) => ({
        ...res,
        value: res[value],
        label: res[label],
        id: res[id],
        shortName: (shortName && res[shortName]) ?? null,
        image: includeImage ? res[includeImage] : null,
    }));
};
const getSelectBoxOptionsIdAsValue = (data: Array<any>, value: string = 'id', label: string = 'name', id: string = 'id') => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((res) => ({
        value: res[value],
        label: res[label],
        id: res[id],
    }));
};

const convertBase64ToFile = (appLogoImage: any) => {
    try {
        const imageExtension = appLogoImage.substring(appLogoImage.indexOf('/') + 1, appLogoImage.indexOf(';base64'));

        const currentTimestamp = Date.now();
        const randomNum = Math.floor(1000000000 + Math.random() * 9000000000);
        const filename = `${currentTimestamp}_${randomNum}.${imageExtension}`;

        const base64Data = appLogoImage.replace(/^data:[^;]+;base64,/, '');

        const uint8Array = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));

        const blob = new Blob([uint8Array], {
            type: 'application/octet-stream',
        });

        const convertedFile = new File([blob], filename, {
            type: 'application/octet-stream',
        });
        return {
            convertedFile: convertedFile,
            filename: filename,
        };
    } catch (error) {
        // console.error('Error converting base64 to file:', error);
    }
};

const capitalizeFirstLetter = (str: string) => (str.length > 0 ? str[0].toUpperCase() + str.slice(1) : str);
const formattedDuration = (duration: string) => {
    const durationFormate = moment.duration(duration);
    return moment.utc(durationFormate.asMilliseconds()).format(durationFormate.hours() > 0 ? 'H [hours] m [minutes]' : 'm [minutes]');
};

const getShortName = (name: string) => {
    const shortName = name
        .split(' ')
        .slice(0, 2)
        .map((word) => word.charAt(0).toUpperCase())
        .join('');
    return shortName;
};

const getShortLastName = (name: string) => {
    const words = name.split(' ');

    const firstName = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
    const lastNameAbbreviation = `${words[1].charAt(0).toUpperCase()}.`;

    return `${firstName} ${lastNameAbbreviation}`;
};

function capitalizeFirstLetterAndUnderscore(str: string) {
    let result = str;
    if (str) {
        const words = str.split('_');
        const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
        result = capitalizedWords.join(' ');
    }
    return result;
}
function capitalizeFirstLetterSpace(str: string) {
    const words = str.split(' ');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    const result = capitalizedWords.join(' ');
    return result;
}
function capitalizeFirstLetterDash(str: string | null | undefined) {
    if (!str) {
        return '';
    }

    const words = str.split('_');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    const result = capitalizedWords.join('-');
    return result;
}

const checkFileTypeValidation = async ({ files, size, minWidth, minHeight }: { files: File[]; size: number; minWidth?: number; minHeight?: number }): Promise<{ result: boolean; message: string }> => {
    const validationResults = await Promise.all(
        _.map(files, async (file: File) => {
            if (!ALLOW_IMAGE_TYPES.includes(file.type)) {
                return {
                    result: false,
                    message: 'Invalid file format. Please upload a valid image file.',
                };
            } else if (file.size > size * 1024) {
                return {
                    result: false,
                    message: `Please ensure your image is within the maximum allowed size of ${size}kb`,
                };
            } else if (minWidth || minHeight) {
                const imageValidation = await validateImageDimensions(file, minWidth, minHeight);
                if (!imageValidation.result) {
                    return imageValidation;
                }
            }

            return { result: true, message: '' };
        }),
    );

    const validationResult = _.every(validationResults, (result: { result: boolean }) => result.result === true);

    return {
        result: validationResult,
        message: validationResult ? '' : validationResults.find((res) => !res.result)?.message || '',
    };
};

// Helper function to validate image dimensions
const validateImageDimensions = (file: File, minWidth?: number, minHeight?: number): Promise<{ result: boolean; message: string }> =>
    new Promise((resolve) => {
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = () => {
            if ((minWidth && img.width < minWidth) || (minHeight && img.height < minHeight)) {
                resolve({
                    result: false,
                    message: `Image dimensions must be at least ${minWidth || 0}px wide and ${minHeight || 0}px tall.`,
                });
            } else {
                resolve({ result: true, message: '' });
            }
            URL.revokeObjectURL(objectUrl);
        };

        img.onerror = () => {
            resolve({
                result: false,
                message: 'Unable to validate image dimensions. Please upload a valid image.',
            });
            URL.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl;
    });

const fontWeightMap: { [key: number]: string } = {
    100: 'thin',
    200: 'extralight',
    300: 'light',
    400: 'normal',
    500: 'medium',
    600: 'semibold',
    700: 'bold',
    800: 'extrabold',
    900: 'black',
};

const getFontWeight = (weight: any) => fontWeightMap[weight] || 'unknown'; // Return 'unknown' if the weight is not found

const generateTimeRanges = (startTime: Date, endTime: Date, item: any) => {
    const ranges = [];

    const start = moment(startTime, 'YYYY-MM-DD HH:mm:ss');
    const end = moment(endTime, 'YYYY-MM-DD HH:mm:ss');

    while (start.isBefore(end)) {
        const nextEnd = moment.min(start.clone().endOf('day'), end);

        ranges.push({
            ...item,
            startTime: start.format('YYYY-MM-DD HH:mm:ss'),
            endTime: nextEnd.format('YYYY-MM-DD HH:mm:ss'),
            status: 'block_time',
        });

        start.add(1, 'day').startOf('day');
    }

    return ranges;
};
const splitBookingTime = (bookingDate: string, startTime: string, endTime: string, item: any) => {
    const start = moment(`${bookingDate} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');
    const end = moment(`${bookingDate} ${endTime}`, 'YYYY-MM-DD HH:mm:ss');

    if (end.isBefore(start)) {
        const firstPart = {
            ...item,
            booking_date: start.format('YYYY-MM-DD'),
            booking_start_time: start.format('HH:mm:ss'),
            booking_end_time: '23:59:59',
        };

        const secondPart = {
            ...item,
            booking_date: end.add(1, 'day').format('YYYY-MM-DD'),
            booking_start_time: '00:00:00',
            booking_end_time: end.format('HH:mm:ss'),
        };

        return [firstPart, secondPart];
    } else {
        return [
            {
                ...item,
                booking_date: start.format('YYYY-MM-DD'),
                booking_start_time: start.format('HH:mm:ss'),
                booking_end_time: end.format('HH:mm:ss'),
            },
        ];
    }
};

const isUpcomingStatus = ['no_show', 'request_canceled', 'pending'];

const getStatusClass = (status: string) => {
    if (isUpcomingStatus.includes(status)) {
        return 'text-yellow-400';
    } else if (status === 'canceled' || status === 'declined' || status === 'expired') {
        return 'text-dangerText';
    } else if (status === 'confirmed') {
        return 'text-primary';
    } else {
        return 'text-green';
    }
};

const getCardTypeImage = (cardType: string) => {
    switch (cardType?.toUpperCase()) {
        case EStripeCardType.VISA.toUpperCase():
            return visaCardIcon;
        case EStripeCardType.MASTER.toUpperCase():
            return masterCardIcon;
        case EStripeCardType.AMERICAN.toUpperCase():
            return americanCardIcon;
        case EStripeCardType.DINER.toUpperCase():
            return dinerCardIcon;
        case EStripeCardType.DISCOVER.toUpperCase():
            return discoverCardIcon;
        case EStripeCardType.JCB.toUpperCase():
            return jcbCardIcon;
        case EStripeCardType.UNION.toUpperCase():
            return unionCardIcon;
        default:
            return defaultCardIcon;
    }
};
function formatName(name: string) {
    if (name === null) {
        return null;
    }
    const words = name.split(' ');
    const firstWordCapitalized = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    const lastNameInitial = words.length > 1 ? `${words[1].charAt(0).toUpperCase()}.` : '';
    return `${firstWordCapitalized} ${lastNameInitial}`;
}

function formatKpiContentLable(num: any) {
    const roundedValue = Number(num).toFixed(2);
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(Number(roundedValue));
}

const onError = (event: any) => {
    event.target.src = defaultImage;
};

const getDayNumber = (day: string) => {
    let number = 0;
    switch (day) {
        case 'sunday':
            number = 0;
            break;
        case 'monday':
            number = 1;
            break;
        case 'tuesday':
            number = 2;
            break;
        case 'wednesday':
            number = 3;
            break;
        case 'thursday':
            number = 4;
            break;
        case 'friday':
            number = 5;
            break;
        case 'saturday':
            number = 6;
            break;
        default:
            break;
    }
    return number;
};
const getDateRange = (range: any, allTimeStartDate?: any) => {
    switch (range) {
        case 'last_7_days':
            return {
                start: moment().subtract(6, 'days').toDate(),
                end: moment().toDate(),
            };
        case 'last_30_days':
            return {
                start: moment().subtract(29, 'days').toDate(),
                end: moment().toDate(),
            };
        case 'last_90_days':
            return {
                start: moment().subtract(89, 'days').toDate(),
                end: moment().toDate(),
            };
        case 'last_180_days':
            return {
                start: moment().subtract(179, 'days').toDate(),
                end: moment().toDate(),
            };
        case 'last_year':
            return {
                start: moment().subtract(1, 'year').toDate(),
                end: moment().toDate(),
            };
        case 'monthly':
            return {
                start: moment().startOf('month').toDate(),
                end: moment().endOf('month').toDate(),
            };
        case 'weekly':
            return {
                start: moment().isoWeekday(0).startOf('week').toDate(),
                end: moment().isoWeekday(0).endOf('week').toDate(),
            };
        case 'quarterly':
            return {
                start: moment(moment().startOf('quarter'), 'YYYY-MM-DD').toDate(),
                end: moment(moment().endOf('quarter'), 'YYYY-MM-DD').toDate(),
            };
        case 'yearly':
            return {
                start: moment(moment().startOf('year'), 'YYYY-MM-DD').toDate(),
                end: moment(moment().endOf('year'), 'YYYY-MM-DD').toDate(),
            };
        case 'daily':
            return {
                start: moment().toDate(),
                end: moment().toDate(),
            };
        case 'all_time':
            return {
                start: moment(allTimeStartDate).toDate(),
                end: moment().toDate(),
            };
        case 'yesterday':
            return {
                start: moment().subtract(1, 'days').toDate(),
                end: moment().subtract(1, 'days').toDate(),
            };
        case 'last_quarter':
            return {
                start: moment().subtract(3, 'months').startOf('month').toDate(),
                end: moment().subtract(1, 'months').endOf('month').toDate(),
            };
        case 'last_week':
            return {
                start: moment().subtract(1, 'weeks').startOf('week').toDate(),
                end: moment().subtract(1, 'weeks').endOf('week').toDate(),
            };
        case 'last_bi_week':
            return {
                start: moment().subtract(2, 'weeks').startOf('week').toDate(),
                end: moment().subtract(1, 'weeks').endOf('week').toDate(),
            };
        case 'last_month':
            return {
                start: moment().subtract(1, 'months').startOf('month').toDate(),
                end: moment().subtract(1, 'months').endOf('month').toDate(),
            };
        case 'this_week':
            return {
                start: moment().startOf('week').toDate(),
                end: moment().endOf('week').toDate(),
            };
        case 'this_bi_week':
            return {
                start: moment().subtract(1, 'week').startOf('week').toDate(),
                end: moment().endOf('week').toDate(),
            };
        case 'this_month':
            return {
                start: moment().startOf('month').toDate(),
                end: moment().endOf('month').toDate(),
            };
        case 'this_year':
            return {
                start: moment().startOf('year').toDate(),
                end: moment().endOf('year').toDate(),
            };
        default:
            return {};
    }
};

const calculateDueDate = ({ currentDateTime, day, frequency, monthDay }: any) => {
    const currentMoment = moment();
    const selectedMoment = moment(currentDateTime);

    const isBeforeDate = selectedMoment.isBefore(currentMoment);
    const selectedDate = isBeforeDate ? currentMoment : selectedMoment;

    const selectedDayNumber = getDayNumber(day);

    let startDate;
    let endDate;

    switch (frequency) {
        case 'monthly':
            startDate = selectedDate.clone().startOf('month');
            if (monthDay === 'first') {
                endDate = selectedDate.clone().add(1, 'month').startOf('month');
            } else {
                endDate = selectedDate.clone().endOf('month');
            }
            break;
        case 'weekly':
            startDate = selectedDate.clone().day(selectedDate.day() >= selectedDayNumber ? selectedDayNumber : selectedDayNumber - 7);
            endDate = startDate.clone().add(6, 'days');
            break;
        case 'bi_weekly':
            startDate = selectedDate.clone().day(selectedDate.day() >= selectedDayNumber ? selectedDayNumber : selectedDayNumber - 7);
            endDate = startDate.clone().add(13, 'days');
            break;
        case 'daily':
            startDate = selectedDate.clone().add(1, 'day');
            endDate = currentMoment.clone().add(1, 'day');
            break;
    }
    return { startDate, endDate };
};
const dateFormate = (date: string) => moment(date).format(DATE_FORMAT);
const dateTimeFormate = (date: string) => moment(date, 'YYYY-MM-DD HH:mm:ss').format(DATE_TIME_FORMAT);

const downloadXLSX = (data: string, name: string) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
        type: 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet',
    });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${name}_${moment().format('YYYY-MM-DD')}.xlsx`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};

const getStatusColor = (status: string) => {
    switch (status) {
        case 'regular':
            return '#2E90FA';
        case 'offline':
            return '#f00';
        case 'confirmed':
        case 'total':
            return '#1642C5';
        case 'completed':
        case 'online':
        case 'variable':
        case 'active':
            return '#17B26A';
        case 'canceled':
        case 'request_canceled':
            return '#F04438';
        case 'expired':
            return '#F79009';
        case 'no_show':
            return '#F79009';
        case 'loyal':
            return '#067647';
        case 'recent':
        case 'pending':
        case 'risk':
            return '#F38744';
        case 'new':
            return '#EE46BC';
        case 'lapsed':
            return '#B54708';
        case 'none':
            return '#026AA2';
        case 'stripe':
            return '#026AA2';
        case 'cash':
            return '#067647';
        case 'pos':
            return ' #F37744';
        case 'loyalty':
            return '#026AA2';
        case 'other':
            return '#026AA2';
        default:
            return '#17B26A';
    }
};

const padZero = (str: string) => (str.length === 1 ? `0${str}` : str);
const autoColorPicker = (hex: string): string => {
    // Remove '#' if it exists
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }

    // Convert 3-digit hex to 6-digit hex
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    // Ensure it's a valid 6-digit hex
    if (hex.length !== 6) {
        return '#FFFFFF';
    }

    // Convert hex to RGB
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // Invert the RGB components
    const invertedR = (255 - r).toString(16);
    const invertedG = (255 - g).toString(16);
    const invertedB = (255 - b).toString(16);

    // Pad each color component with zeros if necessary
    return `#${padZero(invertedR)}${padZero(invertedG)}${padZero(invertedB)}`.toUpperCase();
};

export {
    logout,
    formatAmount,
    formatPercentage,
    formatDateTime,
    formatDateTime2,
    exceptValidation,
    handleNavigate,
    getSelectBoxOptions,
    convertBase64ToFile,
    capitalizeFirstLetter,
    getShortName,
    getSelectBoxOptionsIdAsValue,
    checkFileTypeValidation,
    capitalizeFirstLetterAndUnderscore,
    capitalizeFirstLetterSpace,
    getResourceOptions,
    generateTimeRanges,
    getStatusClass,
    getCardTypeImage,
    formatName,
    formatKpiContentLable,
    onError,
    getDayNumber,
    formattedDuration,
    splitBookingTime,
    getDateRange,
    formatDate,
    getFontWeight,
    capitalizeFirstLetterDash,
    calculateDueDate,
    dateFormate,
    dateTimeFormate,
    downloadXLSX,
    getShortLastName,
    getStatusColor,
    autoColorPicker,
};
