import React from 'react';
import { useTranslation } from 'react-i18next';
import { IEnablePopup } from './ReceptionistMode.interface';
import PopupModal from 'src/components/PopupModal';
import { Headphones01 } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';

const EnablePopup = ({ handleReceptionistMode, handleClose }: IEnablePopup) => {
    const { t } = useTranslation();
    const Title = () => (
        <div className="flex flex-row gap-3 justify-center">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <Headphones01 className="text-gray-700" />
            </div>
        </div>
    );
    return (
        <>
            <PopupModal
                dismissible
                onClose={handleClose}
                size="w-[408px]"
                className="px-5 py-5"
                title={<Title />}
                view={2}
                fixedHeightClass="!px-5 !pt-0 !pb-5"
                // secondaryButton={t('Later')}
                // primaryButton={t('Receptionist Mode')}
                // acceptAction={() => handleReceptionistMode('pinSetup')}
                // declineAction={handleClose}
            >
                <h2 className="font-bold text-[18px] leading-[28px] text-center">{t('Receptionist Mode')}</h2>
                <div className="flex flex-wrap justify-center">
                    <div className="w-full flex justify-center flex-col items-center">
                        <div className="flex flex-col gap-8 text-center pb-4 w-[80%]">
                            <p className="text-secondaryTxtColor text-sm">{t('Are you sure you want to enable receptionist mode?')}</p>
                        </div>
                        <div className="flex flex-col gap-3 w-full">
                            <CustomButton className="w-full" primary onClick={() => handleReceptionistMode('pinSetup')}>
                                {t('Configure Receptionist Mode')}
                            </CustomButton>
                            <CustomButton className="w-full" secondary onClick={handleClose}>
                                {t('Later')}
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </PopupModal>
        </>
    );
};

export default EnablePopup;
