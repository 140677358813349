import React, { forwardRef, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
import { IDateSelectProps } from './dateSelect.interface';
import { format } from 'date-fns';
import { CalendarDate, Clock } from '@untitled-ui/icons-react/build/cjs';

const DateSelect = React.forwardRef(
    (
        {
            inputName,
            placeholder,
            dateFormat,
            className,
            onKeyDown,
            required,
            label,
            labelClassName,
            isFormate = true,
            tabIndex,
            onChangeValue,
            value,
            disabled = false,
            labelEndIcon,
            labelIconClassName,
            InputClass,
            readOnly = false,
            isTime = false,
            timeIntervals = 15,
            timeCaption = 'Time',
            isRange = false,
            startDate = null,
            endDate = null,
            ...props
        }: IDateSelectProps,
        ref: React.ForwardedRef<any>,
    ) => {
        const datePickerRef = useRef<DatePicker | null>(null);
        const [startDateState, setStartDateState] = useState(startDate);
        const [endDateState, setEndDateState] = useState(endDate);
        const onChangeDate = (date: any) => {
            if (isRange) {
                const [start, end] = date;
                setStartDateState(start);
                setEndDateState(end);
                onChangeValue([start, end]);
                return;
            } else if (date) {
                if (isFormate) {
                    const formattedDate = format(date, dateFormat);
                    onChangeValue(formattedDate);
                } else {
                    onChangeValue(date);
                }
            }
        };
        // React.useImperativeHandle(ref, () => ({
        //     setOpen: (isOpen: boolean) => {
        //         if (datePickerRef.current) {
        //             datePickerRef.current.setOpen(isOpen);
        //         }
        //     },
        // }));

        const CustomInput = forwardRef(({ value: val, onClick, className: classes }: any, refs: any) => (
            <div className={classes} onClick={onClick} ref={refs}>
                {val}
            </div>
        ));

        return (
            <>
                {/* {label && <label className={`fl-field-title-label ${labelClassName}`}>{label}</label>}
                {required && <span className="text-red-600">*</span>} */}
                {label && (
                    <div className="flex items-center gap-[10px] mb-[6px]">
                        <label htmlFor={''} className={`fl-field-title-label flex-col leading-5 mb-0 ${labelClassName}`}>
                            {label}
                            {required && <span className="text-red-600">*</span>}
                        </label>
                    </div>
                )}
                <div className={`input-group  date-field-block date-picker-group w-full ${InputClass}`}>
                    <div className={`input-group-prepend text-textSecondary !flex !items-center  ${labelIconClassName}`}>
                        <span className="text">{isTime ? <Clock className="text-gray-700 w-[16px] h-4 " /> : <CalendarDate className="text-gray-700 w-[16px] h-[16px]" />}</span>
                    </div>

                    <DatePicker
                        ref={datePickerRef}
                        placeholderText={placeholder}
                        onChange={onChangeDate}
                        className={`datepicker_control pl-0 min-h-[34px] flex items-center ${className}`}
                        tabIndex={tabIndex}
                        id={props.id}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            if (onKeyDown && (e?.keyCode === 9 || e?.which === 9)) {
                                if (datePickerRef.current) {
                                    datePickerRef.current.setOpen(false);
                                }
                            }
                        }}
                        selected={value ? new Date(value) : null}
                        value={value}
                        autoComplete="off"
                        name={inputName}
                        dropdownMode="select"
                        popperPlacement="top-end"
                        {...props}
                        disabled={disabled}
                        readOnly={readOnly}
                        showTimeSelect={isTime}
                        showTimeSelectOnly={isTime}
                        timeIntervals={timeIntervals}
                        timeCaption={isTime ? timeCaption : ''}
                        dateFormat={dateFormat}
                        startDate={startDateState}
                        endDate={endDateState}
                        selectsRange={isRange}
                        showMonthDropdown={false}
                        showYearDropdown={false}
                        customInput={<CustomInput />}
                    />
                    {labelEndIcon && (
                        <div
                            className="input-group-append"
                            onClick={() => {
                                if (datePickerRef.current) {
                                    datePickerRef.current.setOpen(true);
                                }
                            }}
                        >
                            <span className="text">{labelEndIcon}</span>
                        </div>
                    )}
                </div>
            </>
        );
    },
);

export default DateSelect;
