import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, me, userMe } from 'src/redux/services/common/Common.slice';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { IBusinessDetails } from './BusinessDetails.interface';
import { errorCode } from 'src/constants/errorCode';
import { s3Upload } from 'src/utils/s3Operations';
import { toast } from 'react-toastify';
import BusinessInfo from './BusinessInfo';
import OnlineLinks from './OnlineLinks';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useRolePermission } from 'src/hooks/useRolePermission';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const BusinessDetails = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isAdmin } = useRolePermission();
    const user = useAppSelector(userMe);
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveEnabled, setSaveEnabled] = useState(false);
    const [cropData, setCropData] = useState<any>({
        logo_image_name: {
            title: t('Adjust brand logo image'),
            description: t('Upload a max 800Kb image for best results'),
            cropTypes: ['Rounded', 'Square', 'Rectangular', 'Free Size'],
            default_crop_type: 'Square',
            data: null,
            file: null,
            name: null,
        },
        admin_logo_image_name: {
            title: t('Adjust brand icon image'),
            description: t('Upload a max 800Kb image for best results'),
            cropTypes: ['Rounded', 'Square'],
            default_crop_type: 'Rounded',
            data: null,
            file: null,
            name: null,
        },
    });

    /* const urlSchema = Yup.string()
        .url('Please enter a valid URL')
        .transform((value, originalValue) => {
            if (originalValue && originalValue.startsWith('www')) {
                return `http://${originalValue}`;
            }
            return value;
        })
        .nullable(); */

    const schema = Yup.object().shape({
        business_name: Yup.string().required(t('This field is required')),
        logo_image_name: Yup.string().required(t('This field is required')),
        admin_logo_image_name: Yup.string().required(t('This field is required')),
        logo_image_type: Yup.string().required(t('This field is required')),
        admin_logo_image_type: Yup.string().required(t('This field is required')),
        is_same_as_logo: Yup.boolean().required(t('This field is required')),
        slug: Yup.string().required(t('This field is required')),
        google_review: Yup.string().nullable(),
        facebook_page: Yup.string().nullable(),
        instagram_page: Yup.string().nullable(),
        admin_emails: Yup.array()
            .of(
                Yup.object().shape({
                    value: Yup.string().matches(emailRegex, t('Invalid email format')).required(t('Email is required')),
                }),
            )
            .notRequired(),
    });
    const [defaultValues, setDefaultValues] = useState<IBusinessDetails>({
        business_name: shop.business_name,
        slug: shop.slug,
        logo_image_name: shop.logo_image_name,
        admin_logo_image_name: shop.admin_logo_image_name,
        logo_image_type: shop.logo_image_type,
        admin_logo_image_type: shop.admin_logo_image_type,
        is_same_as_logo: shop.is_same_as_logo,
        google_review: shop.google_review || '',
        facebook_page: shop.facebook_page || '',
        instagram_page: shop.instagram_page || '',
        admin_emails: [],
    });

    const methods = useForm<IBusinessDetails>({
        resolver: yupResolver<IBusinessDetails>(schema),
        defaultValues,
    });

    const {
        handleSubmit,
        setError,
        reset,
        watch,
        formState: { errors, isDirty },
    } = methods;

    const watchAllFields = watch();

    useEffect(() => {
        setSaveEnabled(isDirty || cropData.logo_image_name.file !== null || cropData.admin_logo_image_name.file !== null);
    }, [watchAllFields, isDirty, cropData]);

    useEffect(() => {
        setDefaultValues({
            business_name: shop.business_name,
            slug: shop.slug,
            logo_image_name: shop.logo_image_name,
            admin_logo_image_name: shop.admin_logo_image_name,
            logo_image_type: shop.logo_image_type,
            admin_logo_image_type: shop.admin_logo_image_type,
            is_same_as_logo: shop.is_same_as_logo,
            google_review: shop.google_review || '',
            facebook_page: shop.facebook_page || '',
            instagram_page: shop.instagram_page || '',
            admin_emails: user.shop_admin_invites.map((item: any) => item.email) ?? [],
        });
        setCropData((old: any) => ({
            ...old,
            logo_image_name: {
                ...old.logo_image_name,
                data: shop.logo_image_url,
                file: null,
                name: null,
            },
            admin_logo_image_name: {
                ...old.admin_logo_image_name,
                data: shop.admin_logo_image_url,
                file: null,
                name: null,
            },
        }));
    }, [shop]);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const handleSave = (data: IBusinessDetails) => {
        let adminEmailsPayload = [];
        if (Array.isArray(data.admin_emails)) {
            adminEmailsPayload = data.admin_emails.map((item: any) => item.value);
        }
        const cread: IBusinessDetails = {
            ...data,
            admin_emails: adminEmailsPayload,
        };
        axiosPatch(API.USER.COMPANY, cread, { shop_id: shop.id })
            .then(() => dispatch(me()))
            .catch((error: any) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        let message = '';
                        Object.keys(response.data).forEach((field) => {
                            message = response.data[field][0];
                            setError(field as keyof IBusinessDetails, {
                                type: 'manual',
                                message: message,
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    const handleChange = async (data: IBusinessDetails) => {
        setIsLoading(true);
        let logoImage = {
            status: errorCode.updateSuccess,
        };
        let adminImage = {
            status: errorCode.updateSuccess,
        };
        if (cropData.logo_image_name.file) {
            const logoImageResponse: any = await s3Upload(cropData.logo_image_name.file, `images/business_logos/${cropData.logo_image_name.name}`);
            logoImage.status = logoImageResponse.status;
        }
        if (cropData.admin_logo_image_name.file) {
            const adminImageResponse: any = await s3Upload(cropData.admin_logo_image_name.file, `images/business_logos/${cropData.admin_logo_image_name.name}`);
            adminImage.status = adminImageResponse.status;
        }
        if (logoImage.status === errorCode.updateSuccess && adminImage.status === errorCode.updateSuccess) {
            handleSave(data);
        }
    };

    const resetForm = () => {
        reset();
        setCropData((old: any) => ({
            ...old,
            logo_image_name: {
                ...old.logo_image_name,
                data: shop.logo_image_url,
                file: null,
                name: null,
            },
            admin_logo_image_name: {
                ...old.admin_logo_image_name,
                data: shop.admin_logo_image_url,
                file: null,
                name: null,
            },
        }));
    };

    return (
        <div className="w-[full] flex-1">
            {/* <div className="w-full flex flex-row py-[14px] mt-5 gap-6 items-center">
                <img src={cropData ? cropData : defaultImage} alt="brand" className="w-[140px] min-w-[140px] h-[140px] rounded-lg object-contain" />
                <div className="flex flex-col gap-1">
                    <div className="font-semibold text-3xl text-mainTextColor">{shop.business_name}</div>
                    <div className="text-base text-secondaryTxtColor font-normal flex flex-row gap-1.5">
                        {shop.slug}.getflair.ca
                        <a href={`https://${shop.slug}.getflair.ca`} target="_blank" rel="noopener noreferrer">
                            <LinkExternal01 className="text-[#D0D5DD] w-5 " />
                        </a>
                    </div>
                </div>
            </div> */}
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleChange)} className="w-full px-2 py-1">
                    <div className="detail-page-title-block !py-4 !px-0 border-borderSecondary">
                        <div className="flex flex-col ">
                            <h2 className=" flex flex-row gap-1.5 items-center">
                                <div className="title-text-header">{t('Brand Settings')}</div>
                            </h2>
                            <p className="subtitle-text">{t('Manage your brand’s details and display settings')}</p>
                        </div>
                        {isAdmin && (
                            <div className="flex flex-row gap-3">
                                <CustomButton secondary disabled={isLoading} type="button" onClick={resetForm} className="!h-[36px]">
                                    {t('Cancel')}
                                </CustomButton>
                                <CustomButton type="submit" isLoading={isLoading} disabled={isLoading || !isSaveEnabled} primary className="!h-[36px]">
                                    {t('Save Changes')}
                                </CustomButton>
                            </div>
                        )}
                    </div>

                    <div className="detail-page-data-block !border-b-0 !p-0">
                        <div className="data-wrap-block w-full ">
                            <BusinessInfo errors={errors} cropData={cropData} setCropData={setCropData} />
                        </div>
                    </div>
                    <div className="detail-page-data-block !border-b-0 !p-0">
                        <div className="data-wrap-block w-full">
                            <OnlineLinks errors={errors} />
                        </div>
                    </div>
                    {isAdmin && (
                        <div className="flex flex-row gap-3 w-full justify-end pt-5 mb-36">
                            <CustomButton secondary disabled={isLoading} type="button" onClick={resetForm} className="!h-[36px]">
                                {t('Cancel')}
                            </CustomButton>
                            <CustomButton type="submit" isLoading={isLoading} disabled={isLoading || !isSaveEnabled} primary className="!h-[36px]">
                                {t('Save Changes')}
                            </CustomButton>
                        </div>
                    )}
                </form>
            </FormProvider>
        </div>
    );
};

export default BusinessDetails;
