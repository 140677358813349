import React, { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import CustomButton from 'src/components/CustomButton';
import { ChevronDown, ChevronRight, Plus } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { XClose } from '@untitled-ui/icons-react/build/cjs';
import { CHARACTERS_LIMIT } from 'src/constants/common';

const SubCategoryForm = ({ language }: { language: string }) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext();
    const [isShow, setIsShow] = useState<any>({ 0: true });
    const {
        fields: optionFields,
        append: appendOption,
        remove: removeOption,
    } = useFieldArray({
        control,
        name: 'sub_categories',
    });

    const handleCollapse = (index: number) => () => {
        setIsShow((old: any) => ({ ...old, [index]: !old[index] }));
    };

    return (
        <>
            <div className="flex flex-col gap-5 w-full max-w-[666px]">
                {optionFields.map((item, index) => (
                    <div key={item.id} className="border rounded-lg ">
                        <div className="flex items-center justify-between p-4 cursor-pointer" onClick={handleCollapse(index)}>
                            <p className="text-sm font-medium">{watch(`sub_categories.${index}.${language}_name`) || t('Sub Category Title')}</p>
                            <div className="flex items-center gap-3">
                                {optionFields.length > 1 && (
                                    <div className="text-xs font-semibold text-red-700">
                                        <span className="text-error-700 cursor-pointer" onClick={() => removeOption(index)}>
                                            <XClose width={18} className="text-error-700" />
                                        </span>
                                    </div>
                                )}

                                <button type="button" className=" text-3xl">
                                    {isShow[index] ? <ChevronDown className="text-gray-600" /> : <ChevronRight className="text-gray-600" />}
                                </button>
                            </div>
                        </div>
                        <div className={`flex flex-col gap-5 border-t p-4  ${!isShow[index] ? 'd-none' : ''}`}>
                            <Controller
                                name={`sub_categories.${index}.${language}_name`}
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div>
                                        <InputWithLabel
                                            name={`sub_categories.${index}.${language}_name`}
                                            id={`sub_categories.${index}.${language}_name`}
                                            label={t('Title')}
                                            required
                                            placeholder={t('Enter sub category title')}
                                            onChange={onChange}
                                            value={value}
                                            error={!!error}
                                            maxLenght={CHARACTERS_LIMIT.SHORT}
                                        />
                                        {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                    </div>
                                )}
                            />
                            <Controller
                                name={`sub_categories.${index}.${language}_description`}
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div>
                                        <InputWithLabel
                                            name={`sub_categories.${index}.${language}_description`}
                                            id={`sub_categories.${index}.${language}_description`}
                                            label={t('Description')}
                                            required
                                            placeholder={t('Enter sub category description')}
                                            onChange={onChange}
                                            value={value}
                                            error={!!error}
                                            textArea
                                            textAearClassName="h-[120px] w-full rounded-lg resize-none"
                                            maxLenght={CHARACTERS_LIMIT.LONG}
                                        />
                                        {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                ))}
                <div className="flex justify-end text-primary text-xs font-semibold">
                    <CustomButton onClick={() => appendOption({ name: '', description: '' })} icon={<Plus className="h-4 w-4" />} className="shadow-none !p-0">
                        {t('Add new sub category')}
                    </CustomButton>
                </div>
            </div>
        </>
    );
};

export default SubCategoryForm;
