import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';
import { IFieldKey, IInitLoading, InitLoading, IProfileField, SocialType } from './Profile.interface';
import InputWithLabel from 'src/components/InputWithLabel';
import { currentShop, me, userMe } from 'src/redux/services/common/Common.slice';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { checkFileTypeValidation, convertBase64ToFile } from 'src/utils/global-functions';
import { s3Upload } from 'src/utils/s3Operations';
import UpdateEmail from './UpdateEmail';
import UpdatePhone from './UpdatePhone';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import { PackagePlus } from '@untitled-ui/icons-react/build/cjs';
import AccountChangePassword from './AccountChangePassword';
import { AppleIcon, FacebookIcon, GoogleIcon } from 'src/theme/Images';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { APPLE_CLIENT_ID, APPLE_REDIRECT_URI, FACEBOOK_APP_ID } from 'src/constants/common';
import AppleLogin from 'react-apple-login';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import { FILE_VALIDATION } from 'src/utils/global-variables';
import ImageUpload from 'src/components/ImageUpload';

// const SUPPORTED_FORMATS = ['image/jpg', 'image/png'];
// const FILE_SIZE_LIMIT = 1 * 1024 * 1024; // 4 MB

const initAction = {
    email: false,
    phone: false,
    password: false,
    facebook: false,
    google: false,
    apple: false,
};

const Profile = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const user = useAppSelector(userMe);
    const profileImageUrl = user.profile_image_url;
    const [isLoading, setIsLoading] = useState(false);
    const [socialLoading, setSocialLoading] = useState<IInitLoading>(InitLoading);
    const [facebook, setFacebook] = useState({ token: null, email: null });
    const [accountConnections, setAccountConnections] = useState({
        facebook: null,
        google: null,
        apple: null,
    });
    const [cropData, setCropData] = useState<any>(profileImageUrl || '');
    const [image, setImage] = useState<any>(profileImageUrl || '');
    const [upload, setUpload] = useState(false);
    const [file, setFile] = useState<any>({ convertedFile: '', filename: '' });
    const [action, setAction] = useState(initAction);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSaveEnabled, setSaveEnabled] = useState(false);

    useEffect(() => {
        if (user) {
            setAccountConnections(user.social_id);
        }
    }, [user]);

    useEffect(() => {
        const result = Object.values(isLoading).some((value) => value === true);
        setIsDisabled(result);
    }, [socialLoading]);

    const schema = Yup.object({
        first_name: Yup.string().required(t('This field is required')),
        last_name: Yup.string().required(t('This field is required')),
        email: Yup.string().required(t('This field is required')),
        phone_country_code: Yup.string().required('This field is required'),
        phone: Yup.string().required(t('This field is required')),
        language: Yup.string().required(t('This field is required')),
        profile_image_name: Yup.string().required(t('This field is required')),
        /* profile_image_file: Yup.mixed()
            .required('This field is required')
            .test(
                'fileSize',
                'File size is too large',
                (value: any) => value && value.size <= FILE_SIZE_LIMIT,
            )
            .test(
                'fileFormat',
                'Unsupported file format',
                (value: any) => value && SUPPORTED_FORMATS.includes(value.type),
            ), */
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        reset,
        watch,
        formState: { isDirty },
    } = useForm<IProfileField>({
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone_country_code: user.phone_country_code,
            phone: user.phone,
            profile_image_name: user.profile_image_name,
            language: 'english',
        },
    });

    const watchAllFields = watch();

    useEffect(() => {
        setSaveEnabled(isDirty || file.filename !== '');
    }, [watchAllFields, isDirty, cropData, file]);

    useEffect(() => {
        setValue('email', user.email);
        setValue('phone_country_code', user.phone_country_code);
        setValue('phone', user.phone);
    }, [user]);

    const handleSave = async (data: IProfileField) => {
        await axiosPatch(API.USER.PROFILE, data)
            .then(async (response) => {
                dispatch(me());
            })
            .catch((error: any) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        let message = '';
                        Object.keys(response.data).forEach((field) => {
                            message = response.data[field][0];
                            setError(field as IFieldKey, {
                                type: 'manual',
                                message: message,
                            });
                            return;
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleChange = async (data: IProfileField) => {
        setIsLoading(true);
        if (file.convertedFile && file?.filename) {
            const imgUploadResult: any = await s3Upload(file?.convertedFile, `images/admin_profile/${file?.filename}`);
            if (imgUploadResult.status === 201) {
                const updatedData = {
                    ...data,
                    profile_image_name: file.filename,
                };
                handleSave(updatedData);
            } else {
                setIsLoading(false);
            }
        } else {
            handleSave(data);
        }
    };

    const imageUpload = (data: any) => {
        const appLogoImage = data;
        const fileInfo = convertBase64ToFile(appLogoImage);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue('profile_image_name', fileInfo.filename);
        }
    };

    const onChangeCrop = async (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        // setValue('profile_image_file', files[0]);
        const imageValidation = {
            files: files,
            ...FILE_VALIDATION.APPEARANCE.ABOUT,
        };
        const { result, message } = await checkFileTypeValidation(imageValidation);
        if (!result) {
            setError('profile_image_name', {
                type: 'manual',
                message: message,
            });
            return;
        } else {
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
        }
    };

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);

    const resetForm = () => {
        setCropData(profileImageUrl ?? '');
        setFile({ convertedFile: '', filename: '' });
        reset();
    };

    const handleSocialLogin = async (payload: any) => {
        setSocialLoading((prev) => ({ ...prev, [payload.register_type]: true }));
        await axiosPatch(API.USER.SOCIAL_CONNECT, payload)
            .then(async (response) => {
                await dispatch(me());
                setAction(initAction);
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        let message = '';
                        Object.keys(response.data).forEach((field) => {
                            message = response.data[field][0];
                        });

                        toast.error(message);
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => {
                setAction(initAction);
                setSocialLoading(InitLoading);
            });
    };

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: (successResponse) => {
            const accessToken = successResponse.access_token;
            const payload = {
                register_type: 'google',
                token: accessToken,
                is_connect: true,
            };
            setSocialLoading((prev) => ({ ...prev, google: true }));
            handleSocialLogin(payload);
        },
    });

    useEffect(() => {
        if (facebook.email && facebook.token) {
            const payload = {
                register_type: 'facebook',
                token: facebook.token,
                is_connect: true,
            };
            setSocialLoading((prev) => ({ ...prev, facebook: true }));
            handleSocialLogin(payload);
        }
    }, [facebook]);

    const handleFacebookLogin = (response: any) => {
        setFacebook((old) => ({ ...old, token: response.accessToken }));
    };

    const handleFacebookProfile = (response: any) => {
        if (response.email) {
            setFacebook((old) => ({ ...old, email: response.email }));
        } else {
            toast.error(t('Email access is required to sign in with Facebook'));
        }
    };

    const handleAppleLogin = (response: any) => {
        if (response.authorization) {
            const payload = {
                register_type: 'apple',
                token: response.authorization.id_token,
                is_connect: true,
            };
            setSocialLoading((prev) => ({ ...prev, apple: true }));
            handleSocialLogin(payload);
        }
    };

    const handleSocial = (socialType: SocialType) => () => {
        if (accountConnections[socialType] === null) {
            if (socialType === 'google') {
                handleGoogleLogin();
            }
        } else {
            setAction((old) => ({ ...old, [socialType]: true }));
        }
    };

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <PackagePlus className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{t('Disconnect Social app?')}</p>
                <span className="text-xs font-normal text-gray-500">{t('Disconnect your social app account.')}</span>
            </div>
        </div>
    );

    const handleSocialModalClose = useCallback(
        (status: boolean = false) =>
            async () => {
                const trueKey = Object.keys(action).find((key) => action[key as keyof typeof action] === true);
                if (trueKey) {
                    if (status) {
                        const payload = {
                            register_type: trueKey,
                            is_connect: false,
                        };
                        handleSocialLogin(payload);
                    } else {
                        setAction((old) => ({ ...old, [trueKey]: false }));
                    }
                }
            },
        [action],
    );

    return (
        <>
            <form onSubmit={handleSubmit(handleChange)} className="w-full px-2 py-1">
                <div className="detail-page-title-block !py-4 !px-0 border-borderSecondary">
                    <div className="flex flex-col">
                        <h2 className="flex flex-row gap-1.5 items-center">
                            <div className="title-text-header">{t('Personal Info')}</div>
                        </h2>
                        <p className="subtitle-text ">{t('Update your profile photo and personal details.')}</p>
                    </div>
                    <div className="flex flex-row gap-3">
                        <CustomButton secondary disabled={isLoading} type="button" onClick={resetForm} className="!h-[36px]">
                            {t('Cancel')}
                        </CustomButton>
                        <CustomButton primary isLoading={isLoading} disabled={isLoading || !isSaveEnabled} type="submit" className="!h-[36px]">
                            {t('Save Changes')}
                        </CustomButton>
                    </div>
                </div>
                <div className="flex flex-wrap">
                    <div className="w-full flex flex-row  gap-[32px] border-b  border-borderSecondary py-4">
                        <div className=" w-[310px]">
                            <p className="title-text ">{t('Name')}</p>
                            <p className="subtitle-text ">{t('Enter or update your full name.')}</p>
                        </div>
                        <div className="flex flex-row max-2xl:w-[400px] w-[512px] gap-4">
                            <div className="  w-1/2">
                                <Controller
                                    name="first_name"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel name="first_name" placeholder={t('First Name')} value={value} onChange={onChange} error={!!errors.first_name} />
                                            {errors.first_name && <p className="text-error">{errors.first_name.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="  w-1/2">
                                <Controller
                                    name="last_name"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel name="last_name" placeholder={t('Last Name')} value={value} onChange={onChange} error={!!errors.last_name} />
                                            {errors.last_name && <p className="text-error">{errors.last_name.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-row gap-[32px] border-b  border-borderSecondary py-4">
                        <div className=" w-[310px]  flex flex-col">
                            <p className="title-text ">{t('Your Photo')}</p>
                            <p className="subtitle-text ">{t('Displayed on your profile and visible to others.')}</p>
                        </div>
                        <div className="flex flex-row max-2xl:w-[400px] w-[512px] gap-4">
                            <ImageUpload cropData={cropData} shape="square" onChangeCrop={onChangeCrop} id="admin_logo_image_name" error={errors.profile_image_name} />
                        </div>
                    </div>

                    <div className="w-full flex flex-row justify-between border-b border-borderSecondary py-4 ">
                        <div className="flex flex-row gap-[32px]">
                            <div className=" w-[310px]">
                                <p className="title-text">{t('Email Address')}</p>
                                <p className="subtitle-text ">{t('Your primary email for notifications and communication.')}</p>
                            </div>
                            <div className="flex flex-row max-2xl:w-[400px] w-[512px] gap-4">
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full relative">
                                            <InputWithLabel readOnly name="email" value={value} onChange={onChange} error={!!errors.email} />
                                            {errors.email && <p className="text-error">{errors.email.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <CustomButton onClick={handleAction('email')} secondary className=" !h-[36px] ">
                            Edit Email
                        </CustomButton>
                    </div>
                    <div className="w-full flex flex-row  justify-between border-b border-borderSecondary py-4 ">
                        <div className="flex flex-row gap-[32px]">
                            <div className=" w-[310px]">
                                <p className="title-text">{t('Phone Number')}</p>
                                <p className="subtitle-text ">{t('Your contact number for account and booking updates.')}</p>
                            </div>
                            <div className="flex flex-row max-2xl:w-[400px] w-[512px] gap-4">
                                <Controller
                                    name="phone"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full relative">
                                            <InputWithLabel readOnly name="phone" value={value} onChange={onChange} error={!!errors.phone} />
                                            {errors.phone && <p className="text-error">{errors.phone.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <CustomButton onClick={handleAction('phone')} secondary className=" !h-[36px] ">
                            Edit Phone number
                        </CustomButton>
                    </div>
                    <div className="w-full flex flex-row  justify-between border-b border-borderSecondary py-4">
                        <div className="flex flex-row gap-[32px]">
                            <div className=" w-[310px]">
                                <p className="title-text">{t('Password')}</p>
                                <p className="subtitle-text ">{t('Change your password to keep your account secure.')}</p>
                            </div>
                            <div className="flex flex-row max-2xl:w-[400px] w-[512px] gap-4">
                                <InputWithLabel readOnly name="old_password" onChange={() => {}} placeholder="********" value={''} />
                            </div>
                        </div>
                        <CustomButton onClick={handleAction('password')} secondary className=" !h-[36px] !w-[140px]">
                            Change Password
                        </CustomButton>
                    </div>
                    <div className="w-full flex flex-row   border-b border-borderSecondary pt-4 ">
                        <div className="flex flex-row gap-[32px] w-full">
                            <div className=" max-w-[310px] min-w-[310px] w-[310px]">
                                <p className="title-text ">{t('Connected Social Accounts')}</p>
                                <p className="subtitle-text">{t('View and manage your connected social apps here.')}</p>
                            </div>
                            <div className="flex flex-col w-[66%] 2xl:w-[70%] xlm:w-[75%] 2xlm:w-[76%] xxl:w-[79%]">
                                <div className="flex flex-row gap-4 justify-between items-center  pb-4 border-b">
                                    <div className="flex flex-row gap-3 items-center">
                                        <div className="flex justify-center items-center w-[44px] h-[44px] rounded-lg border border-[#D0D5DD]">
                                            <GoogleIcon />
                                        </div>
                                        <div className="text-base text-[#344054] font-semibold">Google</div>
                                    </div>
                                    <CustomButton
                                        secondary
                                        className=" !w-[224px] !h-[36px]"
                                        isLoading={socialLoading.google}
                                        disabled={isDisabled || socialLoading.google}
                                        onClick={handleSocial('google')}
                                    >
                                        {accountConnections.google === null ? 'connect my Google account' : 'Disconnect my Google account'}
                                    </CustomButton>
                                </div>
                                <div className="flex flex-row gap-4 justify-between items-center  py-4 border-b">
                                    <div className="flex flex-row gap-3 items-center">
                                        <div className="flex justify-center items-center w-[44px] h-[44px] rounded-lg border border-[#D0D5DD]">
                                            <FacebookIcon />
                                        </div>
                                        <div className="text-base text-[#344054] font-semibold">Facebook</div>
                                    </div>
                                    {user.social_id.facebook === null ? (
                                        <FacebookLogin
                                            appId={FACEBOOK_APP_ID || ''}
                                            onSuccess={handleFacebookLogin}
                                            onProfileSuccess={handleFacebookProfile}
                                            render={({ onClick }) => (
                                                <CustomButton
                                                    secondary
                                                    isLoading={socialLoading.facebook}
                                                    disabled={isDisabled || socialLoading.facebook}
                                                    onClick={onClick}
                                                    className="!w-[224px] !h-[36px] "
                                                >
                                                    {t('connect my Facebook account')}
                                                </CustomButton>
                                            )}
                                        />
                                    ) : (
                                        <CustomButton
                                            secondary
                                            isLoading={socialLoading.facebook}
                                            disabled={isDisabled || socialLoading.facebook}
                                            className=" !h-[36px] "
                                            onClick={handleSocial('facebook')}
                                        >
                                            {t('Disconnect my Facebook account')}
                                        </CustomButton>
                                    )}
                                </div>
                                <div className="flex flex-row gap-4 justify-between items-center py-4 ">
                                    <div className="flex flex-row gap-3 items-center">
                                        <div className="flex justify-center items-center w-[44px] h-[44px] rounded-lg border border-[#D0D5DD]">
                                            <AppleIcon />
                                        </div>
                                        <div className="text-base text-[#344054] font-semibold">Apple</div>
                                    </div>
                                    {user.social_id.apple === null ? (
                                        <AppleLogin
                                            usePopup
                                            clientId={APPLE_CLIENT_ID || ''}
                                            redirectURI={APPLE_REDIRECT_URI || ''}
                                            callback={handleAppleLogin}
                                            scope="email name"
                                            responseMode="query"
                                            responseType="code"
                                            render={({ onClick }) => (
                                                <CustomButton
                                                    secondary
                                                    isLoading={socialLoading.apple}
                                                    disabled={isDisabled || socialLoading.apple}
                                                    onClick={onClick}
                                                    className="!w-[224px] !h-[36px] "
                                                >
                                                    {t('connect my Apple account')}
                                                </CustomButton>
                                            )}
                                        />
                                    ) : (
                                        <CustomButton secondary isLoading={socialLoading.apple} disabled={isDisabled || socialLoading.apple} className=" !h-[36px] " onClick={handleSocial('apple')}>
                                            {t('Disconnect my Apple account')}
                                        </CustomButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-3 pt-5 mb-36 justify-end w-full">
                        <CustomButton secondary disabled={isLoading} type="button" onClick={resetForm} className="!h-[36px]">
                            {t('Cancel')}
                        </CustomButton>
                        <CustomButton primary isLoading={isLoading} disabled={isLoading || !isSaveEnabled} type="submit" className="!h-[36px]">
                            {t('Save Changes')}
                        </CustomButton>
                    </div>
                </div>
                {upload && <CropperModal defaultCropType="Square" isCropType={false} imageUrl={image} imageUpload={imageUpload} setUpload={setUpload} setCropData={setCropData} />}
            </form>
            {action.email && <UpdateEmail handleClose={handleModalClose} />}
            {action.phone && <UpdatePhone handleClose={handleModalClose} />}
            {action.password && <AccountChangePassword handleClose={handleModalClose} />}
            {(action.google || action.facebook || action.apple) && (
                <DeletePopupModal
                    headerTitle={<Title />}
                    onClose={handleSocialModalClose}
                    isLoading={socialLoading.google || socialLoading.facebook || socialLoading.apple}
                    size="w-[480px]"
                    description={t('Disconnect social app from the account', { name: shop.business_name })}
                    descriptionClassName="p-5 border-b border-borderSecondary"
                    cancelButtonText={t('No')}
                    confirmButtonText={t('Yes')}
                />
            )}
        </>
    );
};

export default Profile;
