import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { currentShop, me } from 'src/redux/services/common/Common.slice';
import { useTranslation } from 'react-i18next';
import { ICountStep, IPinSetup, IPinSetupConfirm } from './ReceptionistMode.interface';
import PinSetupStep1 from './PinSetup/PinSetupStep1';
import PinSetupStep2 from './PinSetup/PinSetupStep2';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import PopupModal from 'src/components/PopupModal';
import { PasscodeLock } from '@untitled-ui/icons-react/build/cjs';
type FieldKey = 'pin' | 'pin_confirmation';

const PinSetup = ({ handleClose }: any) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const shopId = shop.id;
    const [counter, setCounter] = useState(59);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);
    const handleNext = async () => {
        const isStepValid = await trigger();
        if (isStepValid) setStep((prevActiveStep) => prevActiveStep + 1);
    };

    const defaultValues = {
        pin: '',
        pin_confirmation: '',
    };
    const validationSchema = [
        //validation for step1
        Yup.object({
            pin: Yup.string().length(4, t('pin must be exactly 4 number')).required(t('This field is a required')),
        }).required(),
        //validation for step2
        Yup.object({
            pin_confirmation: Yup.string()
                .length(4, t('pin must be exactly 4 number'))
                .required(t('This field is a required'))
                .test('pins-match', 'PIN and PIN Confirmation must match', function (pinConfirmation) {
                    const pin = this.parent.pin;
                    if (!pin || !pinConfirmation) {
                        return false;
                    }
                    return pin === pinConfirmation;
                })
                .required(t('PIN Confirmation is required')),
        }),
    ];
    const [step, setStep] = useState<number | ICountStep>(1);

    const currentValidationSchema = validationSchema[step - 1] as unknown as Yup.ObjectSchema<IPinSetup | IPinSetupConfirm, Record<string, any>, any, ''>;

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver<any>(currentValidationSchema),
        mode: 'onBlur',
        reValidateMode: 'onSubmit',
    });
    const { handleSubmit, trigger, setError } = methods;

    const handleSave = async (data: any) => {
        setIsLoading(true);
        if (step === 1) {
            setStep(2);
            setIsLoading(false);
        }

        const payload = {
            ...data,
            pin: data.pin ?? '',
            pin_confirmation: data.pin_confirmation ?? '',
        };
        try {
            const response = await axiosPost(API.RECEPTIONIST_MODE.PIN_SETUP, payload, {
                shop_id: shopId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(me());
                handleClose();
                return;
            }

            throw response.data;
        } catch (err: any) {
            if (err.status === errorCode.unprocessable) {
                if (err.response.data.data) {
                    Object.keys(err.response.data.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: err.response.data.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAcept = () => {
        step === 1 ? handleNext() : handleSubmit(handleSave)();
    };

    const Title = () => (
        <div className="flex flex-row gap-3 justify-center">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <PasscodeLock className="text-gray-700" />
            </div>
        </div>
    );
    return (
        <PopupModal
            dismissible
            onClose={handleClose}
            size="w-[408px]"
            className="px-5 py-5"
            title={<Title />}
            view={2}
            fixedHeightClass="!px-5 !pt-0 !pb-5"
            // secondaryButton={t('Cancel')}
            primaryButton={step === 1 ? t('Continue') : t('Confirm')}
            acceptAction={handleAcept}
            isLoading={isLoading}
            isDisabled={isLoading}
            // declineAction={handleReceptionistClose}
        >
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSave)}>{step === 1 ? <PinSetupStep1 /> : <PinSetupStep2 />}</form>
            </FormProvider>
        </PopupModal>
    );
};

export default PinSetup;
