import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { AppearanceAcademy } from 'src/theme/Images';
import { Collapse } from '../Collapse';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { checkFileTypeValidation, convertBase64ToFile } from 'src/utils/global-functions';
import { s3Path } from 'src/constants/s3Path';
import { s3Upload } from 'src/utils/s3Operations';
import { AcademyProps, IFormData } from './Academy.interface';
import TemplateLayout from '../Layout/Layout';
import ImageBoxView from '../../Image/ImageBoxView';
import ImageInput from '../../Image/ImageInput';
import { currentLanguage, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import Switch from 'src/components/Switch/Switch';
import CustomButton from 'src/components/CustomButton';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { FILE_VALIDATION } from 'src/utils/global-variables';
import { useLanguage } from 'src/hooks/useCommon';
import { CHARACTERS_LIMIT } from 'src/constants/common';
import RadioSwitch from 'src/components/RadioSwitch';

const Academy: FC<AcademyProps> = (props) => {
    const { section } = props;
    const { t } = useTranslation();
    const { languageOptions, languages, handleLanguage, handleErrorsAndSetLanguage } = useLanguage();
    const dispatch = useAppDispatch();
    const [image, setImage] = useState<any>('');
    const [isUpload, setIsUpload] = useState(false);
    const [fieldName, setFieldName] = useState<string>('');
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const language = useAppSelector(currentLanguage);

    const schemaFields: any = {
        status: Yup.bool().required(t('This field is required')),
        is_title: Yup.bool().required(t('This field is required')),
        price: Yup.string().required(t('This field is required')),
        date: Yup.string().required(t('This field is required')),
        is_button: Yup.bool().required(t('This field is required')),
        is_description: Yup.bool().required(t('This field is required')),
        image: Yup.object()
            .nullable()
            .shape({
                file: Yup.mixed().nullable(),
                url: Yup.string().nullable(),
                name: Yup.string().required(t('This field is required')),
            })
            .test('required', t('This field is required'), function (value) {
                if (!value) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t('This field is required'),
                    });
                }
                const { name, url } = value;
                if (!name || !url) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t('This field is required'),
                    });
                }
                return true;
            }),
    };
    languages.forEach((name: string) => {
        schemaFields[`${name}_title`] = Yup.string().when('is_title', ([is_title], customSchema) =>
            is_title ? customSchema.required(t('This field is required.')).max(CHARACTERS_LIMIT.SHORT, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.SHORT })) : customSchema.nullable(),
        );
        schemaFields[`${name}_button`] = Yup.string().when('is_button', ([is_button], customSchema) =>
            is_button ? customSchema.required('This field is required.').max(CHARACTERS_LIMIT.SHORT, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.SHORT })) : customSchema.nullable(),
        );
        schemaFields[`${name}_description`] = Yup.string().when('is_description', ([is_description], customSchema) =>
            is_description ? customSchema.required('This field is required.').max(CHARACTERS_LIMIT.LONG, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.LONG })) : customSchema.nullable(),
        );
    });
    const schema = Yup.object(schemaFields);

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as unknown as Resolver<IFormData>,
    });

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        clearErrors,
        watch,
        reset,
        formState: { errors },
    } = methods;

    const isStatus = watch('status');
    const imageData = watch('image');

    useEffect(() => {
        let existingData: IFormData = {
            status: false,
            is_title: false,
            price: '',
            date: '',
            is_button: false,
            is_description: false,
            image: null,
        };
        languages.forEach((locale) => {
            existingData[`${locale}_title`] = '';
            existingData[`${locale}_button`] = '';
            existingData[`${locale}_description`] = '';
        });
        if (section) {
            existingData = {
                status: !!section.status,
                is_title: !!section.is_title,
                price: section.price || '',
                date: section.date || '',
                is_button: !!section.is_button,
                is_description: !!section.is_description,
                image: section.image
                    ? {
                          name: section.image,
                          url: section.image && section.image_url,
                          file: null,
                      }
                    : null,
            };
            languages.forEach((locale) => {
                existingData[`${locale}_title`] = section[`${locale}_title`] || '';
                existingData[`${locale}_button`] = section[`${locale}_button`] || '';
                existingData[`${locale}_description`] = section[`${locale}_description`] || '';
            });
        }
        Object.entries(existingData).forEach(([key, value]) => {
            setValue(key as keyof IFormData, value);
        });
        reset(existingData);
    }, [section]);

    const handleChange = async (data: IFormData) => {
        setIsLoading(true);
        let imageStatus = errorCode.updateSuccess;
        if (imageData?.file && imageData?.name) {
            const logoImageResponse: any = await s3Upload(imageData?.file, `${s3Path.SHOP_ACADEMY}${imageData?.name}`);
            imageStatus = logoImageResponse?.status;
        }

        if (imageStatus === errorCode.updateSuccess) {
            const payload = {
                academy: { ...data, image: data?.image?.name },
                section: 'academy',
            };
            axiosPost(API.THEME.TEMPLATE.SECTION, payload, { shop_id: shop.id })
                .then(async () => {
                    await dispatch(getAllShopSettings({ shop_id: shop.id }));
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                const fieldKey = field.replace('academy.', '');
                                setError(fieldKey as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        }
    };
    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };

    const onChangeCrop = (field: any) => async (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const imageValidation = {
            files: files,
            ...FILE_VALIDATION.APPEARANCE.ACADEMY,
        };
        const { result, message } = await checkFileTypeValidation(imageValidation);
        if (!result) {
            setError(field, {
                type: 'manual',
                message: message,
            });
            return;
        } else {
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setIsUpload(false);
            };
            reader.onloadend = () => {
                setFieldName(field);
                setImage(reader.result as any);
                setIsUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            clearErrors(field);
        }
    };

    const handleRemoveImage = () => {
        setValue('image', null);
    };

    const handleCropData = (data: any) => {
        const convertedFile = convertBase64ToFile(data);
        if (convertedFile && convertedFile.filename && fieldName) {
            setValue('image', {
                file: convertedFile.convertedFile as File,
                url: data,
                name: convertedFile.filename,
            });
        }
    };

    useFormErrorFocus<IFormData>({ errors, formSectionName: 'academy' });
    const handleError = (err: any) => handleErrorsAndSetLanguage(err);
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleChange, handleError)} className="rounded-xl border border-borderPrimary h-full">
                <Collapse
                    title={t('Academy')}
                    description={t('The Academy section provides information about training programs or  courses offered by your brand.')}
                    isCollapsed={isStatus}
                    isLoading={isLoading}
                    handleStatus={handleStatus}
                    handleSave={handleSubmit(handleChange, handleError)}
                >
                    {languages.length > 1 && (
                        <div className="flex justify-end mt-5 mx-5">
                            <RadioSwitch options={languageOptions} name="academy_language" value={language} onChange={handleLanguage} />
                        </div>
                    )}
                    <TemplateLayout ImgProp={AppearanceAcademy} key={language}>
                        <div id="academy-image" className="flex gap-4">
                            <div className="flex gap-4 mb-4">
                                <Controller
                                    name={`image`}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div>
                                            {value && value.url ? (
                                                <ImageBoxView item={value} name="image" onChangeCrop={onChangeCrop} handleRemoveImage={handleRemoveImage} />
                                            ) : (
                                                <ImageInput error={error} onChangeCrop={onChangeCrop} name="image" title="Academy Picture" />
                                            )}

                                            {error?.type && error.type !== 'required' && <p className="text-error  w-[109px]  xl:w-[120px]  xxl:w-[150px]">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex-1 flex flex-col gap-4 mb-3">
                                <Controller
                                    name={`${language}_title`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id={`${language}_title`}
                                                name={`${language}_title`}
                                                label={t('Title')}
                                                placeholder={t('Enter academy title')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name="is_title"
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                                <div className="flex gap-3 xl:gap-4 ">
                                    <Controller
                                        name="price"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        label={t('Price')}
                                                        id="academy-price"
                                                        required
                                                        placeholder={t('Enter academy price')}
                                                        onChange={onChange}
                                                        value={value}
                                                        name={'price'}
                                                        error={!!error}
                                                        className={'!mb-2'}
                                                    />
                                                    {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div id="academy-date" className="w-full">
                                                {/* <label className={`fl-field-title-label `}>{t('Date')}</label> */}
                                                {/* <RangeDateSelect
                                                    onChange={onChangeRange}
                                                    selected={value && value.split(' - ')[0] ? new Date(value.split(' - ')[0]) : null}
                                                    minDate={new Date()}
                                                    startDate={value && value.split(' - ')[0] ? new Date(value.split(' - ')[0]) : null}
                                                    endDate={value && value.split(' - ')[1] ? new Date(value.split(' - ')[1]) : null}
                                                    selectsRange
                                                    label="Date"
                                                    classInput={` ${error ? 'is-invalid' : ''}`}
                                                    className={'!mb-2'}
                                                /> */}

                                                <InputWithLabel
                                                    name={`date`}
                                                    id={`date`}
                                                    label={t('Date')}
                                                    required
                                                    placeholder={t('Enter academy date')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                />

                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name={`${language}_button`}
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    id={`${language}_button`}
                                                    name={`${language}_button`}
                                                    label={t('Button')}
                                                    placeholder={t('Enter button name')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                    toggle={
                                                        <Controller
                                                            name="is_button"
                                                            control={control}
                                                            render={({ field, fieldState }: any) => (
                                                                <>
                                                                    <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                    {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                                </>
                                                            )}
                                                        />
                                                    }
                                                />
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                                <Controller
                                    name={`${language}_description`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id={`${language}_description`}
                                                name={`${language}_description`}
                                                label={t('Description')}
                                                textArea
                                                placeholder={t('Enter academy description')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                rows={15}
                                                maxLenght={CHARACTERS_LIMIT.LONG}
                                                textAearClassName="rounded-md h-[100px]"
                                                toggle={
                                                    <Controller
                                                        name="is_description"
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        {isUpload && fieldName && (
                            <CropperModal
                                imageUrl={image}
                                setUpload={setIsUpload}
                                setCropData={handleCropData}
                                defaultCropType="Rectangular"
                                title={t('Brand Logo')}
                                description={t('Upload a  max 800Kb image for best results.')}
                                isCropType={false}
                                btnTitle={t('Confirm')}
                            />
                        )}
                    </TemplateLayout>
                    <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end">
                        <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </Collapse>
            </form>
        </FormProvider>
    );
};

export default Academy;
