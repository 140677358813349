import React, { FC, useState } from 'react';
import { useForm, Controller, Resolver } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';
import { IOtp } from '../../Password/VerifyOtp/VerifyOtp.interface';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { currentShop, me } from 'src/redux/services/common/Common.slice';
import { errorCode } from 'src/constants/errorCode';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { usePhone } from 'src/hooks/usePhone';
import CustomButton from 'src/components/CustomButton';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputOtpFiled from 'src/components/InputOtp/InputOtpFiled';

interface VerifyOtpProps {
    info: any;
    handleChangeNumber: () => void;
    handleResendOtp: () => void;
}

const VerifyOtp: FC<VerifyOtpProps> = ({ info, handleChangeNumber, handleResendOtp }) => {
    const { getFormatPhoneWithBracket } = usePhone();
    const shop = useAppSelector(currentShop);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const fullFormatedPhoneNumber = getFormatPhoneWithBracket(info.phone, info.phone_country_code);

    const schema = Yup.object({
        otp: Yup.string().length(4, t('OTP must be exactly 4 number')).required(t('This field is a required')),
    }).required();
    const { handleSubmit, control, setError, reset, watch } = useForm<IOtp>({
        resolver: yupResolver(schema) as Resolver<IOtp>,
        defaultValues: {
            otp: '',
        },
    });

    // const handleResendOtp = () => {};
    const handleResendOtpFun = async () => {
        reset();
        handleResendOtp();
    };
    const handleChange = async (data: any) => {
        setIsLoading(true);
        const payload = {
            otp: data.otp,
            phone: info?.phone,
            phone_country_code: info?.phone_country_code,
        };
        await axiosPatch(API.USER.SOCIAL_ACCOUNT_VERIFY, payload, { shop_id: shop.id })
            .then(async (response) => {
                await dispatch(me());
                navigate(ROUTES.SIGNUP);
                return;
            })
            .catch((error: any) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        let message = '';
                        Object.keys(response.data).forEach((field) => {
                            message = response.data[field][0];
                            setError(field as keyof IOtp, {
                                type: 'manual',
                                message: message,
                            });
                            return;
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };
    const otpWatch = watch('otp');
    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            handleSubmit(handleChange);
        }
    };
    return (
        <div className="content mt-[84px] flex justify-center">
            <div className="w-[380px]">
                <h2 className="text-3xl leading-[]38px font-semibold m-0 text-mainTextColor leading-[124%] -tracking-[0.384px]">{t('Enter the verification code')} </h2>
                <div className="mt-1 mb-6 text-secondaryTxtColor text-sm leading-[140%] -tracking-[0.14px]">
                    <span>
                        {t('We sent a code to ')} <span className="font-bold">{fullFormatedPhoneNumber}</span>
                    </span>
                </div>
                <div className="flex justify-center">
                    <form className="w-full" onSubmit={handleSubmit(handleChange)}>
                        <div className="mx-12">
                            <Controller
                                name="otp"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                    <div className="flex flex-col mt-3">
                                        <InputOtpFiled otp={value} setOtp={onChange} errors={error} handleKeyPress={handleKeyPress} />
                                    </div>
                                )}
                            />
                        </div>
                        <CustomButton
                            primary
                            type="submit"
                            isLoading={isLoading}
                            disabled={isLoading || (!otpWatch || (otpWatch && otpWatch.length !== 4) ? true : false)}
                            size="w-full"
                            className="!text-base mt-[20px]"
                        >
                            {t('Continue')}
                        </CustomButton>

                        <div className=" mt-6 flex flex-col items-center">
                            <div className="flex">
                                <p className="text-secondaryTxtColor me-1 font-normal leading-[19.6px] -tracking-[0.384px]">{t('Didn’t receive code')}?</p>
                                <Button type="button" onClick={handleResendOtpFun} className="cursor-pointer flex flex-col leading-[19.6px] text-sm font-bold text-primary">
                                    {t('Resend')}
                                </Button>
                            </div>

                            <Button type="button" onClick={handleChangeNumber} className="cursor-pointer flex flex-col leading-[19.6px] text-sm font-semibold mt-2 text-secondaryTxtColor ">
                                {t('Changed your mobile number?')}
                            </Button>
                        </div>
                        {/* isLoading={loading} disabled={isButtonDisabled} */}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default VerifyOtp;
