import { CountryCode } from 'libphonenumber-js';
import { AlarmClockOff, CoinsStacked01, Percent03, UsersCheck } from '@untitled-ui/icons-react/build/cjs';

export const accountTypeOptions = [
    {
        label: 'Service Provider',
        value: 'service_provider',
    },
];

export const initAction = {
    pay_structure: false,
    policy: false,
    delete: false,
    active: false,
};

export const PAY_STRUCTURE_LIST = [
    {
        name: 'Commission',
        value: 'commission',
        description: 'Commission-based pay.',
        icon: Percent03,
    },
    {
        name: 'Rent',
        value: 'rent',
        description: 'Fixed rent payments.',
        icon: CoinsStacked01,
    },
    {
        name: 'No tracking',
        value: 'no_tracking',
        description: 'No payment tracking.',
        icon: AlarmClockOff,
    },
    {
        name: 'Partner',
        value: 'partner',
        description: 'Rent-free partnership.',
        icon: UsersCheck,
    },
];

export interface IFormData {
    first_name: string;
    last_name: string;
    phone: string;
    phone_country_code: CountryCode | '';
    email: string;
    account_type: 'service_provider' | '';
    shop_staff_role_id: number | null;
    shop_location_id: number[];
    pay_structure_type: 'commission' | 'rent' | 'partner' | 'no_tracking' | null;
    start_date: string;
    commission?: string;
    rent_money?: number | null;
    is_sales_tax?: boolean;
    tax_percentage?: number | undefined;
    tax_number?: string;
    is_self: boolean;
}

export interface IPayFormData {
    pay_structure_type: 'commission' | 'rent' | 'partner' | 'no_tracking' | null;
    start_date: string;
    commission?: string;
    rent_money?: number | null;
    is_sales_tax?: boolean;
    tax_percentage?: number;
    tax_number?: string;
}

export interface ExistingFormData {
    [key: string]: any;
}

export interface IInitialState {
    data: any;
    loading: boolean;
    error: { message: string | undefined; status?: string | undefined } | undefined | null;
}

export interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

export interface IStatementTable {
    type: string;
}

export const INIT_STATE = {
    FILTER: {
        shop_location_id: null,
        shop_admin_id: null,
    },
    LAZY_STATE: {
        first: 0,
        rows: 25,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            shop_location_id: { value: null },
            shop_admin_id: { value: null },
            start_date: { value: null },
            end_date: { value: null },
        },
    },
};
