import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { allShopSettings, currentShop, currentTerminal } from 'src/redux/services/common/Common.slice';
import { ArrowLeft, Check } from '@untitled-ui/icons-react/build/cjs';
import { selectCalendarData, setCalendarStep, setClearPayment, setData, setPayment } from '../../Calendar.slice';
import Switch from 'src/components/Switch/Switch';
import CustomButton from 'src/components/CustomButton';
import { useForm } from 'react-hook-form';
import { IPaymentMethod, IPaymentState } from '../../Calendar.Interface';
import { IInitPaymentStatus, IPaymentValidation, initValidation } from './Payment.interface';
import CashPayment from './CashPayment';
import PosPayment from './PosPayment';
import LoyaltyPayment from './LoyaltyPayment';
import CardPayment from './CardPayment';
import OtherPayment from './OtherPayment';
import RemainingCharge from './RemainingCharge';
import { toast } from 'react-toastify';
import { axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import AppointmentStatus from '../AppointmentStatus';

const INIT_PAYMENT_STATUS = {
    processing: false,
    success: false,
    error: false,
    retry: false,
};

const Payment = () => {
    const calendarData: any = useAppSelector(selectCalendarData);
    const terminal = useAppSelector(currentTerminal);
    const bookedSlotInfo = calendarData.bookedSlotInfo;
    const dispatch = useAppDispatch();
    const [isTaxable, setIsTaxable] = useState<boolean>(true);
    const [totalChargableAmount, setTotalChargableAmount] = useState(bookedSlotInfo.total);
    const [remainingChargeAmount, setRemainingChargedAmount] = useState(bookedSlotInfo.total);
    const [returnAmount, setReturnAmount] = useState<number>(0);
    // const [tip, setTip] = useState<number>(0);
    const [errors, setErrors] = useState<IPaymentValidation>(initValidation);
    const [totalOptions, setTotalOptions] = useState<number[]>([]);
    const [selectedMethod, setSelectedMethod] = useState<string | null>(null);
    const shopSettingInfo = useAppSelector(allShopSettings).find((setting: any) => setting.type === 'loyalty')?.value;
    const [remainingPoints, setRemainingPoints] = useState(calendarData.bookedSlotInfo.user.total_loyalty_points);
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [paymentStatus, setPaymentStatus] = useState<IInitPaymentStatus>(INIT_PAYMENT_STATUS);
    const methods = useForm<any>({
        // resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            tip: 0,
            other_payment_methods: '',
        },
    });

    const handlePaymentStatus = (status: keyof IInitPaymentStatus) => {
        setPaymentStatus({
            ...INIT_PAYMENT_STATUS,
            [status]: true,
        });
    };

    const { control, setValue, watch } = methods;
    const tip = watch('tip');
    const isPaymentMethodAvailable = (type: string) => calendarData.payments.payments.some((payment: IPaymentState) => payment.type === type);
    const updateError = (type: any, status: boolean, message: string = '') => {
        setErrors((currentErrors) => ({
            ...currentErrors,
            [type]: {
                status: status,
                message: message,
            },
        }));
    };

    const remainingPointsFun = (tipAmount = tip) => {
        if (shopSettingInfo === undefined) return;
        const loyaltyPointValue = +(shopSettingInfo.spend.amount / shopSettingInfo.spend.point).toFixed(3);

        const remainingPointsCount = Math.floor((calendarData.bookedSlotInfo.user.total_loyalty_amount - totalChargableAmount - tipAmount) / +loyaltyPointValue);
        setRemainingPoints(remainingPointsCount);
        setRemainingChargedAmount(0);
    };
    const handleBack = () => {
        dispatch(setClearPayment());
        dispatch(setCalendarStep('checkoutAppointment'));
    };

    useEffect(() => {
        calendarData.payments.payments.some((payment: IPaymentState) => payment.type === 'loyalty') && shopSettingInfo !== undefined && remainingPointsFun();
    }, []);

    const generateSequence = (start: number, no: number = 2, increment: number = 10) => {
        if (start === 0) {
            setTotalOptions([]);
            return;
        }
        const ceilAmount = +start;
        const nextDivisibleBy10 = Math.ceil(+ceilAmount / 10) * 10;
        const secondValue = nextDivisibleBy10 === ceilAmount ? +ceilAmount + 10 : +nextDivisibleBy10;
        const totalSequence = [parseFloat(ceilAmount.toFixed(2)), parseFloat(secondValue.toFixed(2)), parseFloat((secondValue + 10).toFixed(2))];
        setTotalOptions(totalSequence);
    };
    useEffect(() => {
        if (!isPaymentMethodAvailable('loyalty')) {
            const total = bookedSlotInfo.payment_option === 'pay_now' ? 0 : +(isTaxable ? bookedSlotInfo.total : bookedSlotInfo.total - bookedSlotInfo.taxes_and_fees);
            generateSequence(bookedSlotInfo.payment_option === 'pay_now' ? +tip : +total);
            setTotalChargableAmount(total);
            const collectedAmount = calendarData.payments.payments.reduce((sum: number, payment: { amount: number }) => sum + payment.amount, 0);
            const remainingAmount = Number(Number(total + +tip - collectedAmount).toFixed(2));
            setRemainingChargedAmount(remainingAmount <= 0 ? 0 : remainingAmount);
            setReturnAmount(remainingAmount <= 0 ? Math.abs(remainingAmount) : 0);
        } else {
            const total = bookedSlotInfo.payment_option === 'pay_now' ? 0 : +(isTaxable ? bookedSlotInfo.total : bookedSlotInfo.total - bookedSlotInfo.taxes_and_fees);
            setTotalChargableAmount(total);
        }
    }, [isTaxable, bookedSlotInfo]);
    useEffect(() => {
        if (!isPaymentMethodAvailable('loyalty')) {
            const total = bookedSlotInfo.payment_option === 'pay_now' ? 0 : +(isTaxable ? bookedSlotInfo.total : bookedSlotInfo.total - bookedSlotInfo.taxes_and_fees);
            generateSequence(bookedSlotInfo.payment_option === 'pay_now' ? (tip ? +tip : 10) : +total + +tip);
        }
    }, [tip]);

    useEffect(() => {
        if (isPaymentMethodAvailable('loyalty') && shopSettingInfo !== undefined) {
            remainingPointsFun();
        }
    }, [totalChargableAmount, isTaxable, bookedSlotInfo, tip]);

    const getPaymentMethods = async (type?: any, value?: number) => {
        let paymentMethods = calendarData.payments.payments;
        if (type !== undefined && value !== undefined) {
            paymentMethods = calendarData.payments.payments.map((payment: { type: string }) => (payment.type === type ? { ...payment, amount: +value } : payment));
        }
        const collectedAmount = paymentMethods.reduce((sum: number, payment: { amount: number }) => sum + +payment.amount, 0);
        const remainingAmount = Number(Number(+totalChargableAmount - +collectedAmount).toFixed(2));
        return {
            paymentMethods: paymentMethods,
            remainingAmount: remainingAmount,
        };
    };

    const handleReturnAmountCalculation = async (event: any) => {
        const value = Number(event.target.value);
        const paymentMethodsInfo = await getPaymentMethods();
        if (paymentMethodsInfo.remainingAmount <= 0) {
            let maxReturnAmount = Number(Number(Math.abs(paymentMethodsInfo.remainingAmount)).toFixed(2));
            const cashAmount = +calendarData.payments.payments.find((payment: IPaymentMethod) => payment.type === 'cash').amount;

            if (maxReturnAmount < value || cashAmount < value) {
                const updateMaxReturnAmount = maxReturnAmount > cashAmount ? cashAmount : maxReturnAmount;
                updateError('returnAmount', true, `Please do not exceed the maximum return amount of $${updateMaxReturnAmount}.`);
            } else {
                updateError('returnAmount', false);
            }
            const updatedTipAmount = +Number(Number(maxReturnAmount - value).toFixed(2));
            // setTip(updatedTipAmount >= 0 ? updatedTipAmount : 0);
            setValue('tip', updatedTipAmount >= 0 ? updatedTipAmount : 0);
            dispatch(setPayment({ tip: updatedTipAmount >= 0 ? updatedTipAmount : 0 }));

            setReturnAmount(+value.toFixed(2));
        }
    };

    const setTipReturnAmount = async (paymentMethods?: any) => {
        const paymentMethodsInfo = (await paymentMethods) ?? (await getPaymentMethods());
        const remainingAmountWithTip = Number(Number(+tip + +paymentMethodsInfo.remainingAmount).toFixed(2));

        if (!paymentMethodsInfo.paymentMethods.some((payment: IPaymentState) => payment.type === 'cash')) {
            if (remainingAmountWithTip < 0) {
                // setTip(Number(Math.abs(remainingAmountWithTip)));
                setValue('tip', +Math.abs(paymentMethodsInfo.remainingAmount));
                dispatch(setPayment({ tip: +Math.abs(paymentMethodsInfo.remainingAmount) }));

                setReturnAmount(0);
                setRemainingChargedAmount(0);
            } else {
                setRemainingChargedAmount(+(remainingAmountWithTip < 0 ? 0 : remainingAmountWithTip).toFixed(2));
            }
        }
        if (paymentMethodsInfo.paymentMethods.some((payment: IPaymentState) => payment.type === 'cash')) {
            if (remainingAmountWithTip < 0) {
                const remainingChargeAmountCalculation = Math.abs(remainingAmountWithTip);
                const cashAmount = paymentMethodsInfo.paymentMethods.find((payment: IPaymentMethod) => payment.type === 'cash').amount;

                if (remainingChargeAmountCalculation > cashAmount) {
                    setReturnAmount(+Math.abs(cashAmount).toFixed(2));
                    // setTip(remainingChargeAmountCalculation - cashAmount);
                    setValue('tip', +(remainingChargeAmountCalculation - cashAmount));
                    dispatch(setPayment({ tip: +(remainingChargeAmountCalculation - cashAmount) }));
                } else {
                    setReturnAmount(+Math.abs(remainingChargeAmountCalculation).toFixed(2));
                }
                setRemainingChargedAmount(0);
            } else {
                setRemainingChargedAmount(+(remainingAmountWithTip < 0 ? 0 : remainingAmountWithTip).toFixed(2));
                setReturnAmount(0);
            }
        }
    };

    const handlePaymentInput = (type: any, value: number) => async () => {
        setValue(type, value);
        const paymentMethodsInfo = await getPaymentMethods(type, value);
        setTipReturnAmount(paymentMethodsInfo);
        dispatch(setPayment({ payments: paymentMethodsInfo.paymentMethods }));
        updateError('returnAmount', false);
    };

    const handleBlurInput = (event: any) => {
        const value = event.target.value;
        const name = event.target.name;
        if (!value) {
            if (name === 'tip') {
                setValue('tip', 0);
                dispatch(setPayment({ tip: 0 }));
            }
            name === 'cash' || name === 'stripe' || (name === 'pos' && handlePaymentInput(name, 0)());
        }
    };
    const handleRadioChange = (method: string) => () => {
        setSelectedMethod((currentMethod) => (currentMethod === method ? null : method));
    };
    const handleTipWithoutCalculation =
        (value: any, isFixed: boolean = false) =>
        () => {
            let tipAmount = value;
            if (isFixed && bookedSlotInfo.location.tips_type !== 'value') {
                tipAmount = Number(((Number(calendarData.bookedSlotInfo.subtotal - +calendarData.bookedSlotInfo.product_amount) * Number(tipAmount)) / 100).toFixed(2));
            }
            if (tipAmount >= 0) {
                const collectedAmount = calendarData.payments.payments.reduce((sum: number, payment: { amount: number }) => sum + payment.amount, 0);
                const total = bookedSlotInfo.payment_option === 'pay_now' ? 0 : +(isTaxable ? bookedSlotInfo.total : bookedSlotInfo.total - bookedSlotInfo.taxes_and_fees);
                let remainingAmount = Number(Number(+total + -+collectedAmount).toFixed(2));

                if (!isPaymentMethodAvailable('cash') && !isPaymentMethodAvailable('loyalty')) {
                    if (remainingAmount < 0 && Math.abs(remainingAmount) > tipAmount) {
                        updateError('tip', true, `Please enter a minimum tip of $${Math.abs(remainingAmount)}`);
                    } else {
                        updateError('tip', false);
                        remainingAmount = +(+remainingAmount + +tipAmount).toFixed(2);
                        setRemainingChargedAmount(+remainingAmount.toFixed(2));
                    }
                }
                remainingAmount = +remainingAmount + +tipAmount;

                if (isPaymentMethodAvailable('cash')) {
                    updateError('returnAmount', false);
                    if (remainingAmount < 0) {
                        let maxReturnAmount = Math.abs(remainingAmount);
                        const cashAmount = calendarData.payments.payments.find((payment: IPaymentMethod) => payment.type === 'cash').amount;
                        const updatedMaxReturnAmount = maxReturnAmount - cashAmount;
                        if (updatedMaxReturnAmount > tipAmount) {
                            updateError('tip', true, `Please enter a minimum tip of $${Math.abs(updatedMaxReturnAmount)}`);
                        } else {
                            updateError('tip', false);
                        }
                        setReturnAmount(+maxReturnAmount.toFixed(2));
                        setRemainingChargedAmount(0);
                    } else {
                        setRemainingChargedAmount(+Math.abs(remainingAmount).toFixed(2));
                        setReturnAmount(0);
                    }
                }

                // setReturnAmount(updatedTipAmount >= 0 ? updatedTipAmount : 0);
                if (isPaymentMethodAvailable('loyalty')) {
                    const maxTipAmount = Math.round(calendarData.bookedSlotInfo.user.total_loyalty_amount - totalChargableAmount);
                    setRemainingChargedAmount(0);
                    if (maxTipAmount < tipAmount) {
                        updateError('tip', true, `You can enter a maximum tip of $${Math.abs(maxTipAmount)}`);
                    } else {
                        updateError('tip', false);
                    }
                }
                // setTip(tipAmount);
                setValue('tip', tipAmount);
                dispatch(setPayment({ tip: tipAmount }));
            }
        };

    const handleTaxAndFees = () => {
        setIsTaxable(!isTaxable);
    };

    const handleCompletePayment = (): boolean => {
        const areAllStatusesFalse = Object.values(errors).every((error) => error.status === false);
        const allPaymentsGreaterThanZero = calendarData.payments.payments.every((payment: IPaymentState) => payment.amount > 0);

        if (calendarData.bookedSlotInfo.payment_option === 'pay_now' && +calendarData.payments.tip === 0) {
            return true;
        }
        if (isLoading) {
            return true;
        }

        if (isPaymentMethodAvailable('other')) {
            if (areAllStatusesFalse && selectedMethod && remainingChargeAmount === 0) {
                return false;
            }
            return true;
        }
        if ((remainingChargeAmount === 0 || isPaymentMethodAvailable('loyalty')) && areAllStatusesFalse && allPaymentsGreaterThanZero) {
            return false;
        }
        return true;
    };
    useEffect(() => {
        handleCompletePayment();
    }, [calendarData.payments.payments]);

    const getBooking = async () => {
        const params = {
            shop_id: shop.id,
            id: calendarData.bookedSlotInfo?.id,
        };
        const booking = await axiosGet(API.BOOKING.GET, params)
            .then((response: any) => response.data.data)
            .catch((error: any) => {
                toast.error(error?.message);
                return null;
            });
        return booking;
    };

    const handlePosStatus = () => {
        const payload = {
            booking_id: calendarData.bookedSlotInfo.id,
        };
        const params = {
            shop_id: shop.id,
            id: terminal.id,
        };
        axiosPost(API.STRIPE.READER.STATUS, payload, params)
            .then(async (response: any) => {
                const status = response.data.data.action.status;
                switch (status) {
                    case 'in_progress':
                        setTimeout(() => {
                            handlePosStatus();
                        }, 5000);
                        handlePaymentStatus('processing');
                        break;
                    case 'succeeded':
                        const booking = await getBooking();
                        const updateState = {
                            bookedSlotInfo: booking,
                            getBookingList: true,
                        };
                        dispatch(setData(updateState));
                        handlePaymentStatus('success');
                        break;
                    case 'failed':
                        handlePaymentStatus('retry');
                        break;
                }
            })
            .catch((err: any) => {
                handlePaymentStatus('retry');
                toast.error(err?.message);
            })
            .finally(() => setIsLoading(false));
    };
    const handleSubmit = () => {
        handlePaymentStatus('processing');
        const isValid = !handleCompletePayment();

        if (isValid && calendarData.bookedSlotInfo.status === 'confirmed') {
            setIsLoading(true);
            let API_URL = API.BOOKING.COMPLETE;
            let payload: any = {};
            if (calendarData.bookedSlotInfo.payment_option !== 'pay_now') {
                let paymentInfo: any = calendarData.payments.payments.map((payment: IPaymentMethod) => {
                    if (payment.type === 'cash' && payment.amount - returnAmount - tip > 0) {
                        return { ...payment, amount: +(payment.amount - returnAmount - tip).toFixed(2) };
                    } else if (payment.type === 'cash') {
                        return null; // Mark for exclusion
                    } else if (payment.type === 'loyalty') {
                        return { ...payment, amount: (+totalChargableAmount).toFixed(2), type: 'loyalty' };
                    } else {
                        return calendarData.payments.payments.length === 1
                            ? { ...payment, amount: parseFloat((+payment.amount - +tip).toFixed(2)) }
                            : { ...payment, amount: parseFloat((+payment.amount).toFixed(2)) };
                    }
                });
                payload = {
                    ...payload,
                    payment_method: paymentInfo,
                    status: 'completed',
                    is_taxable: isTaxable,
                };
                API_URL = API.BOOKING.UPDATE_STATUS;
            } else {
                payload = {
                    payment_method: calendarData.payments.payments[0].type,
                };
            }

            if (calendarData.payments.tip > 0) {
                payload.tip = calendarData.payments.tip;
            }
            if (selectedMethod && isPaymentMethodAvailable('other')) {
                payload.extra_payment_method = selectedMethod;
            }
            if (isPaymentMethodAvailable('pos')) {
                payload.reader_id = terminal.id;
            }

            axiosPatch(API_URL, payload, {
                shop_id: shop.id,
                id: calendarData.bookedSlotInfo.id,
            })
                .then(async () => {
                    if (isPaymentMethodAvailable('pos')) {
                        handlePosStatus();
                    } else {
                        handlePaymentStatus('success');
                    }
                    const booking = await getBooking();
                    const updateState = {
                        bookedSlotInfo: booking,
                        getBookingList: true,
                    };
                    dispatch(setData(updateState));
                })
                .catch((err: any) => {
                    handlePaymentStatus('retry');
                    toast.error(err?.message);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const handleContinueOrBack = () => {
        dispatch(setCalendarStep('bookedAppointment'));
    };
    const handleRetry = () => {
        handleSubmit();
    };

    const isAllPaymentStatusFalse = (): boolean => Object.values(paymentStatus).every((value) => value === false);

    return !isAllPaymentStatusFalse() ? (
        <AppointmentStatus paymentStatus={paymentStatus} handleContinueOrBack={handleContinueOrBack} handleRetry={handleRetry} />
    ) : (
        <>
            <div className="p-4 flex items-center border-b gap-3">
                <div className="close-btn cursor-pointer" onClick={handleBack}>
                    <ArrowLeft className="w-[18px] text-gray-700" />
                </div>

                <div className="flex flex-col">
                    <span className="text-base font-semibold leading-6 text-gray-900">Checkout</span>
                    <span className="text-gray-600 text-xs font-medium leading-[18px]"> Appointment #{calendarData.bookedSlotInfo.receipt_code}</span>
                </div>
            </div>
            <div className="p-4 flex-1 overflow-y-auto appearance-switch-btn">
                <div className="flex justify-between mb-[10px] pb-[10px] border-b border-gray-200">
                    <div className="flex flex-row gap-2">
                        <p className="text-xs font-medium leading-[18px] text-gray-900">Service</p>
                        {calendarData.bookedSlotInfo.payment_option === 'pay_now' && (
                            <span className="h-[22px] ml-2.5 flex items-center border border-success-200 bg-success-50 rounded-md gap-1 py-0.5 pr-1.5 pl-1 text-success-700 text-xs font-medium leading-[18px]">
                                <Check className="w-[14px] h-[14px] text-success-600" /> Paid
                            </span>
                        )}
                    </div>
                    <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${(+calendarData.bookedSlotInfo.subtotal - +calendarData.bookedSlotInfo.product_amount).toFixed(2)}</p>
                </div>
                <div className="flex justify-between mb-[10px] pb-[10px] border-b border-gray-200">
                    <div className="flex flex-row gap-2">
                        <p className="text-xs font-medium leading-[18px] text-gray-900">Product</p>
                        {calendarData.bookedSlotInfo.payment_option === 'pay_now' && (
                            <span className="h-[22px] ml-2.5 flex items-center border border-success-200 bg-success-50 rounded-md gap-1 py-0.5 pr-1.5 pl-1 text-success-700 text-xs font-medium leading-[18px]">
                                <Check className="w-[14px] h-[14px] text-success-600" /> Paid
                            </span>
                        )}
                    </div>
                    <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${calendarData.bookedSlotInfo.product_amount}</p>
                </div>
                <div className="flex justify-between mb-[10px] pb-[10px] border-b border-gray-200">
                    <div className="flex flex-row gap-2">
                        <p className="text-xs font-medium leading-[18px] text-gray-900">Total</p>
                        {calendarData.bookedSlotInfo.payment_option === 'pay_now' && (
                            <span className="h-[22px] ml-2.5 flex items-center border border-success-200 bg-success-50 rounded-md gap-1 py-0.5 pr-1.5 pl-1 text-success-700 text-xs font-medium leading-[18px]">
                                <Check className="w-[14px] h-[14px] text-success-600" /> Paid
                            </span>
                        )}
                    </div>
                    <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${calendarData.bookedSlotInfo.subtotal}</p>
                </div>
                <div className="flex justify-between mb-[12px] pb-[10px] border-b border-gray-200">
                    <div className="flex flex-row gap-2">
                        <p className="text-xs font-medium leading-[18px] text-gray-900">Taxes</p>
                        {bookedSlotInfo.payment_option !== 'pay_now' && (
                            <div className="mt-[1px]">
                                <Switch isChecked={isTaxable} onChange={handleTaxAndFees} className="mt-2" />
                            </div>
                        )}
                        {calendarData.bookedSlotInfo.payment_option === 'pay_now' && (
                            <span className="h-[22px] ml-2.5 flex items-center border border-success-200 bg-success-50 rounded-md gap-1 py-0.5 pr-1.5 pl-1 text-success-700 text-xs font-medium leading-[18px]">
                                <Check className="w-[14px] h-[14px] text-success-600" /> Paid
                            </span>
                        )}
                    </div>
                    <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${isTaxable ? calendarData.bookedSlotInfo.taxes_and_fees : '0.00'}</p>
                </div>

                {tip > 0 && (
                    <div className="flex justify-between mb-[12px] pb-[10px] border-b border-gray-200">
                        <div className="flex flex-row gap-2">
                            <p className="text-xs font-medium leading-[18px] text-gray-900">Tip</p>
                        </div>
                        <p className="ml-auto text-gray-600 font-semibold text-xs leading-[18px]">${parseFloat((+tip).toFixed(2)).toFixed(2)}</p>
                    </div>
                )}

                <div className="flex justify-between mb-6 pb-3 border-b border-gray-200">
                    <p className="text-gray-900 font-bold text-sm leading-5">Payable Amount</p>
                    <p className="ml-auto text-gray-900 font-bold text-sm leading-5">${parseFloat((+totalChargableAmount + +tip).toFixed(2)).toFixed(2)}</p>
                </div>

                <div className="flex flex-col">
                    {calendarData.payments.payments.map((payment: IPaymentState, index: number) => {
                        if (payment.type === 'cash') {
                            return (
                                <CashPayment
                                    totalOptions={totalOptions}
                                    handlePaymentInput={handlePaymentInput}
                                    handleTipWithoutCalculation={handleTipWithoutCalculation}
                                    handleReturnAmountCalculation={handleReturnAmountCalculation}
                                    handleBlurInput={handleBlurInput}
                                    errors={errors}
                                    returnAmount={returnAmount}
                                    control={control}
                                    key={index}
                                />
                            );
                        } else if (payment.type === 'pos') {
                            return (
                                <PosPayment
                                    handlePaymentInput={handlePaymentInput}
                                    handleTipWithoutCalculation={handleTipWithoutCalculation}
                                    handleBlurInput={handleBlurInput}
                                    errors={errors}
                                    control={control}
                                    key={index}
                                />
                            );
                        } else if (payment.type === 'loyalty' && shopSettingInfo !== undefined) {
                            return <LoyaltyPayment handleTipWithoutCalculation={handleTipWithoutCalculation} errors={errors} control={control} remainingPoints={remainingPoints} key={index} />;
                        } else if (payment.type === 'stripe') {
                            return (
                                <CardPayment
                                    handlePaymentInput={handlePaymentInput}
                                    handleTipWithoutCalculation={handleTipWithoutCalculation}
                                    handleBlurInput={handleBlurInput}
                                    errors={errors}
                                    control={control}
                                    key={index}
                                />
                            );
                        } else if (payment.type === 'other') {
                            return (
                                <OtherPayment
                                    handlePaymentInput={handlePaymentInput}
                                    handleTipWithoutCalculation={handleTipWithoutCalculation}
                                    handleBlurInput={handleBlurInput}
                                    errors={errors}
                                    methods={methods}
                                    handleRadioChange={handleRadioChange}
                                    selectedMethod={selectedMethod}
                                    key={index}
                                />
                            );
                        } else {
                            return null;
                        }
                    })}

                    {remainingChargeAmount > 0 && <RemainingCharge returnAmount={remainingChargeAmount} />}
                </div>
            </div>
            <div className="border-t px-4 py-4 w-full">
                <CustomButton disabled={handleCompletePayment()} isLoading={isLoading} primary onClick={handleSubmit} type="button" className="w-full">
                    Complete Payment
                </CustomButton>
            </div>
        </>
    );
};
export default Payment;
