import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { userMe } from 'src/redux/services/common/Common.slice';
import SignupStaff from './SignupStaff';
import SignupAdmin from './SignupAdmin';
import { ROLES } from 'src/constants/common';

const Signup = () => {
    const user = useAppSelector(userMe);
    const [isStaff, setIsStaff] = useState(false);

    useEffect(() => {
        let isAdmins = true;
        let isStaffs = false;
        if (user && user.roles && user.roles.length > 0) {
            isStaffs = user.roles.some((role: any) => [ROLES.STAFF_RENT, ROLES.STAFF_COMMISSION, ROLES.STAFF_PARTNER, ROLES.STAFF_NO_TRACKING].includes(role.name));
            isAdmins = user.roles.some((role: any) => [ROLES.OWNER, ROLES.INDIVIDUAL].includes(role.name));
        }
        setIsStaff(isStaffs && !isAdmins);
    }, [user]);

    return <>{isStaff ? <SignupStaff /> : <SignupAdmin />}</>;
};

export default Signup;
