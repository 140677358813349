import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, me } from 'src/redux/services/common/Common.slice';
import { Controller, useFormContext } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import { checkFileTypeValidation, convertBase64ToFile, onError } from 'src/utils/global-functions';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import { ImageName, ImageType } from './BusinessDetails.interface';
import { FILE_VALIDATION } from 'src/utils/global-variables';
import ImageUpload from 'src/components/ImageUpload';
import Switch from 'src/components/Switch/Switch';
import { CheckCircle, Link03, XCircle } from '@untitled-ui/icons-react/build/cjs';
import { useRolePermission } from 'src/hooks/useRolePermission';

const BusinessInfo = ({ errors, cropData, setCropData }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const { watch, control, setValue, setError, clearErrors } = useFormContext();
    const { isAdmin } = useRolePermission();
    const [image, setImage] = useState<any>('');
    const [upload, setUpload] = useState(false);
    const [publishLoading, setPublishLoading] = useState(false);
    const [imageName, setImageName] = useState<ImageName>(null);
    const [imageType, setImageType] = useState<ImageType>(null);

    const slug = watch('slug');
    const logoImageType = watch('logo_image_type');
    const adminLogoImageType = watch('admin_logo_image_type');
    const isSameAsLogo = watch('is_same_as_logo');
    const logoImageName = watch('logo_image_name');

    useEffect(() => {
        if (isSameAsLogo) {
            setCropData((old: any) => ({
                ...old,
                admin_logo_image_name: { ...old.admin_logo_image_name, data: old.logo_image_name.data },
            }));
            setValue('admin_logo_image_name', logoImageName);
            setValue('admin_logo_image_type', logoImageType);
        } else {
            setCropData((old: any) => ({
                ...old,
                admin_logo_image_name: { ...old.admin_logo_image_name, data: shop.admin_logo_image_url, file: null, name: null },
            }));
            setValue('admin_logo_image_name', shop.admin_logo_image_name);
            setValue('admin_logo_image_type', shop.admin_logo_image_type);
        }
    }, [isSameAsLogo, logoImageName]);

    useEffect(() => {
        ['rectangle', 'free_size'].includes(logoImageType) && setValue('is_same_as_logo', false);
    }, [logoImageType]);

    const handleCropData = (data: any, type: string) => {
        const convertedFile = convertBase64ToFile(data);
        if (convertedFile && convertedFile.filename && imageName && imageType) {
            setValue(imageName, convertedFile.filename);
            setValue(imageType, type);
            setCropData((old: any) => ({ ...old, [imageName]: { ...old[imageName], file: convertedFile.convertedFile, name: convertedFile.filename, data } }));
        }
    };

    const onChangeCrop = (name: ImageName, type: ImageType) => async (e: any) => {
        e.preventDefault();
        if (!isAdmin || name === null) {
            return;
        }
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const imageValidation = {
            files: files,
            ...FILE_VALIDATION.SETTING.BUSINESS_LOGO,
        };
        const { result, message } = await checkFileTypeValidation(imageValidation);

        if (!result) {
            setError(name, {
                type: 'manual',
                message: message,
            });
            return;
        } else {
            setImageName(name);
            setImageType(type);
            setUpload(false);

            clearErrors(name);
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        }
    };

    const handlepublished = () => {
        setPublishLoading(true);
        axiosPost(API.SHOP.SLUG, { is_slug_active: !shop.is_slug_active }, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
            })
            .catch((error: any) => toast.error(error?.message))
            .finally(() => setPublishLoading(false));
    };

    const validateSlug = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const payload = {
            slug: value,
            is_current: true,
        };
        axiosPost(API.SHOP.SLUG_EXISTS, payload, { shop_id: shop.id }).then((response) => {
            const data = response.data.data;
            if (data) {
                setError('slug', {
                    type: 'manual',
                    message: 'The slug has already been taken.',
                });
            } else {
                clearErrors('slug');
            }
        });
    };

    const onImageDownload = (Id: string) => () => {
        const svg = document.getElementById(Id);

        if (svg) {
            const svgData = new XMLSerializer().serializeToString(svg);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (ctx) {
                canvas.width = 500;
                canvas.height = 500;
                const img = new Image();
                img.onload = () => {
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    const pngFile = canvas.toDataURL('image/png');
                    const downloadLink = document.createElement('a');
                    downloadLink.download = `${Id}.png`;
                    downloadLink.href = pngFile;
                    downloadLink.click();
                };

                img.onerror = () => {
                    toast.error('Failed to load SVG data as an image.');
                };

                img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
            }
        }
    };

    return (
        <>
            <div className="w-full flex flex-row gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Brand Name')}</p>
                    <p className="subtitle-text">{t('Set the name that represents your brand on all channels.')}</p>
                </div>
                <div className="flex flex-row max-2xl:w-[400px] w-[512px] gap-4">
                    <Controller
                        name="business_name"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="w-[512px]">
                                <InputWithLabel name="business_name" placeholder={t('Business name')} value={value} onChange={onChange} error={!!error} disabled={!isAdmin} />
                            </div>
                        )}
                    />
                </div>
            </div>

            <div className="w-full flex flex-row gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]  flex flex-col">
                    <p className="title-text">{t('Brand Logo')}</p>
                    <p className="subtitle-text">{t('Upload a logo to display on booking platforms, emails, and more.')}</p>
                </div>
                <div className="flex flex-row max-2xl:w-[400px] w-[512px] gap-4 items-start">
                    <ImageUpload
                        isIcon
                        id="logo_image_name"
                        disabled={!isAdmin}
                        isUploadBox={isAdmin}
                        shape={logoImageType}
                        cropData={cropData.logo_image_name.data}
                        error={errors.logo_image_name}
                        onChangeCrop={onChangeCrop('logo_image_name', 'logo_image_type')}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px] flex flex-col">
                    <p className="title-text">{t('Brand Icon')}</p>
                    <p className="subtitle-text">{t('Upload a icon to display on manager platforms, favicon, and more.')}</p>
                </div>
                <div className="flex flex-row max-2xl:w-[400px] w-[512px] gap-4 items-start">
                    <div className="max-w-[620px] w-full">
                        <Controller
                            name="is_same_as_logo"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    {isAdmin && (
                                        <div className="flex mb-3 items-center">
                                            <Switch onChange={onChange} isChecked={value} isDisabled={!isAdmin || !['circle', 'square'].includes(logoImageType)} />
                                            <span className="ml-2 font-medium text-sm text-gray-700">Use my brand logo</span>
                                        </div>
                                    )}

                                    {!value ? (
                                        <ImageUpload
                                            isIcon
                                            id="admin_logo_image_name"
                                            disabled={!isAdmin}
                                            isUploadBox={isAdmin}
                                            shape={adminLogoImageType}
                                            cropData={cropData.admin_logo_image_name.data}
                                            error={errors.admin_logo_image_name}
                                            onChangeCrop={onChangeCrop('admin_logo_image_name', 'admin_logo_image_type')}
                                        />
                                    ) : (
                                        !isAdmin && (
                                            <img
                                                src={shop.admin_logo_image_url}
                                                alt=""
                                                className={`w-14 h-14 border-[0.75px] border-[#F2F4F7] relative object-cover ${
                                                    shop.admin_logo_image_type === 'circle' ? 'rounded-full' : 'rounded-md'
                                                }`}
                                                onError={onError}
                                            />
                                        )
                                    )}
                                </>
                            )}
                        />
                    </div>
                </div>
            </div>

            {isAdmin && (
                <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                    <div className=" w-[310px] min-w-[310px]">
                        <p className="title-text">{t('Booking URL')}</p>
                        <p className="subtitle-text">{t('Define the link clients will use to book with your brand.')}</p>
                    </div>
                    <div className="flex flex-row w-full justify-between gap-4">
                        <div className="max-2xl:w-[400px] w-[512px]">
                            <Controller
                                name={`slug`}
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                    <div className="data-wrap-block max-w-[512px] gap-4 flex xxl:max-w-[700px]">
                                        <div className="w-full max-w-[512px]">
                                            <InputWithLabel
                                                labelIcon={<Link03 className="w-4 h-4" />}
                                                inputControlClassName="pl-0"
                                                labelEndIcon={
                                                    <div className="flex text-xs leading-[18px] font-semibold text-[#344054] flex-row gap-1 items-center">
                                                        .getflair.ca
                                                        {!!error ? <XCircle className="text-error-500 w-4" /> : <CheckCircle className="text-success-500 w-4" />}
                                                    </div>
                                                }
                                                onChange={onChange}
                                                onBlur={validateSlug}
                                                disabled={!isAdmin}
                                                value={value}
                                                name="slug"
                                                error={!!error}
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        {isAdmin && (
                            <CustomButton outlinePrimary isLoading={publishLoading} disabled={publishLoading} onClick={handlepublished} className="h-[36px]">
                                {shop.is_slug_active ? 'Unpublished' : 'Published'}
                            </CustomButton>
                        )}
                    </div>
                </div>
            )}

            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className="w-[310px] min-w-[310px]">
                    <p className="title-text">{t('Booking QR Code')}</p>
                    <p className="subtitle-text">{t('Generate a QR code to easily share your booking link.')}</p>
                </div>
                <div className="flex items-center justify-between gap-5 w-full">
                    {slug && <QRCode className="min-w-[120px]  w-[120px] h-[120px] inline-block object-cover" value={`https://${slug}.${process.env.REACT_APP_DOMAIN}`} level="H" id="WebQRCode" />}
                    <CustomButton outlinePrimary onClick={onImageDownload('WebQRCode')} className="h-[36px]">
                        Download
                    </CustomButton>
                </div>
            </div>
            {upload && imageName && (
                <CropperModal
                    title={cropData[imageName].title}
                    description={cropData[imageName].description}
                    defaultCropType={cropData[imageName].default_crop_type}
                    types={cropData[imageName].cropTypes}
                    imageUrl={image}
                    setCropData={handleCropData}
                    setUpload={setUpload}
                />
            )}
        </>
    );
};

export default BusinessInfo;
