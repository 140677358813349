export const APP_FULL_NAME: string = process.env.REACT_APP_FULL_NAME as string;
export const APP_API_URL: string = process.env.REACT_APP_API_URL as string;
export const REGION_CODE: any = process.env.REACT_APP_REGION_CODE;
export const TIMEZONE: any = process.env.REACT_APP_TIMEZONE;
export const FACEBOOK_APP_ID: string | undefined = process.env.REACT_APP_FACEBOOK_APP_ID;
export const GOOGLE_CLIENT_ID: string | undefined = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const APPLE_CLIENT_ID: string | undefined = process.env.REACT_APP_APPLE_CLIENT_ID;
export const APPLE_REDIRECT_URI: string | undefined = process.env.REACT_APP_APPLE_REDIRECT_URI;
export const DATE_FORMAT: string = 'MMMM Do, YYYY';
export const DATE_TIME_FORMAT: string = 'MMM Do, YYYY h:mm A';
export const TIME_FORMAT: string = 'hh:mm:ss';
export const LANGUAGE = 'en';
export const LANGUAGE_OPTIONS = [
    { value: 'english', label: 'English' },
    { value: 'french', label: 'French' },
];
export const LANGUAGES = [
    {
        shortName: 'en',
        name: 'English',
    },
    {
        shortName: 'fr',
        name: 'French',
    },
];
export const ERROR_CODE_MESSAGE: { [key: number]: string } = {
    400: 'Page not found',
    401: 'Unauthorized',
    403: 'Forbidden',
    422: 'Unprocessable Content',
    500: 'We are sorry, but something went wrong. Please try again later.',
};

export const ALLOW_IMAGE_TYPES = ['image/jpeg', 'image/pjpeg', 'image/png', 'image/webp'];
export const LOCAL_STORAGE_KEYS = {
    authToken: 'authUser',
    currentRole: 'currentRole',
    currentLanguage: 'currentLanguage',
};
export const SETTING_TABS = {
    ACCOUNT: 'account',
    SUPPORT: 'support',
    PAYMENT: 'payment',
    BUSINESS: 'business',
    CLIENT_NOTIFICATION: 'client-notification',
    LOYALTY: 'loyalty',
    CALENDAR: 'calendar',
    NOTIFICATION: 'notification',
    PRODUCT: 'product',
    TEAM: 'team',
};
export const ROLES = {
    OWNER: 'owner',
    INDIVIDUAL: 'individual',
    STAFF_RENT: 'staff_rent',
    STAFF_COMMISSION: 'staff_commission',
    STAFF_PARTNER: 'staff_partner',
    STAFF_NO_TRACKING: 'staff_no_tracking',
    STAFF_SELF: 'staff_self',
    RECEPTIONIST: 'receptionist',
};
export const PRODUCT_TABS = {
    PRODUCT: 'product',
    CATEGORY: 'category',
};
export const SERVICE_TABS = {
    SERVICE: 'services',
    CATEGORY: 'category',
    STAFF: 'staff',
    SHOP: 'shop',
};
export const CHARACTERS_LIMIT = {
    SHORT: 50,
    MEDIUM: 255,
    LONG: 1500,
};

export const TimeRanges = [
    { label: 'Last 7 days', value: 'last_7_days', key: 'last_7_days' },
    { label: 'Last 30 days', value: 'last_30_days', key: 'last_30_days' },
    { label: 'Last 90 days', value: 'last_90_days', key: 'last_90_days' },
    { label: 'Last Year', value: 'last_year', key: 'last_year' },
    { label: 'Weekly', value: 'weekly', key: 'weekly' },
    { label: 'Monthly', value: 'monthly', key: 'monthly' },
    { label: 'Quarterly', value: 'quarterly', key: 'quarterly' },
    { label: 'Yearly', value: 'yearly', key: 'yearly' },
    { label: 'All Time', value: 'all_time', key: 'all_time' },
    // { label: 'Daily', value: 'daily', key: 'daily' },
    { label: 'Custom', value: 'custom', key: 'custom' },
];
