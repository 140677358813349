import React, { memo, useEffect, useRef, useState } from 'react';
import { ISidebarSchema, ISidebarState } from './Sidebar.Interface';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import AppointmentDetails from './AppointmentDetails';
import { selectCalendarData, setData } from '../Calendar.slice';
import BlockTime from './BlockTime';
import moment from 'moment-timezone';
import { format } from 'date-fns';
import SSidebar from 'src/app/Skeleton/Calendar/SSidebar';
import { currentShop } from 'src/redux/services/common/Common.slice';
import Payment from './Payment/Payment';
import Checkout from './Payment/Checkout';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';

const Sidebar = () => {
    const dispatch = useAppDispatch();
    const shop: any = useAppSelector(currentShop);
    const slotInfo: any = useAppSelector(selectCalendarData);
    const sidebarRef = useRef<HTMLDivElement>(null);
    // const staffInfo = calendarData.staffInfo;
    const action = slotInfo.calendarStep;
    const initialSidebarState = {
        serviceArrayList: [],
        selectedDate: moment(slotInfo.selectedDate).toDate(),
        selectedTime: moment(slotInfo.selectedDate).toDate(),
        selectedOption: null,
        serviceOptionShow: true,
    };
    const [sidebarState, setSidebarState] = useState<ISidebarState>(initialSidebarState);
    const schema = Yup.object().shape({
        staff_id: Yup.number().required('Staff is required'),
        user_id: Yup.number().required('Client is required'),
        payment_method: Yup.string().required('Payment method is required'),
        services: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.number().required('Service is required'),
                    qty: Yup.number().required('Quantity is required'),
                }),
            )
            .min(1, 'At least one service must be selected'),
        booking_date: Yup.date().required('Booking date is required'),
        booking_time: Yup.string().required('Booking time is required'),
    });

    const methods = useForm<ISidebarSchema>({
        resolver: yupResolver(schema) as any,
        defaultValues: {
            services: null,
            payment_method: 'pay_online',
        },
    });

    const { setValue, control } = methods;
    // const onSubmit = async (data: any, update: boolean = false) => {
    // };

    const { remove } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'services',
    });

    const onHandleCloseSidebar = () => {
        remove();
        setSidebarState(initialSidebarState);
        // await dispatch(setClearBooking());
    };

    const getStaff = () => {
        axiosGet(API.STAFF.GET, { shop_id: shop.id, id: slotInfo.selectedSlotStaffId }).then((response) => {
            const services = response.data.data.services.map((item: any) => ({
                ...item,
                value: item.id,
                label: item.name,
            }));
            const updateState = {
                serviceList: services,
                selectedSlotStaff: response.data.data,
            };
            dispatch(setData({ ...updateState, isLoading: false }));
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            if (action.newAppointment) {
                dispatch(setData({ isLoading: true }));
                onHandleCloseSidebar();
            }
            getStaff();
        };
        if (action.completedPaymentAppointment) {
            dispatch(setData({ isLoading: true }));
            // handleSubmitForm();
        } else if (slotInfo.isSidebarOpen && slotInfo.selectedSlotStaffId && (action.newAppointment || action.bookedAppointment || action.updateAppointment || action.cancelAppointment)) {
            fetchData();
        } else if (action.blockTime) {
            dispatch(setData({ isLoading: false }));
        } else {
            if (!action.paymentAppointment && !action.rescheduleAppointment) {
                onHandleCloseSidebar();
            } else if (action.rescheduleAppointment) {
                getStaff();
                // handleReschedule();
            }
        }
    }, [action, slotInfo.selectedSlotStaffId]);

    useEffect(() => {
        onHandleCloseSidebar();
    }, [slotInfo.selectedLocation]);

    useEffect(() => {
        const isValidDate = moment(sidebarState.selectedDate).isValid();
        if (isValidDate) {
            setValue('booking_date', format(sidebarState.selectedDate, 'yyyy-MM-dd'));
        }
    }, [sidebarState.selectedDate]);

    useEffect(() => {
        const isValidDate = moment(sidebarState.selectedTime).isValid();
        if (isValidDate) {
            setValue('booking_time', format(sidebarState.selectedTime, 'HH:mm:ss'));
        }
    }, [sidebarState.selectedTime]);

    return (
        <div
            ref={sidebarRef}
            className={`sideModal calendar_sideModal bg-white z-[999] fixed bottom-0 top-[92px] xxl:top-[95px] right-[0px] border-t !transition-all !duration-[0.5s] shadow-lg border-l ${
                slotInfo.isSidebarOpen && 'w-[361px] xxl:w-[360px]'
            }`}
        >
            <div className={`h-full flex flex-col ${slotInfo.isSidebarOpen ? ' ' : 'hidden'}`}>
                {!slotInfo.isLoading && slotInfo.isSidebarOpen ? (
                    <>
                        {action.newAppointment || action.bookedAppointment || action.updateAppointment || action.cancelAppointment || action.noShowAppointment || action.rescheduleAppointment ? (
                            <AppointmentDetails />
                        ) : action.blockTime ? (
                            <BlockTime />
                        ) : action.checkoutAppointment ? (
                            <Checkout />
                        ) : action.paymentAppointment ? (
                            <Payment />
                        ) : null}
                    </>
                ) : (
                    <SSidebar />
                )}
            </div>
        </div>
    );
};

export default memo(Sidebar);
