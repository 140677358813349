import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { CoinsStacked03, CurrencyDollar } from '@untitled-ui/icons-react/build/cjs';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import InputWithLabel from 'src/components/InputWithLabel';
import InputWithToggle from 'src/components/InputWithToggle';
import Switch from 'src/components/Switch/Switch';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import * as Yup from 'yup';
import { allShopSettings, currentShop, me } from 'src/redux/services/common/Common.slice';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { ILoyalty } from './Loyalty.interface';
import { useRolePermission } from 'src/hooks/useRolePermission';

const LoyaltyReferral = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isAdmin, isRent } = useRolePermission();
    const shop = useAppSelector(currentShop);
    const shopSetting: any = useAppSelector(allShopSettings);
    const [isFormEnabled, setIsFormEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [toggle, setToggle] = useState({
        refferer_type: '$',
        referee_type: '$',
    });
    const [isSaveEnabled, setSaveEnabled] = useState(false);
    const [formValues, setFormValues] = useState<ILoyalty | null>(null);
    const defaultFormValues = {
        is_loyalty: false,
        receive_amount: 0,
        receive_point: 0,
        spend_point: 0,
        spend_amount: 0,
        is_referral: false,
        refferer: 0,
        referee: 0,
    };

    const conditionalSchema = (conditionFieldName: string | string[]) =>
        Yup.number()
            .typeError('This field not allowed null value')
            .when(conditionFieldName, {
                is: true,
                then: (schema: any) => schema.moreThan(0, 'Amount must be greater than zero').max(100, 'Amount must be less than 100').required('This field is required'),
                otherwise: (schema: any) => schema.notRequired(),
            });

    const schema = Yup.object({
        is_loyalty: Yup.boolean(),
        spend_amount: conditionalSchema('is_loyalty'),
        receive_point: conditionalSchema('is_loyalty'),
        spend_point: conditionalSchema('is_loyalty'),
        receive_amount: conditionalSchema('is_loyalty'),
        is_referral: Yup.boolean(),
        refferer: conditionalSchema('is_referral'),
        referee: conditionalSchema('is_referral'),
    }).required();

    const { handleSubmit, control, watch, setError, setValue, reset } = useForm<ILoyalty>({
        resolver: yupResolver(schema) as unknown as Resolver<ILoyalty>,
        defaultValues: defaultFormValues,
    });

    const watchAllFields = watch();
    const isLoyalty = watch('is_loyalty');
    const isReferral = watch('is_referral');

    useEffect(() => {
        const currentValues = {
            is_loyalty: watch('is_loyalty'),
            receive_amount: watch('receive_amount'),
            receive_point: watch('receive_point'),
            spend_point: watch('spend_point'),
            spend_amount: watch('spend_amount'),
            is_referral: watch('is_referral'),
            refferer: watch('refferer'),
            referee: watch('referee'),
        };
        setSaveEnabled(checkIfFormChanged(currentValues));
    }, [watchAllFields]);

    useEffect(() => {
        setIsFormEnabled(isAdmin || isRent);
    }, []);

    const checkIfFormChanged = (currentValues: ILoyalty) => {
        if (!formValues) return false;
        return JSON.stringify(currentValues) !== JSON.stringify(formValues);
    };

    const extractLoyaltyData = (loyaltyData: any) => {
        if (!loyaltyData || typeof loyaltyData !== 'object') {
            return {
                is_loyalty: false,
                receive_amount: 0,
                receive_point: 0,
                spend_point: 0,
                spend_amount: 0,
            };
        }

        const loyaltyValues = {
            is_loyalty: loyaltyData.status ?? false,
            receive_amount: loyaltyData?.value?.spend?.amount || 0,
            receive_point: loyaltyData?.value?.receive?.point || 0,
            spend_point: loyaltyData?.value?.spend?.point || 0,
            spend_amount: loyaltyData?.value?.receive?.amount || 0,
        };

        setValue('is_loyalty', loyaltyValues.is_loyalty);
        setValue('receive_amount', loyaltyValues.receive_amount);
        setValue('receive_point', loyaltyValues.receive_point);
        setValue('spend_point', loyaltyValues.spend_point);
        setValue('spend_amount', loyaltyValues.spend_amount);

        return loyaltyValues;
    };

    const extractReferData = (referData: any) => {
        if (!referData || typeof referData !== 'object') {
            return {
                is_referral: false,
                refferer: 0,
                referee: 0,
            };
        }

        const referValues = {
            is_referral: referData.status ?? false,
            refferer: referData?.value?.refferer || 0,
            referee: referData?.value?.referee || 0,
        };

        setValue('is_referral', referValues.is_referral);
        setValue('refferer', referValues.refferer);
        setValue('referee', referValues.referee);

        setToggle((prev) => ({
            ...prev,
            refferer_type: referData?.value?.refferer_type === 'value' ? '$' : '%',
            referee_type: referData?.value?.referee_type === 'value' ? '$' : '%',
        }));

        return referValues;
    };

    useEffect(() => {
        if (shopSetting) {
            resetForm();
        }
    }, [shopSetting]);

    const handleInputChange = (fieldName: string, value: string) => {
        setToggle((old) => ({ ...old, ...{ [fieldName]: value } }));
    };

    const handleSave = (data: any) => {
        if (!isFormEnabled) {
            return;
        }
        setIsLoading(true);
        const {
            is_loyalty: isLoyaltyPayload,
            spend_amount: spendAmount,
            receive_point: receivePoint,
            spend_point: spendPoint,
            receive_amount: receiveAmount,
            is_referral: isReferralPayload,
            refferer,
            referee,
        } = data;
        let payload = {};
        if (isLoyalty && isReferral) {
            payload = {
                is_loyalty: isLoyaltyPayload,
                spend_amount: spendAmount,
                receive_point: receivePoint,
                spend_point: spendPoint,
                receive_amount: receiveAmount,
                is_referral: isReferralPayload,
                refferer,
                referee,
                refferer_type: toggle.refferer_type === '%' ? 'percentage' : 'value',
                referee_type: toggle.referee_type === '%' ? 'percentage' : 'value',
            };
        } else if (isLoyalty) {
            payload = {
                is_loyalty: isLoyaltyPayload,
                is_referral: isReferralPayload,
                spend_amount: spendAmount,
                receive_point: receivePoint,
                spend_point: spendPoint,
                receive_amount: receiveAmount,
            };
        } else if (isReferral) {
            payload = {
                is_loyalty: isLoyaltyPayload,
                is_referral: isReferralPayload,
                refferer,
                referee,
                refferer_type: toggle.refferer_type === '%' ? 'percentage' : 'value',
                referee_type: toggle.referee_type === '%' ? 'percentage' : 'value',
            };
        } else {
            payload = {
                is_loyalty: isLoyaltyPayload,
                is_referral: isReferralPayload,
            };
        }

        axiosPost(API.SETTING.REFER_LOYALTY, payload, { shop_id: shop.id })
            .then(async (response) => {
                await dispatch(me());
            })
            .catch((error) => {
                if (error.data.status === errorCode.unprocessable) {
                    if (error.data.data) {
                        Object.keys(error.data.data).forEach((field) => {
                            setError(field as keyof ILoyalty, {
                                type: 'manual',
                                message: error.data.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    const resetForm = () => {
        if (shopSetting) {
            const loyalty = shopSetting.find((setting: any) => setting.type === 'loyalty');
            const refer = shopSetting.find((setting: any) => setting.type === 'refer');

            let initialFormValues: any = {};
            if (loyalty) {
                initialFormValues = { ...initialFormValues, ...extractLoyaltyData(loyalty) };
            }
            if (refer) {
                initialFormValues = { ...initialFormValues, ...extractReferData(refer) };
            }

            if (!loyalty && !refer) {
                reset(defaultFormValues);
                setFormValues(defaultFormValues);
            } else {
                setFormValues(initialFormValues);
                reset(initialFormValues);
            }
        } else {
            reset(defaultFormValues);
            setFormValues(defaultFormValues);
        }
    };
    return (
        <div className="w-full flex flex-col flex-1">
            <form onSubmit={handleSubmit(handleSave)} className="w-full px-2 py-1">
                <div className="w-[full] flex-1 flex flex-col  bg-white  ">
                    <div className="detail-page-title-block !py-4 border-borderSecondary !px-0">
                        <div className="flex flex-col ">
                            <h2 className=" flex flex-row gap-1.5 items-center">
                                <div className="title-text-header">{t('Loyalty & Referral Programs')}</div>
                            </h2>
                            <p className="subtitle-text">{t('Manage rewards for loyal clients and referral incentives.')}</p>
                        </div>
                        {isFormEnabled && (
                            <div className="flex flex-row gap-3 ">
                                <CustomButton secondary disabled={isLoading} onClick={resetForm} className="!h-[36px]">
                                    {t('Cancel')}
                                </CustomButton>
                                <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading || !isSaveEnabled} className="!h-[36px]">
                                    {t('Save Changes')}
                                </CustomButton>
                            </div>
                        )}
                    </div>
                    <div className="w-full flex flex-row  gap-[32px] border-b  border-borderSecondary py-4">
                        <div className=" w-[310px]">
                            <p className="title-text">{t('Enable Loyalty Points')}</p>
                            <p className="subtitle-text">{t('Activate points to reward client spending.')}</p>
                        </div>
                        <div className="flex flex-row w-[512px] gap-4">
                            <div className="flex items-start gap-2 text-sm font-medium text-gray-700">
                                <Controller
                                    name={`is_loyalty`}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div className="flex items-center gap-2">
                                            <Switch onChange={onChange} isChecked={value} isDisabled={!isFormEnabled} readOnly={!isFormEnabled} />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {isLoyalty && (
                        <>
                            <div className="w-full flex flex-row  gap-[32px] border-b  border-borderSecondary py-4">
                                <div className=" w-[310px]">
                                    <p className="title-text">{t('Earning Loyalty Points')}</p>
                                    <p className="subtitle-text">{t('Define how clients earn points on their purchases.')}</p>
                                </div>
                                <div className="flex flex-row max-2xl:w-[480px] w-[636px] gap-4">
                                    <div className="  w-1/2">
                                        <Controller
                                            name="spend_amount"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        name="spend_amount"
                                                        id="spend_amount"
                                                        label={t('Client Spend')}
                                                        labelIcon={<CurrencyDollar className="w-4 h-4" />}
                                                        labelIconClassName="text-xs !pr-1 flex items-center pt-1.5 pb-[7px]"
                                                        inputControlClassName="pl-[2px]"
                                                        type="number"
                                                        placeholder={t('Enter Client spend')}
                                                        value={value}
                                                        onChange={onChange}
                                                        error={!!error}
                                                        disabled={!isFormEnabled}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="  w-1/2">
                                        <Controller
                                            name="receive_point"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        name="receive_point"
                                                        id="receive_point"
                                                        label={t('Loyalty Points')}
                                                        placeholder={t('Enter loyalty points')}
                                                        value={value}
                                                        labelIcon={<CoinsStacked03 className="w-4 h-4" />}
                                                        labelIconClassName="text-xs !pr-1 flex items-center pt-1.5 pb-[7px]"
                                                        inputControlClassName="pl-[2px]"
                                                        type="number"
                                                        onChange={onChange}
                                                        error={!!error}
                                                        disabled={!isFormEnabled}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-row  gap-[32px] border-b  border-borderSecondary py-4">
                                <div className=" w-[310px]">
                                    <p className="title-text">{t('Redeeming Loyalty Points')}</p>
                                    <p className="subtitle-text">{t('Set up how clients can spend their loyalty points.')}</p>
                                </div>
                                <div className="flex flex-row max-2xl:w-[480px] w-[636px] gap-4">
                                    <div className="  w-1/2">
                                        <Controller
                                            name="spend_point"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        name="spend_point"
                                                        id="spend_point"
                                                        type="number"
                                                        label={t('Points Required')}
                                                        placeholder={t('Enter Points')}
                                                        labelIcon={<CoinsStacked03 className="w-4 h-4" />}
                                                        labelIconClassName="text-xs !pr-1 flex items-center pt-1.5 pb-[7px]"
                                                        inputControlClassName="pl-[2px]"
                                                        value={value}
                                                        onChange={onChange}
                                                        error={!!error}
                                                        disabled={!isFormEnabled}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="  w-1/2">
                                        <Controller
                                            name="receive_amount"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        name="receive_amount"
                                                        id="receive_amount"
                                                        type="number"
                                                        labelIcon={<CurrencyDollar className="w-4 h-4" />}
                                                        labelIconClassName="text-xs !pr-1 flex items-center pt-1.5 pb-[7px]"
                                                        inputControlClassName="pl-[2px]"
                                                        label={t('Value of Points')}
                                                        placeholder={t('Enter Value of Points')}
                                                        value={value}
                                                        onChange={onChange}
                                                        error={!!error}
                                                        disabled={!isFormEnabled}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="w-full flex flex-row  gap-[32px] border-b  border-borderSecondary py-4">
                        <div className=" w-[310px]">
                            <p className="title-text">{t('Enable Referrals')}</p>
                            <p className="subtitle-text">{t('Allow clients to refer friends for discounts.')}</p>
                        </div>
                        <div className="flex flex-row max-2xl:w-[480px] w-[512px] gap-4">
                            <div className="flex items-start gap-2 text-sm font-medium text-gray-700">
                                <Controller
                                    name={`is_referral`}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div className="flex items-center gap-2">
                                            <Switch onChange={onChange} isChecked={value} isDisabled={!isFormEnabled} readOnly={!isFormEnabled} />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {isReferral && (
                        <>
                            <div className="w-full flex flex-row  gap-[32px] border-b  border-borderSecondary py-4">
                                <div className=" w-[310px]">
                                    <p className="title-text">{t('Referrer Discount')}</p>
                                    <p className="subtitle-text">{t('Set the discount referrers receive for successful referrals.')}</p>
                                </div>
                                <div className="flex flex-row max-2xl:w-[240px] w-[310px] gap-4">
                                    <Controller
                                        name="refferer"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithToggle
                                                    label={t('Referrer Discount')}
                                                    type="number"
                                                    name="refferer"
                                                    placeholder={t('Referrer Discount')}
                                                    className="!pl-[0px]"
                                                    toggleClassName="bg-[#3570F8] text-white drop-shadow-cardShadow"
                                                    value={value}
                                                    toggleValue={toggle.refferer_type}
                                                    setselected={(e: any) => {
                                                        handleInputChange('refferer_type', e);
                                                    }}
                                                    onChange={onChange}
                                                    disabled={!isFormEnabled}
                                                    error={!!error}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-full flex flex-row  gap-[32px] border-b  border-borderSecondary py-4">
                                <div className=" w-[310px]">
                                    <p className="title-text">{t('Referral Discount for New Clients')}</p>
                                    <p className="subtitle-text">{t('Define the first-visit discount for new clients referred.')}</p>
                                </div>
                                <div className="flex flex-row max-2xl:w-[240px] w-[310px] gap-4">
                                    <Controller
                                        name="referee"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithToggle
                                                    label={t('New Client Discount')}
                                                    type="number"
                                                    name="referee"
                                                    className="!pl-[0px]"
                                                    placeholder={t('Enter points')}
                                                    toggleClassName="bg-[#3570F8] text-white drop-shadow-cardShadow"
                                                    value={value}
                                                    toggleValue={toggle.referee_type}
                                                    setselected={(e: any) => {
                                                        handleInputChange('referee_type', e);
                                                    }}
                                                    onChange={onChange}
                                                    disabled={!isFormEnabled}
                                                    error={!!error}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {isFormEnabled && (
                    <div className="flex flex-row gap-3 w-full justify-end pt-5 mb-36 bottom-0">
                        <CustomButton secondary disabled={isLoading} onClick={resetForm} className="!h-[36px]">
                            {t('Cancel')}
                        </CustomButton>
                        <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading || !isSaveEnabled} className="!h-[36px]">
                            {t('Save Changes')}
                        </CustomButton>
                    </div>
                )}
            </form>
        </div>
    );
};

export default LoyaltyReferral;
