import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { overviewPerPageOptions } from './Client.interface';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import { UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { capitalizeFirstLetterAndUnderscore, dateTimeFormate, getShortName } from 'src/utils/global-functions';
import NoDataMessage from 'src/components/NoDataMessage';
import { Box, ArrowUp, ArrowDown } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import BookingDetails from './BookingDetails';
import { perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import { useParams } from 'react-router-dom';
import { PaginatorTemplate } from 'src/utils/global-component';
import Badge from 'src/components/Badge';

export const bookingAction = {
    view: false,
    data: null as any,
};

const ClientBooking = ({ client, type }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [appointmentList, setAppointmentList] = useState([]);
    const [totalAppointment, setTotalAppointment] = useState<number>(0);
    const [action, setAction] = useState(bookingAction);
    const [isImageError, setIsImageError] = useState<{ [key: number]: boolean }>({});
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: type === 'overview' ? overviewPerPageOptions[0].value : perPageOptions[0].value,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
        },
    });

    useEffect(() => {
        getAppointmentList();
    }, [lazyState, shop]);

    const getAppointmentList = async () => {
        setIsLoading(true);
        await axiosGet(API.CLIENT.APPOINTMENT, { shop_id: shop.id, id: client?.id }, lazyState)
            .then((response) => {
                setAppointmentList(response.data.data.data);
                setTotalAppointment(response.data.data.totalRecords);
            })
            .finally(() => setIsLoading(false));
    };

    const handleBookingDownload = async () => {
        setIsDownloading(true);

        await axiosGet(API.CLIENT.BOOKING_HISTORY, { shop_id: shop.id, id: id })
            .then(async (response) => {
                const exportResponse = response.data.data;
                const byteCharacters = atob(exportResponse);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                    type: 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet',
                });

                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `booking_details_${client.full_name}_${moment().format('YYYY-MM-DD')}.xlsx`;

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            .finally(() => setIsDownloading(false));
    };

    const handleImageError = (ids: number) => () => {
        setIsImageError((old: any) => ({ ...old, [ids]: true }));
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const GetStatus = (row: any) => (
        <Badge icon status={row.status}>
            {capitalizeFirstLetterAndUnderscore(row.status)}
        </Badge>
    );

    const GetStylist = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.staff.profile_image_url && !isImageError[row.id] ? (
                    <img src={row.staff.profile_image_url} alt="" className="w-full h-full object-cover" onError={handleImageError(row.id)} />
                ) : (
                    getShortName(row.staff.full_name)
                )}
            </figure>
            <div>
                <p className="text-xs font-medium text-gray-900 leading-[18px] w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {row.staff.full_name}
                </p>
                <p className="text-xs font-normal text-gray-600 leading-[18px] w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {moment(row.created_at).fromNow()}
                </p>
            </div>
        </div>
    );

    // const GetDateTime = (row: any) => `${moment(row.booking_date, 'YYYY-MM-DD').format('D MMM')} ${moment(row.booking_start_time, 'HH:mm:ss').format('h:mm A')}`;

    const GetDateTime = (row: any) => {
        const dateTime = `${row.booking_date} ${row.booking_start_time}`;
        return dateTimeFormate(dateTime);
    };
    const GetDuration = (row: any) => `${moment.duration(row.total_duration).asMinutes()} min`;

    const GetService = (row: any) => {
        const servicesArray = row.booking_services;

        return (
            <p className="text-xs font-medium w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate capitalize">{`${
                servicesArray[0].service.name
            } ${servicesArray.length > 1 ? `+${servicesArray.length - 1}` : ''}`}</p>
        );
    };

    const GetTotal = (row: any) => `$${row.total}`;

    const handleRowClick = (row: any) => {
        setAction((old) => ({ ...old, view: true, data: row.data }));
    };

    const handleModalClose = useCallback(
        (_type: boolean = false) =>
            async () => {
                setAction(bookingAction);
            },
        [action],
    );

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                booking_date: 'loading',
                total_duration: 'loading',
                shop_services: { name: 'loading' },
                shop_admins: { first_name: 'loading' },
                total: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    return (
        <div className="w-full border rounded-xl mt-6  flex-col mb-5 flex shadow staff_service_child_table datatable-custom-service">
            <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                <div className={`${type === 'appointments' ? '' : ''}`}>
                    <h2 className="view-table-title">{type === 'overview' ? t('Recent appointment activity') : t('Appointment History')}</h2>
                    <p className="view-table-subtitle">{type === 'overview' ? `View ${client.first_name}'s latest appointment activity.` : `View ${client.first_name}'s all appointment activity.`}</p>
                </div>
                <div className="flex gap-4 ">
                    <div className="location-dropdown">
                        <CustomButton
                            isLoading={isDownloading}
                            disabled={isDownloading || !totalAppointment}
                            secondary
                            outlineSecondary
                            icon={<UploadCloud02 className="w-[18px] h-[18px] mt-[2px]" />}
                            onClick={handleBookingDownload}
                            className="flex  items-center"
                        >
                            {t('Export')}
                        </CustomButton>
                    </div>
                    {/* <div className={`${type === 'appointments' && ''}`}>
                        <CustomButton primary rightIcon={<Download01 className="w-4" />} className="flex w-full min-w-[124px] items-center">
                            {t('Download All')}
                        </CustomButton>
                    </div> */}

                    <SelectBox
                        name="page"
                        options={type === 'overview' ? overviewPerPageOptions : perPageOptions}
                        isClearable={false}
                        isSearchable={false}
                        onChangeFunc={onPageHandle}
                        value={type === 'overview' ? overviewPerPageOptions.find((option) => option.value === lazyState.rows) : perPageOptions.find((option) => option.value === lazyState.rows)}
                        classComp={`w-[70px] max-w-[70px] ${type === 'appointments' && ''}`}
                    />
                </div>
            </div>
            <DataTable
                lazy
                paginatorTemplate={type === 'appointments' ? PaginatorTemplate() : undefined}
                value={isLoading ? skeletons : appointmentList}
                totalRecords={totalAppointment}
                paginator={!isLoading && type === 'appointments' ? totalAppointment > lazyState.rows : undefined}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onRowClick={handleRowClick}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                dataKey="id"
                selectionMode="single"
                className="rounded-b-xl overflow-hidden"
                paginatorClassName="table-pagination"
                rowClassName={() => 'cursor-pointer'}
                emptyMessage={
                    <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                        <NoDataMessage
                            title={`${lazyState.filters.global.value ? 'No bookings found.' : 'No bookings added.'}`}
                            description={`${lazyState.filters.global.value ? 'Try adjusting your filters or add new bookings.' : 'Add a bookings, to start using the bookings collection.'}`}
                            iconComponent={<Box className="text-gray-700" />}
                        />
                    </div>
                }
            >
                <Column
                    field="shop_admins.first_name"
                    header={renderHeader(t('Team members'), 'shop_admins.first_name')}
                    body={isLoading ? <Skeleton /> : GetStylist}
                    className="font-normal text-mainTextColor truncate"
                    sortable
                    style={{ width: '155px', minWidth: '155px', maxWidth: '155px' }}
                ></Column>
                <Column
                    field="status"
                    header={renderHeader(t('Status'), 'status')}
                    body={isLoading ? <Skeleton /> : GetStatus}
                    // style={{ width: '10%' }}
                    style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                    className="text-xs text-gray-600 font-normal  truncate"
                    sortable
                ></Column>
                <Column
                    field="booking_date"
                    header={renderHeader(t('Date and Time'), 'booking_date')}
                    body={isLoading ? <Skeleton /> : GetDateTime}
                    className="text-xs text-gray-600 font-normal truncate"
                    // style={{ width: '15%' }}
                    style={{ width: '135px', minWidth: '135px', maxWidth: '135px' }}
                    sortable
                ></Column>
                <Column
                    field="total_duration"
                    header={renderHeader(t('Duration'), 'total_duration')}
                    body={isLoading ? <Skeleton /> : GetDuration}
                    className="text-xs text-gray-600 font-normal truncate"
                    // style={{ width: '12.50%' }}
                    style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                    sortable
                ></Column>
                <Column
                    field="shop_services.name"
                    header={renderHeader(t('Service'), 'shop_services.name')}
                    body={isLoading ? <Skeleton /> : GetService}
                    className="text-xs text-gray-600 font-medium truncate"
                    // style={{ width: '20%' }}
                    style={{ width: '110px', minWidth: '110px', maxWidth: '110px' }}
                    sortable
                ></Column>
                <Column
                    field="total"
                    header={renderHeader(t('Total'), 'total')}
                    body={isLoading ? <Skeleton /> : GetTotal}
                    // style={{ width: '12.50%' }}
                    style={{ width: '70px', minWidth: '70px', maxWidth: '70px' }}
                    className="text-xs font-medium text-tableText truncate"
                    sortable
                ></Column>
            </DataTable>
            {action.view && <BookingDetails handleClose={handleModalClose()} bookingData={action.data} />}
        </div>
    );
};

export default ClientBooking;
