import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { IFormData, ISelectBox } from './Terminal.interface';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import { toast } from 'react-toastify';
import { errorCode } from 'src/constants/errorCode';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { MarkerPin04 } from '@untitled-ui/icons-react/build/cjs';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import PopupModal from 'src/components/PopupModal';
import { getCountry } from 'src/redux/services/country/Country.slice';
import { getState } from 'src/redux/services/state/State.slice';
import { getCity } from 'src/redux/services/city/City.slice';

const LocationForm = ({ handleClose, listLocations, data: existingData }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const [countryOptions, setCountryOptions] = useState<ISelectBox[]>([]);
    const [stateOptions, setStateOptions] = useState<ISelectBox[]>([]);
    const [cityOptions, setCityOptions] = useState<ISelectBox[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const schema = Yup.object({
        display_name: Yup.string().required(t('This field is required')),
        line1: Yup.string().required(t('This field is required')),
        country: Yup.object()
            .shape({
                value: Yup.string().required('This field is required'),
                label: Yup.string().required('This field is required'),
                id: Yup.string().required('This field is required'),
            })
            .nullable()
            .required('This field is required'),
        state: Yup.object()
            .shape({
                value: Yup.string().required('This field is required'),
                label: Yup.string().required('This field is required'),
                id: Yup.string().required('This field is required'),
            })
            .nullable()
            .required('This field is required'),
        city: Yup.object()
            .shape({
                value: Yup.string().required('This field is required'),
                label: Yup.string().required('This field is required'),
                id: Yup.string().required('This field is required'),
            })
            .nullable()
            .required('This field is required'),
        postal_code: Yup.string().required(t('This field is required')),
    }).required();

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            display_name: existingData ? existingData.display_name : '',
            line1: existingData ? existingData.address.line1 : '',
            country: undefined,
            state: undefined,
            city: undefined,
            postal_code: existingData ? existingData.address.postal_code : '',
        },
    });

    const {
        handleSubmit,
        setError,
        setValue,
        watch,
        formState: { errors },
        control,
    } = methods;

    const country = watch('country');
    const state = watch('state');

    useFormErrorFocus<IFormData>({ errors });

    useEffect(() => {
        countryList();
    }, []);

    useEffect(() => {
        stateList();
    }, [country]);

    useEffect(() => {
        cityList();
    }, [state]);

    const countryList = async () => {
        const result = await dispatch(getCountry());
        if (result.type === getCountry.fulfilled.toString()) {
            const data = result.payload.data;
            const options: ISelectBox[] | [] = getSelectBoxOptions(data, 'shortname');
            setCountryOptions(options);

            if (existingData && existingData.address.country) {
                const option: any = options.find((i: any) => i.shortname === existingData.address.country);
                setValue('country', option);
            }
        }
    };

    const stateList = async () => {
        if (country) {
            const result = await dispatch(getState({ country_id: country.id }));
            if (result.type === getState.fulfilled.toString()) {
                const data = result.payload.data;
                const options: ISelectBox[] | [] = getSelectBoxOptions(data);
                setStateOptions(options);

                if (existingData && existingData.address.state) {
                    const option: any = options.find((i: any) => i.name === existingData.address.state);
                    setValue('state', option);
                }
            }
        } else {
            setValue('state', undefined);
            setValue('city', undefined);
            setStateOptions([]);
            setCityOptions([]);
        }
    };

    const cityList = async () => {
        if (state) {
            const result = await dispatch(getCity({ state_id: state.id }));
            if (result.type === getCity.fulfilled.toString()) {
                const data = result.payload.data;
                const options: ISelectBox[] | [] = getSelectBoxOptions(data);
                setCityOptions(options);

                if (existingData && existingData.address.city) {
                    const option: any = options.find((i: any) => i.name === existingData.address.city);
                    setValue('city', option);
                }
            }
        } else {
            setValue('city', undefined);
            setCityOptions([]);
        }
    };

    const handleSave = (data: IFormData) => {
        setIsLoading(true);
        const payload = {
            ...data,
            country: data.country?.value,
            state: data.state?.value,
            city: data.city?.value,
            shop_location_id: existingData.id,
        };
        if (existingData) {
            /* axiosPatch(API.STRIPE.LOCATION.UPDATE, payload, { shop_id: shop.id, id: existingData.id })
                .then(() => {
                    listLocations();
                    handleClose();
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        } else { */
            axiosPost(API.STRIPE.LOCATION.CREATE, payload, { shop_id: shop.id })
                .then(() => {
                    listLocations();
                    handleClose();
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <MarkerPin04 className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900">{t('Add Location')}</p>
                <span className="text-xs font-normal text-gray-500">{t('Please add location for terminal.')}</span>
            </div>
        </div>
    );

    return (
        <PopupModal
            dismissible
            size="w-[560px]"
            isLoading={isLoading}
            isDisabled={isLoading}
            primaryButton={t('Save Changes')}
            secondaryButton={t('Cancel')}
            acceptAction={handleSubmit(handleSave)}
            declineAction={handleClose}
            onClose={handleClose}
            title={<Title />}
        >
            <div className="inner-page-wrape !px-0">
                <div className="flex-1">
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(handleSave)}>
                            <div className="flex flex-col gap-4">
                                <Controller
                                    name="display_name"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div>
                                            <InputWithLabel
                                                required
                                                id="display_name"
                                                name="display_name"
                                                label={t('Location Name')}
                                                placeholder={t('Enter location name')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                            />
                                            {error && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="line1"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div>
                                            <InputWithLabel
                                                required
                                                id="line1"
                                                name="line1"
                                                label={t('Address Line 1')}
                                                placeholder={t('Enter address')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                            />
                                            {error && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                                <div className="grid grid-cols-2 gap-4">
                                    <Controller
                                        name="country"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div>
                                                <SelectBox
                                                    required
                                                    id="country"
                                                    name="country"
                                                    label={t('Country')}
                                                    placeholder={t('Select country')}
                                                    options={countryOptions}
                                                    value={value}
                                                    onChangeFunc={onChange}
                                                    error={!!error}
                                                />
                                                {error && error.type !== 'optionality' && error.type !== 'nullable' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="state"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div>
                                                <SelectBox
                                                    required
                                                    id="state"
                                                    name="state"
                                                    label={t('State')}
                                                    placeholder={t('Select state')}
                                                    options={stateOptions}
                                                    value={value}
                                                    onChangeFunc={onChange}
                                                    error={!!error}
                                                />
                                                {error && error.type !== 'optionality' && error.type !== 'nullable' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="city"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div>
                                                <SelectBox
                                                    required
                                                    id="city"
                                                    name="city"
                                                    label={t('City')}
                                                    placeholder={t('Select city')}
                                                    options={cityOptions}
                                                    value={value}
                                                    onChangeFunc={onChange}
                                                    error={!!error}
                                                />
                                                {error && error.type !== 'optionality' && error.type !== 'nullable' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="postal_code"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div>
                                                <InputWithLabel
                                                    required
                                                    id="postal_code"
                                                    name="postal_code"
                                                    label={t('Postal Code')}
                                                    placeholder={t('Enter postal code')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                />
                                                {error && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </PopupModal>
    );
};

export default LocationForm;
