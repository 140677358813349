'use client';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, shopMe, userMe } from 'src/redux/services/common/Common.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICountStep, IStep } from 'src/components/Stepper/Stepper.interface';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'src/constants/routes';
import Invitation from './Invitation';
import WorkingHoursStaff from './WorkingHoursStaff';
import Profile from './Profile';
import Sidebar from '../Layout/SideBarLayout/Sidebar';
import { Mail05, UserEdit, Rocket02, ClockCheck } from '@untitled-ui/icons-react/build/cjs';
import Welcome from './Welcome';

const SignupStaff = () => {
    const user = useAppSelector(userMe);
    const shop = useAppSelector(currentShop);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state, pathname } = useLocation();
    const [inviteStaffShop, setInviteStaffShop] = useState<any>(null);
    const dispatch = useAppDispatch();

    useLayoutEffect(() => {
        const initFetch = async () => {
            if (state !== null) {
                const shopDetail = user.shops.find((shops: any) => shops.id === state?.shop_id);
                setInviteStaffShop(shopDetail);
            }

            await dispatch(shopMe({ shop_id: state !== null ? state?.shop_id : shop.id }));

            if (pathname === ROUTES.INVITE) {
                if (state === null) {
                    navigate(ROUTES.DASHBOARD);
                }
            }

            const shopStaff = state ? user?.shop_staff.find((shop_staff: any) => shop_staff.shop_id === state?.shop_id) : user.shop_staff[0];
            if (shopStaff) {
                const signupStep = shopStaff?.account_step;
                if (shopStaff.is_declined_invitation) {
                    setStep(1);
                } else if (!signupStep?.invitation) {
                    setStep(1);
                } else if (!signupStep?.profile) {
                    setStep(2);
                } else if (!signupStep?.working_hours) {
                    setStep(3);
                } else {
                    if (pathname === ROUTES.INVITE) {
                        if (state === null) {
                            navigate(ROUTES.DASHBOARD);
                        } else {
                            setStep(1);
                        }
                    } else {
                        navigate(ROUTES.DASHBOARD);
                    }
                }
            }
        };
        initFetch();
    }, []);

    const [step, setStep] = useState<ICountStep>(0);

    const handleStep = (direction: 'next' | 'prev') => () => setStep((currentStep) => (direction === 'next' ? currentStep + 1 : currentStep - 1) as ICountStep);

    const STEPS: IStep[] = useMemo(
        () => [
            {
                name: t('Account Invitation'),
                subTitle: t('Review and accept your invitation'),
                image: Mail05,
                step: 1,
                enabled: true,
            },
            {
                name: t('Complete your profile'),
                subTitle: t('Tell us a bit about yourself'),
                image: UserEdit,
                step: 2,
                enabled: true,
            },
            {
                name: t('Set your working hours'),
                subTitle: t('Tell us a bit about your business'),
                image: ClockCheck,
                step: 3,
                enabled: true,
            },
            {
                name: t('Welcome to', { shop_name: process.env.REACT_APP_FULL_NAME }),
                subTitle: t('Finish your account setup'),
                image: Rocket02,
                step: 4,
                enabled: true,
            },
        ],
        [],
    );

    const renderStepComponent = () => {
        const stepToRender = STEPS[step - 1];
        switch (stepToRender.step) {
            case 1:
                return <Invitation handleStep={handleStep} inviteStaffShop={inviteStaffShop} />;
            case 2:
                return <Profile handleStep={handleStep} inviteStaffShop={inviteStaffShop} />;
            case 3:
                return <WorkingHoursStaff handleStep={handleStep} inviteStaffShop={inviteStaffShop} />;
            case 4:
                return <Welcome />;
            default:
                return null;
        }
    };

    return (
        <>
            {step !== 0 && (
                <>
                    <Sidebar activeStep={step} steps={STEPS} handleStep={handleStep} />
                    <div className="flex-grow min-h-screen">
                        {renderStepComponent()}
                        {/* <main className="p-6 h-[calc(100vh-84px)] overflow-y-scroll flex items-center flex-col">{renderStepComponent()}</main>
                        <div className="bottom-0 right-0 start-[480px] fixed">
                            <div className="w-full">
                                <StepProgressBar steps={STEPS.length} currentStep={step} className="w-full mx-auto justify-center" />
                            </div>
                            <Footer />
                        </div> */}
                    </div>
                </>
            )}
        </>
    );
};

export default SignupStaff;
