import React, { useState } from 'react';
import PopupModal from 'src/components/PopupModal';
import { NotificationText } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputWithLabel from 'src/components/InputWithLabel';
import { axiosPatch, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { noteInitAction } from './Client.interface';
import { CHARACTERS_LIMIT } from 'src/constants/common';

const NoteForm = ({ handleClose, setAction, client, getNoteList, noteData }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <NotificationText className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">Add a Client Notes</p>
                <span className="text-xs font-normal leading-[18px]">{t('Enter your client note')}</span>
            </div>
        </div>
    );

    const schema = Yup.object({
        description: Yup.string().required(`${t('This field is required')}`),
    }).required();

    const methods = useForm<any>({
        resolver: yupResolver(schema) as Resolver<any>,
        defaultValues: {
            description: noteData && noteData.id ? noteData.description : '',
        },
    });
    const { handleSubmit, control } = methods;

    const handleSave = async (data: any) => {
        setIsLoading(true);
        if (noteData) {
            await axiosPatch(API.CLIENT.NOTEUPDATE, { description: data.description }, { shop_id: shop.id, client_id: client.id, id: noteData.id })
                .then(async () => {
                    getNoteList();
                })
                .finally(() => {
                    setIsLoading(false);
                    setAction(noteInitAction);
                });
        } else {
            await axiosPost(API.CLIENT.NOTE, { description: data.description }, { shop_id: shop.id, client_id: client.id })
                .then(async () => {
                    getNoteList();
                })
                .finally(() => {
                    setIsLoading(false);
                    setAction(noteInitAction);
                });
        }
    };

    const handleNext = handleSubmit(handleSave);

    return (
        <PopupModal
            dismissible
            onClose={handleClose}
            size="w-[500px]"
            className="px-5 py-5"
            title={<Title />}
            primaryButton={t('Save Changes')}
            secondaryButton={t('Cancel')}
            acceptAction={handleNext}
            declineAction={handleClose}
            isLoading={isLoading}
        >
            <FormProvider {...methods}>
                <div className="flex gap-4">
                    <Controller
                        name="description"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                            <div className="w-full profile_textarea ">
                                <InputWithLabel
                                    placeholder={t('Enter a description...')}
                                    onChange={onChange}
                                    value={value}
                                    required
                                    error={!!error}
                                    name="description"
                                    id="description"
                                    textArea
                                    textAearClassName="h-[120px] rounded-lg resize-none"
                                    maxLenght={CHARACTERS_LIMIT.LONG}
                                    labelClassName={'text-sm font-medium text-gray-700 mb-0'}
                                    inputWrapClassName={'text-sm font-normal text-gray-600'}
                                    inputControlClassName={'px-[14px] py-3'}
                                />
                            </div>
                        )}
                    />
                </div>
            </FormProvider>
        </PopupModal>
    );
};

export default NoteForm;
