import React, { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import RadioSwitch from 'src/components/RadioSwitch';

const TipsForm = ({ errors }: any) => {
    const { control, watch, trigger } = useFormContext();

    const { fields } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'tips',
    });
    const tipsType = watch('tips_type');
    const tips = watch('tips');
    const customTip = watch('custom_tip');

    useEffect(() => {
        trigger(['tips', 'custom_tip']);
    }, [tips, customTip]);

    const handleInputChange = (value: string) => {
        const regex = /^\d*\.?\d*$/;
        if (regex.test(value)) {
            return value;
        }
        return '';
    };
    const radioOptions = [
        { id: 'tip_percentage', value: 'percentage', label: '%', className: 'radio-first' },
        { id: 'tip_dollar', value: 'value', label: '$', className: 'radio-second' },
    ];
    return (
        <div className="flex flex-col gap-3">
            <div className="flex justify-end items-center">
                <Controller name="tips_type" control={control} render={({ field: { onChange, value } }) => <RadioSwitch options={radioOptions} name="tips_type" value={value} onChange={onChange} />} />
            </div>
            <div className="flex flex-wrap gap-4">
                {fields.map((item: any, index) => (
                    <div className="w-full max-w-[88px] h-9" key={item.uuid}>
                        <div className="custom-tip-checkbox-block">
                            <Controller
                                key={`tips.[${index}]`}
                                name={`tips.[${index}]`}
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <>
                                        <input
                                            name="custom_tip"
                                            type="text"
                                            className={`w-full custom-tip-label custom-tip-input-value `}
                                            value={value}
                                            onChange={(e) => onChange(handleInputChange(e.target.value))}
                                        />
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2">{value !== '' ? (tipsType === 'percentage' ? '%' : '$') : ''}</span>
                                    </>
                                )}
                            />
                        </div>
                    </div>
                ))}
                <div className="w-full max-w-[88px] h-9">
                    <div className="custom-tip-checkbox-block">
                        <Controller
                            name="custom_tip"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <input
                                        name="custom_tip"
                                        type="text"
                                        className={`w-full custom-tip-label custom-tip-input-value `}
                                        value={value}
                                        onChange={(e) => onChange(handleInputChange(e.target.value))}
                                    />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2">{value !== '' ? (tipsType === 'percentage' ? '%' : '$') : ''}</span>
                                </>
                            )}
                        />
                    </div>
                </div>
            </div>
            {errors.tips ? errors.tips.root ? <p className="text-error !mt-0">{errors.tips.root.message}</p> : <p className="text-error !mt-0">{errors.tips.message}</p> : null}
        </div>
    );
};

export default TipsForm;
