import React from 'react';

const SChart = () => (
    <div className={`custom-loading graph-loading h-full w-full flex justify-center items-center object-cover`}>
        <div className="flex flex-col w-full gap-[30px] relative px-6">
            <div className="custom-loading h-[1px]"></div>
            <div className="custom-loading h-[1px]"></div>
            <div className="custom-loading h-[1px]"></div>
            <div className="custom-loading h-[1px]"></div>
            <div className="custom-loading h-[1px]"></div>
            <div className="custom-loading h-[1px]"></div>
            <div className="custom-loading h-[1px]"></div>
            <div className="custom-loading h-[1px]"></div>
            <div className="custom-loading h-[1px]"></div>
            <div className="custom-loading h-[1px]"></div>
        </div>
    </div>
);

export default SChart;
