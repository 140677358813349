import React, { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import { currentShop, me } from 'src/redux/services/common/Common.slice';
import { Label, Radio } from 'flowbite-react';
import CustomButton from 'src/components/CustomButton';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { IoList } from 'react-icons/io5';
import { Check, XClose } from '@untitled-ui/icons-react/build/cjs';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import Footer from 'src/app/Layout/Footer';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { IServiceProviderFormData, IProps } from './Signup.interface';
import { useRolePermission } from 'src/hooks/useRolePermission';
import { ROLES } from 'src/constants/common';

const ServiceProvider: FC<IProps> = ({ handleStep }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isRoleExist, allShopRoles } = useRolePermission();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);

    const schema = Yup.object({
        status: Yup.string<IServiceProviderFormData['status']>().required(t('This field is required')),
    }).required();

    const {
        handleSubmit,
        setValue,
        formState: { errors },
        setError,
        control,
    } = useForm<IServiceProviderFormData>({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            status: isRoleExist(ROLES.STAFF_SELF) ? 'yes' : 'no',
        },
    });

    useFormErrorFocus<IServiceProviderFormData>({ errors });

    useEffect(() => {
        setValue('status', isRoleExist(ROLES.STAFF_SELF) ? 'yes' : 'no');
    }, [allShopRoles]);

    const submitForm = (data: IServiceProviderFormData) => {
        setIsLoading(true);
        axiosPatch(API.USER.SERVICE_PROVIDER, data, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
                handleStep('next')();
            })
            .catch((error: any) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IServiceProviderFormData, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            <form onSubmit={handleSubmit(submitForm)}>
                <main className="px-6 h-[calc(100vh-84px)] overflow-y-scroll flex-1 flex">
                    <div className="w-full flex justify-center relative flex-1 items-center flex-col text-center bg-banner bg-top bg-no-repeat">
                        {/* <img src={BackgroundDesign} alt="" className="absolute top-0 z-[-1]" /> */}
                        <div className="w-[700px]">
                            <div className="flex flex-col rounded-md items-center mb-4">
                                <div className="p-[14px] bg-white border border-btnborder rounded-xl">
                                    <IoList className="w-7 h-7" />
                                </div>
                            </div>
                            <div className="text-center">
                                <h3 className="fwpo_heading_title">{t('Do you also work as a service provider?')}</h3>
                                <p className="font-normal leading-[22.4px] mt-[2px] text-base text-secondaryTxtColor">
                                    {t("Select 'Yes' if you should also be listed as a service provider available for client bookings.")}
                                </p>
                            </div>
                            <div className="mt-8">
                                <div className="w-full">
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <>
                                                <div className={`flex flex-col mb-4 ${value === 'yes' ? 'account_active' : ''}`}>
                                                    <Label
                                                        htmlFor="yes"
                                                        className={`flex justify-between items-center px-4 py-3 border  rounded-lg bg-white cursor-pointer hover:border-[#5279F0]  hover:bg-[#F9FAFC] ${
                                                            value === 'yes' ? 'border-[#5279F0] bg-[#F9FAFC]' : 'border-gray-300'
                                                        }`}
                                                    >
                                                        <div className="flex items-center">
                                                            <div className="me-3 w-11 h-11 border border-gray-200 rounded-lg flex justify-center items-center">
                                                                <Check />
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <h4 className="text-base font-medium text-black mb-1 text-justify">{t('Yes, I also provide services')}</h4>
                                                                <span className="text-sm text-gray-500">{t('I own a brand and also I am service provider')}</span>
                                                            </div>
                                                        </div>
                                                        <Radio
                                                            className="orm-radio h-5 w-5 text-blue-600 cursor-pointer"
                                                            id="yes"
                                                            name="status"
                                                            value="yes"
                                                            onChange={onChange}
                                                            checked={value === 'yes'}
                                                        />
                                                    </Label>
                                                </div>
                                                <div className={`flex flex-col ${value === 'no' ? 'account_active' : ''}`}>
                                                    <Label
                                                        htmlFor="no"
                                                        className={`flex justify-between items-center px-4 py-3 border rounded-lg bg-white cursor-pointer hover:border-[#5279F0]  hover:bg-[#F9FAFC] ${
                                                            value === 'no' ? 'border-[#5279F0] bg-[#F9FAFC]' : 'border-gray-300'
                                                        }`}
                                                    >
                                                        <div className="flex items-center">
                                                            <div className="me-3 w-11 h-11 border border-gray-200 rounded-lg flex justify-center items-center">
                                                                <XClose />
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <h4 className="text-base font-medium text-black mb-1 text-justify">{t("No, I don't provide any services")}</h4>
                                                                <span className="text-sm text-gray-500">{t("I own a brand but don't provide services")}</span>
                                                            </div>
                                                        </div>
                                                        <Radio
                                                            className="orm-radio h-5 w-5 text-blue-600 cursor-pointer"
                                                            id="no"
                                                            name="status"
                                                            value="no"
                                                            onChange={onChange}
                                                            checked={value === 'no'}
                                                        />
                                                    </Label>
                                                </div>
                                                {error && <p className="text-error mt-2 absolute text-sm">{error.message}</p>}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <div className="bottom-0 right-0 max-2xl:start-[420px] start-[480px] fixed">
                    <div className="w-full mt-5   mb-2  gap-4 flex justify-center mx-auto">
                        <CustomButton secondary onClick={handleStep('prev')} size="w-[292px]">
                            Back
                        </CustomButton>
                        <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" size="w-[292px]" className="w-[292px]">
                            {t('Continue')}
                        </CustomButton>
                    </div>
                    <Footer />
                </div>
            </form>
        </>
    );
};

export default ServiceProvider;
