import React, { memo, useMemo, useRef } from 'react';
import moment from 'moment';
import { usePhone } from 'src/hooks/usePhone';
import { getShortLastName } from 'src/utils/global-functions';
import { Status } from './Calendar.Interface';
import { GoDotFill } from 'react-icons/go';
import { Tooltip } from 'primereact/tooltip';

const EventCard = ({ eventInfo }: any) => {
    const { event: eventDetails } = eventInfo;
    const bookingStartDateTime = moment(`${eventDetails.booking_date} ${eventDetails.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss');
    const bookingEndDateTime = moment(`${eventDetails.booking_date} ${eventDetails.booking_end_time}`, 'YYYY-MM-DD HH:mm:ss');
    const diff = useMemo(() => bookingEndDateTime.diff(bookingStartDateTime, 'minutes'), [bookingStartDateTime, bookingEndDateTime]);
    const bookingTime = bookingStartDateTime.format('hh:mm A');
    const { getCustomFormatPhone } = usePhone();
    const componentRef = useRef<HTMLDivElement>(null);

    const calculateTotalDuration = () => {
        let totalDuration = moment.duration();

        if (eventDetails.booking_services) {
            eventDetails.booking_services.forEach((item: any) => {
                const duration = moment.duration(item.duration).asMilliseconds() * item.quantity;
                totalDuration.add(moment.duration(duration));
            });
        }

        return moment.utc(totalDuration.asMilliseconds()).format(totalDuration.hours() > 0 ? (totalDuration.minutes() > 0 ? 'H [Hours] m [Minutes]' : 'H [Hours]') : 'm [Minutes]');
    };

    const statusStyles: Record<
        Status,
        {
            timeColor: string;
            titleColor: string;
            borderColor: string;
            bgColor: string;
        }
    > = {
        confirmed: {
            timeColor: 'text-blueExtra-500',
            titleColor: 'text-blueExtra-700',
            borderColor: 'border-[1px] border-blueExtra-300',
            bgColor: 'bg-blueExtra-50',
        },
        pending: {
            timeColor: 'text-yellow-600',
            titleColor: 'text-yellow-700',
            borderColor: 'border-[1px] border-yellow-200',
            bgColor: 'bg-yellow-25',
        },
        canceled: {
            timeColor: 'text-error-600',
            titleColor: 'text-error-700',
            borderColor: 'border-[1px] border-error-200',
            bgColor: 'bg-error-50',
        },
        completed: {
            timeColor: 'text-success-600',
            titleColor: 'text-success-700',
            borderColor: 'border-[1px] border-success-200',
            bgColor: 'bg-success-50',
        },
        no_show: {
            timeColor: 'text-purple-600',
            titleColor: 'text-purple-700',
            borderColor: 'border-[1px] border-purple-200',
            bgColor: 'bg-purple-50',
        },
        declined: {
            timeColor: 'text-gray-500',
            titleColor: 'text-gray-700',
            borderColor: 'border-[1px] border-gray-300',
            bgColor: 'bg-gray-50',
        },
        request_canceled: {
            timeColor: 'text-gray-500',
            titleColor: 'text-gray-700',
            borderColor: 'border-[1px] border-gray-300',
            bgColor: 'bg-gray-50',
        },
    };
    const getStatusStyle = (
        status: Status,
    ): {
        timeColor: string;
        titleColor: string;
        borderColor: string;
        bgColor: string;
    } => {
        if (status !== undefined) {
            return statusStyles[status];
        } else {
            return {
                timeColor: '',
                titleColor: '',
                borderColor: '',
                bgColor: '',
            };
        }
    };
    const statusColor = useMemo(() => getStatusStyle(eventDetails.status), [eventDetails.status]);

    const totalDuration = calculateTotalDuration();

    if (eventDetails.status === 'new') {
        return (
            <div className="event-container-wrap relative flex flex-col h-full !outline-none border border-success-200 bg-success-50 m-0">
                <Tooltip target=".new-appoinment" position="top" />
                <GoDotFill className="absolute top-1 right-1 h-3 w-3 rounded-full text-success-500 new-appoinment z-[9]" data-pr-tooltip={'New Appointment'} />

                <div className="rounded-md text-xs relative flex flex-col h-full px-1.5 pt-1.5">
                    <p className=" text-success-700   items-center gap-[3px]  h-[20px] max-w-[75%] font-semibold text-[12px] leading-[20px] truncate block">New Appointment</p>
                </div>
                {diff > 15 && (
                    <div className="rounded-md text-xs relative flex flex-col h-full px-2 mb-2 justify-end">
                        <span className="gap-[3px] h-[20px] rounded-md  py-0.5 block font-normal text-[11px] text-success-600 leading-[18px] truncate">
                            {bookingTime} ({diff} Minutes)
                        </span>
                    </div>
                )}
            </div>
        );
    }

    if (eventDetails.status === 'block_time') {
        return (
            <div className="event-container-wrap h-full">
                <div className="rounded-md text-xs relative flex flex-col h-full px-2  justify-center">
                    <p className="text-gray-900 font-medium text-xs leading-[18px]">Block Time</p>
                    <p className="font-bold leading-[140%] -tracking-[0.1px] mb-[6px]">{eventDetails.name}</p>
                </div>
            </div>
        );
    }

    return (
        <div className={`event-container-wrap h-full ${statusColor.bgColor} ${statusColor.borderColor} `} ref={componentRef}>
            <Tooltip target=".event-tooltip" position="top" />
            <GoDotFill className={`absolute top-2 right-2 h-3 w-3 rounded-full  event-tooltip z-[9] ${statusColor.timeColor}`} data-pr-tooltip={eventDetails.status} />
            <div className="relative flex flex-col h-full ">
                <div className="flex justify-between flex-1 flex-col">
                    <div className={`flex flex-col flex-1 ${diff >= 30 ? '' : ''}`}>
                        <div className={`flex  ${diff > 30 ? 'flex-col' : ' flex-row justify-between items-center '}`}>
                            <div className="w-full">
                                <p className={`ml-2 font-semibold  ${statusColor.titleColor} mt-1.5  w-[80%] text-xs leading-[16px] truncate mr-2`}>{eventDetails.booking_services[0].service.name}</p>
                                {diff >= 30 && <p className={` ${statusColor.timeColor}  text-[10px] leading-[14px] font-normal ml-2  truncate`}>{`${bookingTime} (${totalDuration})`}</p>}
                            </div>
                        </div>
                    </div>
                    <div>
                        {diff >= 60 && (
                            <div className="flex mt-2.5 justify-between ml-2 mb-2">
                                <p className={`${statusColor.titleColor} font-medium text-[10px] leading-[14px] block truncate capitalize-first mr-1 w-full`}>
                                    {eventDetails.user.full_name
                                        ? getShortLastName(eventDetails.user.full_name)
                                        : getCustomFormatPhone(eventDetails.user?.phone, eventDetails.user?.phone_country_code)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(EventCard);
