import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppSelector } from 'src/redux/hooks';
import { IAccountFormData, IProps } from './MultiBrand.interface';
import { useTranslation } from 'react-i18next';
import { userMe } from 'src/redux/services/common/Common.slice';
import { Label, Radio } from 'flowbite-react';
import CustomButton from 'src/components/CustomButton';
import { IoList } from 'react-icons/io5';
import { User01, UsersCheck } from '@untitled-ui/icons-react/build/cjs';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import StepProgressBar from 'src/components/StepProgressBar';
import Footer from 'src/app/Layout/Footer';
import { XClose } from '@untitled-ui/icons-react/build/cjs';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';

const AccountType: FC<IProps> = ({ setStep, stepLenght, currentStep }) => {
    const { t } = useTranslation();
    const user = useAppSelector(userMe);
    const navigate = useNavigate();
    const {
        trigger,
        formState: { errors },
        control,
    } = useFormContext();

    useFormErrorFocus<IAccountFormData>({ errors });
    const handleContinue = async () => {
        const isValid = await trigger();
        if (isValid) {
            setStep(2);
        }
    };

    const handleClose = () => {
        navigate(ROUTES.DASHBOARD);
    };

    return (
        <>
            <main className="px-6 h-[calc(100vh-84px)] overflow-y-scroll flex-1 flex relative">
                <div className="w-full flex justify-center relative flex-1 items-center flex-col text-center bg-banner bg-top bg-no-repeat">
                    <div className="w-[600px] ">
                        <div className="flex flex-col rounded-md items-center mb-4">
                            <div className="p-[14px] bg-white border border-btnborder rounded-xl">
                                <IoList className="w-7 h-7" />
                            </div>
                        </div>
                        <div className="text-center">
                            <h3 className="fwpo_heading_title">{t('Select account type')}</h3>
                            <p className="font-normal leading-[22.4px] mt-[2px] text-base text-secondaryTxtColor">
                                {t('Welcome! Let us know how you intend on using.', { name: user.first_name, shop_name: process.env.REACT_APP_FULL_NAME })}
                            </p>
                        </div>
                        <div className="mt-8">
                            <Controller
                                name="type"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <>
                                        <div id="type" className={`flex flex-col mb-4 ${value === 'owner' ? 'account_active' : ''}`}>
                                            <Label
                                                htmlFor="owner"
                                                className={`flex justify-between items-center px-4 py-3 border  rounded-lg hover:border-[#5279F0] bg-white hover:bg-[#F9FAFC] cursor-pointer ${
                                                    value === 'owner' ? 'border-[#5279F0] bg-[#F9FAFC]' : 'border-gray-300'
                                                }`}
                                            >
                                                <div className="flex items-center">
                                                    <div className="me-3 w-11 h-11 border border-gray-200 rounded-lg flex justify-center items-center">
                                                        <UsersCheck />
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <h4 className="text-base font-medium text-black mb-1 text-justify">{t('I am a brand owner')}</h4>
                                                        <span className="text-sm text-gray-500">{t('I own a brand and manage a team of professionals')}</span>
                                                    </div>
                                                </div>
                                                <Radio
                                                    className="orm-radio h-5 w-5 text-primary cursor-pointer"
                                                    id="owner"
                                                    name="type"
                                                    value={'owner'}
                                                    onChange={onChange}
                                                    checked={value === 'owner'}
                                                />
                                            </Label>
                                        </div>
                                        <div className={`flex flex-col ${value === 'individual' ? 'account_active' : ''}`}>
                                            <Label
                                                htmlFor="individual"
                                                className={`flex justify-between items-center px-4 py-3 border rounded-lg hover:border-[#5279F0] bg-white hover:bg-[#F9FAFC] cursor-pointer ${
                                                    value === 'individual' ? 'border-[#5279F0] bg-[#F9FAFC]' : 'border-gray-300'
                                                }`}
                                            >
                                                <div className="flex items-center">
                                                    <div className="me-3 w-11 h-11 border border-gray-200 rounded-lg flex justify-center items-center">
                                                        <User01 />
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <h4 className="text-base font-medium text-black mb-1 text-justify">{t('I am independent')}</h4>
                                                        <span className="text-sm text-gray-500">{t('I provide personal care services and work for myself')}</span>
                                                    </div>
                                                </div>
                                                <Radio
                                                    className="orm-radio h-5 w-5 text-primary cursor-pointer"
                                                    id="individual"
                                                    name="type"
                                                    value={'individual'}
                                                    onChange={onChange}
                                                    checked={value === 'individual'}
                                                />
                                            </Label>
                                        </div>
                                    </>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="close-page-btn absolute right-8 top-8">
                    <XClose className="xl:w-5 xl:h-5 w-4 h-4 text-gray-900" onClick={handleClose} />
                </div>
            </main>
            <div className="bottom-0 right-0 max-2xl:start-[420px] start-[480px] fixed">
                <div className="w-full mt-5   mb-2  gap-4 flex justify-center mx-auto">
                    <CustomButton primary type="button" onClick={handleContinue} size="w-[600px]" className="w-[600px]">
                        {t('Continue')}
                    </CustomButton>
                </div>
                <div className="w-full bg-white">
                    <StepProgressBar steps={stepLenght} currentStep={currentStep} className="w-full mx-auto justify-center" size="max-w-[190px]" />
                </div>
                <Footer />
            </div>
        </>
    );
};

export default AccountType;
