import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CustomButton from 'src/components/CustomButton';
import { ArrowUp, ArrowDown, UploadCloud02, Check } from '@untitled-ui/icons-react/build/cjs';
import { initAction } from './Staff.interface';
import StaffPaymentStatus from './StaffPaymentStatus';
import { perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import NoDataMessage from 'src/components/NoDataMessage';
import { axiosGet, axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { downloadXLSX, formatAmount, formatDate, formatPercentage } from 'src/utils/global-functions';
import { PaginatorTemplate } from 'src/utils/global-component';
import Badge from 'src/components/Badge';

const StaffPayment = ({ staff }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isSingleExporting, setIsSingleExporting] = useState<string>('');
    const [isPaidLoading, setIsPaidLoading] = useState<string>('');
    const [payments, setPayments] = useState<any>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [action, setAction] = useState<any>(initAction);
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: perPageOptions[0].value,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            shop_admin_id: { value: staff?.id },
            start_date: { value: null },
            end_date: { value: null },
        },
    });

    const tabs = useMemo(() => {
        if (staff.pay_structure.pay_structure_type === 'rent') {
            return [
                {
                    name: 'Rent Collection',
                    type: 'rent',
                    title: t('All Rent Collection'),
                    subtitle: t('View full rent collection history.', { name: staff.full_name }),
                    frequency: staff.pay_structure.shop_payment.rent_frequency.replace(/_/g, '-'),
                },
            ];
        }

        return [
            {
                name: 'Commission Payouts',
                type: 'commission',
                title: t('All Commission Payouts'),
                subtitle: t('View full commission payout history.', { name: staff.full_name }),
                frequency: staff.pay_structure.shop_payment.commission_frequency.replace(/_/g, '-'),
            },
            {
                name: 'Tip Payouts',
                type: 'tip',
                title: t('All Tip Payouts'),
                subtitle: t('View full tip payout history.', { name: staff.full_name }),
                frequency: staff.pay_structure.shop_payment.tip_frequency.replace(/_/g, '-'),
            },
            {
                name: 'Product Commission',
                type: 'product_commission',
                title: t('All Product Commission'),
                subtitle: t('View full product commission history.', { name: staff.full_name }),
                frequency: staff.pay_structure.shop_payment.product_commission_frequency.replace(/_/g, '-'),
            },
        ];
    }, []);
    const [activeTab, setActiveTab] = useState(tabs[0]);

    useEffect(() => {
        getPaymentList();
    }, [lazyState, activeTab]);

    const getPaymentList = () => {
        setIsLoading(true);

        const payload = {
            ...lazyState,
            type: activeTab.type,
        };

        axiosGet(API.PAYMENT.LIST, { shop_id: shop.id }, payload)
            .then((response) => {
                setPayments(response.data.data.data);
                setTotalRecords(response.data.data.totalRecords);
            })
            .finally(() => setIsLoading(false));
    };

    const handlePaid = (id: any) => () => {
        setIsPaidLoading(id);
        axiosPatch(API.PAYMENT.RETRY, {}, { shop_id: shop.id, id })
            .then(() => getPaymentList())
            .finally(() => setIsPaidLoading(''));
    };

    /* const handleAction = (type: string) => () => {
        setAction((old: any) => ({ ...old, [type]: true }));
    }; */

    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);

    const handleTabClick = (tab: any) => (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setActiveTab(tab);
    };

    const handleExport =
        (id: string = '', name: string = '') =>
        () => {
            if (id) {
                setIsSingleExporting(id);
            } else {
                setIsExporting(true);
            }
            const payload = {
                ...lazyState,
                type: activeTab.type,
                is_export: true,
                id,
            };
            axiosGet(API.PAYMENT.LIST, { shop_id: shop.id }, payload)
                .then(async (response) => {
                    const data = response.data.data;
                    downloadXLSX(data, name ? `${name}_${activeTab.type}_statement` : `${activeTab.type}_statement`);
                })
                .finally(() => {
                    setIsExporting(false);
                    setIsSingleExporting('');
                });
        };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const KPIS = useMemo(() => {
        const payStructure = staff.pay_structure;
        const shopPayment = payStructure.shop_payment;
        const data = [];
        let frequency;
        let day;
        if (payStructure.pay_structure_type === 'rent') {
            frequency = shopPayment.rent_frequency;
            day = frequency === 'monthly' ? `${shopPayment.rent_month_day} day of month` : shopPayment.rent_day;

            data.push({
                name: t('Rent Amount'),
                number: `$${payStructure.rent_money}`,
            });
        } else {
            frequency = shopPayment.commission_frequency;
            day = frequency === 'monthly' ? `${shopPayment.commission_month_day} day of month` : shopPayment.commission_day;

            data.push({
                name: t('Commission Percentage'),
                number: `${payStructure.commission}%`,
            });
            data.push({
                name: t('Tip payout frequency'),
                number: shopPayment.tip_frequency.replace(/_/g, '-'),
            });
        }
        return [
            ...data,
            {
                name: t('Payment type'),
                number: payStructure.pay_structure_type,
            },
            {
                name: t('Frequency'),
                number: frequency.replace(/_/g, '-'),
            },
            {
                name: t('Payment day'),
                number: day,
            },
        ];
    }, [staff]);

    const GetTotal = (row: any) => formatAmount(row.amount);
    const GetStartDate = (row: any) => formatDate(row.start_date);
    const GetEndDate = (row: any) => formatDate(row.end_date);

    const GetStatus = (row: any) => (
        <Badge icon status={row.is_paid ? 'completed' : 'canceled'}>
            {row.is_paid ? 'Paid' : 'Not Paid'}
        </Badge>
    );

    const getAction = (row: any) => (
        <div className="flex items-center gap-4 justify-end">
            {!row.is_paid && (
                <CustomButton isLoading={isPaidLoading === row.id} disabled={!!isPaidLoading} onClick={handlePaid(row.id)} className="shadow-none text-gray-600 hover:text-gray-900 w-4 !px-0">
                    <Check className="h-4 w-4 " />
                </CustomButton>
            )}
            <CustomButton
                isLoading={isSingleExporting === row.id}
                disabled={!!isSingleExporting}
                onClick={handleExport(row.id, row.staff.full_name)}
                className="shadow-none w-4 !px-0 text-gray-600 hover:text-gray-900 !pr-[4px]"
            >
                <UploadCloud02 className="h-4 w-4" />
            </CustomButton>
        </div>
    );

    const CommissionSkeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map((_, index) => ({
                id: index,
                period_start_date: 'loading',
                period_end_date: 'loading',
                total: 'loading',
                taxes: 'loading',
                tips: 'loading',
                due_barber: 'loading',
                due_to_Shop: 'loading',
                payment_status: 'loading',
                download: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const columns = useMemo(() => {
        switch (activeTab.type) {
            case 'rent':
                return [
                    {
                        sortable: true,
                        field: 'is_paid',
                        header: renderHeader(t('Status'), 'is_paid'),
                        body: isLoading ? <Skeleton /> : GetStatus,
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                    },
                    {
                        sortable: true,
                        field: 'start_date',
                        header: renderHeader(t('Period Start Date'), 'start_date'),
                        body: isLoading ? <Skeleton /> : GetStartDate,
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'end_date',
                        header: renderHeader(t('Period End Date'), 'end_date'),
                        body: isLoading ? <Skeleton /> : GetEndDate,
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'rent',
                        header: renderHeader(t('Rent'), 'rent'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatAmount(row.rent),
                        style: { width: '100px', minWidth: '100px', maxWidth: '100px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'amount',
                        header: renderHeader(t('Total'), 'amount'),
                        body: isLoading ? <Skeleton /> : GetTotal,
                        style: { width: '100px', minWidth: '100px', maxWidth: '100px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                ];
            case 'commission':
                return [
                    {
                        sortable: true,
                        field: 'is_paid',
                        header: renderHeader(t('Status'), 'is_paid'),
                        body: isLoading ? <Skeleton /> : GetStatus,
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                    },
                    {
                        sortable: true,
                        field: 'start_date',
                        header: renderHeader(t('Period Start Date'), 'start_date'),
                        body: isLoading ? <Skeleton /> : GetStartDate,
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'end_date',
                        header: renderHeader(t('Period End Date'), 'end_date'),
                        body: isLoading ? <Skeleton /> : GetEndDate,
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'commission',
                        header: renderHeader(t('Commission'), 'commission'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatPercentage(row.commission),
                        style: { width: '100px', minWidth: '100px', maxWidth: '100px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'amount',
                        header: renderHeader(t('Total'), 'amount'),
                        body: isLoading ? <Skeleton /> : GetTotal,
                        style: { width: '100px', minWidth: '100px', maxWidth: '100px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        field: 'tips',
                        sortable: true,
                        header: renderHeader(t('Tips'), 'tips'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatAmount(row.tips),
                        style: { width: '80px', minWidth: '80px', maxWidth: '80px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        field: 'due_to_shop',
                        sortable: true,
                        header: renderHeader(t('Brand Commission'), 'due_to_shop'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatAmount(row.due_to_shop),
                        style: { width: '110px', minWidth: '110px', maxWidth: '110px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        field: 'due_to_staff',
                        sortable: true,
                        header: renderHeader(t('Team Member Commission'), 'due_to_staff'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatAmount(row.due_to_staff),
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                ];
            case 'tip':
                return [
                    {
                        sortable: true,
                        field: 'is_paid',
                        header: renderHeader(t('Status'), 'is_paid'),
                        body: isLoading ? <Skeleton /> : GetStatus,
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                    },
                    {
                        sortable: true,
                        field: 'start_date',
                        header: renderHeader(t('Period Start Date'), 'start_date'),
                        body: isLoading ? <Skeleton /> : GetStartDate,
                        style: { width: '140px', minWidth: '140px', maxWidth: '140px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'end_date',
                        header: renderHeader(t('Period End Date'), 'end_date'),
                        body: isLoading ? <Skeleton /> : GetEndDate,
                        style: { width: '140px', minWidth: '140px', maxWidth: '140px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'total_bookings',
                        header: renderHeader(t('Total Appointment'), 'total_bookings'),
                        body: isLoading ? <Skeleton /> : undefined,
                        style: { width: '140px', minWidth: '140px', maxWidth: '140px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'amount',
                        header: renderHeader(t('Total Tips'), 'amount'),
                        body: isLoading ? <Skeleton /> : GetTotal,
                        style: { width: '140px', minWidth: '140px', maxWidth: '140px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                ];
            case 'product_commission':
                return [
                    {
                        sortable: true,
                        field: 'is_paid',
                        header: renderHeader(t('Status'), 'is_paid'),
                        body: isLoading ? <Skeleton /> : GetStatus,
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                    },
                    {
                        sortable: true,
                        field: 'start_date',
                        header: renderHeader(t('Period Start Date'), 'start_date'),
                        body: isLoading ? <Skeleton /> : GetStartDate,
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'end_date',
                        header: renderHeader(t('Period End Date'), 'end_date'),
                        body: isLoading ? <Skeleton /> : GetEndDate,
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'product_commission',
                        header: renderHeader(t('Product Commission'), 'product_commission'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatPercentage(row.product_commission),
                        style: { width: '150px', minWidth: '150px', maxWidth: '150px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'amount',
                        header: renderHeader(t('Total'), 'amount'),
                        body: isLoading ? <Skeleton /> : GetTotal,
                        style: { width: '100px', minWidth: '100px', maxWidth: '100px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'due_to_shop',
                        header: renderHeader(t('Brand Commission'), 'due_to_shop'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatAmount(row.due_to_shop),
                        style: { width: '140px', minWidth: '140px', maxWidth: '140px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'due_to_staff',
                        header: renderHeader(t('Team Member Commission'), 'due_to_staff'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatAmount(row.due_to_staff),
                        style: { width: '180px', minWidth: '180px', maxWidth: '180px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                ];
            default:
                return [];
        }
    }, [isLoading, activeTab.type]);

    return (
        <div className="w-full flex flex-col">
            <div className="grid grid-cols-3 xl:grid-cols-5 gap-5 mt-8 mb-8">
                {KPIS.map((kpi: any, index) => (
                    <div key={index} className="flex border flex-col border-gray-200 rounded-md p-3">
                        <p className="text-gray-600 text-xs font-normal mb-1.5 capitalize">{kpi.name}</p>
                        <p className="text-sm font-semibold capitalize">{kpi.number}</p>
                    </div>
                ))}
            </div>
            {staff.pay_structure.pay_structure_type === 'commission' && (
                <div className="fl-tab-btn-view3 w-full mb-5 ">
                    {tabs.map((tab, index) => (
                        <button key={index} type="button" className={`fl-tab-link3 !max-w-[182px] ${activeTab.name === tab.name ? 'active' : ''}`} onClick={handleTabClick(tab)}>
                            {tab.name}
                        </button>
                    ))}
                </div>
            )}
            <div className="w-full border rounded-xl flex-col mb-5 flex shadow  datatable-custom ">
                <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                    <div className="flex flex-col">
                        <div className="flex gap-2 items-center">
                            <h2 className="view-table-title">{activeTab.title}</h2>
                            <Badge icon>{activeTab.frequency}</Badge>
                        </div>
                        <p className="view-table-subtitle">{activeTab.subtitle}</p>
                    </div>
                    <div className="flex gap-4">
                        <div className="location-dropdown">
                            <CustomButton
                                isLoading={isExporting}
                                disabled={isExporting || !totalRecords}
                                secondary
                                outlineSecondary
                                icon={<UploadCloud02 className="w-[18px] h-[18px] mt-[2px]" />}
                                onClick={handleExport()}
                                className="flex items-center"
                            >
                                {t('Export')}
                            </CustomButton>
                        </div>
                        <SelectBox
                            name="page"
                            isClearable={false}
                            options={perPageOptions}
                            onChangeFunc={onPageHandle}
                            value={perPageOptions.find((option) => option.value === lazyState.rows)}
                            classComp="w-[70px]"
                        />
                    </div>
                </div>
                <DataTable
                    lazy
                    paginatorTemplate={PaginatorTemplate()}
                    value={isLoading ? CommissionSkeletons : payments}
                    totalRecords={totalRecords}
                    paginator={!isLoading && totalRecords > lazyState.rows}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    sortOrder={lazyState.sortOrder}
                    sortField={lazyState.sortField}
                    filters={lazyState.filters}
                    onPage={onPage}
                    onSort={onSort}
                    onFilter={onFilter}
                    dataKey="id"
                    className="rounded-b-xl overflow-hidden"
                    paginatorClassName="table-pagination"
                    emptyMessage={
                        <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                            <NoDataMessage
                                title={`${lazyState.filters.global.value ? t('No Found', { title: activeTab.name }) : t('No Record', { title: activeTab.name })}`}
                                description={`${
                                    lazyState.filters.global.value
                                        ? t('No statements found matching your search criteria. Try adjusting your filters or record new transactions.')
                                        : t('Add a team member with a tip-based payment structure to start tracking tip payouts.')
                                }`}
                            />
                        </div>
                    }
                >
                    {columns.map((col) => (
                        <Column key={col.field} {...col} />
                    ))}
                    <Column field="action" style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }} body={isLoading ? <Skeleton /> : getAction}></Column>
                </DataTable>
            </div>
            {action.PaymentStatus && <StaffPaymentStatus handleClose={handleModalClose} />}
        </div>
    );
};

export default StaffPayment;
