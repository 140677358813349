import React, { useState } from 'react';
import InputWithLabel from 'src/components/InputWithLabel';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronRight, Plus } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';
import { XClose } from '@untitled-ui/icons-react/build/cjs';
import { CHARACTERS_LIMIT } from 'src/constants/common';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { DURATIONS } from './Services.interface';
import Switch from 'src/components/Switch/Switch';
import { exceptValidation } from 'src/utils/global-functions';
import { useRolePermission } from 'src/hooks/useRolePermission';

const TypeForm = ({ language }: any) => {
    const { t } = useTranslation();
    const { isIndividual } = useRolePermission();
    const { control, watch } = useFormContext();
    const type = watch('type');
    const [isShow, setIsShow] = useState<any>({ 0: true });

    const {
        fields: optionFields,
        append: appendOption,
        remove: removeOption,
    } = useFieldArray({
        control,
        name: 'variables',
    });

    const handleCollapse = (index: number) => () => {
        setIsShow((old: any) => ({ ...old, [index]: !old[index] }));
    };

    const handleAppendOption = () => {
        const newOption: any = {
            id: 0,
            en_name: '',
            en_description: '',
            fr_name: '',
            fr_description: '',
            duration: null,
            price: undefined,
            starting_price: false,
        };
        appendOption(newOption);
    };

    return (
        <div className="w-full">
            {type === 'variable' ? (
                <div className="flex flex-col gap-5">
                    {optionFields.map((item, index) => (
                        <div key={item.id} className="border rounded-lg">
                            <div className="flex items-center justify-between p-4 cursor-pointer" onClick={handleCollapse(index)}>
                                <p className="text-sm font-medium">{watch(`variables.${index}.${language}_name`) || `${t('Variant Title')}`}</p>
                                <div className="flex items-center gap-3">
                                    <div className="text-xs font-semibold text-red-700">
                                        <span className="text-error-700 cursor-pointer" onClick={() => removeOption(index)}>
                                            <XClose width={18} className="text-error-700" />
                                        </span>
                                    </div>
                                    <button type="button" className=" text-3xl">
                                        {isShow[index] ? <ChevronDown className="text-gray-600" /> : <ChevronRight className="text-gray-600" />}
                                    </button>
                                </div>
                            </div>
                            <div className={`flex flex-col gap-5 border-t p-4 ${!isShow[index] ? 'd-none' : ''}`}>
                                <Controller
                                    name={`variables.${index}.${language}_name`}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div>
                                            <InputWithLabel
                                                required
                                                id={`variables.${index}.${language}_name`}
                                                name={`variables.${index}.${language}_name`}
                                                label={t('Variant Title')}
                                                placeholder={t('Enter service Variant Title')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                            />
                                            {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name={`variables.${index}.${language}_description`}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div>
                                            <InputWithLabel
                                                required
                                                id={`variables.${index}.${language}_description`}
                                                name={`variables.${index}.${language}_description`}
                                                label={t('Variant Description')}
                                                placeholder={t('Enter service Variant Description')}
                                                onChange={onChange}
                                                value={value}
                                                textArea
                                                maxLenght={CHARACTERS_LIMIT.LONG}
                                                error={!!error}
                                                textAearClassName="h-[120px] w-full rounded-lg resize-none"
                                            />
                                            {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                                {isIndividual && (
                                    <div className="flex gap-4">
                                        <Controller
                                            name={`variables.${index}.duration`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div className="flex-1">
                                                    <SelectBox
                                                        required
                                                        isClearable={false}
                                                        name={`variables.${index}.duration`}
                                                        label={t('Duration (minutes)')}
                                                        options={DURATIONS}
                                                        value={DURATIONS.find((duration) => duration.value === value)}
                                                        onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                        error={!!error}
                                                        classComp="outline-select-box"
                                                        placeholder={t('Minutes')}
                                                    />
                                                    {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name={`variables.${index}.price`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div className="flex-1">
                                                    <InputWithLabel
                                                        required
                                                        name={`variables.${index}.price`}
                                                        type="number"
                                                        label={t('Price')}
                                                        placeholder={t('Price')}
                                                        value={value}
                                                        error={!!error}
                                                        onChange={onChange}
                                                    />
                                                    {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name={`variables.${index}.starting_price`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div className="flex-1">
                                                    <div className="flex items-center gap-2 text-sm font-medium text-gray-700 mt-8">
                                                        <Switch onChange={onChange} isChecked={value} />
                                                        <span>{t('Starting price')}</span>
                                                    </div>
                                                    {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    <div className="flex justify-end text-primary text-xs font-semibold">
                        <CustomButton onClick={handleAppendOption} icon={<Plus className="h-4 w-4" />} className="shadow-none !p-0">
                            {t('Add new variant')}
                        </CustomButton>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col gap-4">
                    <Controller
                        name={`${language}_description`}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div>
                                <InputWithLabel
                                    required
                                    textArea
                                    id={`${language}_description`}
                                    name={`${language}_description`}
                                    label={t('Description')}
                                    placeholder={t('Enter service description')}
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    textAearClassName="h-[120px] w-full rounded-lg resize-none"
                                    maxLenght={CHARACTERS_LIMIT.LONG}
                                />
                                {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                    {isIndividual && (
                        <div className="flex gap-4">
                            <Controller
                                name="duration"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="flex-1">
                                        <SelectBox
                                            required
                                            isClearable={false}
                                            name="duration"
                                            label={t('Duration (minutes)')}
                                            options={DURATIONS}
                                            value={DURATIONS.find((duration) => duration.value === value)}
                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                            error={!!error}
                                            classComp="outline-select-box"
                                            placeholder={t('Minutes')}
                                        />
                                        {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                    </div>
                                )}
                            />
                            <Controller
                                name="price"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="flex-1">
                                        <InputWithLabel required type="number" label={t('Price')} onChange={onChange} value={value} name="price" placeholder={t('Price')} error={!!error} />
                                        {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                    </div>
                                )}
                            />
                            <Controller
                                name="starting_price"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="flex-1">
                                        <div className="flex items-center gap-2 text-sm font-medium text-gray-700 mt-8">
                                            <Switch onChange={onChange} isChecked={value} />
                                            <span>{t('Starting price')}</span>
                                        </div>
                                        {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
export default TypeForm;
