import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ServiceTable from './ServiceTable';
import PageHeader from 'src/components/PageHeader';
import { APP_FULL_NAME, SERVICE_TABS } from 'src/constants/common';
import StaffServiceTable from './StaffServiceTable';
import { PATH } from 'src/constants/path';
import CategoryTable from './Category/CategoryTable';
import ShopServiceTable from './ShopServiceTable';
import { useRolePermission } from 'src/hooks/useRolePermission';

const Services = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { tab: currentTab } = useParams();
    const { isStaff } = useRolePermission();
    const [activeTabName, setActiveTabName] = useState(SERVICE_TABS.STAFF);
    const [activeTab, setActiveTab] = useState<any>();

    useEffect(() => {
        if (isStaff) {
            switch (activeTabName) {
                case SERVICE_TABS.STAFF:
                    setActiveTab(<StaffServiceTable />);
                    break;
                case SERVICE_TABS.SHOP:
                    setActiveTab(<ShopServiceTable setActiveTabName={setActiveTabName} />);
                    break;
                default:
                    setActiveTab(<StaffServiceTable />);
                    break;
            }
        } else {
            switch (currentTab) {
                case SERVICE_TABS.SERVICE:
                    document.title = `${t('Service')} - ${APP_FULL_NAME}`;
                    setActiveTab(<ServiceTable />);
                    break;
                case SERVICE_TABS.CATEGORY:
                    document.title = `${t('Service Category')} - ${APP_FULL_NAME}`;
                    setActiveTab(<CategoryTable />);
                    break;
                default:
                    document.title = `${t('Service')} - ${APP_FULL_NAME}`;
                    setActiveTab(<ServiceTable />);
                    break;
            }
        }
    }, [activeTabName, currentTab]);

    const handleTab = (tab: any) => (event: { preventDefault: () => void }) => {
        event.preventDefault();
        if (isStaff) {
            setActiveTabName(tab.type);
        } else {
            navigate(tab.url);
        }
    };

    const tabs = useMemo(() => {
        if (isStaff) {
            return [
                {
                    name: 'My services',
                    type: SERVICE_TABS.STAFF,
                },
                {
                    name: 'Brand services',
                    type: SERVICE_TABS.SHOP,
                },
            ];
        } else {
            return [
                {
                    name: 'Services',
                    type: SERVICE_TABS.SERVICE,
                    url: PATH.SERVICES.LIST,
                },
                {
                    name: 'Service Categories',
                    type: SERVICE_TABS.CATEGORY,
                    url: `${PATH.SERVICES.LIST}/${SERVICE_TABS.CATEGORY}`,
                },
            ];
        }
    }, []);

    return (
        <div className="inner-page-wrape pb-5">
            <PageHeader title={t('Services')} subtitle={t('Easily view, add, and manage all the services your brand offers.')} />
            <div className="side-spaching flex flex-1 flex-col w-full">
                <div className=" flex flex-col flex-1">
                    <div className="fl-tab-btn-view w-full mb-5">
                        {tabs.map((tab, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`fl-tab-link !max-w-[182px]  ${activeTabName === tab.type || currentTab === tab.type || (!currentTab && tab.type === SERVICE_TABS.SERVICE) ? 'active' : ''}`}
                                onClick={handleTab(tab)}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>
                    {activeTab}
                </div>
            </div>
        </div>
    );
};

export default Services;
