import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { IReaderFormData, ISelectBox } from './Terminal.interface';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import { toast } from 'react-toastify';
import { errorCode } from 'src/constants/errorCode';
import { axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { MarkerPin04 } from '@untitled-ui/icons-react/build/cjs';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import PopupModal from 'src/components/PopupModal';

const ReaderForm = ({ handleClose, listReaders, data: existingData }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [locationOptions, setLocationOptions] = useState<ISelectBox[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLocationLoading, setIsLocationLoading] = useState(false);

    const schema = Yup.object({
        label: Yup.string().required(t('This field is required')),
        registration_code: Yup.string().required(t('This field is required')),
        location: Yup.object()
            .shape({
                value: Yup.string().required('This field is required'),
                label: Yup.string().required('This field is required'),
            })
            .nullable()
            .required('This field is required'),
    }).required();

    const methods = useForm<IReaderFormData>({
        resolver: yupResolver(schema) as Resolver<IReaderFormData>,
        defaultValues: {
            label: existingData ? existingData.label : '',
            registration_code: existingData ? existingData.registration_code : '',
            location: undefined,
        },
    });

    const {
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
        control,
    } = methods;

    useFormErrorFocus<IReaderFormData>({ errors });

    useEffect(() => {
        locationList();
    }, []);

    const locationList = () => {
        setIsLocationLoading(true);
        axiosGet(API.STRIPE.LOCATION.LIST, { shop_id: shop.id })
            .then((response) => {
                const data = response.data.data;
                const options: ISelectBox[] | [] = getSelectBoxOptions(data, 'id', 'display_name');
                setLocationOptions(options);

                if (existingData && existingData.location) {
                    const option: any = options.find((i: any) => i.id === existingData.location);
                    setValue('location', option);
                }
            })
            .finally(() => setIsLocationLoading(false));
    };

    const handleSave = (data: IReaderFormData) => {
        setIsLoading(true);
        const payload = {
            ...data,
            location: data.location?.value,
        };
        if (existingData) {
            axiosPatch(API.STRIPE.READER.UPDATE, payload, { shop_id: shop.id, id: existingData.id })
                .then(() => {
                    listReaders();
                    handleClose();
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IReaderFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        } else {
            axiosPost(API.STRIPE.READER.CREATE, payload, { shop_id: shop.id })
                .then(() => {
                    listReaders();
                    handleClose();
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IReaderFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <MarkerPin04 className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900">{t('Add Reader')}</p>
                <span className="text-xs font-normal text-gray-500">{t('Please add reader for terminal.')}</span>
            </div>
        </div>
    );

    return (
        <PopupModal
            dismissible
            size="w-[560px]"
            isLoading={isLoading}
            isDisabled={isLoading}
            primaryButton={t('Save Changes')}
            secondaryButton={t('Cancel')}
            acceptAction={handleSubmit(handleSave)}
            declineAction={handleClose}
            onClose={handleClose}
            title={<Title />}
        >
            <div className="inner-page-wrape !px-0">
                <div className="flex-1">
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(handleSave)}>
                            <div className="flex flex-col gap-4">
                                <div className="grid grid-cols-2 gap-4">
                                    <Controller
                                        name="label"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div>
                                                <InputWithLabel
                                                    required
                                                    id="label"
                                                    name="label"
                                                    label={t('Reader Name')}
                                                    placeholder={t('Enter reader name')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                />
                                                {error && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="registration_code"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div>
                                                <InputWithLabel
                                                    required
                                                    id="registration_code"
                                                    name="registration_code"
                                                    label={t('Registration Code')}
                                                    placeholder={t('Enter registration code')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                />
                                                {error && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                                <Controller
                                    name="location"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div>
                                            <SelectBox
                                                required
                                                id="location"
                                                name="location"
                                                label={t('Location')}
                                                placeholder={t('Select location')}
                                                options={locationOptions}
                                                isLoading={isLocationLoading}
                                                value={value}
                                                onChangeFunc={onChange}
                                                error={!!error}
                                            />
                                            {error && error.type !== 'optionality' && error.type !== 'nullable' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </PopupModal>
    );
};

export default ReaderForm;
