import React, { useEffect, useMemo, useRef, useState } from 'react';
import UpcomingBooking from './Tables/UpcomingBooking';
import LatestCheckout from './Tables/LatestCheckout';
import LatestBooking from './Tables/LatestBooking';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { graphOptions, IDashboardDetails, initAction, IPayload, selectAllOption, selectAllTeamOption } from './Dashboard.Interface';
import KpiCard from 'src/components/KpiCard';
import AmChart from './AmChart';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { toast } from 'react-toastify';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, allShopStaff, currentShop, userMe } from 'src/redux/services/common/Common.slice';
import { getDateRange, getSelectBoxOptions } from 'src/utils/global-functions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PageHeader from 'src/components/PageHeader';
import { Inbox01 } from '@untitled-ui/icons-react/build/cjs';
import DateRangePicker from 'src/components/DateRangePicker/DateRangePicker';
import CustomButton from 'src/components/CustomButton';
import { GoDotFill } from 'react-icons/go';
import Notification from 'src/app/Notification/Notification';
import SChart from '../Skeleton/Payment/SChart';
import { INotificationCount } from '../Notification/Notification.interface';
import { useRolePermission } from 'src/hooks/useRolePermission';
import UpcomingBookingPopup from './Tables/UpcomingBookingPopup';
import LatestCheckoutPopup from './Tables/LatestCheckoutPopup';
import LatestBookingPopup from './Tables/LatestBookingPopup';

const Dashboard = () => {
    const { t } = useTranslation();
    const notificationButtonRef = useRef(null);
    const [action, setAction] = useState(initAction);
    const [dashboardDetails, setDashboardDetails] = useState<IDashboardDetails | null>(null);
    const shop: any = useAppSelector(currentShop);
    const user: any = useAppSelector(userMe);
    const { isOwner } = useRolePermission();
    const shopLocationList: any = useAppSelector(allShopLocations);
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>('custom');
    const [startDate, setStartDate] = useState<Date | undefined>(moment().toDate());
    const [endDate, setEndDate] = useState<Date | undefined>(moment().toDate());
    const [activeFilter, setActiveFilter] = useState('daily');
    const [locationOptions, setLocationOptions] = useState<any>([]);
    const [selectedLocation, setSelectedLocation] = useState<any | null>(selectAllOption);
    const [boxLabel, setBoxLabel] = useState('Yesterday');
    const allShopStaffList = useAppSelector(allShopStaff);
    const [staffOptions, setStaffOptions] = useState<any[]>([]);
    const [selectedTeam, setSelectedTeam] = useState<any | null>(selectAllTeamOption);
    const [selectedGraphOption, setSelectedGraphOption] = useState(graphOptions[0]);
    const [notificationCount, setNotificationCount] = useState<INotificationCount>({
        all: 0,
        general: 0,
        appointments: 0,
        statements: 0,
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'name', 'profile_image_url');
        const updatedLocationData = [selectAllOption, ...locationData];
        setLocationOptions(updatedLocationData);
        setSelectedLocation(updatedLocationData[0]);

        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');
        const updatedTeamData = [selectAllTeamOption, ...staff];
        setStaffOptions(updatedTeamData);
        setSelectedTeam(updatedTeamData[0]);
    }, []);

    useEffect(() => {
        if (activeFilter !== '') {
            const { start, end } = getDateRange(activeFilter);
            setStartDate(start);
            setEndDate(end);
        }
        if (activeFilter === 'weekly') {
            setBoxLabel('last week');
            setSelectedTimeRange(activeFilter);
        } else if (activeFilter === 'monthly') {
            setBoxLabel('last month');
            setSelectedTimeRange(activeFilter);
        } else if (activeFilter === 'yearly') {
            setBoxLabel('last year');
            setSelectedTimeRange(activeFilter);
        } else if (activeFilter === 'daily') {
            setBoxLabel('yesterday');
            setSelectedTimeRange('custom');
        }
    }, [activeFilter]);

    useEffect(() => {
        if (startDate && endDate) {
            if (selectedTimeRange === 'all_time') {
                setBoxLabel('Previous period');
            } else if (selectedTimeRange === 'quarterly') {
                setBoxLabel('last quarter');
            } else if (activeFilter === '') {
                let days = moment(endDate).diff(moment(startDate), 'days');
                days = selectedTimeRange === 'last_30_days' || selectedTimeRange === 'last_90_days' || selectedTimeRange === 'last_7_days' || selectedTimeRange === 'custom' ? days + 1 : days;

                setBoxLabel(`Previous ${days} days`);
            }
        }
    }, [startDate, endDate]);

    const tabs = useMemo(
        () => [
            {
                value: 'daily',
                name: 'Today',
            },
            {
                value: 'weekly',
                name: 'This Week',
            },
            {
                value: 'monthly',
                name: 'This Month',
            },
            {
                value: 'yearly',
                name: 'This Year',
            },
        ],
        [],
    );

    const getDashboard = () => {
        if (startDate && endDate) {
            setIsLoading(true);
            let payload: IPayload = {
                type: selectedGraphOption.value,
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
            };
            if (selectedLocation && selectedLocation.value !== 0) {
                payload.location_id = selectedLocation.id;
            }
            if (selectedTeam && selectedTeam.value !== 0) {
                payload.shop_admin_id = selectedTeam.id;
            }
            axiosGet(API.DASHBOARD.GET, { shop_id: shop.id }, payload)
                .then((response) => {
                    const data = response.data.data;
                    setDashboardDetails(data);
                })
                .catch((error: any) => toast.error(error?.message))
                .finally(() => setIsLoading(false));
        }
    };

    useEffect(() => {
        getDashboard();
    }, [selectedLocation, startDate, endDate, selectedTeam, selectedGraphOption]);

    const handleDatePickerChange = (date: any, timeRange?: string) => {
        if (timeRange === 'weekly' || timeRange === 'monthly' || timeRange === 'yearly') {
            setActiveFilter(timeRange);
        } else {
            setActiveFilter('');
            const [start, end] = date;

            setStartDate(start);
            setEndDate(end);
            if (!end) {
                setEndDate(start);
            }
        }
        if (timeRange) {
            setSelectedTimeRange(timeRange);
        }
    };

    const handleFilterTab = (value: string) => () => {
        setActiveFilter(value);
    };
    const handleTeam = (event: any) => {
        setSelectedTeam(event);
    };
    const handleLocation = (event: any) => {
        setSelectedLocation(event);
    };
    const handleGraphOptionChange = (e: any) => (event: any) => {
        setSelectedGraphOption(e ?? event);
    };
    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleClose = () => {
        setAction(initAction);
    };
    return (
        <div className="inner-page-wrape">
            {dashboardDetails && (
                <div className="inner-page-wrape">
                    <div className="w-full flex flex-1 flex-col">
                        <PageHeader
                            title={'Dashboard'}
                            titleGray={`Week of ${moment().startOf('isoWeek').format(' MMMM Do, YYYY')}`}
                            subtitle={`Welcome back, ${user.first_name}. Here’s an overview of your week so far`}
                        >
                            <div className=" cursor-pointer relative mb-[7px]" onClick={handleAction('notification')} ref={notificationButtonRef}>
                                <CustomButton secondary className="  h-[36px]   !min-w-max ">
                                    <div className="relative flex items-center">
                                        <Inbox01 className="w-5 h-5 !relative text-mainTextColor" />
                                        {Number(notificationCount.all) > 0 && (
                                            <div className="absolute  -top-[3px] right-[7px]">
                                                <GoDotFill size={16} color="#17B26A" className="h-3.5 w-3.5 rounded-full flex " />
                                            </div>
                                        )}
                                    </div>
                                    {Number(notificationCount.all) > 0 ? notificationCount.all : ''} Notifications
                                </CustomButton>
                            </div>

                            {/* <div className="flex flex-col ">
                                <div className="flex flex-row text-xs leading-[18px] font-semibold text-[#475467] items-center gap-2 ml-auto">
                                    Rent Collection in 3 days <ArrowRight className="text-[#475467] w-4" />
                                </div>
                                <div className="flex flex-row text-xs leading-[18px] font-semibold text-[#475467] items-center gap-2">
                                    Commission Payouts in 3 days <ArrowRight className="text-[#475467] w-4" />
                                </div>
                            </div> */}
                        </PageHeader>

                        <div className="side-spaching">
                            <div className="grid grid-cols-2 2xl:grid-cols-4 gap-3 xxl:gap-4 justify-between items-center mb-3 xxl:mb-4">
                                <div className="max-2xl:flex max-2xl:flex-row max-2xl:gap-2 ">
                                    <div className="fl-tab-btn-view2 h-[36px] min-h-[36px] flex shadow-custom-sm  w-full  ">
                                        {tabs.map((tab, index) => (
                                            <button
                                                key={index}
                                                type="button"
                                                className={`w-full fl-tab-link2 min-w-fit  !px-1 xxl:!text-xs xl:!text-[11px] xls:!text-[10px] !text-[11px] lg:min-h-[34px] min-h-[34px] flex justify-center items-center lg:py-1 py-2 xl:px-4 lg:px-4 sm:px-3  ${
                                                    activeFilter === tab.value ? 'active !bg-gray-100' : ''
                                                }`}
                                                onClick={handleFilterTab(tab.value)}
                                            >
                                                {tab.name}
                                            </button>
                                        ))}
                                    </div>
                                    <div className="2xl:hidden w-full">
                                        <DateRangePicker
                                            isDisplayAsBox={false}
                                            handleDatePickerChange={handleDatePickerChange}
                                            selectedTimeRange={selectedTimeRange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            allTimeStartDate={shop.created_at}
                                            showYearDropdown={false}
                                            isToday={activeFilter === 'daily'}
                                            isShowDropDownIcon={false}
                                            containerClassName="left-0 "
                                            parentClassName="sales-datepicker w-full"
                                            setStartDate={setStartDate}
                                            setEndDate={setEndDate}
                                            activeFilter={activeFilter}
                                        />
                                    </div>
                                </div>
                                <div className="max-2xl:hidden">
                                    <DateRangePicker
                                        isDisplayAsBox={false}
                                        handleDatePickerChange={handleDatePickerChange}
                                        selectedTimeRange={selectedTimeRange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        allTimeStartDate={shop.created_at}
                                        showYearDropdown={false}
                                        isToday={activeFilter === 'daily'}
                                        isShowDropDownIcon={false}
                                        containerClassName="left-0 "
                                        parentClassName="sales-datepicker w-full"
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                        activeFilter={activeFilter}
                                    />
                                </div>
                                <div className="w-full max-2xl:hidden"></div>
                                <div className="w-full flex flex-row gap-3 col-span-1 justify-end">
                                    <div className="xxl:w-[calc(50%_-_8px)] xl:w-[calc(50%_-_6px)] sms:w-[calc(50%_-_6px)] w-full">
                                        <div className="w-full">
                                            <SelectBox
                                                options={locationOptions}
                                                value={selectedLocation}
                                                noOptionsMessage="No Locations Found"
                                                placeholder={t('All locations')}
                                                onChangeFunc={handleLocation}
                                                classComp=""
                                                isClearable={false}
                                                isSearchable={false}
                                            />
                                        </div>
                                    </div>
                                    {isOwner && (
                                        <div className="xxl:w-[calc(50%_-_8px)] xl:w-[calc(50%_-_6px)] sms:w-[calc(50%_-_6px)] w-full">
                                            <div className="w-full">
                                                <SelectBox
                                                    options={staffOptions}
                                                    noOptionsMessage="No Team Found"
                                                    value={selectedTeam}
                                                    placeholder={t('All Team members')}
                                                    onChangeFunc={handleTeam}
                                                    classComp="outline-select-box"
                                                    isClearable={false}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mb-3 xxl:mb-4 max-2xl:grid-cols-2 grid grid-cols-4 gap-3 xxl:gap-4 ">
                                <div onClick={handleGraphOptionChange(graphOptions.find((option) => option.value === 'sales'))} className="active">
                                    <KpiCard
                                        headerTitle={t('Total Sales')}
                                        contentLable={`${dashboardDetails?.total?.amount}`}
                                        growth={dashboardDetails?.total?.amount_growth}
                                        fromDate={boxLabel}
                                        signIcon={'$'}
                                        growthDescription={dashboardDetails.total?.amount_projected ? `$${dashboardDetails.total?.amount_projected} ${t('Projected')} ` : '$0 Projected'}
                                        isActive={selectedGraphOption.value === 'sales'}
                                        isLoading={isLoading}
                                    />
                                </div>

                                <div onClick={handleGraphOptionChange(graphOptions.find((option) => option.value === 'bookings'))}>
                                    <KpiCard
                                        headerTitle={t('Total Appointments')}
                                        contentLable={`${dashboardDetails?.total?.bookings}`}
                                        growth={dashboardDetails?.total?.bookings_growth}
                                        fromDate={boxLabel}
                                        growthDescription={dashboardDetails.total?.bookings_projected ? `${dashboardDetails.total?.bookings_projected} ${t('Projected')} ` : '0 Projected'}
                                        isActive={selectedGraphOption.value === 'bookings'}
                                        isLoading={isLoading}
                                    />
                                </div>

                                <div onClick={handleGraphOptionChange(graphOptions.find((option) => option.value === 'active_users'))}>
                                    <KpiCard
                                        headerTitle={t('Total Active Clients')}
                                        contentLable={`${dashboardDetails?.total?.active_users}`}
                                        contentDescription="bookings"
                                        growth={dashboardDetails?.total?.active_users_growth}
                                        fromDate={boxLabel}
                                        isActive={selectedGraphOption.value === 'active_users'}
                                        isLoading={isLoading}
                                    />
                                </div>
                                <div onClick={handleGraphOptionChange(graphOptions.find((option) => option.value === 'retention_users'))}>
                                    <KpiCard
                                        headerTitle={t('Client Retention Rate')}
                                        contentLable={`${dashboardDetails?.total?.retention_rate}`}
                                        growth={dashboardDetails?.total?.retention_rate_growth}
                                        fromDate={dashboardDetails?.from_date}
                                        signIcon={'%'}
                                        isActive={selectedGraphOption.value === 'retention_users'}
                                        isLoading={isLoading}
                                    />
                                </div>
                            </div>

                            <div className="border border-gray-200 rounded-xl h-[400px] flex justify-center items-center shadow-default mb-3 xxl:mb-4  ">
                                {/* <h3 className="text-xl font-bold -tracking-[0.4px]">{t('Sales Analytics')}</h3> */}
                                {isLoading ? (
                                    <SChart />
                                ) : (
                                    <AmChart graph={dashboardDetails.graph || []} selectedGraphOption={selectedGraphOption} activeFilter={activeFilter} startDate={startDate} endDate={endDate} />
                                )}
                            </div>
                            <div className=" grid 2xl:grid-cols-3 gap-3 2xl:gap-5 mb-3 xxl:mb-4">
                                <div className=" flex flex-1">
                                    <UpcomingBooking handleAction={handleAction} />
                                </div>
                                <div className=" flex flex-1">
                                    <LatestCheckout handleAction={handleAction} />
                                </div>
                                <div className=" flex flex-1">
                                    <LatestBooking handleAction={handleAction} />
                                </div>
                            </div>
                            <Notification
                                show={action.notification}
                                notificationButtonRef={notificationButtonRef}
                                notificationCount={notificationCount}
                                handleClose={handleClose}
                                setNotificationCount={setNotificationCount}
                            />
                        </div>
                    </div>
                </div>
            )}
            {action.upcoming && <UpcomingBookingPopup handleClose={handleClose} />}
            {action.checkout && <LatestCheckoutPopup handleClose={handleClose} />}
            {action.latest && <LatestBookingPopup handleClose={handleClose} />}
        </div>
    );
};

export default Dashboard;
