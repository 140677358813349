import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { changeRole, currentRole, currentShop, userMe } from 'src/redux/services/common/Common.slice';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IPinVerificationFormData, IPinVerificationProps } from './ReceptionistMode.interface';
import { PasscodeLock } from '@untitled-ui/icons-react/build/cjs';
import PopupModal from 'src/components/PopupModal';
import InputOtpFiled from 'src/components/InputOtp/InputOtpFiled';
import { ROUTES } from 'src/constants/routes';
import { ROLES } from 'src/constants/common';
type FieldKey = 'pin';

const PinVerification = ({ handleReceptionistMode, handleClose }: IPinVerificationProps) => {
    const shop = useAppSelector(currentShop);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(userMe);
    const role = useAppSelector(currentRole);
    const isReceptionistMode = role.name === ROLES.RECEPTIONIST;
    // const role = useAppSelector(selectRoleInfo);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleEnableAndDisableRole = () => {
        const allRoles = user.roles.filter((item: any) => item.pivot.shop_id === shop.id);
        const receptionistRole = allRoles.find((item: any) => item.name === ROLES.RECEPTIONIST);
        const exceptReceptionistRoles = allRoles.filter((item: any) => item.name !== ROLES.RECEPTIONIST);
        const selectedRole = isReceptionistMode ? exceptReceptionistRoles[0] : receptionistRole;
        dispatch(changeRole(selectedRole));
        setTimeout(() => {
            window.location.href = ROUTES.DASHBOARD;
        }, 200);
    };
    const handleChange = async (data: any) => {
        setIsLoading(true);
        const payload = {
            ...data,
            pin: data.pin ?? '',
        };
        try {
            const response = await axiosPost(API.RECEPTIONIST_MODE.PIN_VERIFY, payload, {
                shop_id: shop.id,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                handleEnableAndDisableRole();
                return;
            }

            throw response.data;
        } catch (err: any) {
            if (err.status === errorCode.unprocessable) {
                if (err.response.data.data) {
                    Object.keys(err.response.data.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: err.response.data.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };
    const schema = Yup.object({
        pin: Yup.string().length(4, t('pin must be exactly 4 number')).required(t('This field is a required')),
    }).required();

    const { handleSubmit, control, setError } = useForm<IPinVerificationFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            pin: '',
        },
    });

    const Title = () => (
        <div className="flex flex-row gap-3 justify-center">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <PasscodeLock className="text-gray-700" />
            </div>
        </div>
    );
    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            handleSubmit(handleChange);
        }
    };

    return (
        <>
            <PopupModal
                dismissible
                onClose={handleClose}
                size="w-[408px]"
                className="px-5 py-5"
                title={<Title />}
                view={2}
                fixedHeightClass="!px-5 !py-0"
                isLoading={isLoading}
                isDisabled={isLoading}
                primaryButton={t('Confirm')}
                acceptAction={handleSubmit(handleChange)}
            >
                <form onSubmit={handleSubmit(handleChange)}>
                    <div className="flex flex-wrap">
                        <Controller
                            name="pin"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <>
                                    <div className="w-full">
                                        <div className="flex flex-col gap-1 text-center pb-4">
                                            <h2 className="font-bold text-[18px] leading-[28px] text-center  ">
                                                {isReceptionistMode ? t('Disable receptionist Mode') : t('Enable Receptionist Mode')}
                                            </h2>
                                            <div className="w-full flex justify-center items-center">
                                                <div className="w-[70%] text-center ">
                                                    <p className="font-normal text-secondaryTxtColor text-sm">
                                                        {isReceptionistMode ? t('Enter 4-digit code to disable receptionist mode') : t('Enter 4-digit code to enable receptionist mode')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 mb-1.5 flex flex-col w-full">
                                            <InputOtpFiled inputClassName={'!w-[80px] !h-[80px] otp-input'} otp={value} setOtp={onChange} errors={error} handleKeyPress={handleKeyPress} mask />
                                            {error && <p className="text-error">{error.message}</p>}
                                        </div>
                                    </div>
                                    <Button
                                        type="button"
                                        onClick={() => handleReceptionistMode('forgotPin')}
                                        className={`ml-auto  leading-[140%] text-sm font-medium text-primary -tracking-[0.14px] mb-6`}
                                    >
                                        {t('Forgot PIN?')}
                                    </Button>
                                </>
                            )}
                        />
                    </div>
                </form>
            </PopupModal>
        </>
    );
};

export default PinVerification;
