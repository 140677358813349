import React, { FC } from 'react';
import { IInputOtp } from './InputOtp.interface';
import { InputOtp } from 'primereact/inputotp';

const InputOtpFiled: FC<IInputOtp> = ({ otp, setOtp, errors, inputClassName, handleKeyPress, mask = false }): JSX.Element => {
    const customInput = ({ events, props }: any) => (
        <input
            key={props.id}
            {...events}
            {...props}
            type={'text'}
            className={`w-[64px] h-[64px]  !border !rounded-xl  !border-borderGray !transition p-4 !text-4xl !text-black !font-medium text-center !focus:outline-0 !focus:ring-0 hover:border-primary ${inputClassName} ${
                errors ? '!is-invalid !border-red-500 !text-dangerText' : '!focus:ring-primary'
            }`}
            autoFocus={props.id === 0}
            autoComplete={mask ? 'new-password' : 'off'}
        />
    );

    return (
        <InputOtp
            value={otp}
            onChange={(e) => setOtp(e.value)}
            integerOnly
            className=""
            unstyled
            inputTemplate={customInput}
            pt={{
                root: { className: 'flex justify-between items-center' },
            }}
            mask={mask}
        />
    );
};

export default InputOtpFiled;
