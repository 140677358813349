import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, getAllShopCategory, getAllShopServices } from 'src/redux/services/common/Common.slice';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ExistingFormData, IFormData, ISelectBox } from './Services.interface';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { getSelectBoxOptions, onError } from 'src/utils/global-functions';
import { toast } from 'react-toastify';
import { errorCode } from 'src/constants/errorCode';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import { ArrowLeft, XClose } from '@untitled-ui/icons-react/build/cjs';
import Checkbox from 'src/components/Checkbox';
import TypeForm from './TypeForm';
import Switch from 'src/components/Switch/Switch';
import { allShopLocations } from 'src/redux/services/common/Common.slice';
import { IconCheckdActive } from 'src/theme/Images';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import { PATH } from 'src/constants/path';
import { useLanguage } from 'src/hooks/useCommon';
import RadioSwitch from 'src/components/RadioSwitch';
import { CHARACTERS_LIMIT } from 'src/constants/common';
import { useRolePermission } from 'src/hooks/useRolePermission';

const ServiceForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { languageOptions, languages, language, handleLanguage, setLanguage } = useLanguage();
    const { isIndividual } = useRolePermission();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const shopLocationList: any = useAppSelector(allShopLocations);
    const [categoryOptions, setCategoryOptions] = useState<ISelectBox[]>([]);
    const [locationIds, setLocationIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const SERVICE_OPTIONS = [
        { value: 'regular', label: t('Regular') },
        { value: 'variable', label: t('Variable') },
    ];
    const schemaFields: any = {
        shop_category_id: Yup.string().required(t('This field is required')),
        shop_location_id: Yup.array().min(1, t('This field is required')),
        type: Yup.string().required(t('This field is required')),
        is_active: Yup.boolean().required(t('This field is required')),
        duration: Yup.string().when('type', ([type], customSchema) => (isIndividual && type === 'regular' ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        price: Yup.number().when('type', ([type], customSchema) =>
            isIndividual && type === 'regular'
                ? customSchema.required(t('This field is required')).positive(t('Price must be a positive value')).typeError(t('Price must be a number'))
                : customSchema.nullable(),
        ),
        starting_price: Yup.boolean().when('type', ([type], customSchema) => (isIndividual && type === 'regular' ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        variables: Yup.array().when('type', {
            is: (val: string) => val === 'variable',
            then: (schm) => {
                let childFields: any = {};
                if (isIndividual) {
                    childFields = {
                        ...childFields,
                        duration: Yup.string().required(t('This field is required')),
                        price: Yup.number().required(t('This field is required')).positive(t('Price must be a positive value')).typeError(t('Price must be a number')),
                        starting_price: Yup.boolean().required(t('This field is required')),
                    };
                }
                languages.forEach((name: string) => {
                    childFields[`${name}_name`] = Yup.string()
                        .required(t('This field is required'))
                        .max(CHARACTERS_LIMIT.SHORT, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.SHORT }));
                    childFields[`${name}_description`] = Yup.string()
                        .required(t('This field is required'))
                        .max(CHARACTERS_LIMIT.LONG, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.LONG }));
                });
                return schm.of(Yup.object(childFields));
            },
            otherwise: (schm) => schm.notRequired(),
        }),
    };
    languages.forEach((name: string) => {
        schemaFields[`${name}_name`] = Yup.string()
            .required(t('This field is required'))
            .max(CHARACTERS_LIMIT.SHORT, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.SHORT }));
        schemaFields[`${name}_description`] = Yup.string().when('type', {
            is: (val: string) => val !== 'variable',
            then: (schm) => schm.required(t('This field is required')).max(CHARACTERS_LIMIT.LONG, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.LONG })),
            otherwise: (schm) => schm.notRequired(),
        });
    });
    const schema = Yup.object(schemaFields);

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as unknown as Resolver<IFormData>,
        defaultValues: {
            en_name: '',
            fr_name: '',
            en_description: '',
            fr_description: '',
            type: '',
            shop_category_id: '',
            is_active: true,
            shop_location_id: [],
            duration: null,
            price: undefined,
            starting_price: false,
            variables: [{ en_name: '', en_description: '', fr_name: '', fr_description: '', duration: null, price: undefined, starting_price: false }],
        },
    });

    const {
        handleSubmit,
        setValue,
        setError,
        control,
        formState: { errors },
    } = methods;

    useFormErrorFocus<IFormData>({ errors });

    useEffect(() => {
        const ids = shopLocationList.map((item: any) => item.id);
        setLocationIds(ids);
        // getCategory();
    }, []);
    useEffect(() => {
        getCategory();
    }, [language]);

    useEffect(() => {
        if (id) {
            getService();
        }
    }, [id]);
    const handleErrorsAndSetLanguage = useCallback(
        (err: any) => {
            const currentLanguageErrors = Object.keys(err).some((key) => key.startsWith(`${language}_`));
            if (currentLanguageErrors) {
                return;
            }
            const newLanguage = languages.find((lan) => Object.keys(err).some((key) => key.startsWith(`${lan}_`)));

            if (newLanguage && newLanguage !== language) {
                setLanguage(newLanguage);
            }
        },
        [errors, language, languages, setLanguage],
    );
    const handleError = (err: any) => handleErrorsAndSetLanguage(err);

    const getService = () => {
        setIsLoading(true);
        axiosGet(API.SERVICE.GET, { shop_id: shop.id, id })
            .then((response) => {
                const data = response.data.data;
                const shopLocationIds = data.locations.map((item: any) => item.id);

                let existingData: ExistingFormData = {
                    type: data.type,
                    is_active: data.is_active,
                    shop_category_id: data.shop_category_id || '',
                    shop_location_id: shopLocationIds,
                    variables: [{ en_name: '', en_description: '', fr_name: '', fr_description: '' }],
                };

                if (data.price) {
                    existingData = {
                        ...existingData,
                        duration: data.price.duration || null,
                        price: data.price.price || undefined,
                        starting_price: data.price.starting_price || false,
                    };
                }

                if (data.translations.length) {
                    data.translations.forEach((translation: any) => {
                        existingData[`${translation.locale}_name`] = translation.name || '';
                        existingData[`${translation.locale}_description`] = translation.description || '';
                    });
                } else {
                    existingData[`${language}_name`] = data.name || '';
                    existingData[`${language}_description`] = data.description || '';
                }

                if (data.variables.length) {
                    existingData.variables = data.variables.map((variable: any) => {
                        let newVariable: any = {
                            id: variable.id,
                        };
                        if (variable.price) {
                            newVariable = {
                                ...newVariable,
                                duration: variable.price.duration || null,
                                price: variable.price.price || undefined,
                                starting_price: variable.price.starting_price || false,
                            };
                        }
                        if (variable.translations.length) {
                            variable.translations.forEach((translation: any) => {
                                newVariable[`${translation.locale}_name`] = translation.name || '';
                                newVariable[`${translation.locale}_description`] = translation.description || '';
                            });
                        } else {
                            newVariable[`${language}_name`] = variable.name || '';
                            newVariable[`${language}_description`] = variable.description || '';
                        }
                        return newVariable;
                    });
                }
                Object.keys(existingData).forEach((key) => {
                    setValue(key as keyof IFormData, existingData[key]);
                });
            })
            .catch(() => navigate(PATH.SERVICES.LIST))
            .finally(() => setIsLoading(false));
    };

    const getCategory = async () => {
        const result = await dispatch(getAllShopCategory({ shop_id: shop.id }));
        if (result.type === getAllShopCategory.fulfilled.toString()) {
            const options: ISelectBox[] | [] = getSelectBoxOptions(result.payload.data, 'id');
            setCategoryOptions(options);
        }
    };

    const handleSave = (data: IFormData) => {
        setIsLoading(true);

        const payload = {
            ...data,
        };

        if (data.type === 'regular') {
            delete payload.variables;
        } else {
            delete payload.en_description;
            delete payload.fr_description;
            delete payload.duration;
            delete payload.price;
            delete payload.starting_price;
        }

        if (id) {
            axiosPatch(API.SERVICE.UPDATE, payload, { shop_id: shop.id, id })
                .then(async () => {
                    await dispatch(getAllShopServices({ shop_id: shop.id }));
                    navigate(PATH.SERVICES.LIST);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        } else {
            axiosPost(API.SERVICE.CREATE, payload, { shop_id: shop.id })
                .then(async () => {
                    await dispatch(getAllShopServices({ shop_id: shop.id }));
                    navigate(PATH.SERVICES.LIST);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const resetForm = (event: any) => {
        navigate(PATH.SERVICES.LIST);
        /* event.preventDefault();
        reset(); */
    };

    const handleLocationChange = (selectedId: number, onChange: (value: number[]) => void, value: number[]) => () => {
        const newChecked = value.includes(selectedId)
            ? value.filter((currentId) => currentId !== selectedId) // Remove unchecked item
            : [...value, selectedId]; // Add checked item

        onChange(newChecked);
    };
    return (
        <div className="inner-page-wrape !px-0">
            <div className="flex-1">
                <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                    <Link to={PATH.SERVICES.LIST} className="flex items-center cursor-pointer text-gray-600 h-[36px] text-xs font-semibold gap-1.5 group group-hover:text-gray-900">
                        <ArrowLeft className="w-4 cursor-pointer text-gray-600 group-hover:text-gray-900" /> <div className="text-gray-600 group-hover:text-gray-900">{t('Back to Service')}</div>
                    </Link>
                    <Link to={PATH.SERVICES.LIST} className="close-page-btn">
                        <XClose className="xl:w-5 xl:h-5 w-4 h-4 text-gray-900" />
                    </Link>
                </div>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(handleSave, handleError)}>
                        <div className="relative  w-full xlm:px-8 px-5 pt-4 pb-4 flex sms:gap-4 gap-2 items-center">
                            <div className="flex flex-col flex-1 ">
                                <div className="line-clamp-1 xlm:text-3xl sm:text-xl text-base">
                                    <h1 className="mr-3 text-gray-900 xlm:text-3xl sm:text-[26px] text-base inline align-middle font-semibold tracking-[-0.72px]">
                                        {id ? t('Edit Service') : t('Add a New Service')}
                                    </h1>
                                </div>
                            </div>
                            <div className="flex gap-3">
                                <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading}>
                                    {t('Cancel')}
                                </CustomButton>
                                <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                    {t('Save Changes')}
                                </CustomButton>
                            </div>
                        </div>
                        <div className="flex flex-col xlm:px-8 px-5 border-t">
                            <div className="flex gap-5 py-6 border-b border-gray-200">
                                <div className="title-block w-[280px]">
                                    <h3 className="font-semibold text-sm text-gray-700">{t('Service details')}</h3>
                                    <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal ">{t('Describe the general details of your service.')}</p>
                                </div>
                                <div className="data-wrap-block w-full max-w-[700px] flex flex-col gap-4">
                                    {languages.length > 1 && (
                                        <div className="text-left flex justify-end">
                                            <RadioSwitch options={languageOptions} name="language" value={language} onChange={handleLanguage} />
                                        </div>
                                    )}
                                    <div className="flex gap-4">
                                        <Controller
                                            key={language}
                                            name={`${language}_name`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="flex-1">
                                                    <InputWithLabel
                                                        label={t('Service Name')}
                                                        required
                                                        placeholder={t('Enter service name')}
                                                        onChange={onChange}
                                                        value={value}
                                                        name={`${language}_name`}
                                                        id={`${language}_name`}
                                                        error={!!error}
                                                    />
                                                    {error && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name="shop_category_id"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="flex-1">
                                                    <SelectBox
                                                        name="shop_category_id"
                                                        label={t('Select Service Category')}
                                                        required
                                                        placeholder={t('Select Service Category')}
                                                        options={categoryOptions}
                                                        id="shop_category_id"
                                                        onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                        value={categoryOptions.find((option) => option.value === value)}
                                                        error={!!error}
                                                    />
                                                    {error && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name="type"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="flex-1">
                                                    <SelectBox
                                                        name="type"
                                                        label={t('Service Type')}
                                                        required
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        id="type"
                                                        placeholder={t('Select service type')}
                                                        options={SERVICE_OPTIONS}
                                                        onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                        value={SERVICE_OPTIONS.find((option) => option.value === value)}
                                                        error={!!error}
                                                    />
                                                    {error && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <TypeForm key={language} language={language} />
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-5 py-6 border-b border-gray-200">
                                <div className="title-block w-[280px]">
                                    <h3 className="font-semibold text-sm text-gray-700">
                                        {t('Service locations')}
                                        <span className="asterisk">*</span>
                                    </h3>
                                    <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal ">{t('Select the location where the service will be offered.')}</p>
                                </div>
                                <div className="data-wrap-block w-[calc(100%-280px)]">
                                    <Controller
                                        name="shop_location_id"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <div id="shop_location_id">
                                                <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
                                                    <Switch
                                                        onChange={(e: any) => {
                                                            const isChecked = e.value;
                                                            if (isChecked) {
                                                                onChange([...locationIds]);
                                                            } else {
                                                                onChange([]);
                                                            }
                                                        }}
                                                        isChecked={locationIds.length === value.length && locationIds.every((locationId) => value.includes(locationId))}
                                                    />
                                                    <span>{t('All locations')}</span>
                                                </div>
                                                <div className="gap-5 mt-5 grid grid-cols-3 xxl:grid-cols-4">
                                                    {shopLocationList.map((location: any) => (
                                                        <label
                                                            className={`border rounded-xl p-4 cursor-pointer ${value.includes(location.id) ? 'border-primary' : ''} w-full relative ${
                                                                error ? 'is-invalid' : ''
                                                            }`}
                                                            key={location.id}
                                                            htmlFor={`location_${location.id}`}
                                                        >
                                                            {''}
                                                            <img src={location.profile_image_url} alt="" className="h-[200px] w-full rounded-xl object-cover" onError={onError} />
                                                            <div className="text-wrap mt-4">
                                                                <h2 className="text-base font-semibold capitalize-first">{location.name}</h2>
                                                                <p className="text-sm font-normal text-secondaryTxtColor mt-2">{`${location.street}, ${location.city}, ${location.country}`}</p>
                                                                <div className="d-none">
                                                                    <Checkbox
                                                                        id={`location_${location.id}`}
                                                                        name="shop_location_id"
                                                                        value={location.id} //{Number(location.id)}
                                                                        checked={value ? value.includes(location.id) : false}
                                                                        onChange={handleLocationChange(location.id, onChange, value)}
                                                                    />
                                                                </div>
                                                                <div className={`absolute -top-[7px] -right-[7px] ${value.includes(location.id) ? 'block' : 'hidden'}`}>
                                                                    <i className="icon">
                                                                        <img src={IconCheckdActive} alt="" className="" />
                                                                    </i>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    ))}
                                                </div>
                                                {/* {error && error.type !== 'required' && <p className="text-error">{error.message}</p>} */}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-5 items-center  py-6 border-b border-gray-200">
                                <div className="title-block w-[280px]">
                                    <h3 className="font-semibold text-sm text-gray-700">{t('Service listing')}</h3>
                                    <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal">{t('Toggle to update service visibility in your online booking portal.')}</p>
                                </div>
                                <div className="data-wrap-block">
                                    <div className="flex flex-col gap-5">
                                        <Controller
                                            name="is_active"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div>
                                                    <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
                                                        <Switch onChange={onChange} isChecked={value} />
                                                        <span>{t('List service')}</span>
                                                    </div>
                                                    {error && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-3 justify-end mb-36 pt-5">
                                <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading}>
                                    {t('Cancel')}
                                </CustomButton>
                                <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                    {t('Save Changes')}
                                </CustomButton>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default ServiceForm;
