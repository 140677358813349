export type LogoType = 'logo' | 'logo_text' | 'text';

export interface IFormData {
    logo_type: LogoType;
    name?: string;
    logo_font_family?: string;
    font_weight?: number;
    font_size?: number;
    logo_height?: number;
}

export interface LogoDisplaySettingsProps {
    setFormDetails: React.Dispatch<any>;
    displayType: 'logo' | 'logo_text' | 'text';
}

export const FontSizeOptions = [
    { label: '16px', value: 16 },
    { label: '18px', value: 18 },
    { label: '20px', value: 20 },
    { label: '22px', value: 22 },
    { label: '24px', value: 24 },
    { label: '26px', value: 26 },
    { label: '28px', value: 28 },
    { label: '30px', value: 30 },
];
export const LogoHeightOptions = [
    { label: 'XS', value: 40 },
    { label: 'S', value: 42 },
    { label: 'M', value: 44 },
    { label: 'L', value: 46 },
    { label: 'XL', value: 48 },
    { label: 'XXL', value: 50 },
];
