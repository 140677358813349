import React, { useCallback, useEffect, useState } from 'react';
import { InfoCircle, PlusCircle } from '@untitled-ui/icons-react/build/cjs';
import KpiCard from 'src/components/KpiCard';
import { formatAmount, formatKpiContentLable } from 'src/utils/global-functions';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import DateRangePicker from 'src/components/DateRangePicker/DateRangePicker';
import moment from 'moment';
import AddExpense from './AddExpense';
import { IAdvAnalytics, IAdvAnalyticsDetails, IExpenseGraphData, initAction } from './Analytics.interface';
import AdvAnalyticsChartPie from './AdvAnalyticsChartPie';
import Badge from 'src/components/Badge';

const TimeRangesOption = [
    { label: 'All Time', value: 'all_time', key: 'all_time' },
    { label: 'Last Year', value: 'last_year', key: 'last_year' },
    { label: 'Yesterday', value: 'yesterday', key: 'yesterday' },
    { label: 'Last Quarter', value: 'last_quarter', key: 'last quarter' },
    { label: 'Last Month', value: 'last_month', key: 'last month' },
    { label: 'Last Week', value: 'last_week', key: 'last week' },
    { label: 'This Week', value: 'this_week', key: 'this week' },
    { label: 'This Month', value: 'this_month', key: 'this month' },
    { label: 'This Year', value: 'this_year', key: 'this year' },
    { label: 'Custom', value: 'custom', key: 'custom' },
];

const AdvAnalytics = ({ selectedFilter }: IAdvAnalytics) => {
    const shop = useAppSelector(currentShop);
    const [currentTab, setCurrentTab] = useState('daily');
    const [startDate, setStartDate] = useState<Date | undefined>(moment().toDate());
    const [endDate, setEndDate] = useState<Date | undefined>(moment().toDate());
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>('custom');
    const [isLoading, setIsLoading] = useState(false);
    const [advAnalyticsDetails, setAdvAnalyticsDetails] = useState<IAdvAnalyticsDetails | null>(null);
    const [boxLabel, setBoxLabel] = useState('Yesterday');
    const [action, setAction] = useState<any>(initAction);
    const [graph, setGraph] = useState<IExpenseGraphData[]>([]);
    const [label, setLabel] = useState<string>('Custom');
    const [income, setIncome] = useState(0);

    useEffect(() => {
        if (startDate && endDate) {
            if (selectedTimeRange === 'all_time') {
                setBoxLabel('Previous period');
            } else if (selectedTimeRange === 'yesterday') {
                setBoxLabel('Previous');
            } else if (selectedTimeRange === 'quarterly') {
                setBoxLabel('last quarter');
            } else if (selectedTimeRange === 'last_quarter') {
                setBoxLabel('Previous quarter');
            } else if (selectedTimeRange === 'last_year' || selectedTimeRange === 'this_year') {
                setBoxLabel('Previous year');
            } else if (selectedTimeRange === 'last_month' || selectedTimeRange === 'this_month') {
                setBoxLabel('Previous month');
            } else if (selectedTimeRange === 'last_week' || selectedTimeRange === 'this_week') {
                setBoxLabel('Previous week');
            } else if (currentTab === '') {
                let days = moment(endDate).diff(moment(startDate), 'days');
                days = selectedTimeRange === 'last_30_days' || selectedTimeRange === 'last_90_days' || selectedTimeRange === 'last_7_days' || selectedTimeRange === 'custom' ? days + 1 : days;

                setBoxLabel(`Previous ${days} days`);
            }
        }
    }, [startDate, endDate]);

    const extractTotals = (total: any) => {
        const amount = parseFloat(total.amount) || 0;
        const commission = parseFloat(total.commission) || 0;
        const expense = parseFloat(total.expense) || 0;
        const rent = parseFloat(total.rent) || 0;

        const totalIncome = amount + commission;
        const totalExpenses = expense + rent;
        const netIncome = totalIncome - totalExpenses;

        setIncome(netIncome);

        const resultArray = [
            { name: 'Total Income', value: +totalIncome.toFixed(2) },
            { name: 'Total Expenses', value: +totalExpenses.toFixed(2) },
            { name: 'Net Income', value: Math.abs(+netIncome.toFixed(2)) },
        ];

        return resultArray;
    };

    const fetchAnalytics = async () => {
        setIsLoading(true);
        const payload = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            type: 'custom',
            ...(selectedFilter.location_id !== null && { location_id: selectedFilter.location_id }),
            ...(selectedFilter.staff_id !== null && { staff_id: selectedFilter.staff_id }),
            is_advance: true,
        };
        await getAnalytics(payload);
    };
    useEffect(() => {
        fetchAnalytics();
    }, [selectedFilter, startDate, endDate]);

    const getAnalytics = async (payload: any) => {
        await axiosGet(API.ANALYTICS.GET, { shop_id: shop.id }, payload)
            .then(async (response) => {
                const data = response.data.data;
                setGraph(extractTotals(data.total));
                setAdvAnalyticsDetails(data);
            })
            .finally(() => setIsLoading(false));
    };

    const handleDatePickerChange = (date: any, timeRange?: string, labelVal?: string) => {
        if (timeRange === 'weekly' || timeRange === 'monthly' || timeRange === 'yearly') {
            setCurrentTab(timeRange);
        } else {
            setCurrentTab('');
            const [start, end] = date;

            setStartDate(start);
            setEndDate(end);
            if (!end) {
                setEndDate(start);
            }
        }
        if (timeRange) {
            setSelectedTimeRange(timeRange);
        }
        if (labelVal) {
            setLabel(labelVal);
        }
    };

    const handleExpenseAdd = (actionType: string) => () => {
        setAction((old: any) => ({ ...old, [actionType]: true }));
    };

    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);

    return (
        <>
            <div className=" border border-borderSecondary rounded-l-xl w-[75%] max-2xl:w-[75%]">
                <div className=" px-5 py-4 flex flex-row gap-2 items-center border-b border-borderSecondary">
                    <div className="font-semibold text-xl text-mainTextColor  max-2xl:text-[18px] leading-[30px]">Advanced Analytics</div>
                    <div className="pt-[2px]">
                        <Badge icon>{label}</Badge>
                    </div>
                    <div className="ml-auto">
                        <DateRangePicker
                            isDisplayAsBox={false}
                            handleDatePickerChange={handleDatePickerChange}
                            selectedTimeRange={selectedTimeRange}
                            startDate={startDate}
                            endDate={endDate}
                            allTimeStartDate={shop.created_at}
                            showYearDropdown={false}
                            isToday={currentTab === 'daily'}
                            isShowDropDownIcon={false}
                            containerClassName="right-0 bottom-10"
                            parentClassName="sales-datepicker w-[280px] min-w-[280px] xlm:w-[310px] xlm:min-w-[310px] xxl:w-[340px] xxl:min-w-[340px]"
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            activeFilter={currentTab}
                            options={TimeRangesOption}
                        />
                    </div>
                </div>
                <div className="p-5 grid max-2xl:grid-cols-2 grid-cols-4 gap-3 xxl:gap-5">
                    <KpiCard
                        headerTitle="Total Sales"
                        contentLable={`${formatKpiContentLable(advAnalyticsDetails?.total?.amount)}`}
                        signIcon={'$'}
                        growth={advAnalyticsDetails?.total?.amount_growth}
                        fromDate={boxLabel}
                        isLoading={isLoading}
                    />
                    <KpiCard
                        headerTitle="Rent Collection"
                        contentLable={`${formatKpiContentLable(advAnalyticsDetails?.total?.rent)}`}
                        signIcon={'$'}
                        growth={advAnalyticsDetails?.total?.rent_growth}
                        fromDate={boxLabel}
                        isLoading={isLoading}
                    />
                    <KpiCard
                        headerTitle="Comm. Payouts"
                        contentLable={`${formatKpiContentLable(advAnalyticsDetails?.total?.commission)}`}
                        signIcon={'$'}
                        growth={advAnalyticsDetails?.total?.commission_growth}
                        fromDate={boxLabel}
                        isLoading={isLoading}
                    />
                    {Number(advAnalyticsDetails?.total?.expense) > 0 ? (
                        <div onClick={handleExpenseAdd('expense')}>
                            <KpiCard
                                headerTitle="Projected Total Expe."
                                contentLable={`${formatKpiContentLable(advAnalyticsDetails?.total?.expense)}`}
                                signIcon={'$'}
                                growth={advAnalyticsDetails?.total?.expense_growth}
                                isAnalytics={true}
                                isEdit={true}
                                fromDate={boxLabel}
                                isLoading={isLoading}
                            />
                        </div>
                    ) : (
                        <div
                            onClick={handleExpenseAdd('expense')}
                            className="border border-[#5279F0] bg-[#F0F3FF] shadow rounded-lg p-4 flex flex-col justify-center items-center relative cursor-pointer"
                        >
                            <div className=" absolute top-[15px] right-[15px] ">
                                <InfoCircle className="w-5 h-5 text-[#475467] " />
                            </div>
                            <div className="flex flex-row gap-1.5 items-center justify-center">
                                <PlusCircle className="w-5 h-5 text-[#5279F0]" />
                                <p className="text-[#5279F0] font-semibold text-base">Add Expenses</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="w-[25%] max-2xl:w-[25%] border border-borderSecondary border-l-0 rounded-r-xl ">
                <div className=" px-4 py-4 flex flex-row justify-between items-center border-dotted border-b-2 border-borderSecondary">
                    <div className="flex flex-row gap-1 items-center">
                        <div className="font-semibold text-xl text-mainTextColor  max-2xl:text-[18px] leading-[35px]">Net Income</div>
                    </div>
                    <div className="font-semibold text-xl max-xlm:text-sm max-2xl:text-[16px] max-2xl:leading-[30px] 2xl:text-sm text-mainTextColor leading-[30px]">{formatAmount(income)}</div>
                </div>
                {Number(advAnalyticsDetails?.total?.expense) > 0 ? (
                    <div className="flex flex-col 2xl:flex-row px-4 py-[21px] items-center gap-3  xxl:gap-5">
                        <div className="">
                            <AdvAnalyticsChartPie data={graph} />
                        </div>
                        <div className=" flex flex-col h-[116px] justify-between w-full">
                            <h4 className="flex flex-row justify-between items-center">
                                <span className="font-normal text-secondaryTxtColor text-xs leading-[18px]"> Total Sales</span>{' '}
                                <span className={`${isLoading ? 'custom-loading' : 'text-[#079455] font-medium text-xs xxl:text-sm  xxl:w-[84px]'}`}>
                                    + ${advAnalyticsDetails && advAnalyticsDetails.total.amount}
                                </span>
                            </h4>
                            <h4 className="flex flex-row justify-between items-center">
                                <span className="font-normal text-secondaryTxtColor text-xs leading-[18px]"> Rent Collection </span>
                                <span className={`${isLoading ? 'custom-loading' : 'text-[#079455] font-medium text-xs xxl:text-sm  xxl:w-[84px]'}`}>
                                    + ${advAnalyticsDetails && advAnalyticsDetails.total.rent}
                                </span>
                            </h4>
                            <h4 className="flex flex-row justify-between items-center">
                                <span className="font-normal text-secondaryTxtColor text-xs leading-[18px]">Comm. Payouts</span>
                                <span className={`${isLoading ? 'custom-loading' : 'text-[#D92D20] font-medium text-xs xxl:text-sm  xxl:w-[84px]'}`}>
                                    - ${advAnalyticsDetails && advAnalyticsDetails.total.commission}
                                </span>
                            </h4>
                            <h4 className="flex flex-row justify-between items-center">
                                <span className="font-normal text-secondaryTxtColor text-xs leading-[18px]">Total Expenses</span>
                                <span className={`${isLoading ? 'custom-loading' : 'text-[#D92D20] font-medium text-xs xxl:text-sm  xxl:w-[84px]'}`}>
                                    - ${advAnalyticsDetails && advAnalyticsDetails.total.expense}
                                </span>
                            </h4>
                        </div>
                    </div>
                ) : (
                    <div className="p-5 flex flex-1 max-2xl:h-[320px] h-[155px] xxl:h-[180px] flex-col justify-center items-center">
                        <div className="flex flex-col">
                            <div className=" text-mainTextColor text-center  font-semibold  text-sm"> No Expenses found</div>
                            <div className=" text-secondaryTxtColor text-center  font-normal semibold text-sm">Add expenses to calculate Net Income</div>
                        </div>
                    </div>
                )}
            </div>
            {action.expense && <AddExpense handleClose={handleModalClose} fetchAnalytics={fetchAnalytics} />}
        </>
    );
};

export default AdvAnalytics;
