import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NoDataMessage from 'src/components/NoDataMessage';
import SearchBar from 'src/components/SearchBar/SearchBar';
import { ROUTES } from 'src/constants/routes';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CustomButton from 'src/components/CustomButton';
import { Edit01, Trash01, ArrowUp, ArrowDown } from '@untitled-ui/icons-react/build/cjs';
import { useNavigate } from 'react-router-dom';
import { initAction } from './Category.interface';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import { axiosDelete, axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { PATH } from 'src/constants/path';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import { Skeleton } from 'primereact/skeleton';
import { PaginatorTemplate } from 'src/utils/global-component';
import Badge from 'src/components/Badge';
import { useLanguage } from 'src/hooks/useCommon';
import RadioSwitch from 'src/components/RadioSwitch';

const CategoryTable = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [categoryListArray, setCategoryListArray] = useState([]);
    const [totalCategorys, setTotalCategorys] = useState<number>(0);
    const { languageOptions, languages, language, handleLanguage } = useLanguage();

    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
        },
    });
    const [expandedRows, setExpandedRows] = useState({});
    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };
    const [action, setAction] = useState(initAction);

    const getCategory = async () => {
        setIsLoading(true);
        await axiosGet(API.PRODUCT_CATEGORY.LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setCategoryListArray(response.data.data.data);
                setTotalCategorys(response.data.data.totalRecords);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getCategory();
    }, [lazyState, language]);

    const handleAction =
        (row: any, type: string = '') =>
        () => {
            if (!type) {
                navigate(`${PATH.PRODUCT.CATEGORY.UPDATE}/${row.id}`);
            } else {
                setAction((old) => ({ ...old, [type]: true, id: row.id }));
            }
        };

    const allowExpansion = (row: any) => row.sub_categories.length > 0;

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const onRowToggle = (event: any) => {
        setExpandedRows(event.data);
    };

    const handleClick = (path: string) => () => {
        navigate(path);
    };

    const rowExpansionTemplate = (data: any) => (
        <DataTable value={data.sub_categories} showHeaders={false}>
            <Column expander={false} style={{ width: '20px', minWidth: '20px', maxWidth: '20px', paddingRight: '0px' }} />
            <Column field="name" className="text-xs font-medium text-mainTextColor" style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}></Column>
            <Column field="description" className="text-xs font-medium" style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}></Column>
            <Column expander={false} style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }} />
        </DataTable>
    );

    const getAction = (row: any) => (
        <div className="flex items-center gap-4 justify-end">
            <CustomButton className="shadow-none w-4 !px-0 !pr-[4px]" onClick={handleAction(row)}>
                <Edit01 className="h-4 w-4 text-gray-600" />
            </CustomButton>
            <CustomButton className="shadow-none w-4 !px-0" onClick={handleAction(row, 'delete')}>
                <Trash01 className="h-4 w-4 text-gray-600" />
            </CustomButton>
        </div>
    );

    const handleModalClose = useCallback(
        (status: boolean = false) =>
            async () => {
                if (status) {
                    setIsLoading(true);
                    const params = {
                        shop_id: shop.id,
                        id: action.id,
                    };
                    await axiosDelete(API.PRODUCT_CATEGORY.DELETE, {}, params)
                        .then(getCategory)
                        .finally(() => setIsLoading(false));
                }
                setAction(initAction);
            },
        [action],
    );

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                name: { pay_structure_type: 'loading' },
                description: 'loading',
                action: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };
    return (
        <div
            className={`w-full border flex flex-1 flex-col border-gray-200 rounded-xl xxl:mt-4 mt-3 staff_service_table datatable-custom-service ${
                categoryListArray.length === 0 ? 'datatable-full-height datatable-noshow' : ''
            }`}
        >
            <div className={`flex items-center flex-col `}>
                <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                    <div className="flex flex-col flex-1">
                        <div className="flex justify-start items-center gap-1.5">
                            <h2 className="table-title">{t('All product categories')}</h2>
                            <Badge icon isLoading={isLoading}>
                                {totalCategorys} {t('Product Categories')}
                            </Badge>
                        </div>
                        <p className="table-subtitle">{t('Add, view and manage your product categories.')}</p>
                    </div>
                    <div className="flex relative gap-3 xl:gap-4">
                        <CustomButton outlinePrimary onClick={handleClick(ROUTES.PRODUCT.CATEGORY.CREATE)} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                            {t('Add New Product Category')}
                        </CustomButton>
                    </div>
                </div>
                <div className="flex items-center px-5 py-3 justify-between w-full">
                    <div className="table-searchInput">
                        <SearchBar placeholder={t('Search')} className="form-control-md" onChange={handleSearch} />
                    </div>
                    {languages.length > 1 && (
                        <div className="text-right">
                            <RadioSwitch options={languageOptions} name="language" value={language} onChange={handleLanguage} />
                        </div>
                    )}
                </div>
            </div>
            <DataTable
                lazy
                paginatorTemplate={PaginatorTemplate()}
                value={isLoading ? skeletons : categoryListArray}
                totalRecords={totalCategorys}
                paginator={!isLoading && totalCategorys > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                rowExpansionTemplate={rowExpansionTemplate}
                expandedRows={expandedRows}
                onRowToggle={onRowToggle}
                dataKey="id"
                className="border-t rounded-b-xl overflow-hidden"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <NoDataMessage
                        title={`${lazyState.filters.global.value ? 'No categories found' : 'No categories added.'}`}
                        description={`${lazyState.filters.global.value ? 'Try adjusting your filters or add new categories.' : 'Add a category, to start using the categories collection.'}`}
                    />
                }
            >
                <Column
                    expander={isLoading ? false : allowExpansion}
                    body={isLoading && <Skeleton />}
                    className="table-arrow-btn"
                    style={{ width: '20px', minWidth: '20px', maxWidth: '20px', paddingRight: '0px' }}
                />
                <Column
                    field="name"
                    header={renderHeader(t('Category'), 'name')}
                    body={isLoading ? <Skeleton /> : undefined}
                    className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                    style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
                    sortable
                ></Column>
                <Column
                    field="description"
                    header={renderHeader(t('Description'), 'description')}
                    className="text-xs leading-[18px] font-normal text-gray-600"
                    body={isLoading ? <Skeleton /> : undefined}
                    style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
                    sortable
                ></Column>
                <Column
                    field="action"
                    header={t('')}
                    className="text-xs justify-end font-medium "
                    style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                    body={isLoading ? <Skeleton /> : getAction}
                ></Column>
            </DataTable>
            {action.delete && <DeletePopupModal onClose={handleModalClose} size="w-[400px]" title={t('Delete category')} description={t('Are you sure you want to delete category?')} />}
        </div>
    );
};

export default CategoryTable;
