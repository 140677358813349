export interface IFormData {
    en_name?: string;
    en_description?: string;
    fr_name?: string;
    fr_description?: string;
    sub_categories: Array<{ en_name: string; en_description: string; fr_name: string; fr_description: string }>;
}

export interface ExistingFormData {
    [key: string]: any;
}

export const initAction = {
    id: null,
    delete: false,
};
