import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { loginSlice } from 'src/app/Auth/Login/Login.slice';
import { CountrySlice } from './services/country/Country.slice';
import { StateSlice } from './services/state/State.slice';
import { CitySlice } from './services/city/City.slice';
import { staffSlice } from 'src/app/Staff/Staff.slice';
import { CalendarSlice } from 'src/app/Calendar/Calendar.slice';
import { adminSlice } from 'src/app/Admin/Admin.slice';
import { NotificationSlice } from 'src/app/Notification/Notification.slice';
import { forgotPasswordSlice } from 'src/app/Auth/Password/ForgotPassword/ForgotPassword.slice';
import { verifyOtpSlice } from 'src/app/Auth/Password/VerifyOtp/VerifyOtp.slice';
import { RolePermissionSlice } from './RolePermission/RolePermission.slice';
import { commonSlice } from './services/common/Common.slice';
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['Common', 'verifyOtp', 'RolePermission', 'Location'],
};

const rootReducer = combineReducers({
    Common: commonSlice.reducer,
    User: loginSlice.reducer,
    ForgotPassword: forgotPasswordSlice.reducer,
    verifyOtp: verifyOtpSlice.reducer,
    Countries: CountrySlice.reducer,
    States: StateSlice.reducer,
    Cities: CitySlice.reducer,
    Staff: staffSlice.reducer,
    Admin: adminSlice.reducer,
    CalendarInfo: CalendarSlice.reducer,
    Notification: NotificationSlice.reducer,
    RolePermission: RolePermissionSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
