import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { AppearanceService } from 'src/theme/Images';
import { Collapse } from '../Collapse';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { IFormData, IService, ITranslation, InitServiceInfoData, ServiceProps } from './Service.interface';
import Form from './Form';
import TemplateLayout from '../Layout/Layout';
import { allShopServices, currentLanguage, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import Switch from 'src/components/Switch/Switch';
import CustomButton from 'src/components/CustomButton';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import { useLanguage } from 'src/hooks/useCommon';
import { CHARACTERS_LIMIT } from 'src/constants/common';
import RadioSwitch from 'src/components/RadioSwitch';

const Service: FC<ServiceProps> = (props) => {
    const { section } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { languageOptions, languages, handleLanguage, handleErrorsAndSetLanguage } = useLanguage();
    const shop = useAppSelector(currentShop);
    const shopId = shop.id;
    const [isLoading, setIsLoading] = useState(false);
    const services = useAppSelector(allShopServices);
    const language = useAppSelector(currentLanguage);

    const schemaFields: any = {
        status: Yup.bool().required(t('This field is required')),
        is_title: Yup.bool().required(t('This field is required')),
        is_subtitle: Yup.bool().required(t('This field is required')),
        services: Yup.array()
            .test({
                name: 'locations',
                message: 'Please add at least one service',
                test: function (value) {
                    const { status } = this.parent;
                    if (status && (!value || value.length === 0)) {
                        return false;
                    }
                    return true;
                },
            })
            .of(
                Yup.object().shape({
                    is_description: Yup.bool().required(t('This field is required')),
                    price: Yup.string().required(t('This field is required')),
                    ...languages.reduce((fields: any, name) => {
                        fields[`${name}_name`] = Yup.string()
                            .required(t('This field is required'))
                            .max(CHARACTERS_LIMIT.SHORT, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.SHORT }));
                        fields[`${name}_description`] = Yup.string().when('is_description', ([is_description], customSchema) =>
                            is_description
                                ? customSchema.required(t('This field is required')).max(CHARACTERS_LIMIT.LONG, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.LONG }))
                                : customSchema.nullable(),
                        );
                        return fields;
                    }, {}),
                }),
            )
            .when('status', ([status], customSchema) => (status ? customSchema.required() : customSchema.nullable())),
    };
    languages.forEach((name: string) => {
        schemaFields[`${name}_title`] = Yup.string().when('is_title', ([is_title], customSchema) =>
            is_title ? customSchema.required(t('This field is required.')).max(CHARACTERS_LIMIT.SHORT, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.SHORT })) : customSchema.nullable(),
        );
        schemaFields[`${name}_subtitle`] = Yup.string().when('is_subtitle', ([is_subtitle], customSchema) =>
            is_subtitle ? customSchema.required('This field is required.').max(CHARACTERS_LIMIT.MEDIUM, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.MEDIUM })) : customSchema.nullable(),
        );
    });
    const schema = Yup.object(schemaFields);

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as unknown as Resolver<IFormData>,
    });
    const {
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = methods;
    const isStatus = watch('status');
    const servicesWatch = watch('services');

    useEffect(() => {
        let existingData: IFormData = {
            status: false,
            is_title: false,
            is_subtitle: false,
            services: [],
        };
        languages.forEach((locale) => {
            existingData[`${locale}_title`] = '';
            existingData[`${locale}_subtitle`] = '';
        });
        if (section) {
            existingData = {
                status: section.status || false,
                is_title: section.is_title || false,
                is_subtitle: section.is_subtitle || false,
                services: section.services || [],
            };
            languages.forEach((locale) => {
                existingData[`${locale}_title`] = section[`${locale}_title`] || '';
                existingData[`${locale}_subtitle`] = section[`${locale}_subtitle`] || '';
            });
        }
        Object.entries(existingData).forEach(([key, value]) => {
            setValue(key as keyof IFormData, value);
        });
        reset(existingData);
    }, [section]);

    const handleChange = async (data: IFormData) => {
        setIsLoading(true);
        const payload = {
            service: data,
            section: 'service',
        };
        try {
            const response = await axiosPost(API.THEME.TEMPLATE.SECTION, payload, {
                shop_id: shopId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(getAllShopSettings({ shop_id: shop.id }));
                return;
            }
            if (response.data.status === errorCode.unprocessable) {
                if (response.data.data) {
                    Object.keys(response.data.data).forEach((field) => {
                        setError(field as keyof IFormData, {
                            type: 'manual',
                            message: response.data.data[field][0],
                        });
                    });
                }
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };
    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };

    const handleSync = (event: any) => {
        event.stopPropagation();
        const existingServices: IService[] = services.map((service: IService) => {
            const newData = { ...InitServiceInfoData };
            if (service.translations) {
                service.translations.forEach((translation: ITranslation) => {
                    newData[`${translation.locale}_name`] = translation.name || InitServiceInfoData[`${translation.locale}_name`];
                    newData[`${translation.locale}_description`] = translation.description || InitServiceInfoData[`${translation.locale}_description`];
                });
            } else {
                newData[`${language}_name`] = service[`${language}_name`] || InitServiceInfoData[`${language}_name`];
                newData[`${language}_description`] = service[`${language}_description`] || InitServiceInfoData[`${language}_description`];
            }
            newData.is_description = InitServiceInfoData.is_description;
            newData.price = InitServiceInfoData.price;
            return newData;
        });
        setValue('services', existingServices);
    };

    useFormErrorFocus<IFormData>({ errors, formSectionName: 'service' });
    const handleError = (err: any) => handleErrorsAndSetLanguage(err);

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit(handleChange, handleError)}
                className={`h-full ${errors?.services && errors?.services?.message ? 'is-invalid rounded-xl border' : 'rounded-xl border border-borderPrimary'}`}
            >
                <Collapse
                    title={t('Services')}
                    description={t('The Services section lists all the haircuts, shaves, and grooming services offered by your brand.')}
                    isCollapsed={isStatus}
                    isLoading={isLoading}
                    handleStatus={handleStatus}
                    handleSave={handleSubmit(handleChange, handleError)}
                    // handleSync={handleSync}
                    // syncButtonTitle={`${services.length !== servicesWatch?.length ? `Synced with services (${services.length})` : ''}`}
                >
                    {languages.length > 1 && (
                        <div className="flex justify-end mt-5 mx-5">
                            <RadioSwitch options={languageOptions} name="service_language" value={language} onChange={handleLanguage} />
                        </div>
                    )}
                    <TemplateLayout ImgProp={AppearanceService} key={language}>
                        <>
                            <div className="flex gap-4 mb-4">
                                <Controller
                                    name={`${language}_title`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id={`${language}_title`}
                                                name={`${language}_title`}
                                                label={t('Title')}
                                                placeholder={t('Enter service title')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name={'is_title'}
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name={`${language}_subtitle`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id={`${language}_subtitle`}
                                                name={`${language}_subtitle`}
                                                label={t('Subtitle')}
                                                placeholder={t('Enter service subtitle')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name={'is_subtitle'}
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <Form key={language} language={language} />
                        </>
                        {errors.services && errors?.services?.message && <p className="text-error text-center">{errors?.services?.message}</p>}
                    </TemplateLayout>
                    <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end gap-4">
                        {services.length !== servicesWatch?.length && (
                            <CustomButton type="button" secondary onClick={handleSync}>
                                <span className="h-[8px] w-[8px] bg-success-500 rounded-full"></span>
                                <span className="flex items-center ">{`${services.length !== servicesWatch?.length ? `Sync (${services.length})` : ''}`}</span>
                            </CustomButton>
                        )}
                        <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </Collapse>
            </form>
        </FormProvider>
    );
};

export default Service;
