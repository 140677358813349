import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/hooks';
import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import './../../utils/Datatable.scss';
import './../../utils/prime-react-datatable.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import { allShopLocations, currentShop } from 'src/redux/services/common/Common.slice';
import { Box, Plus, ArrowUp, ArrowDown } from '@untitled-ui/icons-react/build/cjs';
import { DURATIONS, IStaffFormData, initAction } from './Services.interface';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import { axiosDelete, axiosGet, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import InputWithLabel from 'src/components/InputWithLabel';
import { Checkbox } from 'flowbite-react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import NoDataMessage from 'src/components/NoDataMessage';
import { Skeleton } from 'primereact/skeleton';
import { PaginatorTemplate } from 'src/utils/global-component';
import { AllLocationsOptions } from 'src/utils/global-variables';
import { SERVICE_TABS } from 'src/constants/common';
import Badge from 'src/components/Badge';

const ShopServiceTable = ({ setActiveTabName }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const shopLocationList = useAppSelector(allShopLocations);
    const [totalServices, setTotalServices] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState({});
    const [action, setAction] = useState(initAction);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [schema, setSchema] = useState(Yup.object());
    const [formData, setFormData] = useState<IStaffFormData>({
        rows: [],
        subRows: {},
    });
    const [selectedFilter, setSelectedFilter] = useState({
        shop_location_id: null,
    });
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            shop_location_id: { value: null },
        },
    });

    const {
        control,
        formState: { isValid },
        handleSubmit,
        getValues,
        setValue,
        setError,
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
            rows: [],
            subRows: {},
        },
    });

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'name', 'profile_image_url');
        setLocationOptions([AllLocationsOptions, ...locationData]);
    }, []);

    useEffect(() => {
        listServices();
    }, [lazyState, shop]);

    useEffect(() => {
        setSchema(generateSchema(formData));
    }, [formData]);

    const generateSchema = (data: IStaffFormData) => {
        const initSchema = Yup.array().of(
            Yup.object().shape({
                is_add: Yup.boolean(),
                price: Yup.number().when('is_add', ([is_add], customSchema) =>
                    is_add ? customSchema.required(t('This field is required')).positive(t('Price must be a positive value')).typeError(t('Price must be a number')) : customSchema.nullable(),
                ),
                duration: Yup.string().when('is_add', ([is_add], customSchema) => (is_add ? customSchema.required(t('This field is required')) : customSchema.nullable())),
            }),
        );

        const subRowsSchema = Object.keys(data.subRows).reduce((rows: any, id: string) => {
            rows[id] = initSchema;
            return rows;
        }, {});

        return Yup.object({
            rows: initSchema,
            subRows: Yup.object().shape(subRowsSchema),
        }).test('check-rows-or-subrows', t('At least one service must be selected'), (value) => {
            const { rows, subRows } = value;
            const rowsExist = rows && rows.length > 0 && rows.some((row: any) => row.is_add === true);
            const subRowsExist = subRows && Object.values(subRows).some((subRow: any) => subRow.some((row: any) => row.is_add === true));
            return rowsExist || subRowsExist;
        });
    };

    const listServices = async () => {
        setIsLoading(true);
        await axiosGet(API.SERVICE.LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setTotalServices(response.data.data.totalRecords);
                const services = response.data.data.data;
                const subRows: any = {};
                const rows = services.map((service: any) => {
                    if (service.variables.length) {
                        subRows[service.id] = service.variables.map((variable: any) => ({
                            id: variable.id,
                            name: variable.name,
                            description: variable.description,
                            type: variable.type,
                            parent_id: variable.parent_id,
                            duration: null,
                            price: undefined,
                            starting_price: false,
                            is_add: false,
                        }));
                    }
                    return {
                        id: service.id,
                        name: service.name,
                        description: service.description,
                        type: service.type,
                        parent_id: service.parent_id,
                        category: service.category,
                        variables: service.variables,
                        locations: service.locations,
                        duration: null,
                        price: undefined,
                        starting_price: false,
                        is_add: false,
                    };
                });
                setValue('rows', rows);
                setValue('subRows', subRows);
                setFormData({ ...formData, rows, subRows });
            })
            .finally(() => setIsLoading(false));
    };

    const handleSave = async (data: IStaffFormData) => {
        setIsLoading(true);
        const payload: any = {
            options: [],
        };
        data.rows.forEach((row: any) => {
            if (row.is_add) {
                payload.options.push({
                    price: row.price,
                    duration: row.duration,
                    starting_price: row.starting_price,
                    shop_service_id: row.id,
                });
            }
        });
        Object.values(data.subRows).forEach((subRows: any) => {
            subRows.forEach((row: any) => {
                if (row.is_add) {
                    payload.options.push({
                        price: row.price,
                        duration: row.duration,
                        starting_price: row.starting_price,
                        shop_service_id: row.id,
                    });
                }
            });
        });

        await axiosPost(API.SERVICE.STAFF_ATTACH, payload, { shop_id: shop.id })
            .then(() => setActiveTabName(SERVICE_TABS.STAFF))
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IStaffFormData, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                } else {
                    toast.error(response.message);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : null;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const handleModalClose = useCallback(
        (status: boolean = false) =>
            async () => {
                if (status) {
                    setIsLoading(true);
                    const params = {
                        shop_id: shop.id,
                        id: action.id,
                    };
                    await axiosDelete(API.SERVICE.DELETE, {}, params)
                        .then(listServices)
                        .finally(() => setIsLoading(false));
                }
                setAction(initAction);
            },
        [action],
    );

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const onRowToggle = (event: any) => {
        setExpandedRows(event.data);
    };

    const allowExpansion = (row: any) => row.variables.length > 0;

    const getFields = (name: string, type: string) => {
        switch (type) {
            case 'action':
                return <Controller name={name} control={control} render={({ field: { onChange, value } }) => <Checkbox name={name} value={value} checked={value} onChange={onChange} />} />;
            case 'starting_price':
                return (
                    <Controller
                        name={name}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => <Checkbox name={name} value={value} checked={value} onChange={onChange} />}
                    />
                );
            case 'duration':
                return (
                    <Controller
                        name={name}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="max-w-[131px]">
                                <SelectBox
                                    name={name}
                                    options={DURATIONS}
                                    value={DURATIONS.find((duration) => duration.value === value)}
                                    onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                    classComp="outline-select-box"
                                    placeholder={t('Minutes')}
                                />
                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                );
            case 'price':
                return (
                    <Controller
                        name={name}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="max-w-[131px]">
                                <InputWithLabel type="number" onChange={onChange} value={value} name={name} placeholder={t('Price')} />
                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                );
            default:
                break;
        }
    };

    const getType = (row: any) => (
        <Badge icon status={row.type}>
            {row.type}
        </Badge>
    );

    const getLocation = (row: any) => row.locations[0].name;
    const getCategories = (row: any) => row.category.name;

    const getDuration = (data: any, row: any) => (data.type === 'regular' || data.parent_id !== null ? getFields(`rows.${row.rowIndex}.${row.field}`, 'duration') : null);
    const getDuration2 = (id: number) => (data: any, row: any) => getFields(`subRows.${id}.${row.rowIndex}.${row.field}`, 'duration');

    const getPrice = (data: any, row: any) => (data.type === 'regular' || data.parent_id !== null ? getFields(`rows.${row.rowIndex}.${row.field}`, 'price') : null);
    const getPrice2 = (id: number) => (data: any, row: any) => getFields(`subRows.${id}.${row.rowIndex}.${row.field}`, 'price');

    const getStartingPrice = (data: any, row: any) => (data.type === 'regular' || data.parent_id !== null ? getFields(`rows.${row.rowIndex}.${row.field}`, 'starting_price') : null);
    const getStartingPrice2 = (id: number) => (data: any, row: any) => getFields(`subRows.${id}.${row.rowIndex}.${row.field}`, 'starting_price');

    const getAction = (data: any, row: any) => (data.type === 'regular' || data.parent_id !== null ? getFields(`rows.${row.rowIndex}.${row.field}`, 'action') : null);
    const getAction2 = (id: number) => (data: any, row: any) => getFields(`subRows.${id}.${row.rowIndex}.${row.field}`, 'action');

    const rowExpansionTemplate = (data: any) => (
        <DataTable value={data.variables} showHeaders={false}>
            <Column expander={false} style={{ width: '30px', minWidth: '30px', maxWidth: '30px' }} />
            <Column
                field="name"
                header={t('Service name')}
                className="text-xs font-medium text-mainTextColor truncate"
                colSpan={3}
                style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
            ></Column>
            <Column expander={false} style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }} />
            <Column expander={false} style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }} />
            <Column field="description" header={t('Service description')} className="text-xs font-medium truncate" style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}></Column>
            <Column
                field="location"
                header={t('Location')}
                className="text-xs font-semibold text-mainTextColor truncate"
                style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                body={data.locations[0].name}
            ></Column>
            <Column field="duration" header={t('Duration (minutes)')} body={getDuration2(data.id)} style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}></Column>
            <Column field="price" header={t('Price')} body={getPrice2(data.id)} style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}></Column>
            <Column field="starting_price" header={t('Starting price')} body={getStartingPrice2(data.id)} style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}></Column>
            <Column field="is_add" body={getAction2(data.id)} style={{ width: '50px', minWidth: '50px', maxWidth: '50px' }}></Column>
        </DataTable>
    );

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map((_, index) => ({
                id: index,
                type: 'loading',
                name: 'loading',
                category: { name: 'loading' },
                description: 'loading',
                locations: 'loading',
                duration: 'loading',
                price: 'loading',
                starting_price: 'loading',
                is_add: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    return (
        <div
            className={`w-full border border-gray-200 rounded-xl shadow staff_service_table datatable-custom-service flex-1 flex flex-col ${
                getValues('rows').length === 0 ? 'datatable-full-height datatable-noshow' : ''
            }`}
        >
            <form onSubmit={handleSubmit(handleSave)} className="flex flex-col flex-1">
                <div className="flex items-center flex-col">
                    <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                        <div className="flex flex-col flex-1">
                            <div className="flex justify-start items-center gap-1.5 xxl:gap-1.5">
                                <h2 className="table-title">{t('All services')}</h2>
                                <Badge icon isLoading={isLoading}>
                                    {totalServices} {t('Services')}
                                </Badge>
                            </div>
                            <p className="table-subtitle">{t('View and manage your services.')}</p>
                        </div>
                        <div className="flex gap-3">
                            <div className="">
                                <CustomButton
                                    outlinePrimary
                                    type="submit"
                                    disabled={isLoading || !isValid}
                                    isLoading={isLoading}
                                    icon={<Plus width="16" />}
                                    className="!px-4 py-[9px] rounded-lg shadow-InputAndButton"
                                >
                                    {t('Add service')}
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center px-5 py-3 justify-between w-full ">
                        <div className="table-searchInput">
                            <SearchBar placeholder={t('Search')} className="form-control-md" onChange={handleSearch} />
                        </div>
                        <div className="w-[170px] xl:w-[200px]">
                            <SelectBox
                                isClearable={false}
                                isSearchable={false}
                                options={locationOptions}
                                noOptionsMessage="No Locations Found"
                                value={locationOptions.find((option) => option.value === selectedFilter.shop_location_id)}
                                placeholder={t('All locations')}
                                onChangeFunc={handleFilter('shop_location_id')}
                                classComp="outline-select-box"
                            />
                        </div>
                    </div>
                </div>

                <DataTable
                    lazy
                    paginatorTemplate={PaginatorTemplate()}
                    value={isLoading ? skeletons : getValues('rows')}
                    totalRecords={totalServices}
                    paginator={!isLoading && totalServices > lazyState.rows}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    sortOrder={lazyState.sortOrder}
                    sortField={lazyState.sortField}
                    filters={lazyState.filters}
                    expandedRows={expandedRows}
                    onPage={onPage}
                    onSort={onSort}
                    onFilter={onFilter}
                    rowExpansionTemplate={rowExpansionTemplate}
                    onRowToggle={onRowToggle}
                    dataKey="id"
                    className="border-t rounded-b-md overflow-hidden"
                    paginatorClassName="table-pagination"
                    emptyMessage={
                        <NoDataMessage
                            title="No configured services"
                            description="Select from available brand services to list them on your profile"
                            iconComponent={<Box className="text-gray-700" />}
                        />
                    }
                >
                    <Column expander={isLoading ? false : allowExpansion} body={isLoading && <Skeleton />} className="table-arrow-btn" style={{ width: '30px', minWidth: '30px', maxWidth: '30px' }} />
                    <Column
                        field="name"
                        header={renderHeader(t('Service Name'), 'name')}
                        body={isLoading ? <Skeleton /> : undefined}
                        className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                        style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                        sortable
                    ></Column>
                    <Column
                        field="type"
                        header={renderHeader(t('Type'), 'type')}
                        className="font-medium text-mainTextColor truncate"
                        body={isLoading ? <Skeleton /> : getType}
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                        sortable
                    ></Column>
                    <Column
                        field="shop_categories.name"
                        header={renderHeader(t('Category'), 'shop_categories.name')}
                        body={isLoading ? <Skeleton /> : getCategories}
                        className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                        sortable
                    ></Column>
                    <Column
                        field="description"
                        header={renderHeader(t('Service description'), 'description')}
                        body={isLoading ? <Skeleton /> : undefined}
                        className="text-xs leading-[18px] font-normal text-gray-600 truncate"
                        style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                        sortable
                    ></Column>
                    <Column
                        field="shop_locations.name"
                        header={renderHeader(t('Location'), 'shop_locations.name')}
                        className="text-xs leading-[18px] font-semibold text-mainTextColor truncate"
                        body={isLoading ? <Skeleton /> : getLocation}
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                        sortable
                    ></Column>
                    <Column field="duration" header={t('Duration (minutes)')} body={isLoading ? <Skeleton /> : getDuration} style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}></Column>
                    <Column field="price" header={t('Price')} body={isLoading ? <Skeleton /> : getPrice} style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}></Column>
                    <Column
                        field="starting_price"
                        header={t('Starting price')}
                        body={isLoading ? <Skeleton /> : getStartingPrice}
                        style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                    ></Column>
                    <Column field="is_add" body={isLoading ? <Skeleton /> : getAction} style={{ width: '50px', minWidth: '50px', maxWidth: '50px' }}></Column>
                </DataTable>
            </form>
            {action.delete && <DeletePopupModal onClose={handleModalClose} size="w-[400px]" title={t('Delete service')} description={t('Are you sure you want to delete service?')} />}
        </div>
    );
};

export default ShopServiceTable;
