import React, { FC, Fragment } from 'react';
import { IProps } from './RadioSwitch.interface';

const RadioSwitch: FC<IProps> = ({ options, name, value, onChange }) => (
    <div className="custom-square-radio-block">
        {options.map((option, index) => (
            <Fragment key={option.value}>
                <input
                    type="radio"
                    className={`hidden custom-radio ${index === 0 ? 'radio-first' : 'radio-second'}`}
                    // className={`hidden custom-radio ${option.className}`}
                    id={`${option.id}-${name}`}
                    name={name}
                    value={option.value}
                    checked={value === option.value}
                    onChange={onChange}
                />
                <label className="custome-label" htmlFor={`${option.id}-${name}`}>
                    {option.label}
                </label>
            </Fragment>
        ))}
        <span className="switch-base"></span>
    </div>
);

export default RadioSwitch;
