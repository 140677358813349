import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';

export const PaginatorTemplate = (): any => {
    const { t } = useTranslation();

    return {
        layout: 'CurrentPageReport PrevPageLink NextPageLink',
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
        CurrentPageReport: ({ currentPage, totalPages }: { currentPage: number; totalPages: number }) => (
            <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
                <span>
                    {t('Page')} {currentPage} {t('of')} {totalPages}
                </span>
            </div>
        ),
    };
};
