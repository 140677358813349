export interface IPinSetup {
    pin: string;
}
export interface IPinSetupConfirm {
    pin_confirmation: string;
}
export type ICountStep = 0 | 1 | 2;
export interface IPinSetupProps {
    onClick: any;
}
export interface IPinSetup2Props {
    isLoading: boolean;
}
export interface IEnablePopup {
    handleReceptionistMode: any;
    handleClose: any;
}
export interface IPinVerificationFormData {
    pin: string;
}

export interface IPinForgotFormData {
    password: string;
}

export interface IPinVerificationProps {
    handleReceptionistMode: any;
    handleClose: any;
}
export interface IForgotPinProps {
    handleClose: any;
    handleReceptionistMode: any;
}
export const receptionistInitAction = {
    enablePopup: false,
    pinSetup: false,
    forgotPin: false,
    pinVerification: false,
};
