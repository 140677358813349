export type IActionType = {
    upcoming: boolean;
    checkout: boolean;
    latest: boolean;
    notification: boolean;
};

export interface IDashboardDetails {
    from_date: Date | null;
    total: {
        active_users: number;
        active_users_growth: number;
        amount: number;
        amount_growth: number;
        bookings: number;
        bookings_growth: number;
        retention_rate: number;
        retention_rate_growth: number;
        amount_projected: number;
        bookings_projected: number;
    };
    graph: any[];
}

export const overviewPerPageOptions = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '150', value: 150 },
    { label: '200', value: 200 },
];

export const initAction: IActionType = {
    upcoming: false,
    checkout: false,
    latest: false,
    notification: false,
};
export interface IPayload {
    type: string;
    location_id?: number;
    end_date?: any;
    start_date?: any;
    shop_admin_id?: number;
}

export interface IGraphOptions {
    label: string;
    value: string;
    seriesArray: any[];
}
export const graphOptions: IGraphOptions[] = [
    { label: 'Total Sales', value: 'sales', seriesArray: [{ label: 'Total Sales', value: 'total_amount' }] },
    { label: 'Total Appointments', value: 'bookings', seriesArray: [{ label: 'Total Appointments', value: 'total_bookings' }] },
    { label: 'Total Active Clients', value: 'active_users', seriesArray: [{ label: 'Total Clients', value: 'total_users' }] },
    { label: 'Client Retention Rate', value: 'retention_users', seriesArray: [{ label: 'Total Client Retention Rate', value: 'total_users' }] },
];
export const selectAllOption: any = {
    value: 0,
    label: 'All Locations ',
};
export const selectAllTeamOption: any = {
    value: 0,
    label: 'All Team members',
};
