import React, { useCallback, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import { ArrowLeft, ArrowRight } from '@untitled-ui/icons-react/build/cjs';
import { onError } from 'src/utils/global-functions';

const ImageSlider = ({ SliderImage }: any) => {
    const [swiperRef, setSwiperRef] = useState<SwiperType>();
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);
    return (
        <div className="relative h-[70px] w-full">
            <div className={`w-full rounded-md mb-3 overflow-hidden h-full main_slider relative`}>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={10}
                    pagination={{
                        enabled: true,
                        clickable: true,
                    }}
                    onSwiper={setSwiperRef}
                    navigation={false}
                    loop={true}
                    className="mySwiper w-full h-full"
                >
                    {SliderImage.map((data: any, index: any) => (
                        <SwiperSlide key={index}>
                            <div className="relative w-[70px] h-[70px]">
                                <img src={data.image_url} alt="" className="w-[70px] h-[70px] object-cover object-center rounded-lg" onError={onError} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {SliderImage.length >= 5 && (
                <div className="absolute inset-0 flex justify-between items-center  z-50 ">
                    <button
                        className="rounded-full flex items-center justify-center w-[32px] h-[32px] cursor-pointer bg-white bg-opacity-90 border border-white -ml-[12px] shadow-md"
                        onClick={handlePrevious}
                    >
                        <ArrowLeft className=" text-secondaryTxtColor w-5 h-5" />
                    </button>
                    <button
                        className="rounded-full flex items-center justify-center w-[32px] h-[32px] cursor-pointer bg-white bg-opacity-90 border border-white -mr-[12px] shadow-md"
                        onClick={handleNext}
                    >
                        <ArrowRight className=" text-secondaryTxtColor w-5 h-5" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default ImageSlider;
