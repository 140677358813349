import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { Collapse } from '../Collapse';
import { convertBase64ToFile } from 'src/utils/global-functions';
import ImageBoxView from '../../Image/ImageBoxView';
import Switch from 'src/components/Switch/Switch';
import { CHARACTERS_LIMIT } from 'src/constants/common';

const Form = () => {
    const { control, setValue, watch, getValues } = useFormContext();
    const { t } = useTranslation();
    const { fields } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'products',
    });
    const onChangeCrop = (fieldName: any) => (e: any) => {
        e.preventDefault();
        let files;

        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        // return;
        const promises = Array.from(files).map(
            (file: any) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const newFile = convertBase64ToFile(reader.result);
                        if (newFile && newFile.filename && newFile.convertedFile) {
                            resolve({
                                file: newFile.convertedFile as File,
                                url: reader.result as string,
                                name: newFile.filename,
                            });
                        } else {
                            reject(new Error('Invalid file'));
                        }
                    };
                    reader.onerror = () => reject(reader.error);
                    reader.readAsDataURL(file);
                }),
        );
        Promise.all(promises)
            .then((results: any) => {
                setValue(fieldName, [...(getValues(fieldName) || []), ...results]);
            })
            .catch((error) => {
                console.error(error);
            });
        e.target.value = null;
    };

    // const handleRemoveImage = (fieldName: string, index: number) => () => {
    //     const imageData = watch(fieldName);
    //     const image: any = imageData?.filter((_: any, i: number) => i !== index);
    //     setValue(fieldName, image);
    // };
    // const addProduct = () => {
    //     InitServiceInfoData && append(InitServiceInfoData);
    // };
    // const removeProduct = (index: number) => () => {
    //     remove(index);
    // };
    return (
        <div className="data-wrap-block space-y-4 mb-3">
            {fields?.map((item: any, index: number) => (
                <div className={'rounded-xl border border-borderSecondary'} key={index}>
                    <Collapse title={watch(`products.${index}.name`) || `${t('Product Name')}`} classNames="!p-4 !border-borderSecondary">
                        <div className="lex gap-4 relative  border-borderPrimary p-4">
                            {/* <div className="text-right">
                        <Button type="button" onClick={() => remove(index)} className="text-dangerErrorText text-xs font-medium">
                            Remove
                        </Button>
                    </div> */}
                            <div className="grid grid-cols-4 gap-3 xl:gap-4 mb-4" key={item.uuid}>
                                <Controller
                                    name={`products.${index}.name`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                required
                                                name={`staff.${index}.name`}
                                                id={`products.${index}.name`}
                                                label={t('Name')}
                                                placeholder={t('Enter product name')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                readOnly
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name={`products.${index}.category`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                name={`staff.${index}.category`}
                                                id={`products.${index}.category`}
                                                label={t('Category')}
                                                placeholder={t('Enter product category')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                required
                                                readOnly
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name={`products.${index}.price`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                name={`staff.${index}.price`}
                                                id={`products.${index}.price`}
                                                label={t('Price')}
                                                placeholder={t('Enter product price')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                type="number"
                                                required
                                                readOnly
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name={`products.${index}.button`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                name={`staff.${index}.button`}
                                                label={t('Product buton title')}
                                                id={`products.${index}.button`}
                                                placeholder={t('Enter product button title')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                required
                                                readOnly
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-full mb-2" key={index}>
                                <Controller
                                    name={`products.${index}.description`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                name={`products.${index}.description`}
                                                label={t('Description')}
                                                id={`products.${index}.description`}
                                                textArea
                                                placeholder={t('Enter product Description')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                rows={15}
                                                maxLenght={CHARACTERS_LIMIT.LONG}
                                                textAearClassName="rounded-md h-[100px]"
                                                readOnly
                                                toggle={
                                                    <Controller
                                                        name={`products.${index}.is_description`}
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch isDisabled readOnly className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <label className="mb-1.5 flex">{t('Image')}</label>
                            <div className="flex gap-4 ">
                                <Controller
                                    name={`products.${index}.images`}
                                    control={control}
                                    render={({ field: { value }, fieldState: { error } }: any) => (
                                        <>
                                            <div id={`products.${index}.images`} className="flex flex-wrap gap-4">
                                                {value && Array.isArray(value) && (
                                                    <>
                                                        {value.map((imageItem: any, imagIndex: number) => (
                                                            <>
                                                                <ImageBoxView
                                                                    item={imageItem}
                                                                    name={`products.${index}.images`}
                                                                    onChangeCrop={onChangeCrop}
                                                                    // handleRemoveImage={handleRemoveImage(`products.${index}.images`, imagIndex)}
                                                                    key={index}
                                                                    isEdit={false}
                                                                />
                                                            </>
                                                        ))}
                                                    </>
                                                )}
                                                {/* <ImageInput error={error} onChangeCrop={onChangeCrop} name={`products.${index}.images`} title="Product Picture" /> */}
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </Collapse>
                </div>
            ))}
            {/* <div className="flex justify-end items-center !mt-2 !mb-2">
                <Button type="button" className="text-xs font-medium text-blue-600 flex gap-1 focus-visible:outline-none focus:outline-none focus:shadow-none" onClick={addProduct}>
                    <Plus width={16} />
                    {t('Add new Product')}
                </Button>
            </div> */}
        </div>
    );
};
export default Form;
