import { currentRole, currentShop, userMe } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';
import { ROLES } from 'src/constants/common';
import { useEffect, useState } from 'react';

export const useRolePermission = () => {
    const user = useAppSelector(userMe);
    const role = useAppSelector(currentRole);
    const shop = useAppSelector(currentShop);
    const [allShopRoles, setAllShopRoles] = useState([]);

    useEffect(() => {
        if (user) {
            setAllShopRoles(user.roles.filter((item: any) => item.pivot.shop_id === shop.id));
        }
    }, [user.roles]);

    const getRoleByShop = (shopId: number) => user.roles.find((item: any) => item?.pivot && item.pivot.shop_id === shopId);

    const isRoleExist = (role_name: string) => allShopRoles.find((item: any) => item.name === role_name);

    const hasRole = (roleName: string) => role.name === roleName;

    const hasPermission = (permissionName: string) => (Object.keys(role).length && role.permissions.length ? role.permissions.some((permission: any) => permission.name === permissionName) : false);

    const isAdmin = [ROLES.OWNER, ROLES.INDIVIDUAL].includes(role.name);

    const isOwner = role.name === ROLES.OWNER;

    const isIndividual = role.name === ROLES.INDIVIDUAL;

    const isReceptionist = role.name === ROLES.RECEPTIONIST;

    const isStaff = [ROLES.STAFF_RENT, ROLES.STAFF_COMMISSION, ROLES.STAFF_PARTNER, ROLES.STAFF_NO_TRACKING, ROLES.STAFF_SELF].includes(role.name);

    const isRent = [ROLES.STAFF_RENT, ROLES.STAFF_PARTNER].includes(role.name);

    return { isRoleExist, hasRole, hasPermission, getRoleByShop, isAdmin, isOwner, isIndividual, isStaff, isRent, isReceptionist, allShopRoles };
};
