import React, { useState } from 'react';

import { IInputPasswordWithLabel } from './InputPasswordWithLabel.interface';
import { AlertCircle, Eye, EyeOff } from '@untitled-ui/icons-react/build/cjs';

const InputPasswordWithLabel = ({ error, onChange, value, name, placeholder, label, index, isFocus, required, inputClass }: IInputPasswordWithLabel) => {
    const [isPasswordShow, setIsPasswordShow] = useState(false);

    const handlePasswordHideShow = () => {
        setIsPasswordShow(!isPasswordShow);
    };

    return (
        <div className={` flex flex-col w-full rounded-3xl`}>
            <label htmlFor={name} className="fl-field-title-label">
                {label}
                {required && <span className="asterisk">*</span>}
            </label>
            <div className={`relative input-group items-center ${inputClass} ${error && 'is-invalid'}`}>
                <input
                    className={`form_input_control form_password_control `}
                    type={isPasswordShow ? 'text' : 'password'}
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e, index)}
                    placeholder={placeholder}
                    autoFocus={isFocus}
                    autoComplete="new-password"
                />

                <div className="h-full px-2 flex items-center" onClick={handlePasswordHideShow}>
                    {error ? (
                        <AlertCircle className="cursor-pointer min-w-4 h-4 m-0 text-error" />
                    ) : isPasswordShow ? (
                        <EyeOff className="cursor-pointer m-0 min-w-4 h-4 text-gray-400" />
                    ) : (
                        <Eye className="cursor-pointer m-0 min-w-4 h-4 text-gray-400" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default InputPasswordWithLabel;
