import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { AppearanceAbout } from 'src/theme/Images';
import { Collapse } from '../Collapse';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { checkFileTypeValidation, convertBase64ToFile } from 'src/utils/global-functions';
import Switch from 'src/components/Switch/Switch';
import { s3Upload } from 'src/utils/s3Operations';
import { s3Path } from 'src/constants/s3Path';
import { AboutProps, IFormData } from './About.interface';
import TemplateLayout from '../Layout/Layout';
import { currentLanguage, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import CropperModal from 'src/components/CropperModal/CropperModal';
import CustomButton from 'src/components/CustomButton';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import ImageUpload from 'src/components/ImageUpload';
import { FILE_VALIDATION } from 'src/utils/global-variables';
import { useLanguage } from 'src/hooks/useCommon';
import { CHARACTERS_LIMIT } from 'src/constants/common';
import RadioSwitch from 'src/components/RadioSwitch';

const About: FC<AboutProps> = (props) => {
    const { section } = props;
    const { t } = useTranslation();
    const { languageOptions, languages, handleLanguage, handleErrorsAndSetLanguage } = useLanguage();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    const [image, setImage] = useState<any>('');
    const [fieldName, setFieldName] = useState<keyof IFormData | null>(null);
    const language = useAppSelector(currentLanguage);

    const schemaFields: any = {
        status: Yup.bool().required(t('This field is required')),
        is_title: Yup.bool().required(t('This field is required')),
        is_subtitle: Yup.bool().required(t('This field is required')),
        is_description: Yup.bool().required(t('This field is required')),
        is_image: Yup.bool().required(t('This field is required')),
        image: Yup.object()
            .nullable()
            .shape({
                name: Yup.string().when('is_image', ([is_image], customSchema) => (is_image ? customSchema.required(t('This field is required')) : customSchema.nullable())),
                file: Yup.string().nullable(),
                url: Yup.string().when('is_image', ([is_image], customSchema) => (is_image ? customSchema.required(t('This field is required')) : customSchema.nullable())),
            })
            .test('required', t('This field is required'), function (value) {
                const { is_image: isImage } = this.parent;
                if (!isImage) return true; // If is_image is false, no need to validate
                if (!value) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t('This field is required'),
                    });
                }
                const { name, url } = value;
                if (!name || !url) {
                    return this.createError({
                        path: `${this.path}`,
                        message: t('This field is required'),
                    });
                }
                return true;
            }),
    };
    languages.forEach((name: string) => {
        schemaFields[`${name}_title`] = Yup.string().when('is_title', ([is_title], customSchema) =>
            is_title ? customSchema.required(t('This field is required.')).max(CHARACTERS_LIMIT.SHORT, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.SHORT })) : customSchema.nullable(),
        );
        schemaFields[`${name}_subtitle`] = Yup.string().when('is_subtitle', ([is_subtitle], customSchema) =>
            is_subtitle ? customSchema.required('This field is required.').max(CHARACTERS_LIMIT.MEDIUM, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.MEDIUM })) : customSchema.nullable(),
        );
        schemaFields[`${name}_description`] = Yup.string().when('is_description', ([is_description], customSchema) =>
            is_description ? customSchema.required('This field is required.').max(CHARACTERS_LIMIT.LONG, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.LONG })) : customSchema.nullable(),
        );
    });
    const schema = Yup.object(schemaFields);

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as unknown as Resolver<IFormData>,
    });
    const handleError = (err: any) => handleErrorsAndSetLanguage(err);

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        clearErrors,
        reset,
        formState: { errors },
    } = methods;
    const isStatus = watch('status');
    const isImage = watch('is_image');
    const imageData = watch('image');

    useEffect(() => {
        let existingData: IFormData = {
            status: false,
            is_title: false,
            is_subtitle: false,
            is_description: false,
            is_image: false,
            image: null,
        };
        languages.forEach((locale) => {
            existingData[`${locale}_title`] = '';
            existingData[`${locale}_subtitle`] = '';
            existingData[`${locale}_description`] = '';
        });
        if (section) {
            existingData = {
                status: section.status || false,
                is_title: section.is_title || false,
                is_subtitle: section.is_subtitle || false,
                is_description: section.is_description || false,
                is_image: section.is_image || false,
                image: section.image ? { name: section.image, url: section.image_url, file: null } : null,
            };
            languages.forEach((locale) => {
                existingData[`${locale}_title`] = section[`${locale}_title`] || '';
                existingData[`${locale}_subtitle`] = section[`${locale}_subtitle`] || '';
                existingData[`${locale}_description`] = section[`${locale}_description`] || '';
            });
        }
        Object.entries(existingData).forEach(([key, value]) => {
            setValue(key as keyof IFormData, value);
        });
        reset(existingData);
    }, [section]);

    const handleSwitchOnChange = (name: keyof IFormData) => (event: any) => {
        setValue(name, event.value);
    };

    const handleChange = async (data: any) => {
        setIsLoading(true);
        let imageStatus = errorCode.updateSuccess;
        if (imageData?.file && imageData?.name) {
            const logoImageResponse: any = await s3Upload(imageData?.file, `${s3Path.SHOP_ABOUT}${imageData?.name}`);
            imageStatus = logoImageResponse?.status;
        }

        if (imageStatus === errorCode.updateSuccess) {
            submitForm(data);
        }
    };

    const submitForm = (data: any) => {
        const payload = {
            about: { ...data, image: data?.image?.name },
            section: 'about',
        };
        axiosPost(API.THEME.TEMPLATE.SECTION, payload, { shop_id: shop.id })
            .then(async () => {
                await dispatch(getAllShopSettings({ shop_id: shop.id }));
            })
            .catch((error) => {
                if (error.response.data.status === errorCode.unprocessable) {
                    if (error.response.data.data) {
                        Object.keys(error.response.data.data).forEach((field) => {
                            const fieldKey = field.replace('about.', '');
                            setError(fieldKey as keyof IFormData, {
                                type: 'manual',
                                message: error.response.data.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };
    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };
    const onChangeCrop = (type: keyof IFormData) => async (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const imageValidation = {
            files: files,
            ...FILE_VALIDATION.APPEARANCE.ABOUT,
        };
        const { result, message } = await checkFileTypeValidation(imageValidation);
        if (!result) {
            setError('image', {
                type: 'manual',
                message: message,
            });
            return;
        } else {
            // setImage(innerFileType);
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setIsUpload(false);
            };
            setIsUpload(false);
            clearErrors('image');
            reader.onloadend = () => {
                setImage(reader.result as any);
                setIsUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            setFieldName(type);
        }
    };
    // const handleRemoveImage = () => {
    //     setValue('image', null);
    // };

    const handleCropData = (data: any) => {
        const convertedFile = convertBase64ToFile(data);
        if (convertedFile && convertedFile.filename && fieldName) {
            setValue(fieldName, {
                file: convertedFile.convertedFile as File,
                url: data,
                name: convertedFile.filename,
            });
        }
    };
    useFormErrorFocus<FormData>({ errors, formSectionName: 'about' });

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleChange, handleError)} className="rounded-xl border border-borderPrimary h-full">
                    <Collapse
                        title={t('About')}
                        description={t('The About section presents a brief history and the mission of your brand, giving visitors insight into your history and experience.')}
                        isCollapsed={isStatus}
                        isLoading={isLoading}
                        handleStatus={handleStatus}
                        handleSave={handleSubmit(handleChange, handleError)}
                    >
                        {languages.length > 1 && (
                            <div className="flex justify-end mt-5 mx-5">
                                <RadioSwitch options={languageOptions} name="about_language" value={language} onChange={handleLanguage} />
                            </div>
                        )}
                        <TemplateLayout ImgProp={AppearanceAbout} key={language}>
                            <div>
                                <div className="flex justify-between items-start mb-4 gap-4">
                                    <Controller
                                        name={`${language}_title`}
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    id={`${language}_title`}
                                                    name={`${language}_title`}
                                                    label={t('Title')}
                                                    placeholder={t('Enter about title')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                    toggle={
                                                        <Controller
                                                            name={'is_title'}
                                                            control={control}
                                                            render={({ field, fieldState }: any) => (
                                                                <>
                                                                    <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                    {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                                </>
                                                            )}
                                                        />
                                                    }
                                                />
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name={`${language}_subtitle`}
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    id={`${language}_subtitle`}
                                                    name={`${language}_subtitle`}
                                                    label={t('Subtitle')}
                                                    placeholder={t('Enter about subtitle')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                    toggle={
                                                        <Controller
                                                            name={'is_subtitle'}
                                                            control={control}
                                                            render={({ field, fieldState }: any) => (
                                                                <>
                                                                    <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                    {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                                </>
                                                            )}
                                                        />
                                                    }
                                                />
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="flex gap-4 mb-4">
                                    <Controller
                                        name={`${language}_description`}
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    id={`${language}_description`}
                                                    name={`${language}_description`}
                                                    label={t('Description')}
                                                    placeholder={t('Enter about description...')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                    textArea
                                                    textAearClassName={`h-[100px] ${error ? 'is-invalid border' : ''}`}
                                                    maxLenght={CHARACTERS_LIMIT.LONG}
                                                    toggle={
                                                        <Controller
                                                            name={'is_description'}
                                                            control={control}
                                                            render={({ field, fieldState }: any) => (
                                                                <>
                                                                    <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                    {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                                </>
                                                            )}
                                                        />
                                                    }
                                                />
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="flex gap-4 mb-4">
                                    <Controller
                                        name={`image`}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div id="about-image" className="w-full">
                                                <div className="flex items-center gap-[10px] mb-[6px]" color="gray">
                                                    <label className="fl-field-title-label mb-0">{t('Image')}</label>
                                                    <Switch className="" onChange={handleSwitchOnChange('is_image')} isChecked={isImage} />
                                                </div>
                                                <ImageUpload cropData={value?.url} onChangeCrop={onChangeCrop('image')} id="image" shape="rectangle" error={error} />

                                                {/* <div className="relative cursor-pointer h-9 flex">
                                                    <input type="file" id="image" className="w-full absolute opacity-0" onChange={onChangeCrop(`image`)} />
                                                    <div
                                                        className={`rounded-l-lg border border-borderPrimary px-3 rounded-r-none flex-1 flex items-center text-xs custom-hover-effect ${
                                                            error ? 'is-invalid' : ''
                                                        }`}
                                                    >
                                                        <span className="flex-auto text-gray-500">Click to upload about image</span>
                                                        <XCircle className="text-gray-400" width="16" />
                                                    </div>
                                                    <label
                                                        htmlFor="image"
                                                        className="px-4 py-2 font-medium gap-1 flex items-center justify-between rounded-r-lg border border-primary text-primary text-xs !rounded-l-0"
                                                    >
                                                        <UploadCloud01 width="16" />
                                                        {t('Upload')}
                                                    </label>
                                                </div>
                                                {value && value.url && (
                                                    <div className="mt-[6px] flex gap-2 flex-wrap">
                                                        <ImageInfo className="w-[350px] h-[72px] relative" name={value.name} url={value?.url} onRemove={handleRemoveImage} isApperance />
                                                    </div>
                                                )} */}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </TemplateLayout>
                        <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end">
                            <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                                {t('Save changes')}
                            </CustomButton>
                        </div>
                    </Collapse>
                </form>
            </FormProvider>
            {isUpload && fieldName && (
                <CropperModal
                    title={'About Image'}
                    description={t('Upload a  max 800Kb image for best results.')}
                    imageUrl={image}
                    setUpload={setIsUpload}
                    setCropData={handleCropData}
                    defaultCropType="Rectangular"
                    isCropType={false}
                    btnTitle={t('Confirm')}
                />
            )}
        </>
    );
};

export default About;
