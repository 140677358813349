import { TLanguage } from 'src/redux/services/common/Common.interface';

export interface ITestimonial {
    name: string;
    is_description: true;
    en_description?: string;
    fr_description?: string;
    image?: {
        file: File | null;
        url: string | null;
        name: string | null;
    } | null;
}

export interface IFormData {
    status: boolean;
    is_title: boolean;
    en_title?: string;
    fr_title?: string;
    is_subtitle: boolean;
    en_subtitle?: string;
    fr_subtitle?: string;
    testimonials: ITestimonial[];
}

export const InitTestimonialInfoData: ITestimonial = {
    name: '',
    is_description: true,
    en_description: '',
    fr_description: '',
    image: {
        file: null,
        url: null,
        name: null,
    },
};

export interface TestimonialProps {
    section: any;
}

export interface FormProps {
    language: TLanguage;
}
