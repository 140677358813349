import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API } from 'src/constants/api';
import { PATH } from 'src/constants/path';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosDelete, axiosGet } from 'src/utils/requestClient';
import { ArrowLeft, DotsVertical, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import { Dropdown } from 'flowbite-react';
import { initAction } from './Products.interface';
import { useTranslation } from 'react-i18next';
import { onError } from 'src/utils/global-functions';
import { defaultImage } from 'src/theme/Images';
import CustomButton from 'src/components/CustomButton';
import ProductTab from './ProductTab';
import ImageSlider from './ImageSlider';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import SProductDetails from '../Skeleton/Products/SProductDetails';
interface ExpandedDetails {
    [key: string]: boolean;
}

const ProductDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState<any>(null);
    const [action, setAction] = useState(initAction);
    const [expandedDetails, setExpandedDetails] = useState<ExpandedDetails>({});

    const handleToggleDetail = (detailId: any) => {
        setExpandedDetails((prevState: any) => ({
            ...prevState,
            [detailId]: !prevState[detailId],
        }));
    };

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            axiosGet(API.PRODUCT.GET, { shop_id: shop.id, id })
                .then((response) => {
                    const data = response.data.data;
                    setProduct(data);
                    // setSelectedDetails(data.details[0]);
                })
                .catch(() => navigate(PATH.PRODUCT.LIST))
                .finally(() => setIsLoading(false));
        }
    }, [id, shop]);

    const handleEdit = () => {
        navigate(`${PATH.PRODUCT.UPDATE}/${id}`);
    };

    const renderTrigger = () => (
        <div>
            <DotsVertical className="custom-icon z-20" fill="#475467" />
        </div>
    );

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleModalClose = useCallback(
        (status: boolean = false) =>
            async () => {
                if (status) {
                    setIsLoading(true);
                    const params = {
                        shop_id: shop.id,
                        id,
                    };
                    await axiosDelete(API.PRODUCT.DELETE, {}, params)
                        .then(() => navigate(PATH.PRODUCT.LIST))
                        .finally(() => setIsLoading(false));
                }
                setAction(initAction);
            },
        [action],
    );

    return (
        <>
            <div className="inner-page-wrape !px-0">
                {isLoading ? (
                    <SProductDetails />
                ) : (
                    product && (
                        <div className="flex-1 flex flex-col mb-4">
                            <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                                <div className="flex items-center justify-between w-full">
                                    <Link to={PATH.PRODUCT.LIST} className="flex items-center cursor-pointer text-gray-600 h-[36px] text-xs font-semibold gap-1.5 group hover:text-gray-800">
                                        <ArrowLeft className="w-4 cursor-pointer text-gray-600 group-hover:text-gray-800" /> {t('Back to Products')}
                                    </Link>
                                    <div className="z-[1] location_menu">
                                        <Dropdown label="" renderTrigger={renderTrigger} className="w-[200px] border border-gray-200 rounded-lg">
                                            <Dropdown.Item icon={Trash01} onClick={handleAction('delete')} className="text-sm font-medium text-gray-700 hover:text-gray-800">
                                                <span>{t('Delete')}</span>
                                            </Dropdown.Item>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col flex-1">
                                <div className="xlm:px-8 px-5 relative mb-[26px] w-full xl:pt-[10px] pt-2 pb-2 flex sms:gap-4 gap-2 xs:items-end items-start">
                                    <img
                                        src={product.images.find((image: any) => image.is_main === true)?.image_url || defaultImage}
                                        alt=""
                                        title={product.name}
                                        onError={onError}
                                        className="max-2xl:w-[112px] max-2xl:h-[112px] w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px]"
                                    />
                                    <div className="flex justify-between items-center w-full gap-2">
                                        <div className="flex gap-2">
                                            <div className="flex flex-col">
                                                <div className="flex flex-row">
                                                    <h1 className="mr-3 text-gray-900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px] ">{product.name}</h1>
                                                </div>
                                                <p className="flex gap-2.5 text-gray-600 sm:text-sm text-xs mt-1 font-normal items-center">{product.category.name}</p>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <CustomButton outlinePrimary onClick={handleEdit} className="!py-2 !px-3">
                                                {t('Edit Product')}
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>

                                <div className="border-t border-[#EAECF0] py-[30px] bg-white flex flex-wrap flex-1 xlm:px-8 px-5">
                                    <div className="flex flex-col xl:w-[calc(100%_-_370px)] xls:w-[calc(100%_-_342px)]  w-full xl:mr-8 xls:mr-4 lg:mr-2 mr-0 xls:mb-0 mb-8">
                                        <ProductTab product={product} />
                                    </div>
                                    <div className="w-full xl:w-[306px] xls:w-[306px]  xl:ml-8 xls:ml-4 lg:ml-2 ml-0">
                                        <div className="flex flex-col">
                                            <div className="grid grid-cols-1 gap-4 mb-5">
                                                <div className="title-block">
                                                    <p className="sideBarHeading">{t('Product Name')}</p>
                                                    <h3 className=" text-mainTextColor text-xs font-medium flex items-center">{product.name}</h3>
                                                </div>
                                                <div className="title-block">
                                                    <p className="sideBarHeading">{t('Category')}</p>
                                                    <h3 className="text-mainTextColor text-xs font-medium flex items-center">{product.category.name}</h3>
                                                </div>
                                                <div className="title-block">
                                                    <p className="sideBarHeading">{t('Sub Category')}</p>
                                                    <h3 className="text-mainTextColor text-xs font-medium flex items-center">{product.sub_category.name}</h3>
                                                </div>
                                                <div className="title-block">
                                                    <p className="sideBarHeading">{t('Unit of Measure')}</p>
                                                    <h3 className="text-mainTextColor text-xs font-medium flex items-center">{product.unit_of_measure}</h3>
                                                </div>
                                                <div className="title-block">
                                                    <p className="sideBarHeading">{t('Brand')}</p>
                                                    <h3 className="text-mainTextColor text-xs font-medium flex items-center">{product.brand}</h3>
                                                </div>
                                                <div className="title-block">
                                                    <p className="sideBarHeading">{t('Description')}</p>
                                                    <h3 className="text-mainTextColor text-xs font-medium flex items-center">{product.description}</h3>
                                                </div>
                                            </div>
                                            <h6 className="text-sm font-semibold mb-1.5 text-gray-900">{t('Product Images')}</h6>
                                            <div className="flex flex-col gap-5 mb-5">
                                                <ImageSlider SliderImage={product.images} />
                                            </div>
                                            <h6 className="text-sm font-semibold mb-0 text-gray-900">{t('Additional Information')}</h6>
                                            <div className="flex flex-col ">
                                                {product.details?.map((details: any) => (
                                                    <div className="border-b py-4" key={details.id}>
                                                        <div
                                                            className="flex  justify-between items-center  border-headerBorderColor text-textGrey cursor-pointer "
                                                            onClick={() => handleToggleDetail(details.id)}
                                                        >
                                                            <span>{details.name}</span>
                                                            <button className="text-xl">{expandedDetails[details.id] ? '-' : '+'}</button>
                                                        </div>
                                                        {expandedDetails[details.id] && <div className="pt-1.5 text-[#475467] text-xs font-normal ">{details.description}</div>}
                                                    </div>
                                                ))}
                                            </div>
                                            {product.supplier_name || product.supplier_email || product.supplier_website ? (
                                                <h6 className="text-sm font-semibold mb-4 text-gray-900 pt-4">{t('Supplier Information')}</h6>
                                            ) : (
                                                <></>
                                            )}
                                            <div className="flex flex-col">
                                                {product.supplier_name && (
                                                    <div className="title-block">
                                                        <p className="sideBarHeading">{t('First Name')}</p>
                                                        <h3 className="text-mainTextColor text-xs font-medium flex items-center">{product.supplier_name}</h3>
                                                    </div>
                                                )}
                                                {product.supplier_email && (
                                                    <div className="title-block">
                                                        <p className="sideBarHeading">{t('Email')}</p>
                                                        <h3 className="text-mainTextColor text-xs font-medium flex items-center">{product.supplier_email}</h3>
                                                    </div>
                                                )}
                                                {product.supplier_website && (
                                                    <div className="title-block">
                                                        <p className="sideBarHeading">{t('Website')}</p>
                                                        <h3 className="text-mainTextColor text-xs font-medium flex items-center">{product.supplier_website}</h3>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
            {action.delete && (
                <DeletePopupModal isLoading={isLoading} onClose={handleModalClose} size="w-[400px]" title={t('Delete product')} description={t('Are you sure you want to delete product?')} />
            )}
        </>
    );
};

export default ProductDetails;
