import { IPaymentValidationState } from 'src/app/Calendar/Calendar.Interface';

export interface IPaymentValidation {
    paymentMethod: IPaymentValidationState;
    tip: IPaymentValidationState;
    returnAmount: IPaymentValidationState;
    chargeAmount: IPaymentValidationState;
    otherPaymentMethod: IPaymentValidationState;
}

export const initValidation: IPaymentValidation = {
    paymentMethod: {
        status: false,
        message: '',
    },
    tip: {
        status: false,
        message: '',
    },
    returnAmount: {
        status: false,
        message: '',
    },
    chargeAmount: {
        status: false,
        message: '',
    },
    otherPaymentMethod: {
        status: false,
        message: '',
    },
};

export interface IInitPaymentStatus {
    processing: boolean;
    success: boolean;
    error: boolean;
    retry: boolean;
}

export const initPayments = {
    payments: [],
    tip: 0,
    extra_payment_method: undefined,
};
