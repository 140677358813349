import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { useParams } from 'react-router-dom';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InitWorkingHours, INTERVAL_OPTIONS, IWorkingHoursValue } from '../Signup/Signup.interface';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { errorCode } from 'src/constants/errorCode';
import { IViewStaffLocation } from './Location.interface';
import PopupModal from 'src/components/PopupModal';
import { ClockRewind } from '@untitled-ui/icons-react/build/cjs';
import WeekHours from 'src/components/WeekHours/WeekHours';

const ViewStaffLocation = (props: any) => {
    const { location, handleClose, getStaff } = props;
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    const schema = Yup.object({
        frequency_booking: Yup.string().required(t('This field is required')),
        id: Yup.number().required(t('This field is required')).integer(t('The value must be an integer')).positive(t('The value must be a positive number')),
        hours: Yup.array().of(
            Yup.object().shape({
                day: Yup.string().required('Day is required').oneOf(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], 'Invalid day'),
                status: Yup.boolean().required('Status is required'),
                from_time_hours: Yup.string()
                    .required('From time hours are required')
                    .test({
                        name: 'from_valid_hours_2',
                        message: '"From" time must be less than "To" time',
                        test: function (value) {
                            const { to_time_hours: toTimeHours, status } = this.parent;

                            if (status) {
                                const fromMoment = moment(value, 'HH:mm');
                                const toMoment = moment(toTimeHours, 'HH:mm');

                                if (fromMoment.isSameOrAfter(toMoment)) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                            return true;
                        },
                    }),

                to_time_hours: Yup.string()
                    .required('To time hours are required')
                    .test({
                        name: 'to_valid_hours_2',
                        message: '"To" time must be greater than "From" time',
                        test: function (value) {
                            const { from_time_hours: fromTimeHours, status } = this.parent;

                            if (status) {
                                const fromMoment = moment(fromTimeHours, 'HH:mm');
                                const toMoment = moment(value, 'HH:mm');

                                if (toMoment.isSameOrBefore(fromMoment)) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                            return true;
                        },
                    }),
            }),
        ),
    });

    const methods = useForm<IViewStaffLocation>({
        resolver: yupResolver(schema) as Resolver<IViewStaffLocation>,
        defaultValues: {
            frequency_booking: '',
            id: null,
            hours: InitWorkingHours,
        },
    });

    useEffect(() => {
        if (location) {
            let hours = InitWorkingHours;
            if (location.staff_working_hours.length) {
                hours = location.staff_working_hours.map((hour: any) => {
                    const momentFromTime = moment(hour.from, 'HH:mm:ss').format('HH:mm');
                    const momentToTime = moment(hour.to, 'HH:mm:ss').format('HH:mm');
                    return {
                        day: hour.day,
                        status: hour.status,
                        from_time_hours: momentFromTime,
                        to_time_hours: momentToTime,
                    };
                });
            }
            setValue('id', Number(location.id));
            setValue('hours', hours);
            setValue('frequency_booking', location.staff_working_hours.length ? location.staff_working_hours[0].frequency_booking : '');
        }
    }, [location]);

    const {
        handleSubmit,
        setValue,
        control,
        setError,
        formState: { errors },
    } = methods;

    const onSubmit = (data: IViewStaffLocation) => {
        setIsLoading(true);
        const payload = {
            frequency_booking: data.frequency_booking,
            hours: data.hours.map((day: IWorkingHoursValue) => {
                const from = moment(day.from_time_hours, 'HH:mm').format('HH:mm:ss');
                const to = moment(day.to_time_hours, 'HH:mm').format('HH:mm:ss');
                return {
                    day: day.day,
                    status: day.status,
                    from,
                    to,
                };
            }),
        };
        axiosPatch(API.LOCATION.HOURS, payload, { shop_id: shop.id, id: Number(id) })
            .then(() => {
                getStaff();
                handleClose();
            })
            .catch((error: any) => {
                const response = error.response.data;

                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        let message = '';
                        Object.keys(response.data).forEach((field) => {
                            message = response.data[field][0];
                            setError(field as keyof IViewStaffLocation, {
                                type: 'manual',
                                message: message,
                            });
                            return;
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <ClockRewind className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{t('Edit Your Working Hours')}</p>

                <span className="text-xs font-normal text-gray-500">{t('Set working hours of your location')}</span>
            </div>
        </div>
    );

    return (
        <>
            <PopupModal
                dismissible
                onClose={handleClose}
                size="w-[640px]"
                className="px-5 py-5"
                title={<Title />}
                primaryButton={t('Continue')}
                secondaryButton={t('Cancel')}
                acceptAction={handleSubmit(onSubmit)}
                declineAction={handleClose}
                isLoading={isLoading}
                isDisabled={isLoading}
                fixedHeightClass="h-[500px]"
            >
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className=" ">
                            <div className="flex flex-row justify-between items-start gap-5 border border-borderSecondary rounded-3xl p-6 mb-2 shadow">
                                <div className="flex flex-col gap-3 max-w-[304px]">
                                    <div className="font-base leading-[19.36px] font-semibold text-mainTextColor">Appointment intervals</div>
                                    <p className="text-sm font-normal text-gray-500">{t('Set the time intervals at which your calendar will have openings for client bookings.')}</p>
                                </div>
                                <div className="w-full max-w-[200px] login_service">
                                    <Controller
                                        name="frequency_booking"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div>
                                                <SelectBox
                                                    value={INTERVAL_OPTIONS.filter((item: any) => item.value === value)}
                                                    name="frequency_booking"
                                                    id="frequency_booking"
                                                    options={INTERVAL_OPTIONS}
                                                    required
                                                    onChangeFunc={(selectedOption: any) => onChange(selectedOption.value)}
                                                    placeholder={t('Select')}
                                                    errorText={!!error}
                                                    classComp={`!h-[36px] ${error ? 'is-invalid' : ''}`}
                                                    isClearable={false}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col gap-5">
                                <WeekHours errors={errors} name={`hours`} isLoginStep />
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </PopupModal>
        </>
    );
};

export default ViewStaffLocation;
