import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatAmount, formatName, getShortName } from 'src/utils/global-functions';
import NoDataMessage from 'src/components/NoDataMessage';
import { ArrowUp, ArrowDown, List, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { Skeleton } from 'primereact/skeleton';
import Badge from 'src/components/Badge';
import PopupModal from 'src/components/PopupModal';
import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { perPageOptions } from 'src/utils/global-variables';
import { PaginatorTemplate } from 'src/utils/global-component';

const LatestCheckoutPopup = (props: any) => {
    const { handleClose } = props;
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [isImageError, setIsImageError] = useState<{ [type: string]: { [key: number]: boolean } }>({
        staff: {},
        user: {},
    });
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: perPageOptions[0].value,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
        },
    });

    useEffect(() => {
        getList();
    }, [lazyState]);

    const getList = () => {
        setIsLoading(true);
        axiosPost(API.BOOKING.LIST, { type: 'checkout', ...lazyState }, { shop_id: shop.id })
            .then((response) => {
                setData(response.data.data.data);
                setTotalRecords(response.data.data.totalRecords);
            })
            .finally(() => setIsLoading(false));
    };

    const handleImageError = (id: number, types: string) => () => {
        setIsImageError((old: any) => ({ ...old, [types]: { ...old[types], [id]: true } }));
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, first: 0, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, first: 0, filters: { ...old.filters, global: { value } } }));
    };

    const getClient = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.user.profile_image_url && !isImageError.user[row.id] ? (
                    <img src={row.user.profile_image_url} alt="" className="min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] object-cover" onError={handleImageError(row.id, 'user')} />
                ) : (
                    getShortName(row.user.full_name)
                )}
            </figure>
            <div>
                <p className="text-xs font-medium text-gray-900 leading-[18px] w-[120px] min-w-[120px]  max-w-[120px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {row.user.full_name}
                </p>
            </div>
        </div>
    );
    const getTeam = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.staff.profile_image_url && !isImageError.staff[row.id] ? (
                    <img src={row.staff.profile_image_url} alt="" className=" w-full h-full object-cover" onError={handleImageError(row.id, 'staff')} />
                ) : (
                    getShortName(row.staff.full_name)
                )}
            </figure>
            <div>
                <p className="text-xs font-normal text-secondaryTxtColor leading-[18px] w-[120px] min-w-[120px]  max-w-[120px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {formatName(row.staff.full_name)}
                </p>
            </div>
        </div>
    );

    const getService = (row: any) => `${row.service_name} ${row.booking_services.length > 1 ? `+${row.booking_services.length - 1}` : ''}`;

    const getStatus = (row: any) => (
        <Badge icon status={row.status === 'confirmed' ? 'pending' : row.status}>
            {row.status === 'confirmed' ? 'Pending' : row.status}
        </Badge>
    );

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map((_, index) => ({
                id: index,
                booking_date: 'loading',
                total_duration: 'loading',
                shop_services: { name: 'loading' },
                shop_admins: { first_name: 'loading' },
                total: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const Title = () => (
        <div className="flex flex-row gap-3">
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900">{t('Latest Checkouts')}</p>
                <span className="text-xs font-normal text-gray-500">{t('Track overdue and completed appointment checkouts.')}</span>
            </div>
        </div>
    );

    return (
        <PopupModal dismissible onClose={handleClose} size="w-[1140px]" fixedHeightClass="h-[750px] overflow-auto" title={<Title />}>
            <div className={`w-full border rounded-xl flex-col flex shadow datatable-custom h-full ${!totalRecords ? 'datatable-full-height datatable-noshow' : ''}`}>
                <div className="flex items-center justify-between w-full border-b border-gray-200 px-5 py-3 gap-4">
                    <div className="table-searchInput">
                        <SearchBar labelIconClassName="text-gray-500" placeholder={t('Search')} onChange={handleSearch} className="form-control-md" />
                    </div>
                    <div className="location-dropdown">
                        <SelectBox
                            isClearable={false}
                            isSearchable={false}
                            options={perPageOptions}
                            value={perPageOptions.find((option) => option.value === lazyState.rows)}
                            onChangeFunc={onPageHandle}
                            classComp="w-[70px] max-w-[70px]"
                        />
                    </div>
                </div>
                <DataTable
                    lazy
                    value={isLoading ? skeletons : data}
                    totalRecords={totalRecords}
                    paginator={!isLoading && totalRecords > lazyState.rows}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    sortOrder={lazyState.sortOrder}
                    sortField={lazyState.sortField}
                    filters={lazyState.filters}
                    onPage={onPage}
                    onSort={onSort}
                    onFilter={onFilter}
                    paginatorTemplate={PaginatorTemplate()}
                    dataKey="id"
                    className="rounded-xl overflow-hidden"
                    paginatorClassName="table-pagination"
                    emptyMessage={
                        <NoDataMessage
                            title={`${lazyState.filters.global.value ? t('No any latest checkout found') : t('No any latest checkout')}`}
                            description={`${lazyState.filters.global.value ? t('Try adjusting your search criteria.') : t('No have any pending checkout and completed appointments.')}`}
                            iconComponent={lazyState.filters.global.value ? <SearchLg className="text-gray-700" /> : <List className="text-gray-700" />}
                        />
                    }
                >
                    <Column
                        sortable
                        field="user_name"
                        header={renderHeader(t('Client'), 'user_name')}
                        body={isLoading ? <Skeleton /> : getClient}
                        className="truncate"
                        style={{ width: '190px', minWidth: '190px', maxWidth: '190px' }}
                    ></Column>
                    <Column
                        sortable
                        field="staff_name"
                        header={renderHeader(t('Team member'), 'staff_name')}
                        body={isLoading ? <Skeleton /> : getTeam}
                        className="truncate"
                        style={{ width: '190px', minWidth: '190px', maxWidth: '190px' }}
                    ></Column>
                    <Column
                        sortable
                        field="service_name"
                        header={renderHeader(t('Service'), 'service_name')}
                        body={isLoading ? <Skeleton /> : getService}
                        className="text-xs text-gray-600 font-medium truncate"
                        style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                    ></Column>
                    <Column
                        sortable
                        field="status"
                        header={renderHeader(t('Checkout Status'), 'status')}
                        body={isLoading ? <Skeleton /> : getStatus}
                        style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                    ></Column>
                    <Column
                        sortable
                        field="total"
                        header={renderHeader(t('Total'), 'total')}
                        style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                        body={isLoading ? <Skeleton /> : (row: any) => formatAmount(row.total)}
                        className="text-xs font-medium text-mainTextColor truncate"
                    ></Column>
                </DataTable>
            </div>
        </PopupModal>
    );
};

export default LatestCheckoutPopup;
