import React, { FC, useEffect, useMemo, useState } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, allShopStaff, currentShop } from 'src/redux/services/common/Common.slice';
import {
    calculateDueDate,
    capitalizeFirstLetterAndUnderscore,
    capitalizeFirstLetterDash,
    downloadXLSX,
    formatAmount,
    formatDate,
    formatName,
    formatPercentage,
    getDayNumber,
    getSelectBoxOptions,
    getShortName,
} from 'src/utils/global-functions';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { axiosGet, axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import { useTranslation } from 'react-i18next';
import NoDataMessage from 'src/components/NoDataMessage';
import { ArrowUp, ArrowDown, SearchLg, Users01, UploadCloud02, Check } from '@untitled-ui/icons-react/build/cjs';
import SearchBar from 'src/components/SearchBar/SearchBar';
import { AllLocationsOptions, AllTeamMembersOptions, perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import DateRangePicker from 'src/components/DateRangePicker/DateRangePicker';
import Badge from 'src/components/Badge';
import { PaginatorTemplate } from 'src/utils/global-component';
import { INIT_STATE, IStatementTable } from './Staff.interface';

const StaffPayoutTable: FC<IStatementTable> = ({ type }) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const allShopStaffList = useAppSelector(allShopStaff);
    const shopLocationList: any = useAppSelector(allShopLocations);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [lazyState, setLazyState] = useState<any>(INIT_STATE.LAZY_STATE);
    const [filters, setFilters] = useState<any>(INIT_STATE.LAZY_STATE.filters);
    const [isPaidLoading, setIsPaidLoading] = useState<string>('');
    const [payoutList, setPayoutList] = useState<any[]>([]);
    const [totalListRecords, settotalListRecords] = useState(0);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [staffOptions, setStaffOptions] = useState<any[]>([]);
    const [selectedFilter, setSelectedFilter] = useState(INIT_STATE.FILTER);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>('');
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isSingleExporting, setIsSingleExporting] = useState<string>('');
    const [isImageError, setIsImageError] = useState<{ [key: number]: boolean }>({});
    const [datePickerOptions, setDatePickerOptions] = useState<any[]>([]);
    const [details, setDetails] = useState({
        title: '',
        subtitle: '',
        frequency: '',
    });

    useEffect(() => {
        if (startDate && endDate) {
            getPayoutStaffList();
        }
    }, [lazyState]);

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'name', 'profile_image_url');
        setLocationOptions([AllLocationsOptions, ...locationData]);

        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');
        setStaffOptions([AllTeamMembersOptions, ...staff]);
    }, []);

    useEffect(() => {
        let options = [
            { label: 'This Month', value: 'this_month' },
            { label: 'Last Month', value: 'last_month' },
            { label: 'Last 3 Months', value: 'last_quarter' },
            { label: 'This Year', value: 'this_year' },
            { label: 'Last Year', value: 'last_year' },
            { label: 'All Time', value: 'all_time' },
            { label: 'Custom', value: 'custom' },
        ];
        let title = '';
        let subtitle = '';

        let extraOptions: any = [];
        let frequency;
        let day;
        let startDates;
        let monthDay;
        const shopPayment = shop.shop_payment;
        switch (type) {
            case 'rent':
                frequency = shopPayment.rent_frequency;
                day = shopPayment.rent_day;
                startDates = shopPayment.rent_start_date;
                monthDay = shopPayment.rent_month_day;

                title = t('All Rent Collection');
                subtitle = t('Track and manage rent collection history for your rent-based team members.');
                break;
            case 'commission':
                frequency = shopPayment.commission_frequency;
                day = shopPayment.commission_day;
                startDates = shopPayment.commission_start_date;
                monthDay = shopPayment.commission_month_day;

                title = t('All Commission Payouts');
                subtitle = t('Track and manage commission payout history for your commission-based team members.');
                break;
            case 'tip':
                frequency = shopPayment.tip_frequency;
                day = shopPayment.tip_day;
                startDates = shopPayment.tip_start_date;
                monthDay = shopPayment.rent_month_day;

                title = t('All Tip Payouts');
                subtitle = t('Track and manage tip payout history for your commission-based team members.');
                break;
            case 'product_commission':
                frequency = shopPayment.product_commission_frequency;
                day = shopPayment.product_commission_day;
                startDates = shopPayment.product_commission_start_date;
                monthDay = shopPayment.product_commission_month_day;

                title = t('All Product Commission');
                subtitle = t('Track and manage product commission history for your team members.');
                break;
            default:
                break;
        }
        setDetails({
            title,
            subtitle,
            frequency,
        });

        const range = calculateDueDate({
            frequency,
            day,
            currentDateTime: startDates ? moment(startDates) : moment(),
            monthDay,
        });

        if (frequency === 'weekly' || frequency === 'bi_weekly') {
            moment.updateLocale('en', {
                week: {
                    dow: getDayNumber(day),
                },
            });

            if (frequency === 'weekly') {
                extraOptions = [
                    { label: 'This Week', value: 'this_week' },
                    { label: 'Last Week', value: 'last_week' },
                ];
            } else {
                extraOptions = [
                    { label: 'This Bi-Week', value: 'this_bi_week' },
                    { label: 'Last Bi-Week', value: 'last_bi_week' },
                ];
            }
        } else if (frequency === 'daily') {
            extraOptions = [
                { label: 'Yesterday', value: 'yesterday' },
                { label: 'This Week', value: 'this_week' },
                { label: 'Last Week', value: 'last_week' },
            ];
        }

        setStartDate(range?.startDate?.toDate());
        setEndDate(range?.endDate?.toDate());

        const newOptions = [...extraOptions, ...options];
        setDatePickerOptions(newOptions);
        setSelectedTimeRange(newOptions[0].value);

        return () => {
            moment.updateLocale('en', {
                week: {
                    dow: 0,
                    doy: 6,
                },
            });
        };
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            setLazyState({
                ...lazyState,
                page: 1,
                first: 0,
                filters: {
                    ...lazyState.filters,
                    start_date: {
                        value: moment(startDate).format('YYYY-MM-DD'),
                    },
                    end_date: {
                        value: moment(endDate).format('YYYY-MM-DD'),
                    },
                },
            });
        }
    }, [startDate, endDate]);

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const getPayoutStaffList = () => {
        setIsLoading(true);

        const payload = {
            ...lazyState,
            type,
        };

        axiosGet(API.PAYMENT.LIST, { shop_id: shop.id }, payload)
            .then((response) => {
                setPayoutList(response.data.data.data);
                settotalListRecords(response.data.data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const handlePaid = (id: any) => () => {
        setIsPaidLoading(id);
        axiosPatch(API.PAYMENT.RETRY, {}, { shop_id: shop.id, id })
            .then(() => getPayoutStaffList())
            .finally(() => setIsPaidLoading(''));
    };

    const handleExport =
        (id: string = '', name: string = '') =>
        () => {
            if (id) {
                setIsSingleExporting(id);
            } else {
                setIsExporting(true);
            }
            const payload = {
                ...lazyState,
                type,
                is_export: true,
                id,
            };
            axiosGet(API.PAYMENT.LIST, { shop_id: shop.id }, payload)
                .then(async (response) => {
                    const data = response.data.data;
                    downloadXLSX(data, name ? `${name}_${type}_statement` : `${type}_statement`);
                })
                .finally(() => {
                    setIsExporting(false);
                    setIsSingleExporting('');
                });
        };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, first: 0, filters: { ...old.filters, global: { value } } }));
    };

    const handleDatePickerChange = (date: any, timeRange?: string) => {
        const [start, end] = date;
        setStartDate(start);
        setEndDate(end);
        if (!end) {
            setEndDate(start);
        }
        if (timeRange) {
            setSelectedTimeRange(timeRange);
        }
    };

    const handleImageError = (ids: number) => () => {
        setIsImageError((old: any) => ({ ...old, [ids]: true }));
    };

    const GetFullName = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.staff.profile_image_url && !isImageError[row.id] ? (
                    <img src={row.staff.profile_image_url} alt="" className="w-full h-full object-cover" onError={handleImageError(row.id)} />
                ) : (
                    getShortName(row.staff.full_name)
                )}
            </figure>
            <div className="text-mainTextColor">
                <p className="text-xs font-medium mb-[2px] leading-[140%] -tracking-[0.14px] w-[100px] max-w-[100px] xlm:max-w-[110px] xlm:w-[110px] 2xlm:max-w-[130px] 2xlm:w-[130px] xxl:max-w-[140px] xxl:w-[140px] truncate">
                    {row.staff.full_name ? formatName(row.staff.full_name) : ''}
                </p>
                <p className="text-xs font-normal leading-[150%] -tracking-[0.12px] text-secondaryTxtColor w-[100px] max-w-[100px] xlm:max-w-[110px] xlm:w-[110px] 2xlm:max-w-[130px] 2xlm:w-[130px] xxl:max-w-[140px] xxl:w-[140px] truncate">
                    {row.staff.staff_role.name ? `${row.staff.staff_role.name}` : ''}
                </p>
            </div>
        </div>
    );

    const GetTotal = (row: any) => formatAmount(row.amount);
    const GetStartDate = (row: any) => formatDate(row.start_date);
    const GetEndDate = (row: any) => formatDate(row.end_date);

    const GetStatus = (row: any) => (
        <Badge icon status={row.is_paid ? 'completed' : 'canceled'}>
            {row.is_paid ? 'Paid' : 'Not Paid'}
        </Badge>
    );

    const getAction = (row: any) => (
        <div className="flex items-center gap-4 justify-end">
            {!row.is_paid && (
                <CustomButton isLoading={isPaidLoading === row.id} disabled={!!isPaidLoading} onClick={handlePaid(row.id)} className="shadow-none text-gray-600 hover:text-gray-900 w-4 !px-0">
                    <Check className="h-4 w-4 " />
                </CustomButton>
            )}
            <CustomButton
                isLoading={isSingleExporting === row.id}
                disabled={!!isSingleExporting}
                onClick={handleExport(row.id, row.staff.full_name)}
                className="shadow-none w-4 !px-0 text-gray-600 hover:text-gray-900 !pr-[4px]"
            >
                <UploadCloud02 className="h-4 w-4" />
            </CustomButton>
        </div>
    );

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map((_, index) => ({
                id: index,
                start_date: 'loading',
                end_date: 'loading',
                amount: 'loading',
                taxes: 'loading',
                tips: 'loading',
                due_to_staff: 'loading',
                due_to_shop: 'loading',
                commission: 'loading',
                is_paid: 'loading',
                first_name: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const columns = useMemo(() => {
        switch (type) {
            case 'rent':
                return [
                    {
                        sortable: true,
                        field: 'shop_admins.first_name',
                        header: renderHeader(t('Team Member'), 'shop_admins.first_name'),
                        body: isLoading ? <Skeleton /> : GetFullName,
                        className: 'font-medium text-mainTextColor text-xs',
                        style: { width: '170px', minWidth: '170px', maxWidth: '170px' },
                    },
                    {
                        sortable: true,
                        field: 'is_paid',
                        header: renderHeader(t('Status'), 'is_paid'),
                        body: isLoading ? <Skeleton /> : GetStatus,
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                    },
                    {
                        sortable: true,
                        field: 'start_date',
                        header: renderHeader(t('Period Start Date'), 'start_date'),
                        body: isLoading ? <Skeleton /> : GetStartDate,
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'end_date',
                        header: renderHeader(t('Period End Date'), 'end_date'),
                        body: isLoading ? <Skeleton /> : GetEndDate,
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'rent',
                        header: renderHeader(t('Rent'), 'rent'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatAmount(row.rent),
                        style: { width: '100px', minWidth: '100px', maxWidth: '100px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'amount',
                        header: renderHeader(t('Total'), 'amount'),
                        body: isLoading ? <Skeleton /> : GetTotal,
                        style: { width: '100px', minWidth: '100px', maxWidth: '100px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                ];
            case 'commission':
                return [
                    {
                        sortable: true,
                        field: 'shop_admins.first_name',
                        header: renderHeader(t('Team Member'), 'shop_admins.first_name'),
                        body: isLoading ? <Skeleton /> : GetFullName,
                        className: 'font-medium text-mainTextColor text-xs',
                        style: { width: '170px', minWidth: '170px', maxWidth: '170px' },
                    },
                    {
                        sortable: true,
                        field: 'is_paid',
                        header: renderHeader(t('Status'), 'is_paid'),
                        body: isLoading ? <Skeleton /> : GetStatus,
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                    },
                    {
                        sortable: true,
                        field: 'start_date',
                        header: renderHeader(t('Period Start Date'), 'start_date'),
                        body: isLoading ? <Skeleton /> : GetStartDate,
                        style: { width: '135px', minWidth: '135px', maxWidth: '135px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'end_date',
                        header: renderHeader(t('Period End Date'), 'end_date'),
                        body: isLoading ? <Skeleton /> : GetEndDate,
                        style: { width: '135px', minWidth: '135px', maxWidth: '135px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'commission',
                        header: renderHeader(t('Commission'), 'commission'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatPercentage(row.commission),
                        style: { width: '110px', minWidth: '110px', maxWidth: '110px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'amount',
                        header: renderHeader(t('Total'), 'amount'),
                        body: isLoading ? <Skeleton /> : GetTotal,
                        style: { width: '100px', minWidth: '100px', maxWidth: '100px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        field: 'tips',
                        sortable: true,
                        header: renderHeader(t('Tips'), 'tips'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatAmount(row.tips),
                        style: { width: '80px', minWidth: '80px', maxWidth: '80px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        field: 'due_to_shop',
                        sortable: true,
                        header: renderHeader(t('Brand Commission'), 'due_to_shop'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatAmount(row.due_to_shop),
                        style: { width: '150px', minWidth: '150px', maxWidth: '150px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        field: 'due_to_staff',
                        sortable: true,
                        header: renderHeader(t('Team Member Commission'), 'due_to_staff'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatAmount(row.due_to_staff),
                        style: { width: '180px', minWidth: '180px', maxWidth: '180px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                ];
            case 'tip':
                return [
                    {
                        sortable: true,
                        field: 'shop_admins.first_name',
                        header: renderHeader(t('Team Member'), 'shop_admins.first_name'),
                        body: isLoading ? <Skeleton /> : GetFullName,
                        className: 'font-medium text-mainTextColor text-xs',
                        style: { width: '170px', minWidth: '170px', maxWidth: '170px' },
                    },
                    {
                        sortable: true,
                        field: 'is_paid',
                        header: renderHeader(t('Status'), 'is_paid'),
                        body: isLoading ? <Skeleton /> : GetStatus,
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                        style: { width: '120px', minWidth: '120px', maxWidth: '120px' },
                    },
                    {
                        sortable: true,
                        field: 'start_date',
                        header: renderHeader(t('Period Start Date'), 'start_date'),
                        body: isLoading ? <Skeleton /> : GetStartDate,
                        style: { width: '140px', minWidth: '140px', maxWidth: '140px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'end_date',
                        header: renderHeader(t('Period End Date'), 'end_date'),
                        body: isLoading ? <Skeleton /> : GetEndDate,
                        style: { width: '140px', minWidth: '140px', maxWidth: '140px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'total_bookings',
                        header: renderHeader(t('Total Appointment'), 'total_bookings'),
                        body: isLoading ? <Skeleton /> : undefined,
                        style: { width: '140px', minWidth: '140px', maxWidth: '140px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'amount',
                        header: renderHeader(t('Total Tips'), 'amount'),
                        body: isLoading ? <Skeleton /> : GetTotal,
                        style: { width: '140px', minWidth: '140px', maxWidth: '140px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                ];
            case 'product_commission':
                return [
                    {
                        sortable: true,
                        field: 'shop_admins.first_name',
                        header: renderHeader(t('Team Member'), 'shop_admins.first_name'),
                        body: isLoading ? <Skeleton /> : GetFullName,
                        className: 'font-medium text-mainTextColor text-xs',
                        style: { width: '200px', minWidth: '200px', maxWidth: '200px' },
                    },
                    {
                        sortable: true,
                        field: 'is_paid',
                        header: renderHeader(t('Status'), 'is_paid'),
                        body: isLoading ? <Skeleton /> : GetStatus,
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                        style: { width: '100px', minWidth: '100px', maxWidth: '100px' },
                    },
                    {
                        sortable: true,
                        field: 'start_date',
                        header: renderHeader(t('Period Start Date'), 'start_date'),
                        body: isLoading ? <Skeleton /> : GetStartDate,
                        style: { width: '135px', minWidth: '135px', maxWidth: '135px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'end_date',
                        header: renderHeader(t('Period End Date'), 'end_date'),
                        body: isLoading ? <Skeleton /> : GetEndDate,
                        style: { width: '135px', minWidth: '135px', maxWidth: '135px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'product_commission',
                        header: renderHeader(t('Product Commission'), 'product_commission'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatPercentage(row.product_commission),
                        style: { width: '160px', minWidth: '160px', maxWidth: '160px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'amount',
                        header: renderHeader(t('Total'), 'amount'),
                        body: isLoading ? <Skeleton /> : GetTotal,
                        style: { width: '100px', minWidth: '100px', maxWidth: '100px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'due_to_shop',
                        header: renderHeader(t('Brand Commission'), 'due_to_shop'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatAmount(row.due_to_shop),
                        style: { width: '140px', minWidth: '140px', maxWidth: '140px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                    {
                        sortable: true,
                        field: 'due_to_staff',
                        header: renderHeader(t('Team Member Commission'), 'due_to_staff'),
                        body: isLoading ? <Skeleton /> : (row: any) => formatAmount(row.due_to_staff),
                        style: { width: '180px', minWidth: '180px', maxWidth: '180px' },
                        className: 'font-medium text-secondaryTxtColor truncate text-xs',
                    },
                ];
            default:
                return [];
        }
    }, [isLoading]);

    return (
        <div
            className={` w-full border rounded-xl mt-4 flex-1 flex-col mb-5 flex shadow  staff_service_table datatable-custom-service ${
                !totalListRecords ? 'datatable-full-height datatable-noshow' : ''
            }`}
        >
            <div className="w-full justify-between items-center">
                <div className={` flex items-center flex-col w-full border-b border-gray-200`}>
                    <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                        <div className="flex flex-col  flex-1">
                            <div className="flex justify-start items-center gap-1.5">
                                <h2 className="table-title">{details.title}</h2>
                                <Badge icon>{capitalizeFirstLetterDash(details.frequency)}</Badge>
                            </div>
                            <p className="table-subtitle">{details.subtitle}</p>
                        </div>
                    </div>
                    <div className={`flex max-2xl:flex-col  items-start  justify-between w-full `}>
                        <div className="max-2xl:flex max-2xl:border-b max-2xl:w-full px-5 py-3 max-2xl:border-borderSecondary max-2xl:gap-5">
                            <div className="max-2xl:w-[50%] 2xl:table-searchInput">
                                <SearchBar placeholder={t('Search')} className="form-control-md " onChange={handleSearch} />
                            </div>
                            <div className="location-dropdown 2xl:hidden max-2xl:w-[50%]">
                                <div className="w-full flex justify-center">
                                    <DateRangePicker
                                        isDisplayAsBox={false}
                                        showYearDropdown={false}
                                        isShowDropDownIcon={false}
                                        options={datePickerOptions}
                                        selectedTimeRange={selectedTimeRange}
                                        activeFilter={selectedTimeRange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        allTimeStartDate={shop.created_at}
                                        handleDatePickerChange={handleDatePickerChange}
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                        containerClassName="right-0"
                                        parentClassName="sales-datepicker min-w-[340px] "
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-3 px-5 py-3 max-2xl:w-full">
                            <div className="location-dropdown max-2xl:hidden">
                                <div className="w-full flex justify-center">
                                    <DateRangePicker
                                        isDisplayAsBox={false}
                                        showYearDropdown={false}
                                        isShowDropDownIcon={false}
                                        options={datePickerOptions}
                                        selectedTimeRange={selectedTimeRange}
                                        activeFilter={selectedTimeRange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        allTimeStartDate={shop.created_at}
                                        handleDatePickerChange={handleDatePickerChange}
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                        containerClassName="left-0"
                                        parentClassName="sales-datepicker w-[280px] min-w-[280px] xlm:w-[310px] xlm:min-w-[310px] xxl:w-[340px] xxl:min-w-[340px]"
                                    />
                                </div>
                            </div>
                            <div className="max-2xl:w-[40%] w-[180px] ">
                                <SelectBox
                                    options={locationOptions}
                                    placeholder={t('All locations')}
                                    noOptionsMessage="No Locations Found"
                                    value={locationOptions.find((option) => option.value === selectedFilter.shop_location_id)}
                                    onChangeFunc={handleFilter('shop_location_id')}
                                    classComp="outline-select-box"
                                    isClearable={false}
                                    isSearchable={false}
                                />
                            </div>
                            <div className="max-2xl:w-[40%] w-[180px]">
                                <SelectBox
                                    options={staffOptions}
                                    value={staffOptions.find((option) => option.value === selectedFilter.shop_admin_id)}
                                    noOptionsMessage="No Team Found"
                                    placeholder={t('All Team members')}
                                    onChangeFunc={handleFilter('shop_admin_id')}
                                    classComp="outline-select-box"
                                    isClearable={false}
                                    isSearchable={false}
                                />
                            </div>
                            <div className="">
                                <CustomButton
                                    isLoading={isExporting}
                                    disabled={isExporting || !totalListRecords}
                                    secondary
                                    outlineSecondary
                                    icon={<UploadCloud02 className="w-[18px] h-[18px] mt-[2px]" />}
                                    onClick={handleExport()}
                                    className="flex items-center"
                                >
                                    {t('Export')}
                                </CustomButton>
                            </div>
                            <div className="w-[8%] 2xl:w-[70px] page-dropdown">
                                <SelectBox
                                    name="page"
                                    options={perPageOptions}
                                    isClearable={false}
                                    isSearchable={false}
                                    onChangeFunc={onPageHandle}
                                    value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                    classComp="outline-select-box"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DataTable
                lazy
                paginatorTemplate={PaginatorTemplate()}
                value={isLoading ? skeletons : payoutList}
                paginator={!isLoading && totalListRecords > lazyState.rows}
                totalRecords={totalListRecords}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                filters={lazyState.filters}
                sortField={lazyState.sortField}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                dataKey="id"
                className="rounded-b-md overflow-hidden w-full overflow-x-auto"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <NoDataMessage
                        title={`${
                            filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value || filters.start_date.value || filters.end_date.value
                                ? t('No Statement Found.', { dataType: capitalizeFirstLetterAndUnderscore(type) })
                                : t('No Statement Recorded.', { dataType: capitalizeFirstLetterAndUnderscore(type) })
                        }`}
                        description={`${
                            filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value || filters.start_date.value || filters.end_date.value
                                ? t('No statements found matching your search criteria. Try adjusting your filters or record new transactions.')
                                : t('Add a team member with a relevant payment structure to start tracking statements.', { dataType: capitalizeFirstLetterAndUnderscore(type) })
                        }`}
                        iconComponent={
                            filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value || filters.start_date.value || filters.end_date.value ? (
                                <SearchLg className="text-gray-700" />
                            ) : (
                                <Users01 className="text-gray-700" />
                            )
                        }
                    />
                }
            >
                {columns.map((col) => (
                    <Column key={col.field} {...col} />
                ))}
                <Column field="action" style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }} body={isLoading ? <Skeleton /> : getAction}></Column>
            </DataTable>
        </div>
    );
};

export default StaffPayoutTable;
