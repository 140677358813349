import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { AppearanceTestimonial } from 'src/theme/Images';
import { Collapse } from '../Collapse';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { IFormData, ITestimonial, TestimonialProps } from './Testimonial.interface';
import Form from './Form';
import { s3Upload } from 'src/utils/s3Operations';
import { s3Path } from 'src/constants/s3Path';
import TemplateLayout from '../Layout/Layout';
import { currentLanguage, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import Switch from 'src/components/Switch/Switch';
import CustomButton from 'src/components/CustomButton';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import { CHARACTERS_LIMIT } from 'src/constants/common';
import { useLanguage } from 'src/hooks/useCommon';
import RadioSwitch from 'src/components/RadioSwitch';

const Testimonial: FC<TestimonialProps> = (props) => {
    const { section } = props;
    const { t } = useTranslation();
    const { languageOptions, languages, handleLanguage, handleErrorsAndSetLanguage } = useLanguage();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const language = useAppSelector(currentLanguage);

    const schemaFields: any = {
        status: Yup.bool().required(t('This field is required')),
        is_title: Yup.bool().required(t('This field is required')),
        is_subtitle: Yup.bool().required(t('This field is required')),
        testimonials: Yup.array()
            .test({
                name: 'testimonials',
                message: 'Please add at least one testimonial',
                test: function (value) {
                    const { status } = this.parent;
                    if (status && (!value || value.length === 0)) {
                        return false;
                    }
                    return true;
                },
            })
            .of(
                Yup.object().shape({
                    name: Yup.string().required(t('This field is required')),
                    is_description: Yup.bool().required(t('This field is required')),
                    image: Yup.object()
                        .shape({
                            file: Yup.mixed().nullable(),
                            url: Yup.string().nullable(),
                            name: Yup.string().required(t('This field is required')),
                        })
                        .test('required', t('This field is required'), function (value) {
                            if (!value) {
                                return this.createError({
                                    path: `${this.path}`,
                                    message: t('This field is required'),
                                });
                            }
                            const { name, url } = value;
                            if (!name || !url) {
                                return this.createError({
                                    path: `${this.path}`,
                                    message: t('This field is required'),
                                });
                            }
                            return true;
                        }),
                    ...languages.reduce((fields: any, name) => {
                        fields[`${name}_description`] = Yup.string().when('is_description', ([is_description], customSchema) =>
                            is_description
                                ? customSchema.required(t('This field is required')).max(CHARACTERS_LIMIT.LONG, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.LONG }))
                                : customSchema.nullable(),
                        );
                        return fields;
                    }, {}),
                }),
            )
            .when('status', ([status], customSchema) => (status ? customSchema.required() : customSchema.nullable()))
            .nullable(),
    };
    languages.forEach((name: string) => {
        schemaFields[`${name}_title`] = Yup.string().when('is_title', ([is_title], customSchema) =>
            is_title ? customSchema.required(t('This field is required.')).max(CHARACTERS_LIMIT.SHORT, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.SHORT })) : customSchema.nullable(),
        );
        schemaFields[`${name}_subtitle`] = Yup.string().when('is_subtitle', ([is_subtitle], customSchema) =>
            is_subtitle ? customSchema.required('This field is required.').max(CHARACTERS_LIMIT.MEDIUM, t('Errors.Max Characters', { number: CHARACTERS_LIMIT.MEDIUM })) : customSchema.nullable(),
        );
    });
    const schema = Yup.object(schemaFields);

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as unknown as Resolver<IFormData>,
    });

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = methods;
    const isStatus = watch('status');

    useEffect(() => {
        let existingData: IFormData = {
            status: false,
            is_title: false,
            is_subtitle: false,
            testimonials: [],
        };
        languages.forEach((locale) => {
            existingData[`${locale}_title`] = '';
            existingData[`${locale}_subtitle`] = '';
        });
        if (section) {
            const testimonials = section.testimonials.map((testimonial: any) => {
                const newData: ITestimonial = {
                    image: testimonial.image
                        ? {
                              name: testimonial.image,
                              url: testimonial.image && testimonial.image_url,
                              file: null,
                          }
                        : null,
                    name: testimonial.name,
                    is_description: testimonial.is_description,
                };
                languages.forEach((locale) => {
                    newData[`${locale}_description`] = testimonial[`${locale}_description`] || '';
                });

                return newData;
            });
            existingData = {
                status: !!section.status,
                is_title: !!section.is_title,
                is_subtitle: !!section.is_subtitle,
                testimonials,
            };
            languages.forEach((locale) => {
                existingData[`${locale}_title`] = section[`${locale}_title`] || '';
                existingData[`${locale}_subtitle`] = section[`${locale}_subtitle`] || '';
            });
        }
        Object.entries(existingData).forEach(([key, value]) => {
            setValue(key as keyof IFormData, value);
        });
        reset(existingData);
    }, [section]);

    const handleChange = async (data: IFormData) => {
        setIsLoading(true);

        const imagePromises = data?.testimonials?.map((testimonial: ITestimonial) =>
            testimonial?.image?.file ? s3Upload(testimonial.image.file, `${s3Path.SHOP_TESTIMONIAL}${testimonial.image.name}`) : Promise.resolve(null),
        );
        const imageResponses = await Promise.allSettled(imagePromises);
        const isSuccess = imageResponses.every((response: any) => response.status === 'fulfilled' && (response.value?.status === errorCode.updateSuccess || response?.value === null));
        if (isSuccess) {
            const payload = {
                testimonial: {
                    ...data,
                    testimonials: data.testimonials.map((testimonial: ITestimonial) => ({
                        ...testimonial,
                        image: testimonial?.image?.name,
                    })),
                },
                section: 'testimonial',
            };
            axiosPost(API.THEME.TEMPLATE.SECTION, payload, { shop_id: shop.id })
                .then(async () => {
                    await dispatch(getAllShopSettings({ shop_id: shop.id }));
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                const fieldKey = field.replace('testimonial.', '');
                                setError(fieldKey as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
            toast.error(t('Something went wrong!'));
        }
    };
    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };
    useFormErrorFocus<IFormData>({ errors, formSectionName: 'testimonial' });
    const handleError = (err: any) => handleErrorsAndSetLanguage(err);
    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit(handleChange, handleError)}
                className={`h-full ${errors?.testimonials && errors?.testimonials?.message ? 'is-invalid rounded-xl border' : 'rounded-xl border border-borderPrimary'}`}
            >
                <Collapse
                    title={t('Testimonials')}
                    description={t('The Testimonials section features reviews and feedback from satisfied customers to build trust and credibility.')}
                    isCollapsed={isStatus}
                    isLoading={isLoading}
                    handleStatus={handleStatus}
                    handleSave={handleSubmit(handleChange, handleError)}
                >
                    {languages.length > 1 && (
                        <div className="flex justify-end mt-5 mx-5">
                            <RadioSwitch options={languageOptions} name="testimonial_language" value={language} onChange={handleLanguage} />
                        </div>
                    )}
                    <TemplateLayout ImgProp={AppearanceTestimonial} key={language}>
                        <div>
                            <div className="flex justify-between items-start mb-4 gap-4">
                                <Controller
                                    name={`${language}_title`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id={`${language}_title`}
                                                name={`${language}_title`}
                                                label={t('Title')}
                                                placeholder={t('Enter testimonial title')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name="is_title"
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name={`${language}_subtitle`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id={`${language}_subtitle`}
                                                name={`${language}_subtitle`}
                                                label={t('SubTitle')}
                                                placeholder={t('Enter testimonial subtitle')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                textAearClassName="rounded-md h-[100px]"
                                                toggle={
                                                    <Controller
                                                        name="is_subtitle"
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <Form key={language} language={language} />
                        </div>
                        {errors.testimonials && errors?.testimonials?.message && <p className="text-error text-center">{errors?.testimonials?.message}</p>}
                    </TemplateLayout>
                    <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end">
                        <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </Collapse>
            </form>
        </FormProvider>
    );
};

export default Testimonial;
