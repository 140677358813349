export const APP_ROOT = '/';

export const ROUTES = {
    HOME: `${APP_ROOT}`,
    MOBILE: `${APP_ROOT}mobile`,
    OTP: `${APP_ROOT}otp`,
    REGISTER: `${APP_ROOT}register`,
    FORGOT_PASSWORD: `${APP_ROOT}forgot-password`,
    DASHBOARD: `${APP_ROOT}dashboard`,
    ANALYTICS: `${APP_ROOT}analytics`,
    CALENDAR: `${APP_ROOT}calendar`,
    SIGNUP: `${APP_ROOT}signup`,
    MULTI_BRAND: `${APP_ROOT}multi-brand`,
    INVITE: `${APP_ROOT}invite`,
    STRIPE_CONNECT_RETURN: `${APP_ROOT}stripe-connect-return`,
    SETTINGS: `${APP_ROOT}settings/:tab?`,
    ADMIN: `${APP_ROOT}admin`,
    PAYMENT: `${APP_ROOT}payment`,
    SALES: `${APP_ROOT}sales`,
    SUPPORT: `${APP_ROOT}support`,
    APPEARANCE: `${APP_ROOT}appearance`,
    ENV: `${APP_ROOT}env/:type`,
    STAFF: {
        LIST: `${APP_ROOT}staff`,
        CREATE: `${APP_ROOT}staff/form`,
        UPDATE: `${APP_ROOT}staff/form/:id`,
        VIEW: `${APP_ROOT}staff/:id`,
    },
    LOCATION: {
        LIST: `${APP_ROOT}locations`,
        CREATE: `${APP_ROOT}locations/form`,
        UPDATE: `${APP_ROOT}locations/form/:id`,
        VIEW: `${APP_ROOT}locations/:id`,
    },
    SERVICES: {
        LIST: `${APP_ROOT}services/:tab?`,
        CREATE: `${APP_ROOT}services/form`,
        UPDATE: `${APP_ROOT}services/form/:id`,
        CATEGORY: {
            CREATE: `${APP_ROOT}services/category/form`,
            UPDATE: `${APP_ROOT}services/category/form/:id`,
        },
    },
    PRODUCT: {
        LIST: `${APP_ROOT}products/:tab?`,
        CREATE: `${APP_ROOT}products/form`,
        UPDATE: `${APP_ROOT}products/form/:id`,
        VIEW: `${APP_ROOT}products/view/:id`,
        CATEGORY: {
            CREATE: `${APP_ROOT}products/category/form`,
            UPDATE: `${APP_ROOT}products/category/form/:id`,
        },
    },
    CLIENT: {
        LIST: `${APP_ROOT}client`,
        CREATE: `${APP_ROOT}client/form`,
        UPDATE: `${APP_ROOT}client/form/:id`,
        VIEW: `${APP_ROOT}client/:id`,
    },
};
