import React, { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Collapse } from '../Collapse';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import Number from 'src/components/Number';
import { get } from 'lodash';
import Switch from 'src/components/Switch/Switch';
import { ILocationData, InitAppendLocationData } from './Location.interface';
import { Label } from 'flowbite-react';
import { checkFileTypeValidation, convertBase64ToFile } from 'src/utils/global-functions';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import { Button } from 'primereact/button';
import CropperModal from 'src/components/CropperModal/CropperModal';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import ImageUpload from 'src/components/ImageUpload';
import { FILE_VALIDATION } from 'src/utils/global-variables';
import WeekHours from 'src/components/WeekHours/WeekHours';
const Form = () => {
    const {
        control,
        setValue,
        setError,
        watch,
        clearErrors,
        formState: { errors },
    } = useFormContext<ILocationData>();
    const [isUpload, setIsUpload] = useState(false);
    const [image, setImage] = useState<any>('');
    const [fieldName, setFieldName] = useState<any>(null);
    const { t } = useTranslation();
    const { remove, fields, append } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'locations',
    });
    const removeLocation = (index: number) => () => {
        remove(index);
    };

    const onChangeCrop = (fieldNames: any) => async (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const imageValidation = {
            files: files,
            ...FILE_VALIDATION.APPEARANCE.LOCATION,
        };
        const { result, message } = await checkFileTypeValidation(imageValidation);
        if (!result) {
            setError(fieldNames, {
                type: 'manual',
                message: message,
            });
            return;
        } else {
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setIsUpload(false);
            };

            reader.onloadend = () => {
                setImage(reader.result as any);
                setIsUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            setFieldName(fieldNames);
            clearErrors(fieldNames);
        }
    };
    const handleCropData = (data: any) => {
        const convertedFile = convertBase64ToFile(data);
        if (convertedFile && convertedFile.filename && fieldName) {
            setValue(fieldName, {
                file: convertedFile.convertedFile as File,
                url: data,
                name: convertedFile.filename,
            });
        }
    };

    const addLocation = () => {
        append(InitAppendLocationData);
    };
    useFormErrorFocus<FormData>({ errors });

    return (
        <div className="data-wrap-block space-y-4">
            {fields?.map((item: any, index: number) => (
                <div key={item.uuid} className={`rounded-xl border border-borderSecondary mb-4 ${errors.locations?.[index] ? 'is-invalid' : ''}`}>
                    <Collapse title={watch(`locations.${index}.title`) || `${t('Location Title')}`} classNames="!p-4 !border-borderSecondary" handleRemove={removeLocation(index)}>
                        <div className="!p-4 flex flex-col gap-4">
                            <div className="flex gap-4 location-dropdown-custom ">
                                <Controller
                                    name={`locations.${index}.title`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-1/2">
                                            <InputWithLabel
                                                name={`locations.${index}.title`}
                                                id={`locations.${index}.title`}
                                                label={t('Title')}
                                                placeholder={t('Enter location title')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name={`locations.${index}.is_title`}
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {error?.type && error.type !== 'required' && error.type !== 'nullable' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name={`locations.${index}.contact`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-1/2">
                                            <div className="flex items-center gap-[10px] mb-[6px]" color="gray">
                                                <label htmlFor="" className="fl-field-title-label mb-0">
                                                    {t('Contact')}
                                                </label>
                                                <Controller
                                                    name={`locations.${index}.is_contact`}
                                                    control={control}
                                                    render={({ field, fieldState }: any) => (
                                                        <>
                                                            <Switch id={`locations.${index}.contact`} className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                            {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <Controller
                                                name={`locations.${index}.country_code`}
                                                control={control}
                                                render={({ field, fieldState }: any) => (
                                                    <>
                                                        <Number
                                                            errors={error}
                                                            name={`locations.${index}.contact`}
                                                            id={`locations.${index}.country_code`}
                                                            parentClassName="min-h-9"
                                                            value={value}
                                                            defaultValues={{
                                                                phone: value,
                                                                phone_country_code: field.value,
                                                            }}
                                                            onNumberChange={(country: any, phone: any, code: any, isValid: any) => {
                                                                onChange(`+${code}${phone}`);
                                                                field.onChange(country);
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            />

                                            {error?.type && error.type !== 'required' && error.type !== 'nullable' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <Controller
                                name={`locations.${index}.image`}
                                control={control}
                                render={({ field: { value }, fieldState: { error } }: any) => (
                                    <div id={`locations.${index}.image`} className="">
                                        <div className="flex items-center gap-[10px] mb-[6px]" color="gray">
                                            <label htmlFor="" className="fl-field-title-label mb-0">
                                                {t('Image')}
                                            </label>
                                            <Controller
                                                name={`locations.${index}.is_image`}
                                                control={control}
                                                render={({ field, fieldState }: any) => (
                                                    <>
                                                        <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                        {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                    </>
                                                )}
                                            />
                                        </div>

                                        <ImageUpload cropData={value?.url} onChangeCrop={onChangeCrop(`locations.${index}.image`)} id={`locations_${index}_image`} shape="rectangle" error={error} />
                                    </div>
                                )}
                            />
                            <Controller
                                name={`locations.${index}.hours`}
                                control={control}
                                key={`shop_locations.[${index}].shop_location_name`}
                                render={() => (
                                    <>
                                        <Label className="mt-2 font-semibold">{t('Working Hours')}</Label>
                                        <WeekHours errors={get(errors, `locations[${index}]`)} baseTabIndex={index} index={index} name={`locations.${index}.hours`} />
                                    </>
                                )}
                            />
                        </div>
                    </Collapse>
                </div>
            ))}
            <div className="flex justify-end !mt-2 !mb-2">
                <Button type="button" className="text-xs font-medium text-blue-600 flex gap-1 focus:shadow-none" onClick={addLocation}>
                    <Plus width={16} />
                    <span className="">{t('Add another location')}</span>
                </Button>
            </div>
            {isUpload && fieldName && (
                <CropperModal
                    imageUrl={image}
                    setUpload={setIsUpload}
                    setCropData={handleCropData}
                    defaultCropType="Rectangular"
                    title={t('Background Image')}
                    description={t('Upload a  max 800Kb image for best results.')}
                    isCropType={false}
                    btnTitle={t('Confirm')}
                />
            )}
        </div>
    );
};
export default Form;
