import React from 'react';
import { MarkerPin01 } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import { image1Location } from 'src/theme/Images';
import SLocationBooking from './SLocationBooking';
import { useRolePermission } from 'src/hooks/useRolePermission';

const SLocationDetails = () => {
    const { t } = useTranslation();
    const { isAdmin } = useRolePermission();

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return (
        <>
            <div className="flex flex-col flex-1">
                <div className="xlm:px-8 px-5 relative mb-[26px] w-full xl:pt-[10px] pt-2 pb-2 flex sms:gap-4 gap-2 xs:items-end items-start">
                    <div className="custom-loading max-2xl:w-[112px] max-2xl:h-[112px] w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px]">
                        <img src={image1Location} alt="" />
                    </div>
                    <div className="flex justify-between items-center w-full">
                        <div>
                            <h1 className="mr-3 text-gray-900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px] custom-loading">Bloor Street</h1>
                            <div className="flex gap-2.5 text-gray-600 sm:text-sm text-xs mt-1 font-normal items-center">
                                <MarkerPin01 className="h-4 w-4" />
                                <p className="text-sm font-normal text-secondaryTxtColor custom-loading">Bloor St East at Church St, Toronto, ON M4W</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-t border-[#EAECF0] py-[30px] bg-white flex flex-wrap flex-1 xlm:px-8 px-5">
                    <div className="flex flex-col xl:w-[calc(100%_-_370px)] xls:w-[calc(100%_-_342px)]  w-full xl:mr-8 xls:mr-4 lg:mr-2 mr-0 xls:mb-0 mb-8">
                        <div className={`${isAdmin ? 'flex justify-between mb-6 pb-5 border-b' : ' grid grid-cols-3 pb-6'}`}>
                            <div className="title-block">
                                <p className="location-card-heading ">{t('Contact number')}</p>
                                <h3 className="location-card-heading-detail custom-loading">+1 (204) 555-4545</h3>
                            </div>
                            {isAdmin && (
                                <>
                                    <div className="title-block">
                                        <p className="location-card-heading ">{t('Default currency')}</p>
                                        <h3 className="location-card-heading-detail custom-loading">USD</h3>
                                    </div>
                                    <div className="title-block">
                                        <p className="location-card-heading">{t('Time zone')}</p>
                                        <h3 className="location-card-heading-detail custom-loading">loading/loading</h3>
                                    </div>
                                </>
                            )}
                            <div className="title-block">
                                <p className="location-card-heading">Total Clients</p>
                                <h3 className="location-card-heading-detail custom-loading">123</h3>
                            </div>
                            <div className="title-block">
                                <p className="location-card-heading">Active Clients</p>
                                <h3 className="location-card-heading-detail custom-loading">123</h3>
                            </div>
                        </div>
                        {isAdmin && (
                            <>
                                <div className="mb-8">
                                    <div className="flex justify-start items-center gap-[6px] mb-3">
                                        <h2 className="text-lg font-semibold">{t('Team Members')}</h2>
                                        <span className="custom-loading">loadingload</span>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4 justify-start items-start gap-4">
                                        {[...Array(12)].map((_$, index) => (
                                            <div key={index} className="w-full hover:shadow flex items-center gap-3 border rounded-lg p-3 border-btnborder shadow-InputAndButton cursor-pointer">
                                                <div className="w-[40px] h-[40px] rounded-full object-cover custom-loading">
                                                    <img src={image1Location} alt="" />
                                                </div>
                                                <div>
                                                    <span className="xls:w-[70px] custom-loading">loadingingloa</span>
                                                    <p className="xls:w-[123px] 2xl:w-[170px] xlm:w-[175px] xxl:w-[175px] h-[17px] truncate custom-loading">loadingloadingingin</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="mb-8">
                                    <div className="flex justify-start items-center gap-[6px] mb-3">
                                        <h2 className="text-lg font-semibold">{t('Services')}</h2>
                                        <span className="custom-loading">loadingload</span>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4 justify-start items-start gap-4">
                                        {[...Array(4)].map((_$, index) => (
                                            <div key={index} className="w-full cursor-pointer hover:shadow border rounded-lg p-3 border-btnborder shadow-InputAndButton">
                                                <div className="flex justify-start items-center gap-[6px] mb-0.5">
                                                    <p className="text-gray-900 font-semibold text-sm truncate custom-loading">loadingload</p>
                                                </div>
                                                <p className="header_notification_menu_profile_date truncate custom-loading">Traditional styles with a modern twist, tai</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="mb-3 viewLocation">
                            <SLocationBooking />
                        </div>
                    </div>
                    <div className="w-full xl:w-[306px] xls:w-[306px]  xl:ml-8 xls:ml-4 lg:ml-2 ml-0">
                        <div className="mb-4 custom-loading w-[300px] h-[306px]">
                            <img src={image1Location} alt="" />
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col">
                                <h6 className="sideBarHeading">{t('Address')}</h6>
                                <p className="sideBarSubHeading custom-loading">Bloor St East at Church St, Toronto, </p>
                            </div>
                            {isAdmin && (
                                <>
                                    <div>
                                        <h6 className="sideBarHeading">{t('Tax Details')}</h6>
                                        <div className="w-full border border-btnborder rounded-md p-3 mb-3">
                                            <p className="card_heading">{t('Business tax ID')}</p>
                                            <p className="card_sub_heading custom-loading">455154578</p>
                                        </div>
                                        <div className="flex justify-between gap-3">
                                            <div className="w-full border border-btnborder rounded-md p-3">
                                                <p className="card_heading">{t('GST')}</p>
                                                <p className="card_sub_heading custom-loading">%123</p>
                                            </div>
                                            <div className="w-full border border-btnborder rounded-md p-3">
                                                <p className="card_heading">{t('PST')}</p>
                                                <p className="card_sub_heading custom-loading">%123</p>
                                            </div>
                                            <div className="w-full border border-btnborder rounded-md p-3">
                                                <p className="card_heading">{t('HST')}</p>
                                                <p className="card_sub_heading custom-loading">%123</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <h6 className="text-sm font-semibold mb-[10px]">{t('Default Tipping Options')}</h6>
                                        <div className="flex justify-start gap-3">
                                            {[...Array(4)].map((_$, index) => (
                                                <div key={index} className="w-full border border-blue-300 rounded-md py-2 px-3">
                                                    <span className="text-sm font-medium custom-loading">30%</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div>
                                <h6 className="text-sm font-semibold mb-4">{t('Working Hours')}</h6>
                                <div className="grid grid-cols-2 justify-start gap-4">
                                    {daysOfWeek.map((day, index2) => (
                                        <div key={index2} className="w-full">
                                            <span className="header_notification_menu_profile_date mb-1 capitalize custom-loading">{day}</span>
                                            <p className="text-xs font-semibold text-gray-600 custom-loading">09:00AM - 09:00PM</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SLocationDetails;
