import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import './../../utils/Datatable.scss';
import './../../utils/prime-react-datatable.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { currentShop, currentTerminal, setTerminal } from 'src/redux/services/common/Common.slice';
import { Box, Edit01, Trash01, ArrowUp, ArrowDown, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import { axiosDelete, axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import NoDataMessage from 'src/components/NoDataMessage';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import { Skeleton } from 'primereact/skeleton';
import { perPageOptions } from 'src/utils/global-variables';
import ReaderForm from './ReaderForm';
import { initAction } from './Terminal.interface';
import Badge from 'src/components/Badge';
import moment from 'moment';
import { selectCalendarData } from '../Calendar/Calendar.slice';
import { ICalendarData } from '../Calendar/Calendar.Interface';
import { PaginatorTemplate } from 'src/utils/global-component';

const ReaderTable = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const calendarData: ICalendarData = useAppSelector(selectCalendarData);
    const terminal = useAppSelector(currentTerminal);
    const shop = useAppSelector(currentShop);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [readers, setReaders] = useState([]);
    const [action, setAction] = useState(initAction);
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: 25,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
        },
    });
    const [filters, setFilters] = useState<any>({
        global: { value: null },
    });

    useEffect(() => {
        listReaders();
    }, [lazyState]);

    const listReaders = () => {
        setIsLoading(true);
        axiosGet(API.STRIPE.READER.LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setReaders(response.data.data);
                setTotal(response.data.data.length);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const handleAction =
        (type: string, data: any = null) =>
        () => {
            setAction((old: any) => ({ ...old, [type]: true, data }));
        };

    const handleActionClose = () => {
        setAction(initAction);
    };

    const handleModalClose = useCallback(
        (status: boolean = false) =>
            async () => {
                if (status) {
                    setIsLoading(true);
                    const params = {
                        shop_id: shop.id,
                        id: action.data,
                    };
                    await axiosDelete(API.STRIPE.READER.DELETE, {}, params)
                        .then(listReaders)
                        .finally(() => setIsLoading(false));
                }
                setAction(initAction);
            },
        [action],
    );

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const handleTerminal = (terminals: any) => () => {
        if (terminals.id === terminal.id) {
            dispatch(setTerminal({}));
        } else {
            dispatch(setTerminal(terminals));
        }
    };

    const getStatus = (row: any) => (
        <Badge icon status={row.status}>
            {row.status}
        </Badge>
    );

    const getLastSeen = (row: any) => moment(row.last_seen_at).fromNow();

    const getAction = (row: any) => (
        <div className="flex items-center gap-4 justify-between">
            <CustomButton secondary onClick={handleTerminal(row)} disabled={row.status !== 'online'}>
                {calendarData.isTerminalLoading ? 'Connecting...' : row.id === terminal.id ? t('Disconnect') : t('Connect')}
            </CustomButton>
            <div className="flex items-center gap-4 justify-end">
                <CustomButton className="shadow-none w-4 !px-0 text-gray-600 hover:text-gray-900 !pr-[4px]" onClick={handleAction('update', row)}>
                    <Edit01 className="h-4 w-4" />
                </CustomButton>
                <CustomButton className="shadow-none text-gray-600 hover:text-gray-900 w-4 !px-0" onClick={handleAction('delete', row.id)}>
                    <Trash01 className="h-4 w-4 " />
                </CustomButton>
            </div>
        </div>
    );

    const skeletons = useMemo(
        () =>
            Array.from({ length: 10 }).map((_, index) => ({
                id: index,
                display_name: 'loading',
                address: { line1: 'loading', city: 'loading', country: 'loading' },
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    return (
        <div
            className={`w-full border border-gray-200 rounded-xl shadow staff_service_table datatable-custom-service flex-1 flex flex-col ${
                !readers.length ? 'datatable-full-height datatable-noshow' : ''
            }`}
        >
            <div className={`flex items-center w-full flex-col`}>
                <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                    <div className="flex flex-col flex-1">
                        <div className="flex justify-start items-center gap-1.5 xxl:gap-1.5">
                            <h2 className="table-title">{t('All Readers')}</h2>
                            <Badge icon isLoading={isLoading}>
                                {total} {t('Readers')}
                            </Badge>
                        </div>
                        <p className="table-subtitle">{t('Add, view and manage your readers.')}</p>
                    </div>
                    <div className="flex gap-3">
                        <CustomButton outlinePrimary onClick={handleAction('create')} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                            {t('Add new reader')}
                        </CustomButton>
                    </div>
                </div>
                <div className="flex items-center px-5 py-3 justify-between w-full ">
                    <div className="table-searchInput">
                        <SearchBar placeholder={t('Search')} className="form-control-md" onChange={handleSearch} />
                    </div>
                    <div className="flex gap-3">
                        <div className="w-[70px] xl:w-[70px] page-dropdown">
                            <SelectBox
                                name="page"
                                options={perPageOptions}
                                isClearable={false}
                                isSearchable={false}
                                onChangeFunc={onPageHandle}
                                value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                classComp="outline-select-box"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <DataTable
                lazy
                paginatorTemplate={PaginatorTemplate()}
                value={isLoading ? skeletons : readers}
                totalRecords={total}
                paginator={!isLoading && total > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                dataKey="id"
                className="border-t rounded-b-md overflow-hidden"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <NoDataMessage
                        title={`${filters.global.value ? 'No Readers Found.' : 'No Reader Added.'}`}
                        description={`${
                            filters.global.value
                                ? 'There are no readers matching your search criteria. Try adjusting your filters or create a new reader to list it here.'
                                : 'Reader not available on your stripe account.'
                        }`}
                        iconComponent={filters.global.value ? <SearchLg className="text-gray-700" /> : <Box className="text-gray-700" />}
                    />
                }
            >
                <Column
                    field="label"
                    header={renderHeader(t('Reader Name'), 'label')}
                    body={isLoading ? <Skeleton /> : undefined}
                    className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                    style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                    sortable
                ></Column>
                <Column
                    field="device_type"
                    header={renderHeader(t('Device Type'), 'device_type')}
                    className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                    body={isLoading ? <Skeleton /> : undefined}
                    style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                    sortable
                ></Column>
                <Column
                    field="status"
                    header={renderHeader(t('Status'), 'status')}
                    className="text-xs leading-[18px] font-medium text-mainTextColor"
                    body={isLoading ? <Skeleton /> : getStatus}
                    style={{ width: '70px', minWidth: '70px', maxWidth: '70px' }}
                    sortable
                ></Column>
                <Column
                    field="last_seen_at"
                    header={renderHeader(t('Last Seen'), 'last_seen_at')}
                    className="text-xs leading-[18px] font-medium text-mainTextColor"
                    body={isLoading ? <Skeleton /> : getLastSeen}
                    style={{ width: '70px', minWidth: '70px', maxWidth: '70px' }}
                    sortable
                ></Column>
                <Column field="action" className="text-xs font-medium" style={{ width: '60px', minWidth: '60px', maxWidth: '60px' }} body={isLoading ? <Skeleton /> : getAction}></Column>
            </DataTable>
            {(action.create || (action.update && action.data)) && <ReaderForm data={action.data} handleClose={handleActionClose} listReaders={listReaders} />}
            {action.delete && <DeletePopupModal onClose={handleModalClose} size="w-[400px]" title={t('Delete reader')} description={t('Are you sure you want to delete reader?')} />}
        </div>
    );
};

export default ReaderTable;
