import React, { useLayoutEffect, useMemo, useState } from 'react';
import AccountSettings from './AccountSettings/AccountSettings';
import BusinessDetails from './BusinessDetails/BusinessDetails';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SETTING_TABS } from 'src/constants/common';
import PageHeader from 'src/components/PageHeader';
import PaymentSettings from './PaymentSettings/PaymentSettings';
import { PATH } from 'src/constants/path';
import LoyaltyReferral from './LoyaltyReferral/LoyaltyReferral';
import Calendar from './Calendar/Calendar';
import Payment from './Payment/Payment';
import ClientNotifications from './ClientNotifications/ClientNotifications';
import Notifications from './Notifications/Notifications';
import Product from './Product/Product';
import { useRolePermission } from 'src/hooks/useRolePermission';

const Setting = () => {
    const { t } = useTranslation();
    const { isOwner } = useRolePermission();
    const { pathname } = useLocation();
    const { tab: currentTab } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<any>();

    const tabs = useMemo(
        () => [
            [
                {
                    name: t('Brand Overview'),
                    slug: SETTING_TABS.BUSINESS,
                    permission: true,
                },
                {
                    name: t('Loyalty & Referral'),
                    slug: SETTING_TABS.LOYALTY,
                    permission: true,
                },
                {
                    name: t('Client Notifications'),
                    slug: SETTING_TABS.CLIENT_NOTIFICATION,
                    permission: true,
                },
                {
                    name: t('Products'),
                    slug: SETTING_TABS.PRODUCT,
                    permission: true,
                },
                {
                    name: t('Payments'),
                    slug: SETTING_TABS.PAYMENT,
                    permission: true,
                },
                {
                    name: t('Team Management'),
                    slug: SETTING_TABS.TEAM,
                    permission: isOwner,
                },
            ],
            [
                {
                    name: t('My Account'),
                    slug: SETTING_TABS.ACCOUNT,
                    permission: true,
                },
                {
                    name: t('Calendar'),
                    slug: SETTING_TABS.CALENDAR,
                    permission: true,
                },
                {
                    name: t('Notifications'),
                    slug: SETTING_TABS.NOTIFICATION,
                    permission: true,
                },
            ],
        ],
        [],
    );

    useLayoutEffect(() => {
        const test = tabs[0].find((item: any) => item.slug === currentTab && !item.permission);
        const test1 = tabs[1].find((item: any) => item.slug === currentTab && !item.permission);

        if (currentTab && (test || test1)) {
            navigate(`${PATH.SETTINGS}${SETTING_TABS.BUSINESS}`);
            return;
        }
        switch (currentTab) {
            case SETTING_TABS.BUSINESS:
                document.title = t('Titles.Setting Business Details');
                setActiveTab(<BusinessDetails />);
                break;
            case SETTING_TABS.ACCOUNT:
                document.title = t('Titles.Setting Account Settings');
                setActiveTab(<AccountSettings />);
                break;
            case SETTING_TABS.LOYALTY:
                document.title = t('Titles.Setting Loyalty Referral');
                setActiveTab(<LoyaltyReferral />);
                break;
            case SETTING_TABS.CALENDAR:
                document.title = t('Titles.Setting Calendar Settings');
                setActiveTab(<Calendar />);
                break;
            case SETTING_TABS.CLIENT_NOTIFICATION:
                document.title = t('Titles.Setting Client Notifications');
                setActiveTab(<ClientNotifications />);
                break;
            case SETTING_TABS.PAYMENT:
                document.title = t('Titles.Setting Payment Settings');
                setActiveTab(<Payment />);
                break;
            case SETTING_TABS.TEAM:
                document.title = t('Titles.Setting Team Management');
                setActiveTab(<PaymentSettings />);
                break;
            case SETTING_TABS.NOTIFICATION:
                document.title = t('Titles.Setting Notifications');
                setActiveTab(<Notifications />);
                break;
            case SETTING_TABS.PRODUCT:
                document.title = t('Titles.Setting Product');
                setActiveTab(<Product />);
                break;
            default:
                navigate(`${PATH.SETTINGS}${SETTING_TABS.BUSINESS}`);
                break;
        }
    }, [currentTab, navigate, tabs]);

    const handleTabClick = (slug: string) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        navigate(`${PATH.SETTINGS}${slug}`);
    };

    return (
        <div className="inner-page-wrape ">
            <div className="flex flex-col flex-1">
                <PageHeader
                    title={tabs[1].some((tab) => tab.slug === currentTab) ? t('Account Settings') : t('Brand Settings')}
                    subtitle={t('Manage your brand settings', {
                        settingName: tabs[1].some((tab) => tab.slug === currentTab) ? 'account' : 'brand',
                    })}
                />
                <div className="side-spaching flex flex-1 flex-col w-full">
                    <div className="fl-tab-btn-view w-full">
                        {tabs[1].some((tab) => tab.slug === currentTab)
                            ? tabs[1].map(
                                  (tab, index) =>
                                      tab.permission && (
                                          <button
                                              key={index}
                                              type="button"
                                              className={`fl-tab-link !max-w-[182px] ${
                                                  currentTab === tab.slug ||
                                                  (pathname === '/settings' && tab.slug === SETTING_TABS.BUSINESS) ||
                                                  (pathname === '/settings/' && tab.slug === SETTING_TABS.BUSINESS)
                                                      ? 'active'
                                                      : ''
                                              }`}
                                              onClick={handleTabClick(tab.slug)}
                                          >
                                              {tab.name}
                                          </button>
                                      ),
                              )
                            : tabs[0].map(
                                  (tab, index) =>
                                      tab.permission && (
                                          <button
                                              key={index}
                                              type="button"
                                              className={`fl-tab-link !max-w-[182px] ${
                                                  currentTab === tab.slug ||
                                                  (pathname === '/settings' && tab.slug === SETTING_TABS.BUSINESS) ||
                                                  (pathname === '/settings/' && tab.slug === SETTING_TABS.BUSINESS)
                                                      ? 'active'
                                                      : ''
                                              }`}
                                              onClick={handleTabClick(tab.slug)}
                                          >
                                              {tab.name}
                                          </button>
                                      ),
                              )}
                    </div>
                    {activeTab}
                </div>
            </div>
        </div>
    );
};

export default Setting;
