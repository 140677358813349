import React from 'react';
import KpiCard from 'src/components/KpiCard';
import { formatKpiContentLable } from 'src/utils/global-functions';

const Overview = ({ AnalyticsDetails, boxLabel, isLoading }: any) => {
    const AnalyticsTotal = AnalyticsDetails ? AnalyticsDetails.total : null;
    return (
        <div className=" grid max-2xl:grid-cols-2  grid-cols-3 w-full gap-3">
            <div>
                <KpiCard
                    headerTitle="Total Revenue"
                    contentLable={`${formatKpiContentLable(AnalyticsTotal?.amount)}`}
                    signIcon={'$'}
                    growth={AnalyticsTotal?.amount_growth}
                    fromDate={boxLabel}
                    isLoading={isLoading}
                />
            </div>
            <div>
                <KpiCard headerTitle="Total Appointments" contentLable={AnalyticsTotal?.bookings} growth={AnalyticsTotal?.bookings_growth} fromDate={boxLabel} isLoading={isLoading} />
            </div>
            <div>
                <KpiCard
                    headerTitle="Client Retention"
                    contentLable={`${AnalyticsTotal?.client_retention_rate}`}
                    signIcon={'%'}
                    growth={AnalyticsTotal?.client_retention_rate_growth}
                    fromDate={boxLabel}
                    isLoading={isLoading}
                />
            </div>
            <div>
                <KpiCard
                    headerTitle="Productivity"
                    contentLable={`${formatKpiContentLable(AnalyticsTotal?.productivity)}`}
                    signIcon={'%'}
                    growth={AnalyticsTotal?.productivity_growth}
                    fromDate={boxLabel}
                    isLoading={isLoading}
                />
            </div>
            <div>
                <KpiCard headerTitle="New Customers" contentLable={AnalyticsTotal?.client} growth={AnalyticsTotal?.client_growth} fromDate={boxLabel} isLoading={isLoading} />
            </div>
            <div>
                <KpiCard
                    headerTitle="Client Booking Frequency"
                    contentLable={`${AnalyticsTotal?.avg_bookings_frequency}`}
                    signIcon={'days'}
                    growth={AnalyticsTotal?.avg_bookings_frequency_growth}
                    fromDate={boxLabel}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
};

export default Overview;
