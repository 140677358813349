import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/redux/hooks';
import { me } from 'src/redux/services/common/Common.slice';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IForgotPinProps, IPinForgotFormData } from './ReceptionistMode.interface';
import InputPasswordWithLabel from 'src/components/InputPasswordWithLabel';
import { PasscodeLock } from '@untitled-ui/icons-react/build/cjs';
import PopupModal from 'src/components/PopupModal';
type FieldKey = 'password';

const ForgotPin = ({ handleReceptionistMode, handleClose }: IForgotPinProps) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const schema = Yup.object({
        password: Yup.string().required(t('Password required')),
    }).required();

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
    } = useForm<IPinForgotFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            password: '',
        },
    });
    const handleChange = async (data: any) => {
        setIsLoading(true);
        try {
            const response = await axiosPost(API.RECEPTIONIST_MODE.PIN_FORGOT, data, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(me());
                handleReceptionistMode('pinSetup');
                return;
            }

            throw response.data;
        } catch (err: any) {
            if (err.status === errorCode.unprocessable) {
                if (err.response.data.data) {
                    Object.keys(err.response.data.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: err.response.data.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };
    const Title = () => (
        <div className="flex flex-row gap-3 justify-center">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <PasscodeLock className="text-gray-700" />
            </div>
        </div>
    );
    return (
        <>
            <PopupModal
                dismissible
                onClose={handleClose}
                size="w-[408px]"
                className="px-5 py-5"
                title={<Title />}
                view={2}
                fixedHeightClass="!px-5 !py-0"
                isLoading={isLoading}
                isDisabled={isLoading}
                primaryButton={t('Confirm')}
                acceptAction={handleSubmit(handleChange)}
            >
                <form onSubmit={handleSubmit(handleChange)}>
                    <h2 className="font-bold text-[18px] leading-[28px] text-center pb-1">{t('Forgot 4-digit code?')}</h2>
                    <div className="flex flex-wrap">
                        <Controller
                            name="password"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="w-full flex justify-center flex-col items-center">
                                    <div className="flex flex-col gap-8 text-center pb-4 w-[80%]">
                                        <p className="text-secondaryTxtColor leading-[19.6px] -tracking-[0.384px]">{t('Enter your password to reset the 4-digit code and setup a new one')}</p>
                                    </div>
                                    <div className="flex flex-col w-full mt-5 mb-6">
                                        <InputPasswordWithLabel label={t('Password')} placeholder={t('Password')} onChange={onChange} value={value.password} name="password" error={error} />
                                        {errors.password && <p className="text-error">{errors.password.message}</p>}
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                </form>
            </PopupModal>
        </>
    );
};

export default ForgotPin;
