import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { INotification, IProps } from './Notification.interface';
import { API } from 'src/constants/api';
import { axiosGet, axiosPatch } from 'src/utils/requestClient';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { onMessageListener, requestForFirebaseToken } from 'src/utils/firebase';
import { notificationInfo, setNotificationToken } from 'src/app/Notification/Notification.slice';
import { axiosPost } from 'src/utils/requestClient';
import { errorCode } from 'src/constants/errorCode';
import { allShopLocations, userMe } from 'src/redux/services/common/Common.slice';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import { FilterLines, Inbox01, MarkerPin01, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { GoDotFill } from 'react-icons/go';
import NotificationGeneralAll from './NotificationGeneralAll';
import NotificationGeneral from './NotificationGeneral';
import NotificationAppointment from './NotificationAppointment';
import NotificationStatements from './NotificationStatements';
import CustomButton from 'src/components/CustomButton';
import { useTranslation } from 'react-i18next';
import { DoubleCheck } from 'src/theme/Images';
import NoDataMessage from 'src/components/NoDataMessage';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Notification = ({ show, notificationButtonRef, notificationCount, setNotificationCount, handleClose }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const notificationData = useAppSelector(notificationInfo);
    const user = useAppSelector(userMe);
    const drawerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isMarkingAsRead, setIsMarkingAsRead] = useState(false);
    const [notifications, setNotifications] = useState<any>([]);
    const shopLocationList = useAppSelector(allShopLocations);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [lazyState, setlazyState] = useState<any>({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            type: { value: '' },
            shop_location_id: { value: null },
        },
    });

    useEffect(() => {
        fetchNotifications();
    }, [lazyState]);

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'name', 'profile_image_url');
        setLocationOptions([...[{ label: 'All Locations', value: null }], ...locationData]);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (drawerRef.current && !((drawerRef.current as any).contains(event.target) || notificationButtonRef.current?.contains(event.target))) {
                handleClose();
            }
            if (drawerRef.current && event.key === 'Escape') {
                handleClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        getOrUpdateFirebaseNotificationToken();

        if (user) {
            fetchNotifications();

            onMessageListener()
                .then((payload: any) => {
                    fetchNotifications();
                    toast.success(payload?.notification?.body);
                })
                .catch((err) => err);
        }
    }, [user]);

    const getOrUpdateFirebaseNotificationToken = async () => {
        let FCM_TOKEN = await requestForFirebaseToken();
        if (!FCM_TOKEN) {
            FCM_TOKEN = await requestForFirebaseToken();
        }

        if (FCM_TOKEN && FCM_TOKEN !== notificationData.notificationToken && user) {
            dispatch(setNotificationToken(FCM_TOKEN));

            try {
                const response = await axiosPost(API.NOTIFICATION.USER_NOTIFICATION_CREATE_UPDATE, {
                    token: FCM_TOKEN,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            }
        }
    };

    const viewDetail = (notification: any) => () => {
        switch (notification.status) {
            case 'staff_invite':
                navigate(ROUTES.INVITE, { state: { shop_id: notification.shop_id } });
                break;
            default:
                break;
        }
    };

    const notificationOptions = useMemo(
        () => [
            {
                label: 'View All',
                value: '',
            },
            {
                label: 'General',
                value: 'general',
            },
            {
                label: 'Appointments',
                value: 'appointment',
            },
            {
                label: 'Statements',
                value: 'statement',
            },
        ],
        [],
    );

    const fetchNotifications = async () => {
        setIsLoading(true);
        axiosGet(API.NOTIFICATION.LIST, {}, lazyState)
            .then((response) => {
                const data = response.data.data;
                setNotifications(data);
                setNotificationCount((prev) => ({ ...prev, all: data.unread_count }));
            })
            .catch((error) => toast.error(error.message))
            .finally(() => setIsLoading(false));
    };

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setlazyState((old: any) => ({ ...old, page: 0, first: 0, filters: { ...old.filters, [filter]: { value } } }));
    };

    const onPage = (event: any) => {
        setlazyState(event);
    };

    const checkNotificationStatus =
        (payloadType: string, data: any = null) =>
        async () => {
            if (notifications.unread_count === 0) return;

            setIsMarkingAsRead(true);
            const payload: any = {
                type: payloadType,
                ...(data && { id: data.id }),
            };

            await axiosPatch(API.NOTIFICATION.READ, payload)
                .then(async (response) => {
                    fetchNotifications();
                    return;
                })
                .finally(() => setIsMarkingAsRead(false));
        };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink',
        PrevPageLink: ({ onClick }: any) =>
            !isLoading &&
            notifications?.totalRecords > lazyState.rows && (
                <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                    {t('Previous')}
                </CustomButton>
            ),
        NextPageLink: ({ onClick }: any) =>
            notifications?.totalRecords > lazyState.rows && (
                <CustomButton secondary onClick={onClick}>
                    {t('Next')}
                </CustomButton>
            ),
        CurrentPageReport: ({ currentPage, totalPages }: { currentPage: number; totalPages: number }) => (
            <div className="text-gray-700 flex items-center text-sm font-medium mr-auto gap-3">
                <CustomButton isLoading={isMarkingAsRead} disabled={notifications.unread_count === 0} secondary onClick={checkNotificationStatus('all')}>
                    <DoubleCheck />
                    {t('Mark as all read')}
                </CustomButton>
                {!isLoading && notifications?.totalRecords > lazyState.rows && (
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                )}
            </div>
        ),
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                name: 'loading',
                description: 'loading',
                created_at: '2024-09-13T11:23:34.000000Z',
                is_read: true,
                type: 'general',
            })),
        [lazyState],
    );

    const renderColumnBody = (rowData: INotification, index: any) => {
        if (lazyState.filters.type.value === '') {
            return (
                <NotificationGeneralAll
                    notificationRecord={rowData}
                    isLoading={isLoading}
                    viewDetail={viewDetail}
                    checkNotificationStatus={checkNotificationStatus}
                    isMarkingAsRead={isMarkingAsRead}
                />
            );
        } else if (lazyState.filters.type.value === 'general') {
            return (
                <NotificationGeneral
                    key={`${rowData.name}_${rowData.id}`}
                    notificationRecord={rowData}
                    isLoading={isLoading}
                    viewDetail={viewDetail}
                    checkNotificationStatus={checkNotificationStatus}
                    isMarkingAsRead={isMarkingAsRead}
                />
            );
        } else if (lazyState.filters.type.value === 'appointment') {
            return <NotificationAppointment key={`${rowData.name}_${rowData.id}`} notificationRecord={rowData} isLoading={isLoading} />;
        } else if (lazyState.filters.type.value === 'statement') {
            return <NotificationStatements key={`${rowData.name}_${rowData.id}`} notificationRecord={rowData} isLoading={isLoading} />;
        }
        return null;
    };

    return (
        <>
            {show && (
                <div className="fixed inset-0 z-[9999]  flex items-center justify-center transition-all  duration-700 ">
                    {/* Background Overlay */}
                    <div className="absolute inset-0 bg-black opacity-40  transition-all  duration-700 "></div>

                    {/* Notification Drawer */}
                    <div className="fixed  right-5 md:right-[20px] z-[9999] top-5 flex-1 bottom-5 w-full max-w-[460px] transition-all  duration-700 " ref={drawerRef}>
                        <div className="flex flex-col bg-white shadow-[0px_5.3px_15.9px_0px_#928C9740] h-[calc(100vh-38px)] max-h-[calc(100vh-38px)] border rounded-xl border-gray-200  w-full ">
                            <div className="flex justify-between items-center p-4 border-b border-borderSecondary">
                                <div className="flex items-center gap-2">
                                    <div className=" w-8 h-8 flex justify-center items-center border border-[#EAECF0] rounded-[8px] shadow-sm">
                                        <Inbox01 className="w-4 h-4 text-mainTextColor" />
                                    </div>
                                    <div className="font-semibold text-lg text-mainTextColor">Notifications</div>
                                    <div className=" min-w-fit px-[3px] w-[20px] h-[20px] flex justify-center items-center border bg-[#EFF8FF] border-[#B2DDFF] rounded-full text-xs leading-[18px] shadow-sm text-primary">
                                        {notificationCount?.all}
                                    </div>
                                </div>
                                <div className="flex flex-row items-center gap-5">
                                    <div className=" relative">
                                        <SelectBox
                                            name="shop_location_id"
                                            id="shop_location_id"
                                            value={locationOptions.find((option) => option.value === lazyState.filters.shop_location_id.value)}
                                            options={locationOptions}
                                            onChangeFunc={handleFilter('shop_location_id')}
                                            classComp="outline-select-box"
                                            isClearable={false}
                                            isMulti={false}
                                            isMultiIcon={false}
                                            isSearchable={false}
                                            labelIcon={
                                                <div className="relative cursor-pointer rounded-lg">
                                                    <MarkerPin01 className="w-[18px] text-gray-700 hover:text-gray-900 hover:shadow" />
                                                </div>
                                            }
                                        />
                                        {lazyState.filters.shop_location_id.value && (
                                            <GoDotFill className="h-3 min-h-3 max-h-3 w-3 min-w-3 max-w-3 top-1 z-99 -right-1 absolute    rounded-full flex  text-primary" />
                                        )}
                                    </div>
                                    <div className=" relative">
                                        <SelectBox
                                            name="type"
                                            id="type"
                                            options={notificationOptions}
                                            onChangeFunc={handleFilter('type')}
                                            value={notificationOptions.find((option) => option.value === lazyState.filters.type.value)}
                                            classComp="outline-select-box"
                                            isClearable={false}
                                            isMulti={false}
                                            isMultiIcon={false}
                                            isSearchable={false}
                                            labelIcon={
                                                <div className="relative cursor-pointer rounded-lg">
                                                    <FilterLines className="w-[18px] text-gray-700 hover:text-gray-900 hover:shadow" />
                                                </div>
                                            }
                                        />
                                        {lazyState.filters.type.value && (
                                            <GoDotFill className="h-3 min-h-3 max-h-3 w-3 min-w-3 max-w-3 top-1 z-99 -right-1 absolute    rounded-full flex  text-primary" />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`flex flex-col flex-1 relative overflow-y-auto overflow-hidden scrollbar-hide notification_modal w-full rounded-b-xl  ${
                                    notifications.data.length === 0 ? 'datatable-full-height ' : ''
                                }`}
                            >
                                <DataTable
                                    scrollable
                                    scrollHeight="flex"
                                    value={isLoading ? skeletons : notifications.data}
                                    paginator
                                    lazy
                                    first={lazyState.first}
                                    rows={lazyState.rows}
                                    paginatorTemplate={customPaginatorTemplate}
                                    paginatorClassName="  !bottom-0 !bg-white !z-10 !w-full !border-t !border-borderSecondary py-3 mt-1.5 rounded-b-xl"
                                    totalRecords={notifications?.totalRecords}
                                    onPage={onPage}
                                    sortOrder={lazyState.sortOrder}
                                    filters={lazyState.filters}
                                    dataKey="id"
                                    className=" text-sm flex-1 text-mainTextColor overflow-hidden   absolute w-full "
                                    showHeaders={false}
                                    onRowClick={(e) => checkNotificationStatus('single', e.data)()}
                                    emptyMessage={
                                        <div className="flex flex-col mb-[50px] flex-1 justify-center items-center ">
                                            <NoDataMessage
                                                title={`${lazyState.filters.shop_location_id.value ? t('No notifications found.') : t('No notifications added.')}`}
                                                description={`${
                                                    lazyState.filters.shop_location_id.value
                                                        ? t('Try adjusting your filters to view more specific notifications.')
                                                        : t('You have no notifications. Check back later for updates or new messages.')
                                                }`}
                                                iconComponent={lazyState.filters.shop_location_id.value ? <SearchLg className="text-gray-700" /> : <Inbox01 className="text-gray-700" />}
                                            />
                                        </div>
                                    }
                                >
                                    <Column body={renderColumnBody} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Notification;
