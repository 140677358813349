import React, { FC, useState } from 'react';
import CustomButton from 'src/components/CustomButton';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IAccountFormData, IProps } from './Signup.interface';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { checkFileTypeValidation, convertBase64ToFile } from 'src/utils/global-functions';
import { s3Upload } from 'src/utils/s3Operations';
import { FILE_VALIDATION } from 'src/utils/global-variables';
import { useTranslation } from 'react-i18next';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { LANGUAGE_OPTIONS } from 'src/constants/common';
import { UsersPlus } from '@untitled-ui/icons-react/build/cjs';
import { currentShop, me, userMe } from 'src/redux/services/common/Common.slice';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import CountryInput from 'src/components/Country/CountryInput';
import ImageUpload from 'src/components/ImageUpload';
import Footer from 'src/app/Layout/Footer';
import CropperModal from 'src/components/CropperModal/CropperModal';

const Account: FC<IProps> = ({ setStep, handleStep }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const user = useAppSelector(userMe);
    const shop = useAppSelector(currentShop);
    const [file, setFile] = useState<any>({ convertedFile: '', filename: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [upload, setUpload] = useState(false);
    const imageUrl = user.profile_image_url ? user.profile_image_url : null;
    const [cropData, setCropData] = useState<any>(imageUrl || '');
    const [image, setImage] = useState<any>(imageUrl || '');

    const defaultCountry = 'CA';
    const defaultLanguage = 'english';
    const schema = Yup.object({
        country_name: Yup.string().required(t('Country required')),
        language_name: Yup.string().required(t('Language required')),
    }).required();

    const onChangeCrop = async (e: any) => {
        setUpload(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const imageValidation = {
            files: files,
            ...FILE_VALIDATION.SETTING.ACCOUNT,
        };
        const { result, message } = await checkFileTypeValidation(imageValidation);

        if (result) {
            clearErrors('profile_image_name');
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        } else {
            setError('profile_image_name' as 'profile_image_name', {
                type: 'manual',
                message: message,
            });
        }
    };

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        clearErrors,
    } = useForm<IAccountFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            country_name: user.country ?? defaultCountry,
            language_name: user.language ?? defaultLanguage,
            profile_image_name: user.profile_image_name ?? '',
        },
    });

    const handleChange = async (data: IAccountFormData) => {
        setIsLoading(true);

        if (file.convertedFile && file?.filename) {
            const imgUploadResult: any = await s3Upload(file?.convertedFile, `images/admin_profile/${file?.filename}`);
            if (imgUploadResult.status === 201) {
                const updatedData = {
                    ...data,
                    logo_image_name: file.filename,
                };
                submitForm(updatedData);
            }
        } else {
            submitForm(data);
        }
    };

    const submitForm = async (data: IAccountFormData) => {
        const payload = {
            country: data.country_name,
            language: data.language_name,
            profile_image_name: data.profile_image_name ?? '',
        };
        axiosPatch(API.USER.SIGNUP_PROFILE, payload, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
                if (setStep && user.shop_staff[0].created_by_id) {
                    setStep(4);
                } else {
                    handleStep('next')();
                }
            })
            .catch((error) => {
                const response = error.payload.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IAccountFormData, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    setIsLoading(false);
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    const imageUpload = (data: any) => {
        const appLogoImage = data;
        const fileInfo = convertBase64ToFile(appLogoImage);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue('profile_image_name', fileInfo.filename);
        }
    };

    useFormErrorFocus<IAccountFormData>({ errors });

    return (
        <>
            <form onSubmit={handleSubmit(handleChange)} className="">
                <main className="px-6 h-[calc(100vh-84px)] overflow-y-scroll flex-1 flex">
                    <div className="w-full flex justify-center relative flex-1 items-center flex-col text-center bg-banner bg-top bg-no-repeat">
                        <div className="w-[600px]">
                            <div className="flex flex-col rounded-md items-center mb-4">
                                <div className=" p-[14px] bg-white border border-btnborder rounded-xl">
                                    <UsersPlus className="w-7" />
                                </div>
                            </div>
                            <div className="text-center">
                                <h3 className="fwpo_heading_title">{t('Complete your profile')}</h3>
                                <p className="font-normal leading-[22.4px] mt-[2px] text-base text-secondaryTxtColor">{t('Tell us a bit about yourself')}</p>
                            </div>
                            <div className="mt-6 ">
                                <div className="flex flex-col">
                                    <div className="">
                                        <div className="w-full mb-4">
                                            <label className={`fl-field-title-label text-start`}> {t('Profile Image')} </label>
                                            <ImageUpload cropData={cropData} onChangeCrop={onChangeCrop} shape="square" id="profile_image_name" error={errors.profile_image_name} />
                                        </div>
                                        <div className="flex gap-4">
                                            <div className="w-full">
                                                <Controller
                                                    name="country_name"
                                                    control={control}
                                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                        <>
                                                            <label className={`fl-field-title-label text-start`}> {t('Country')} </label>
                                                            {/* <SelectBox name="country" label={t('Country')} placeholder={t('Country')} /> */}
                                                            <CountryInput name="country" value={value} onChangeFunc={onChange} errors={error ? error.message : ''} />
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <Controller
                                                    name="language_name"
                                                    control={control}
                                                    render={({ field: { onChange, value } }: any) => (
                                                        <div id="language_name">
                                                            <label className={`fl-field-title-label text-start`}> {t('Default language')} </label>
                                                            <SelectBox
                                                                name="country"
                                                                id="handlingUnit"
                                                                placeholder={t('Default language')}
                                                                options={LANGUAGE_OPTIONS}
                                                                onChangeFunc={(selectedOption: any) => {
                                                                    onChange(selectedOption.value);
                                                                }}
                                                                value={LANGUAGE_OPTIONS.find((option) => option?.value === value)}
                                                                errorText={errors.language_name && true}
                                                                isDisabled={isLoading}
                                                                isSearchable
                                                                isClearable={false}
                                                            />
                                                            <p className="text-error mt-2 absolute text-sm">{errors.language_name && errors.language_name.message}</p>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <div className="bottom-0 right-0 max-2xl:start-[420px] start-[480px] fixed">
                    <div className="w-full mt-5   mb-2  gap-4 flex justify-center mx-auto">
                        <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" size="w-full" className="!w-[600px]">
                            {t('Continue')}
                        </CustomButton>
                    </div>
                    <Footer />
                </div>
            </form>
            {upload && <CropperModal imageUpload={imageUpload} imageUrl={image} setUpload={setUpload} setCropData={setCropData} isCropType={false} defaultCropType="Square" />}
        </>
    );
};

export default Account;
