import React, { Fragment, useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { checkFileTypeValidation, convertBase64ToFile, exceptValidation } from 'src/utils/global-functions';
import CropperModal from 'src/components/CropperModal/CropperModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { mapLocationPin } from 'src/theme/Images';
import { FormProps, InitStaffData } from './Team.interface';
import { allShopSettings } from 'src/redux/services/common/Common.slice';
import ImageBoxView from '../../Image/ImageBoxView';
import ImageInput from '../../Image/ImageInput';
import { Button } from 'primereact/button';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import Switch from 'src/components/Switch/Switch';
import { Collapse } from '../Collapse';
import { FILE_VALIDATION } from 'src/utils/global-variables';

const Form = (props: FormProps) => {
    const { language } = props;
    const [image, setImage] = useState<any>('');
    const [isUpload, setIsUpload] = useState(false);
    const [fieldName, setFieldName] = useState<any>(null);
    const [locationOptions, setLocationOptions] = useState<any>([]);
    const { t } = useTranslation();
    const locationSetting: any = useAppSelector(allShopSettings).find((setting: any) => setting.type === 'template')?.value?.location;

    useEffect(() => {
        const locationData: any = locationSetting?.locations?.map((location: any) => ({
            value: location.title,
            label: location.title,
            id: location.title,
        }));

        setLocationOptions(locationData);
    }, [locationSetting]);
    const {
        control,
        setValue,
        watch,
        setError,
        getValues,
        clearErrors,
        formState: { errors },
    } = useFormContext<any>();
    const { remove, append, fields } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'staff',
    });

    useEffect(() => {
        if (!isUpload) {
            setFieldName('');
        }
    }, [isUpload]);

    const handleLocationChange = (field: any) => (value: any) => {
        const ids = value?.map((item: any) => item.value);
        setValue(field, ids);
    };
    const handleCropData = (data: any) => {
        const convertedFile = convertBase64ToFile(data);
        const isGallery = fieldName.endsWith('gallery');
        if (convertedFile && convertedFile.filename && fieldName) {
            if (isGallery) {
                const currentImages = getValues(fieldName) || [];
                const updatedImages: any = [...currentImages, { file: convertedFile.convertedFile as File, url: data, name: convertedFile.filename }];
                setValue(fieldName, updatedImages);
            } else {
                setValue(fieldName, {
                    file: convertedFile.convertedFile as File,
                    url: data,
                    name: convertedFile.filename,
                });
            }
        }
    };
    const onChangeCrop = (field: any) => async (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const imageValidation = {
            files: files,
            ...FILE_VALIDATION.APPEARANCE.TEAM,
        };
        const { result, message } = await checkFileTypeValidation(imageValidation);
        if (!result) {
            setError(field, {
                type: 'manual',
                message: message,
            });
            return;
        } else {
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setIsUpload(false);
            };
            reader.onloadend = () => {
                setFieldName(field);
                setImage(reader.result as any);
                setIsUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            clearErrors(field);
        }
    };

    const handleRemoveImage = (field: string, index?: number) => () => {
        if (index) {
            const currentImages = getValues(field) || [];
            const updatedImages = currentImages.filter((_: any, i: number) => i !== index) ?? null;
            setValue(field, updatedImages);
        } else {
            setValue(field, null);
        }
    };
    const addTeam = () => {
        InitStaffData && append(InitStaffData);
    };
    const removeTeam = (index: number) => () => {
        remove(index);
    };

    return (
        <div className="data-wrap-block space-y-4">
            {fields?.map((item: any, index: number) => (
                <div key={`staff_${item?.uuid}`} className={`rounded-xl border border-borderSecondary mb-4 ${errors?.staff && Array.isArray(errors.staff) && errors.staff[index] ? 'is-invalid' : ''}`}>
                    <Collapse title={watch(`staff.${index}.name`) || `${t('Team Name')}`} classNames="!p-4 !border-borderSecondary" handleRemove={removeTeam(index)}>
                        <div className="flex gap-4 relative  border-borderPrimary p-4">
                            <div className="flex">
                                <Controller
                                    name={`staff.${index}.image`}
                                    control={control}
                                    render={({ field: { value }, fieldState: { error } }: any) => (
                                        <div id={`staff.${index}.image`} className="">
                                            {value && value.url ? (
                                                <ImageBoxView
                                                    item={value}
                                                    name={`staff.${index}.image`}
                                                    onChangeCrop={onChangeCrop}
                                                    handleRemoveImage={handleRemoveImage(`staff.${index}.image`)}
                                                    key={`staff.${index}.image`}
                                                />
                                            ) : (
                                                <ImageInput error={error} onChangeCrop={onChangeCrop} name={`staff.${index}.image`} title="Team Picture" />
                                            )}
                                            {error?.type && error.type !== 'required' && <p className="text-error   w-[109px]  xl:w-[120px]  xxl:w-[150px] ">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex-1 flex flex-col gap-4 relative">
                                {/* {
                                    <Button type="button" onClick={() => remove(index)} className="text-dangerErrorText absolute right-0 top-[1px] text-xs font-medium">
                                        Delete member
                                    </Button>
                                } */}
                                <div className="grid grid-col xl:grid-cols-2 gap-4 location-dropdown">
                                    <Controller
                                        name={`staff.${index}.name`}
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    name={`staff.${index}.name`}
                                                    id={`staff.${index}.name`}
                                                    label={t('Name')}
                                                    required
                                                    placeholder={t('Enter team name')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                />
                                                {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name={`staff.${index}.instgram_button`}
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    name={`staff.${index}.instgram_button`}
                                                    id={`staff.${index}.instgram_button`}
                                                    label={t('Instgram Button')}
                                                    placeholder={t('Enter your instgram button')}
                                                    onChange={onChange}
                                                    value={value}
                                                    labelIcon="https://"
                                                    labelIconClassName="border-r border-secondaryBorder text-xs text-gray-600"
                                                    inputControlClassName="pl-3"
                                                    error={!!error}
                                                    toggle={
                                                        <Controller
                                                            name={`staff.${index}.is_instgram_button`}
                                                            control={control}
                                                            render={({ field, fieldState }: any) => (
                                                                <>
                                                                    <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                    {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                                </>
                                                            )}
                                                        />
                                                    }
                                                />
                                                {/* {error && <p className="text-error">{error.message}</p>} */}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="flex gap-4 location-dropdown-custom">
                                    <Controller
                                        name={`staff.${index}.role`}
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    name={`staff.${index}.role`}
                                                    id={`staff.${index}.role`}
                                                    label={t('Role')}
                                                    required
                                                    placeholder={t('Enter team role')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                />
                                                {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name={`staff.${index}.location`}
                                        control={control}
                                        render={({ field: { value }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <div className="flex items-center gap-[10px] mb-[6px]">
                                                    <label htmlFor="" className="fl-field-title-label mb-0 leading-5 ">
                                                        {''}
                                                        Location<span className="asterisk">*</span>
                                                    </label>
                                                </div>
                                                <SelectBox
                                                    name={`staff.${index}.location`}
                                                    id={`staff.${index}.location`}
                                                    value={locationOptions?.filter((option: any) => value?.includes(option?.value))}
                                                    options={locationOptions}
                                                    onChangeFunc={handleLocationChange(`staff.${index}.location`)}
                                                    classComp=""
                                                    openOnFocus={true}
                                                    autofocus={true}
                                                    isMulti
                                                    allowIcon={<img src={mapLocationPin} alt="" />}
                                                    error={error}
                                                    placeholder={t('Select team locations')}
                                                />
                                                {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                                {fields.length === 1 && (
                                    <>
                                        <Controller
                                            name={`staff.${index}.${language}_description`}
                                            control={control}
                                            render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        id={`staff.${index}.${language}_description`}
                                                        name={`staff.${index}.${language}_description`}
                                                        label={t('Description')}
                                                        required
                                                        textArea
                                                        rows={5}
                                                        placeholder={t('Enter team description')}
                                                        onChange={onChange}
                                                        value={value}
                                                        error={!!error}
                                                    />
                                                    {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name={`staff.${index}.is_gallery`}
                                            control={control}
                                            render={({ field, fieldState }: any) => (
                                                <>
                                                    <div className="flex items-center mb-1 gap-2.5">
                                                        <label htmlFor="" className="font-bold leading-5 text-gray-700 text-sm">
                                                            {t('Gallery')}
                                                        </label>
                                                        <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                    </div>
                                                    {fieldState.error && exceptValidation(fieldState.error) && <p className="text-error">{fieldState.error.message}</p>}
                                                </>
                                            )}
                                        />
                                        <div className="data-wrap-block w-full flex gap-5 flex-wrap max-w-full">
                                            <Controller
                                                name={`staff.${index}.gallery`}
                                                control={control}
                                                render={({ field: { value }, fieldState: { error } }: any) => (
                                                    <>
                                                        {value && Array.isArray(value) && (
                                                            <>
                                                                {value.map((galleryItem: any, galleryIndex: number) => (
                                                                    <div
                                                                        key={index}
                                                                        className={` w-[109px] h-[109px] xl:w-[120px] xl:h-[120px] xxl:w-[150px] xxl:h-[150px] relative cursor-pointer rounded-xl `}
                                                                    >
                                                                        <ImageBoxView
                                                                            item={galleryItem}
                                                                            name={`staff.${index}.gallery`}
                                                                            onChangeCrop={onChangeCrop}
                                                                            handleRemoveImage={handleRemoveImage(`staff.${index}.gallery`, galleryIndex)}
                                                                            key={galleryIndex}
                                                                            isEdit={false}
                                                                            className="border border-gray-200"
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </>
                                                        )}
                                                        <div>
                                                            <ImageInput
                                                                imageMultiple={false}
                                                                id={`staff.${index}.gallery`}
                                                                error={error}
                                                                onChangeCrop={onChangeCrop}
                                                                name={`staff.${index}.gallery`}
                                                                title="Gallery Picture"
                                                            />
                                                            {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                                        </div>
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </Collapse>
                </div>
            ))}

            <div className="flex justify-end items-center !mt-2 !mb-2">
                <Button type="button" className="text-xs font-medium text-blue-600 flex gap-1 focus-visible:outline-none focus:outline-none focus:shadow-none" onClick={addTeam}>
                    <Plus width={16} />
                    {t('Add new Team member')}
                </Button>
            </div>
            {isUpload && fieldName && (
                <CropperModal
                    imageUrl={image}
                    setUpload={setIsUpload}
                    setCropData={handleCropData}
                    defaultCropType="Square"
                    title={t('Brand Logo')}
                    description={t('Upload a  max 800Kb image for best results.')}
                    isCropType={false}
                    btnTitle={t('Confirm')}
                />
            )}
        </div>
    );
};
export default Form;
