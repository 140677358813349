import React, { FC, useEffect, useState } from 'react';
import { ICalulationDetails } from './Sidebar.Interface';
import { Check, ChevronDown, ChevronUp } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/hooks';
import { selectCalendarData } from '../Calendar.slice';
const CalulationDetails: FC<ICalulationDetails> = ({ appointmentCalulation }) => {
    useEffect(() => {});
    const { t } = useTranslation();
    const calendarData = useAppSelector(selectCalendarData);
    const [isColapse, setIsColapse] = useState(false);
    const handleCollapse = () => {
        setIsColapse((prev) => !prev);
    };
    const getPaymentType = (type: string) => {
        switch (type) {
            case 'stripe':
                return 'Card on file';
            case 'cash':
                return 'Cash';
            case 'pos':
                return 'Terminal';
            case 'other':
                return 'Other';
            case 'loyalty':
                return 'Loyalty';
        }
    };
    return (
        <div className="border-t border-gray-200 bg-gray-50 p-4 ">
            <div className={`flex flex-col mb-[10px] mr-[42px] ${!isColapse ? 'hidden' : ''}`}>
                <div className="flex items-center gap-[10px] mb-1.5 ">
                    <span className="text-gray-600 font-normal text-xs leading-[18px]">{t('Services')}</span>
                    <span className="border-b border-dashed border-gray-300 flex flex-1"></span>
                    <span className="text-gray-600 font-normal text-xs leading-[18px]">${appointmentCalulation.service ? appointmentCalulation.service.toFixed(2) : ''}</span>
                </div>
                {appointmentCalulation.product !== 0 && (
                    <div className="flex items-center gap-[10px] mb-1.5 ">
                        <span className="text-gray-600 font-normal text-xs leading-[18px]">{t('Product')}</span>
                        <span className="border-b border-dashed border-gray-300 flex flex-1"></span>
                        <span className="text-gray-600 font-normal text-xs leading-[18px]">${appointmentCalulation.product ? appointmentCalulation.product.toFixed(2) : ''}</span>
                    </div>
                )}
                {appointmentCalulation.taxes_and_fees !== 0 && (
                    <div className="flex items-center gap-[10px] mb-1.5">
                        <span className="text-gray-600 font-normal text-xs leading-[18px]">{t('Taxes')}</span>
                        <span className="border-b border-dashed border-gray-300 flex flex-1"></span>
                        <span className="text-gray-600 font-normal text-xs leading-[18px]">${appointmentCalulation.taxes_and_fees ? appointmentCalulation.taxes_and_fees.toFixed(2) : ''}</span>
                    </div>
                )}
                {appointmentCalulation.tips !== 0 && (
                    <div className="flex items-center gap-[10px] mb-1.5">
                        <span className="text-gray-600 font-normal text-xs leading-[18px]">
                            {t('Tips')} ({getPaymentType(calendarData.bookedSlotInfo?.tip_transaction[0]?.payment_method)})
                        </span>
                        <span className="border-b border-dashed border-gray-300 flex flex-1"></span>
                        <span className="text-gray-600 font-normal text-xs leading-[18px]">${appointmentCalulation.tips ? appointmentCalulation.tips.toFixed(2) : ''}</span>
                    </div>
                )}

                {calendarData.bookedSlotInfo.booking_transaction?.length > 0 &&
                    calendarData.bookedSlotInfo.booking_transaction?.map((transaction: any) => (
                        <div className="flex items-center gap-[10px] mb-1.5" key={transaction.id}>
                            <span className="text-gray-600 font-medium text-xs leading-[18px]">{getPaymentType(transaction.payment_method)}</span>
                            <span className="border-b border-dashed border-gray-300 flex flex-1"></span>
                            <span className="text-gray-600 font-medium text-xs leading-[18px]">${transaction.total_amount}</span>
                        </div>
                    ))}
            </div>
            <div className="flex items-center justify-between flex-1 gap-3">
                <div className="flex items-center justify-between w-full">
                    <span className="text-gray-900 text-sm font-medium leading-5">{t('Total')}</span>
                    {(calendarData.bookedSlotInfo.payment_option === 'pay_now' || calendarData.bookedSlotInfo.status === 'completed') && (
                        <span className="h-[22px] ml-2.5 flex items-center border border-success-200 bg-success-50 rounded-md gap-1 py-0.5 pr-1.5 pl-1 text-success-700 text-xs font-medium leading-[18px]">
                            <Check className="w-[14px] h-[14px] text-success-600" /> {t('Paid')}
                        </span>
                    )}

                    <span className="text-gray-900 text-sm font-medium leading-5 ml-auto">${appointmentCalulation.total ? appointmentCalulation.total?.toFixed(2) : ''}</span>
                </div>
                <div className="w-[30px] cursor-pointer" onClick={handleCollapse}>
                    {isColapse ? <ChevronUp className="w-[20px] text-gray-700 " /> : <ChevronDown className="w-[20px] text-gray-700" />}
                </div>
            </div>
        </div>
    );
};

export default CalulationDetails;
